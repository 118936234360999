import { IonItem, IonLabel, IonSkeletonText } from "@ionic/react";

export const PersonalDataSkeleton = () => {
  return (
    <div className="personal-data-content">
      <div className="">
        <IonLabel className="chargerNameTitle">
          <IonSkeletonText animated={true} style={{ width: "100px" }} />
        </IonLabel>
        <IonItem lines="full" className="ion-margin-top">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          </IonLabel>
        </IonItem>
        <IonItem lines="full" className="ion-margin-top">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          </IonLabel>
        </IonItem>
        <IonItem lines="full" className="ion-margin-top">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          </IonLabel>
        </IonItem>
        <IonItem lines="full" className="ion-margin-top">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          </IonLabel>
        </IonItem>
        <IonItem lines="full">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          </IonLabel>
        </IonItem>
        <IonItem lines="full">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          </IonLabel>
        </IonItem>
      </div>

      <div className="">
        <IonLabel className="chargerNameTitle">
          <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
        </IonLabel>
        <IonItem lines="full" className="ion-margin-top">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          </IonLabel>
        </IonItem>
        <IonItem lines="full" className="ion-margin-top">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
            <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          </IonLabel>
        </IonItem>
        <IonLabel className="chargerNameTitle">
          <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
          <IonSkeletonText animated={true} style={{ width: "180px", height: "20px" }} />
        </IonLabel>
      </div>
    </div>
  );
};
