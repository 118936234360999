import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";

export const HistoryTransactionSkeleton = () => {
  return (
    <IonItem lines="full">
      <IonGrid>
        <IonRow>
          <IonCol className="ion-no-padding" size="5">
            <IonLabel className="strong-text">
              <IonSkeletonText animated />
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-no-padding" size="3">
            <IonLabel>
              <IonSkeletonText animated />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
