import i18n from "@/assets/i18n/i18n";
import { useQuery } from "@/commons/hooks/useQuery";
import { dateDiff } from "@/commons/utils/dateUtils/dateUtils";
import { AppNotificationModel, AppNotificationObjectType } from "@/core/models/appNotificationModel";
import { putSeenNotification } from "@/core/services/user/userServices";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { buildPath } from "@/router/routerUtils";
import { APP_PATH } from "@/router/routes";
import { IonCol, IonItem, IonLabel, IonRow } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./NotificationCard.scss";

interface NotificationCardProps {
  notificationInfo: AppNotificationModel;
}

export const NotificationCard = ({ notificationInfo }: NotificationCardProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { searchParams } = useQuery();

  const { app_notifications_type_id, object_type, object_id } = notificationInfo;
  const createdAt: any = new Date(notificationInfo.created_at);

  const currentLanguage = i18n.language;
  const timeValue = dateDiff(createdAt, currentLanguage);

  const [seenNotification, setSeenNotification] = useState(notificationInfo.seen);

  const notificationClick = async (notificationInfo: AppNotificationModel) => {
    setSeenNotification(true);

    if (app_notifications_type_id === 10) {
      history.push(APP_PATH.WALLET);
      return;
    }

    if (object_type && object_id) {
      const routes: Record<AppNotificationObjectType, () => void> = {
        Location: () => handleGoToLocation(object_id),
        ChargerGraph: () => handleGoToPath(APP_PATH.CHARGES, object_id),
        SupportTicket: () => {
          history.push(buildPath(APP_PATH.INCIDENCE, { id: object_id }));
        },
        Reservation: () => handleGoToPath(APP_PATH.RESERVATIONS, object_id),
        MarketingNotification: () => openMarketingModal(object_id),
      };
      try {
        if (!notificationInfo.seen) putSeenNotification(notificationInfo.id);
      } finally {
        routes[object_type] && routes[object_type]();
      }
    }
  };

  const handleGoToPath = (path: string, object_id?: number) => {
    history.push(path, { state: object_id });
  };

  const openMarketingModal = (object_id: number) => {
    dispatch(uiActions.setIsMarketingModalOpen(true));
    dispatch(uiActions.setMarketingNotificationId(object_id));
  };

  const handleGoToLocation = (locationId: number) => {
    dispatch(mapActions.setSelectedLocationId(locationId));

    searchParams.set("locationId", locationId.toString());

    history.push({
      pathname: APP_PATH.HOME,
      search: searchParams.toString(),
    });
  };

  return (
    <IonItem
      className={!seenNotification ? "notification" : "notification-no-seen"}
      lines="full"
      onClick={() => notificationClick(notificationInfo)}
    >
      <IonRow className="notification-row">
        <IonCol>
          <IonLabel className="notification-title">{notificationInfo.message}</IonLabel>
          <IonLabel>{timeValue}</IonLabel>
        </IonCol>
      </IonRow>
    </IonItem>
  );
};
