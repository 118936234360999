import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { buildURLFilters } from "@/commons/utils/locationUtils/locationUtil";
import { getLocationsService } from "@/core/services/location/locationServices";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButton, IonContent, IonModal, IonRow, IonSpinner } from "@ionic/react";
import { t } from "i18next";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { FiltersChargeType } from "./FiltersChargeType/FiltersChargeType";
import { FiltersConnectorType } from "./FiltersConnectorType/FiltersConnectorType";
import { FiltersServices } from "./FiltersServices/FiltersServices";
import { FiltersToggles } from "./FiltersToggles/FiltersToggles";
import "./MapFilters.scss";

interface FilterProps {
  locationsInit: any;
}

export const MapFilters = ({ locationsInit }: FilterProps) => {
  const dispatch = useAppDispatch();
  const { setLogEvent } = useFirebaseAnalytics();

  const { parameters } = useParametersContext();

  const { showFilters, selectedFilters, activeFilters } = useAppSelector((state) => state.mapReducer.filters);
  const { user } = useAppSelector((state) => state.userReducer);

  const [numFilter, setNumFilter] = useState<any>(locationsInit.length);
  const [loadingFilters, setLoadingFilters] = useState(false);

  useEffect(() => {
    showFilters && handleViewCount();
  }, [showFilters, selectedFilters]);

  const handleDismissModal = () => {
    dispatch(mapActions.setShowFilters(false));
    dispatch(mapActions.setSelectedFilters(activeFilters));
  };

  const handleViewCount = async () => {
    try {
      setLoadingFilters(true);

      const urlFilters = buildURLFilters(selectedFilters) + "return_count=true&";
      const filteredChargers: any = await getLocationsService(parameters.token_api, user.auth_token, urlFilters);

      setNumFilter(filteredChargers?.Locations);
      setLoadingFilters(false);
    } catch (error: any) {
      if (error.name === "AbortError") {
        return;
      }

      dispatch(uiActions.setToastError(error));
      setNumFilter(0);
      setLoadingFilters(false);
    }
  };

  const handleDeleteFilters = () => {
    dispatch(mapActions.deleteActiveFilters());
    handleDismissModal();
  };

  const handleViewResults = async () => {
    setLogEvent("filter_map", selectedFilters);

    dispatch(mapActions.setActiveFilters(selectedFilters));
    dispatch(uiActions.setToast({ show: true, message: t("TOAST.FILTERS_SUCCESS"), color: "success" }));
    handleDismissModal();
  };

  return (
    <IonModal className="filters-modal" isOpen={showFilters} onDidDismiss={handleDismissModal}>
      <AppHeader
        className="ion-margin-bottom filters-modal-header"
        hiddenMenu
        hiddenTitle
        endIcon={<IconButton color="dark" icon={closeOutline} onClick={handleDismissModal} />}
      />

      <IonContent>
        <FiltersToggles />
        <FiltersServices />
        <FiltersChargeType />
        <FiltersConnectorType />
      </IonContent>
      <IonRow className="filter-buttons">
        <IonButton className="buttonFilter" fill="outline" color="dark" onClick={handleDeleteFilters}>
          {t("BUTTONS.DELETE_FILTERS")}
        </IonButton>
        <IonButton className="buttonFilter" onClick={() => !loadingFilters && handleViewResults()}>
          {!loadingFilters ? (
            `${t("BUTTONS.FILTER")} (${numFilter})`
          ) : (
            <IonSpinner name="lines-small" style={{ marginLeft: "2px" }} />
          )}
        </IonButton>
      </IonRow>
    </IonModal>
  );
};
