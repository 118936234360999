import { formatToMinutes } from "@/commons/utils/dateUtils/dateUtils";
import { ReservationModel } from "@/core/models/reservationModel";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { differenceInSeconds, format } from "date-fns";
import { useEffect, useState } from "react";
import { ActiveRervationDetail } from "./ActiveRervationDetail/ActiveRervationDetail";
import "./ActiveReservation.scss";
import { ActiveReservationData } from "./ActiveReservationData/ActiveReservationData";

interface ReservationActiveProps {
  reservation: ReservationModel;
}

export interface ReservationInfo {
  startHour: string;
  endHour: string;
  totalTime: number;
  remainingTime: number;
  remainingTimeFormat: string;
  completeTimePercentage: number;
}

export const ActiveReservation = ({ reservation }: ReservationActiveProps) => {
  const dispatch = useAppDispatch();

  const { activeReservations } = useAppSelector((state) => state.mapReducer);

  const [showDetail, setShowDetail] = useState(false);

  const [reservationInfo, setReservationInfo] = useState<ReservationInfo | undefined>();

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    loadData();
  }, [reservation]);

  useEffect(() => {
    intervalId && clearInterval(intervalId);

    const newIntervalId = setInterval(() => {
      const remainingTimes = getRemaingTime();

      if (remainingTimes.remainingTime <= 0) {
        clearInterval(newIntervalId);

        const newActiveReservations = activeReservations.filter(
          (activeReservations) => activeReservations.id !== reservation.id,
        );

        dispatch(mapActions.setActiveReservations(newActiveReservations));
        dispatch(uiActions.setToastType({ type: "reservationFinished" }));
      }

      reservationInfo &&
        setReservationInfo({
          ...reservationInfo,
          ...remainingTimes,
        });
    }, 1000);

    setIntervalId(newIntervalId);

    return () => {
      newIntervalId && clearInterval(newIntervalId);
    };
  }, [reservationInfo]);

  const getRemaingTime = () => {
    const now = new Date();

    const startDate = new Date(reservation.start_reservation);
    const endDate = new Date(reservation.end_reservation);

    const totalTime = differenceInSeconds(endDate, startDate);
    const completeTime = differenceInSeconds(now, startDate);
    let remainingTime = differenceInSeconds(endDate, now);
    let remainingTimeFormat = formatToMinutes(remainingTime);
    const completeTimePercentage = (completeTime * 100) / totalTime;

    if (completeTimePercentage >= 100) {
      remainingTime = 0;
      remainingTimeFormat = "--:--";
    }

    return {
      totalTime,
      remainingTime,
      remainingTimeFormat,
      completeTimePercentage,
    };
  };

  const loadData = () => {
    const startDate = new Date(reservation.start_reservation);
    const endDate = new Date(reservation.end_reservation);

    const remainingTimes = getRemaingTime();

    setReservationInfo({
      startHour: format(startDate, "HH:mm"),
      endHour: format(endDate, "HH:mm"),
      ...remainingTimes,
    });
  };

  const handleDissmisModal = () => {
    setShowDetail(false);
  };

  return (
    <>
      {reservationInfo && (
        <div className="active-reservation">
          <ActiveRervationDetail
            showDetail={showDetail}
            handleDissmisModal={handleDissmisModal}
            reservation={reservation}
            reservationInfo={reservationInfo}
          />
          <ActiveReservationData
            reservation={reservation}
            reservationInfo={reservationInfo}
            setShowDetail={setShowDetail}
          />
        </div>
      )}
    </>
  );
};
