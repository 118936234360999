import {
  Illustration11,
  Illustration12,
  Illustration16,
  Illustration17,
  Illustration2,
  Illustration3,
  Illustration4,
  Illustration5,
  Illustration7,
  Illustration9,
} from "@/commons/constants/imgs";
import { APP_PATH } from "@/router/routes";
import { t as translate } from "i18next";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";

export type AlertType =
  | "successInitCharge"
  | "alertRegister"
  | "tooFarCharger"
  | "maxCharges"
  | "stoppingCharge"
  | "successReservation"
  | "cancelReservation"
  | "rateLimitsExceeded"
  | "alertB2BOnly"
  | "disabledCharger"
  | "logout"
  | "badCredentials"
  | "error"
  | "deleteCreditCard"
  | "successAddBalance"
  | "errorAddBalance"
  | "chatbotDefaultLanguage"
  | "deleteUser"
  | "requiredFields"
  | "deleteVehicle"
  | "deleteFavoriteCharger"
  | "unsavedChanges"
  | "notificationSeenAll"
  | "cancelCharge"
  | "farChargers"
  | "satDisabled"
  | "infoButton"
  | "disableNearLocation"
  | "successActivePromotion";

export interface AlertTypeComponent {
  i18n_key?: string;
  illustration?: typeof Illustration3;
  title?: ReactNode;
  message?: ReactNode;
  cssClass?: string;
  primaryText?: string;
  primaryAction?: () => void;
  secondaryText?: string;
  secondaryAction?: () => void;
  hiddenSecondary?: boolean;
  closingTime?: number;
}

export interface AlertTypeOptions {
  message?: string;
  illustration?: typeof Illustration3;
  primaryAction?: () => void;
  hiddenSecondary?: boolean;
}

export const alertTypesComponent = (
  t: typeof translate,
  history: ReturnType<typeof useHistory>,
  options?: AlertTypeOptions,
): Record<AlertType, AlertTypeComponent> => ({
  successInitCharge: {
    illustration: Illustration3,
    i18n_key: "ALERT.SUCCESS_INIT_CHARGE",
  },
  alertRegister: {
    illustration: Illustration5,
    i18n_key: "ALERT.NO_LOGGED",
    primaryText: t("BUTTONS.REGISTER"),
    primaryAction: () => history.push(APP_PATH.LOGIN),
    hiddenSecondary: false,
  },
  tooFarCharger: {
    illustration: Illustration4,
    i18n_key: "ALERT.TOO_FAR",
    closingTime: 5,
  },
  maxCharges: {
    illustration: Illustration5,
    i18n_key: "ALERT.MAX_CHARGES",
    primaryText: t("BUTTONS.REGISTER"),
    primaryAction: () => history.push(APP_PATH.LOGIN),
    hiddenSecondary: false,
  },
  stoppingCharge: {
    illustration: Illustration2,
    i18n_key: "ALERT.STOPPING_CHARGE",
    primaryText: t("BUTTONS.STOP"),
    primaryAction: options?.primaryAction,
    hiddenSecondary: false,
  },
  successReservation: {
    illustration: Illustration3,
    title: t("ALERT.COMPLETED_BOOK_TITLE"),
    message: (
      <>
        {t("ALERT.COMPLETED_BOOK_TEXT")} <strong>{options?.message}</strong>
      </>
    ),
  },
  cancelReservation: {
    illustration: Illustration3,
    i18n_key: "ALERT.CANCEL_BOOK",
    secondaryText: t("ALERT_BUTTONS.NO_THANKS"),
    primaryText: t("ALERT_BUTTONS.NULL"),
    primaryAction: options?.primaryAction,
  },
  rateLimitsExceeded: {
    illustration: Illustration9,
    i18n_key: "ALERT.RATE_LIMITS_EXCEEDED",
  },
  alertB2BOnly: {
    illustration: Illustration2,
    i18n_key: "ALERT.B2B_ONLY",
  },
  disabledCharger: {
    illustration: Illustration2,
    title: t("ALERT.DISABLED_CHARGER_TITLE"),
    message: options?.message || t("ALERT.DISABLED_CHARGER_TEXT"),
  },
  logout: {
    illustration: Illustration2,
    i18n_key: "ALERT.LOG_OUT",
    primaryText: t("ALERT_BUTTONS.EXIT"),
    primaryAction: options?.primaryAction,
    hiddenSecondary: false,
  },

  badCredentials: {
    illustration: Illustration2,
    i18n_key: "ALERT.WRONG_CREDENTIALS",
  },
  error: {
    i18n_key: "ALERT.SERVER_ERROR",
  },
  deleteCreditCard: {
    illustration: Illustration12,
    title: t("ALERT.DELETE_CARD_TITLE"),
    message: (
      <>
        {t("ALERT.DELETE_CARD_TEXT")} <strong>{options?.message}</strong>
      </>
    ),
    primaryText: t("ALERT_BUTTONS.DELETE"),
    primaryAction: options?.primaryAction,
    hiddenSecondary: false,
  },
  successAddBalance: {
    illustration: Illustration12,
    title: t("ALERT.SUCCESS_ADD_WALLET_TITLE"),
    message: (
      <>
        {t("ALERT.SUCCESS_ADD_WALLET_TEXT1")}
        <strong>{options?.message}</strong>
        {t("ALERT.SUCCESS_ADD_WALLET_TEXT2")}
      </>
    ),
  },
  errorAddBalance: {
    illustration: Illustration12,
    i18n_key: "ALERT.ERROR_ADD_WALLET",
  },
  chatbotDefaultLanguage: {
    illustration: Illustration16,
    i18n_key: "ALERT.CHATBOT_DEFAULT_LANGUAGE",
    closingTime: 5,
  },
  deleteUser: {
    illustration: Illustration2,
    i18n_key: "ALERT.DELETE_USER",
    primaryText: t("ALERT_BUTTONS.DELETE"),
    primaryAction: options?.primaryAction,
    hiddenSecondary: false,
  },
  requiredFields: {
    illustration: Illustration2,
    i18n_key: "ALERT.REQUIRED_FIELDS",
  },
  deleteVehicle: {
    illustration: Illustration7,
    i18n_key: "ALERT.DELETE_VEHICLE",
    hiddenSecondary: false,
    primaryAction: options?.primaryAction,
  },
  deleteFavoriteCharger: {
    illustration: Illustration4,
    i18n_key: "ALERT.DELETE_FAV_CHARGER",
    hiddenSecondary: false,
    primaryAction: options?.primaryAction,
  },
  unsavedChanges: {
    illustration: Illustration2,
    i18n_key: "ALERT.UNSAVED_CHANGES",
    hiddenSecondary: false,
    primaryAction: options?.primaryAction,
  },
  notificationSeenAll: {
    title: t("NOTIFICATIONS.SEEN_ALL"),
    hiddenSecondary: false,
    primaryAction: options?.primaryAction,
  },
  cancelCharge: {
    illustration: Illustration2,
    i18n_key: "ALERT.CANCEL_CHARGE",
    primaryText: t("ALERT_BUTTONS.YES"),
    primaryAction: options?.primaryAction,
    hiddenSecondary: false,
    secondaryText: t("ALERT_BUTTONS.NO"),
  },
  farChargers: {
    illustration: Illustration4,
    i18n_key: "ALERT.FAR_CHARGERS",
    closingTime: 15,
  },
  satDisabled: {
    illustration: Illustration2,
    i18n_key: "ALERT.SAT_DISABLED",
    primaryText: t("ALERT_BUTTONS.INCIDENCE"),
    primaryAction: options?.primaryAction,
    hiddenSecondary: false,
    closingTime: 15,
  },
  infoButton: {
    i18n_key: `ALERT_INFO.${options?.message}`,
    primaryAction: options?.primaryAction,
    hiddenSecondary: options?.hiddenSecondary,
  },
  disableNearLocation: {
    illustration: Illustration11,
    i18n_key: "ALERT.DISABLE_NEAR_LOCATION",
    primaryText: t("BUTTONS.ACCEPT"),
    primaryAction: options?.primaryAction,
    hiddenSecondary: false,
  },
  successActivePromotion: {
    illustration: Illustration17,
    i18n_key: "ALERT.SUCCESS_ACTIVE_PROMOTION",
  },
});
