import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { useLocationContext } from "@/commons/hooks/useLocationContext";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { getRouteService } from "@/core/services/route/routeServices";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonRangeCustomEvent, IonSelectCustomEvent, RangeChangeEventDetail } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRange,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  SelectChangeEventDetail,
} from "@ionic/react";
import { batteryHalfOutline, carOutline, locateOutline, locationOutline, sendOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./RouteMapModal.scss";
import { SearchTermModal } from "./SearchTermModal/SearchTermModal";

export const RouteMapModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setLogEvent } = useFirebaseAnalytics();

  const { parameters } = useParametersContext();
  const { userLocation } = useLocationContext();

  const { userInfo, user } = useAppSelector((state) => state.userReducer);
  const {
    showRouteMenu,
    modal: { form },
  } = useAppSelector((state) => state.mapReducer.routeInfo);

  const [isLoading, setIsLoading] = useState(false);

  // Load predetermined vehicle
  useEffect(() => {
    if (userInfo) {
      let newForm = { ...form };
      const firstVehicle = userInfo.vehicles.find((vehicle) => vehicle.predetermined);
      newForm = {
        ...newForm,
        vehicle: firstVehicle || null,
      };

      if (userLocation.latitude !== null && userLocation.longitude !== null) {
        newForm = {
          ...newForm,
          from: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
            isUserLocation: true,
            name: t("ROUTE.LOCATION_NOW"),
          },
        };
      }

      dispatch(mapActions.setRouteForm(newForm));
    }
  }, [userInfo]);

  const handleChangeVehicle = (event: IonSelectCustomEvent<SelectChangeEventDetail<string | null>>) => {
    const newCardId = event.detail.value;

    const newVehicle = userInfo?.vehicles.find((vehicle) => vehicle.vehicle_id === newCardId);

    dispatch(
      mapActions.setRouteForm({
        ...form,
        vehicle: newVehicle || null,
      }),
    );
  };

  const handleChangeRange = (e: IonRangeCustomEvent<RangeChangeEventDetail>) => {
    const newRangePercentage = e.detail.value as number;
    dispatch(
      mapActions.setRouteForm({
        ...form,
        rangePercentage: newRangePercentage,
        rangeKm: (Number(form.vehicle?.range_km) || 500) * (newRangePercentage / 100),
      }),
    );
  };

  const handleClickCoord = (searchType: "from" | "to") => {
    dispatch(mapActions.setShowSearchModal({ showSearchModal: true, searchType }));
  };

  const handleSendRouteRequest = async () => {
    const { vehicle, rangeKm, from, to } = form;
    if (!vehicle || !rangeKm || !from || !to) {
      dispatch(uiActions.setToastError(t("ALERT.MISSING_VALUES")));
      return;
    }

    try {
      setIsLoading(true);
      const waypoints = await getRouteService(
        {
          autonomy: rangeKm,
          efficiency: 200,
          lat_o: from.latitude,
          lon_o: from.longitude,
          lat_d: to.latitude,
          lon_d: to.longitude,
          token_user: user.auth_token,
        },
        parameters.token_api,
      );

      dispatch(mapActions.setWaypoints({ waypoints, originIsUser: from.isUserLocation }));

      handleDismissSearchModal();
      dispatch(mapActions.setShowRoute(true));

      setLogEvent("search_route", {
        locationFrom: from,
        locationTo: to,
        vehicle: vehicle.vehicle_id,
        range: rangeKm,
        waypoints: waypoints.length,
      });
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDismissSearchModal = () => {
    dispatch(mapActions.setShowRouteMenu(false));
  };

  return (
    <>
      <IonLoading isOpen={isLoading} message={t("ROUTE.GENERATING_ROUTE")} />

      {userInfo && (
        <>
          <SearchTermModal />
          <IonModal
            isOpen={showRouteMenu}
            initialBreakpoint={0.7}
            breakpoints={[0, 0.7]}
            backdropDismiss={true}
            onDidDismiss={handleDismissSearchModal}
          >
            <IonContent className="ion-padding">
              <div className="route-map-modal">
                <IonRow className="beta">{parameters.beta_features && <StatusLabel active>BETA</StatusLabel>}</IonRow>
                <IonRow className="row">
                  <IonIcon size="large" icon={carOutline} />
                  <IonItem lines="none">
                    <IonSelect
                      label={t("TITLES.VEHICLE")}
                      labelPlacement="floating"
                      placeholder={t("TITLES.VEHICLE")}
                      onIonChange={handleChangeVehicle}
                      value={form.vehicle?.vehicle_id}
                      style={{ minWidth: "200px" }}
                    >
                      {userInfo.vehicles.map((vehicle) => (
                        <IonSelectOption key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                          {(vehicle.vehicle ? vehicle.vehicle : vehicle.vehicle_brand_name) +
                            " " +
                            (vehicle.model ? vehicle.model : vehicle.vehicle_model_name) +
                            " - " +
                            vehicle.car_id}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </IonRow>
                <IonRow className="row">
                  <IonRange
                    onIonChange={handleChangeRange}
                    value={form.rangePercentage}
                    style={{ margin: "0 25px", padding: "0" }}
                    min={0}
                    max={100}
                    pin={true}
                    pinFormatter={(value: number) => `${value}%`}
                  ></IonRange>
                </IonRow>
                <IonRow className="row">
                  <IonLabel style={{ display: "block", textAlign: "center", fontSize: "14px" }}>
                    <IonRow style={{ justifyContent: "center" }}>
                      <IonIcon size="large" icon={batteryHalfOutline}></IonIcon>
                    </IonRow>
                    <IonRow>{`${form.rangePercentage} % - ${form.rangeKm.toFixed(0)} km`}</IonRow>
                  </IonLabel>
                </IonRow>
                <div>
                  <IonRow className="row">
                    <IonIcon size="large" icon={locateOutline} />
                    <IonSearchbar
                      mode="ios"
                      style={{ width: "90%" }}
                      onIonCancel={() => dispatch(mapActions.setRouteForm({ ...form, from: null }))}
                      onClick={() => handleClickCoord("from")}
                      placeholder={t("PLACEHOLDERS.SEARCH")}
                      showClearButton="focus"
                      value={form.from?.name}
                    />
                  </IonRow>
                  <IonRow className="row">
                    <IonIcon size="large" icon={locationOutline} />
                    <IonSearchbar
                      mode="ios"
                      style={{ width: "90%" }}
                      onIonCancel={() => dispatch(mapActions.setRouteForm({ ...form, to: null }))}
                      onClick={() => handleClickCoord("to")}
                      placeholder={t("PLACEHOLDERS.SEARCH")}
                      showClearButton="focus"
                      value={form.to?.name}
                    />
                  </IonRow>
                </div>
                <IonRow className="row">
                  <IonButton onClick={handleSendRouteRequest} style={{ width: "50%" }}>
                    {t("TITLES.SEARCH_ROUTE")}
                    <IonIcon size="small" style={{ marginLeft: "10px" }} icon={sendOutline} />
                  </IonButton>
                </IonRow>
              </div>
            </IonContent>
          </IonModal>
        </>
      )}
    </>
  );
};
