import { useSlack } from "@/commons/hooks/useSlack";
import { PaymentIntentModel } from "@/core/models/PaymentModel";
import { createPaymentCustomerService } from "@/core/services/payment/paymentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButton, IonIcon, IonLabel, IonLoading } from "@ionic/react";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { t } from "i18next";
import { addCircleOutline } from "ionicons/icons";
import { useState } from "react";
import { AddCreditCardFeedback } from "../../AddCreditCardFeedback/AddCreditCardFeedback";
import "./AddCreditCard.scss";

interface AddCreditCardProps {
  stripe: Stripe;
  paymentIntent: PaymentIntentModel;
  selectPaymentId?: string;
  callbackAddCard: (paymentMethod: string, extraField: string) => void;
}

const inputStripStyle = {
  base: {
    fontSize: "16px",
    color: "#333",
  },
};

export const AddCreditCard = ({ stripe, paymentIntent, selectPaymentId, callbackAddCard }: AddCreditCardProps) => {
  const dispatch = useAppDispatch();
  const elements = useElements();
  const slack = useSlack();

  const { user, theme } = useAppSelector((state) => state.userReducer);

  if (theme === "dark") {
    inputStripStyle.base.color = "#fff";
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isCreditCardAdded, setIsCreditCardAdded] = useState(!!selectPaymentId);

  const handleAddCard = async () => {
    try {
      if (!stripe || !elements || !paymentIntent) {
        throw new Error(t("APP_ERRORS.INIT_PAYMENT"));
      }

      setIsLoading(true);

      const { error, setupIntent } = await stripe.confirmCardSetup(paymentIntent.setup_intent.client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement)!,
          billing_details: { name: `${user.user_id}` },
        },
      });

      if (error) throw error;

      const paymentMethod = setupIntent.payment_method as string;

      const { customer } = await createPaymentCustomerService(paymentMethod);

      callbackAddCard(paymentMethod, customer.id);

      setIsCreditCardAdded(true);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
      slack.error("handleAddCard", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="add-credit-card">
      <IonLoading isOpen={isLoading} />
      {!isCreditCardAdded ? (
        <>
          <IonLabel className="title-h3">{t("TITLES.INTRODUCE_CREDITCARD_DATA")}</IonLabel>

          <div className="add-credit-card-form">
            <div className="input-credit-card">
              <IonLabel>{t("CREDIT_CARD.CARD_NUMBER")}</IonLabel>
              <CardNumberElement options={{ showIcon: true, iconStyle: "solid", style: inputStripStyle }} />
            </div>
            <div className="input-row">
              <div className="input-credit-card">
                <IonLabel>{t("CREDIT_CARD.EXPIRATION_DATE")}</IonLabel>
                <CardExpiryElement options={{ style: inputStripStyle }} />
              </div>

              <div className="input-credit-card">
                <IonLabel>{t("CREDIT_CARD.CVC")}</IonLabel>
                <CardCvcElement options={{ style: inputStripStyle }} />
              </div>
            </div>
          </div>
          <IonButton className="add-credit-card-button" onClick={handleAddCard}>
            <IonIcon slot="start" icon={addCircleOutline}></IonIcon>
            {t("BUTTONS.ADD_CARD")}
          </IonButton>
        </>
      ) : (
        <AddCreditCardFeedback />
      )}
    </div>
  );
};
