import i18n from "@/assets/i18n/i18n";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { useLogoApp } from "@/commons/hooks/useLogoApp";
import { MarketingNotification, MarketingNotificationInfo } from "@/core/models/appNotificationModel";
import { getMarketingNotification } from "@/core/services/notifications/notificationService";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
} from "@ionic/react";
import { t } from "i18next";
import { closeOutline, notificationsOff } from "ionicons/icons";
import { useEffect, useState } from "react";
import "./AppMarketingModal.scss";

export const AppMarketingModal = () => {
  const dispatch = useAppDispatch();
  const { appLogo } = useLogoApp();

  const currentLanguage = i18n.language;

  const { isMarketingModalOpen, marketingNotificationId } = useAppSelector((state) => state.uiReducer);

  const [notification, setNotification] = useState<MarketingNotification | undefined>();
  const [notificationMessage, setNotificationMessage] = useState<MarketingNotificationInfo | undefined>();
  const [showMarketingModal, setShowMarketingModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const language_ids: Record<string, string> = {
    "1": "es",
    "2": "en",
    "3": "fr",
    "4": "pt",
    "5": "ca",
    "6": "de",
    "7": "ro",
  };

  useEffect(() => {
    marketingNotificationId && getMarketingNotificationInfo();
  }, [marketingNotificationId]);

  useEffect(() => {
    marketingNotificationId && isMarketingModalOpen && notification && setShowMarketingModal(true);
  }, [marketingNotificationId, isMarketingModalOpen, notification]);

  const getMarketingNotificationInfo = async () => {
    if (marketingNotificationId) {
      setIsLoading(true);
      try {
        const response = await getMarketingNotification(marketingNotificationId);
        if (response) {
          const language: any = Object.keys(language_ids).find((key) => language_ids[key] === currentLanguage);
          const filteredNotifications = response.notificationInfo.filter(
            (info) => info.language_id === Number(language),
          );
          if (filteredNotifications) {
            setNotification(response);
            setNotificationMessage(filteredNotifications[0]);
          }
        }
      } catch (e) {
        console.error("Error loading notification info", e);
        setShowMarketingModal(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const dismissMarketingModal = async () => {
    setShowMarketingModal(false);
    setNotification(undefined);
    dispatch(uiActions.setIsMarketingModalOpen(false));
    dispatch(uiActions.setMarketingNotificationId(undefined));
  };

  return (
    <>
      <IonLoading isOpen={isLoading} message={t("LOADING.LOADING") ?? ""} duration={4000}></IonLoading>
      <IonModal
        initialBreakpoint={notification && notificationMessage ? 1 : 0.75}
        isOpen={showMarketingModal}
        onDidDismiss={dismissMarketingModal}
      >
        <AppHeader
          hiddenMenu
          hiddenTitle
          endIcon={<IconButton icon={closeOutline} color="dark" onClick={dismissMarketingModal} />}
        />
        {notification && notificationMessage ? (
          <>
            <IonContent className="publi-modal">
              <IonCol>
                <IonImg className="publi-modal-img" src={notification.notification_image || appLogo} />
                <div className="publi-modal-title">
                  <IonLabel>{notificationMessage.translation}</IonLabel>
                </div>
                <div className="publi-modal-text">
                  <IonRow>
                    <IonCol>
                      <div dangerouslySetInnerHTML={{ __html: notificationMessage.translation2 || "" }} />
                    </IonCol>
                  </IonRow>
                </div>
              </IonCol>
            </IonContent>
            {notification.notification_link && (
              <IonFooter className="publi-modal-footer ion-no-border">
                <IonButton className="publi-modal-button" onClick={() => window.open(notification.notification_link)}>
                  {t("BUTTONS.MORE")}
                </IonButton>
              </IonFooter>
            )}
          </>
        ) : (
          <IonContent>
            <div className="no-notification">
              <IonRow>
                <IonIcon src={notificationsOff} className="icon" />
              </IonRow>
              <IonRow>
                <IonLabel className="no-notification-text">{t("ERRORS.NO_NOTIFICATION")}</IonLabel>
              </IonRow>
            </div>
          </IonContent>
        )}
      </IonModal>
    </>
  );
};
