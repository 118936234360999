import { mapActions } from "@/core/store/slices/mapSlice";
import { useAppDispatch } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { useHistory } from "react-router";
import { useQuery } from "./useQuery";

export const useOpenLocation = () => {
  const dispatch = useAppDispatch();
  const { searchParams } = useQuery();
  const history = useHistory();

  const openLocation = (locationId: number, chargerId?: number, socketNumber?: number) => {
    dispatch(mapActions.setSelectedLocationId(locationId));

    searchParams.set("locationId", locationId.toString());
    chargerId && searchParams.set("chargerId", chargerId.toString());
    socketNumber && searchParams.set("socketNumber", socketNumber.toString());

    history.replace({
      pathname: APP_PATH.HOME,
      search: searchParams.toString(),
    });
  };

  return { openLocation };
};
