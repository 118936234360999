import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { getURLDomain } from "@/commons/utils/apiUtils/apiUtils";
import { useAppSelector } from "@/core/store/store";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { IonLoading } from "@ionic/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { AddCreditCardFeedback } from "../AddCreditCardFeedback/AddCreditCardFeedback";

interface AddCreditCardModalProps {
  selectPaymentId?: string;
  callbackAddCard: (paymentMethod: string, extraField: string) => void;
}

export const AddCreditCardModal = ({ selectPaymentId, callbackAddCard }: AddCreditCardModalProps) => {
  const {
    parameters: { payment_type },
  } = useParametersContext();

  const { loadUserInfo } = useUserContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isCreditCardAdded, setIsCreditCardAdded] = useState(!!selectPaymentId);
  const {
    user: { user_id },
    userInfo,
  } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    openSite();

    const listenerPromises = App.addListener("resume", handleBrowserFinished);

    return () => {
      listenerPromises.then((listener) => listener.remove());
    };
  }, [payment_type]);

  const openSite = async () => {
    if (selectPaymentId) return;

    let url = "";

    if (payment_type.includes("redsys")) {
      url = getURLDomain(`api/v1/redsys/load_form_add_card_redsys`, { user_id }, { isRootPath: true });
    }

    Browser.open({ url });
  };

  const handleBrowserFinished = async () => {
    try {
      setIsLoading(true);
      const newUserInfo = await loadUserInfo();

      if (!newUserInfo) throw new Error(t("APP_ERRORS.LOAD_USER_INFO"));

      // Check if the payment method was added
      if (newUserInfo.stripe_payment_methods.length <= (userInfo?.stripe_payment_methods.length || 0)) {
        throw new Error(t("APP_ERRORS.ADD_PAYMENT"));
      }

      const lastPaymentMethod = newUserInfo.stripe_payment_methods.at(-1);

      if (!lastPaymentMethod) throw new Error(t("APP_ERRORS.GET_PAYMENT"));

      const { payment_method, id } = lastPaymentMethod;

      setIsCreditCardAdded(true);
      callbackAddCard(payment_method, id);
    } catch (error) {
      console.error(error);
      setIsCreditCardAdded(false);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <IonLoading isOpen={isLoading} />
      {!isLoading && <AddCreditCardFeedback isCreditCardAdded={isCreditCardAdded} handleRetry={openSite} />}
    </>
  );
};
