import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { VehicleModel } from "@/core/models/userModel";
import { IonCol, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import "./VehicleCard.scss";

interface VehicleCardProps {
  vehicle: VehicleModel;
  active?: boolean;
  highlightBorder?: boolean;
  onClick?: (vehicle: VehicleModel) => void;
}

export const VehicleCard = ({ vehicle, highlightBorder, active, onClick }: VehicleCardProps) => {
  return (
    <div
      className={`vehicle-card ${classIf(!!highlightBorder || !!active, "highlight-border")} ${classIf(
        !!active,
        "vehicle-active",
      )}`}
      onClick={() => {
        onClick && onClick(vehicle);
      }}
    >
      <IonRow className="vehicle-card-info-row">
        <IonCol className="modalColumn">
          <IonLabel className="vehicleInfoLabel">{t("CAR.LICENSE_PLATE")}</IonLabel>
          <IonLabel className="labelData">{vehicle.car_id}</IonLabel>
        </IonCol>
        <IonCol className="modalColumn">
          <IonLabel className="vehicleInfoLabel">{t("CAR.BRAND")}</IonLabel>
          <IonLabel className="labelData">{vehicle.vehicle_brand_name || vehicle.vehicle}</IonLabel>
        </IonCol>
        <IonCol className="modalColumn">
          <IonLabel className="vehicleInfoLabel">{t("CAR.MODEL")}</IonLabel>
          <IonLabel className="labelData">{vehicle.vehicle_model_name || vehicle.model}</IonLabel>
        </IonCol>
      </IonRow>
      {vehicle.predetermined && (
        <IonRow style={{ padding: "5px" }}>
          <StatusLabel active>{t("VEHICLES.DEFAULT_VEHICLE")}</StatusLabel>
        </IonRow>
      )}
    </div>
  );
};
