import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { SetState } from "@/core/models/globalModel";
import { IonIcon, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface FirstStepProps {
  values: {
    email: string;
    password: string;
    confirmPassword: string;
  };
  emailGuest: string;
  emailExist: boolean;
  setEmailExist: SetState<boolean>;
  onStringsReceived: (userEmail: string, userPassword: string, confirmPassword: string) => void;
}

export const FirstStep = ({ values, onStringsReceived, emailGuest, emailExist, setEmailExist }: FirstStepProps) => {
  const { t } = useTranslation();
  const { getLabel } = useIsValidUser();

  const [email, setEmail] = useState(values.email);
  const [password, setPassword] = useState(values.password);
  const [confirmPassword, setConfirmPassword] = useState(values.confirmPassword);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordsNotMatchError, setPasswordsNotMatchError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = ({ detail: { value } }: CustomEvent) => {
    const regEx = /^\S+@\S+\.\S+$/;

    setEmail(value);
    setEmailError(!regEx.test(value));
    setEmailExist(false);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = ({ detail: { value } }: CustomEvent) => {
    setPassword(value);
    setPasswordError(value.length < 8);
    setPasswordsNotMatchError(value !== confirmPassword);
  };

  const handleConfirmPasswordChange = ({ detail: { value } }: CustomEvent) => {
    setConfirmPassword(value);
    setPasswordsNotMatchError(value !== password);
  };

  useEffect(() => {
    if (emailGuest) setEmail(emailGuest);
  }, [emailGuest]);

  useEffect(() => {
    onStringsReceived(email, password, confirmPassword);
  }, [email, password, confirmPassword]);

  return (
    <>
      <IonRow className="ion-margin-bottom">
        <IonLabel className="neutralBlack70">
          {t("STEPS.STEP")} <IonLabel className="number number-bold">1</IonLabel> {t("STEPS.OUT_OF")}{" "}
          <IonLabel className="number number-bold">6</IonLabel>
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel className="titleH3">{t("TITLES.REGISTRATION")}</IonLabel>
      </IonRow>

      <IonItem>
        <IonInput
          label={getLabel("email")}
          labelPlacement="floating"
          type="text"
          value={email}
          onIonInput={handleEmailChange}
        />
      </IonItem>

      {emailError && <IonLabel className="formError">{t("ERRORS.EMAIL_ERROR")}</IonLabel>}
      {emailExist && <IonLabel className="formError">{t("ERRORS.EMAIL_EXIST")}</IonLabel>}

      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("password")}
          labelPlacement="floating"
          type={showPassword ? "text" : "password"}
          value={password}
          onIonInput={handlePasswordChange}
        />
        <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} onClick={toggleShowPassword} slot="end" />
      </IonItem>
      {passwordError && <IonLabel className="formError bottom">{t("ERRORS.PASSWORD_ERROR")}</IonLabel>}

      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("confirm_password")}
          labelPlacement="floating"
          type="password"
          value={confirmPassword}
          onIonInput={handleConfirmPasswordChange}
        />
      </IonItem>

      {passwordsNotMatchError && <IonLabel className="formError bottom">{t("ERRORS.CONFIRM_ERROR")}</IonLabel>}
    </>
  );
};
