import { IonLabel, IonList, IonRow, IonSkeletonText } from "@ionic/react";

export const IncidenceSkeleton = () => {
  return (
    <>
      <div className="incidence-info">
        <div className="title-container">
          <IonLabel>
            <IonSkeletonText animated={true} style={{ width: "120px", height: "24px" }} />
          </IonLabel>
          <IonRow>
            <IonSkeletonText animated={true} style={{ width: "60px" }} />
          </IonRow>
        </div>
        <IonLabel>
          <IonSkeletonText animated={true} style={{ width: "120px", height: "24px" }} />
        </IonLabel>
      </div>
      <IonList className="incidence-comments">
        <IonSkeletonText animated={true} style={{ alignSelf: "flex-end", width: "60%", height: "100px" }} />
        <IonSkeletonText animated={true} style={{ alignSelf: "flex-end", width: "60%", height: "100px" }} />
        <IonSkeletonText animated={true} style={{ alignSelf: "flex-start", width: "80%", height: "200px" }} />
        <IonSkeletonText animated={true} style={{ alignSelf: "flex-end", width: "60%", height: "100px" }} />
        <IonSkeletonText animated={true} style={{ alignSelf: "flex-start", width: "80%", height: "100px" }} />
      </IonList>
    </>
  );
};
