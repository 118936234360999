import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { formatNumber } from "@/commons/utils/numberUtils";
import { getPriceReservationService } from "@/core/services/reservation/reservationServices";
import { reservationStepsActions } from "@/core/store/slices/reservationStepsSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonGrid, IonIcon, IonLabel, IonRange, IonRow, IonSpinner } from "@ionic/react";
import { t } from "i18next";
import { informationCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import "./ReservationStepChoose.scss";

export const ReservationStepChoose = () => {
  const dispatch = useAppDispatch();

  const { parameters } = useParametersContext();

  const { time, price, isLoadingPrice } = useAppSelector((state) => state.reservationStepsReducer.chooseStep);
  const { selectedSocket } = useAppSelector((state) => state.mapReducer.selectedLocation);

  const maxTime = Number(selectedSocket?.max_time_min_reservation) || 240;
  const isFreeReservation = selectedSocket?.price_time_min_reservation === "0";

  const [timeInput, setTimeInput] = useState(time);

  useEffect(() => {
    dispatch(reservationStepsActions.setNextStepDisabled(!time));
  }, [time]);

  const handleInputTime = (event: CustomEvent) => {
    const newTime = event.detail.value || 0;

    setTimeInput(newTime);
  };

  const handleChangeTime = async (event: CustomEvent) => {
    const newTime = event.detail.value || 0;
    if (!newTime) {
      dispatch(
        reservationStepsActions.setChooseStep({
          time: 0,
          price: 0,
        }),
      );
      return;
    }

    if (selectedSocket) {
      try {
        dispatch(reservationStepsActions.setChooseStepIsLoadingPrice(true));

        const newPrice = await getPriceReservationService(selectedSocket.id_socket, newTime);

        dispatch(
          reservationStepsActions.setChooseStep({
            time: newTime,
            price: newPrice,
          }),
        );
      } catch (error) {
        dispatch(uiActions.setToastError(error));
      } finally {
        dispatch(reservationStepsActions.setChooseStepIsLoadingPrice(false));
      }
    }
  };

  return (
    <IonGrid className="reservation-step-choose ion-margin">
      <IonRow className="reservation-step-choose-time">
        <IonLabel className="title-h3">{t("TITLES.TIME_BOOK")}</IonLabel>
      </IonRow>
      <IonRow className="reservation-step-choose-time">
        <div className="time-label-container">
          {timeInput < 60 ? (
            <>{timeInput} m</>
          ) : (
            <>
              {Math.floor(timeInput / 60)} h {timeInput % 60} m
            </>
          )}
        </div>
      </IonRow>
      <IonRow className="ion-margin-horizontal">
        <IonRange
          onIonInput={handleInputTime}
          onIonChange={handleChangeTime}
          value={timeInput}
          min={0}
          max={maxTime}
          ticks
          snaps
          step={maxTime <= 60 ? 5 : 10}
          mode="ios"
          disabled={!maxTime || isLoadingPrice}
        />
      </IonRow>
      <div className="charge-step-choose-info">
        <IonRow className="price-row">
          <IonRow>
            <IonIcon icon={informationCircleOutline} />
            <IonLabel>
              <strong>{t("TITLES.TOTAL")}</strong>
            </IonLabel>
          </IonRow>
          <IonRow>
            {isFreeReservation ? (
              <StatusLabel>{t("CHARGER.FREE")}</StatusLabel>
            ) : isLoadingPrice ? (
              <IonSpinner className="price-loading" name="dots" />
            ) : (
              <IonLabel>
                {formatNumber(price, { minimumFractionDigits: 2 })} {parameters.currency}
              </IonLabel>
            )}
          </IonRow>
        </IonRow>
      </div>
    </IonGrid>
  );
};
