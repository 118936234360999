import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { SupportTicketModel, SupportTicketTypeModel } from "@/core/models/incidenceModel";

export const getUserIncidencesService = (page: number): Promise<{ support_tickets: SupportTicketModel[] }> => {
  return api
    .get(`support_tickets/list`, {
      page: page,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const getIncidenceService = (id: number): Promise<{ support_ticket: SupportTicketModel }> => {
  return api.get(`support_tickets/show/${id}`).then(resolveResponse).catch(rejectError);
};

export const getIncidenceTypesService = (language: string): Promise<SupportTicketTypeModel[]> => {
  return api.get(`support_tickets/types`, { language }).then(resolveResponse).catch(rejectError);
};

export const createTicketService = async (body: {
  user_id: number;
  type_support_ticket_id: number;
  charger_id: string;
  comment: string;
  image_id: number | null;
}): Promise<{ support_ticket: SupportTicketModel }> => {
  return api.post(`support_tickets/create`, undefined, body).then(resolveResponse).catch(rejectError);
};

export const addIncidenceCommentService = async (body: {
  user_id: number;
  support_ticket_id: string;
  comment: string;
  image_id: number | null;
}): Promise<{ comment_support_ticket: SupportTicketModel }> => {
  return api.post(`comment_support_ticket/create`, undefined, body).then(resolveResponse).catch(rejectError);
};
