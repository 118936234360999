import { SelectInput } from "@/commons/components/Form/SelectInput/SelectInput";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { SetState } from "@/core/models/globalModel";
import { CountryModel, UserInfoModel } from "@/core/models/userModel";
import { getCountryStatesUserService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonInput, IonItem, IonLabel } from "@ionic/react";
import { t } from "i18next";
import { TargetFormType } from "../PersonalDataPage";

interface AddressInputsProps {
  showErrors: boolean;
  userInfoTemp: UserInfoModel;
  countries: CountryModel[];
  handleChangeForm: (target: TargetFormType) => void;
}
export const AddressInputs = ({ showErrors, userInfoTemp, countries, handleChangeForm }: AddressInputsProps) => {
  const dispatch = useAppDispatch();
  const { isEmpty, getLabel, checkRequiredFields } = useIsValidUser();

  const getCountryStates = async (setLoading: SetState<boolean>) => {
    if (userInfoTemp.country) {
      try {
        setLoading(true);
        const newStates = await getCountryStatesUserService(userInfoTemp.country);
        return newStates.map((state) => ({ value: `${state.id || ""}`, label: state.name }));
      } catch (error) {
        console.error(error);
        dispatch(uiActions.setToastType({ type: "error" }));
        return [];
      } finally {
        setLoading(false);
      }
    }
    return [];
  };

  return (
    <div className="address">
      <IonLabel className="title-h3">{t("PROFILE.UBICACIO")}</IonLabel>
      <SelectInput
        name="country"
        className="ion-margin-top"
        label={getLabel("country")}
        placeholder={getLabel("country", "placeholder")}
        isClearable
        loadData={async () =>
          countries.map((country) => ({ value: country.id, label: `${country.flag} ${country.name}` }))
        }
        currentValue={userInfoTemp.country}
        onIonChange={(event) => handleChangeForm(event.target)}
      />
      {checkRequiredFields("country") && isEmpty(userInfoTemp.country) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <SelectInput
        name="state"
        className="ion-margin-top"
        label={getLabel("state")}
        placeholder={getLabel("state", "placeholder")}
        isClearable
        loadData={getCountryStates}
        loadDependencies={[userInfoTemp.country]}
        currentValue={userInfoTemp.state}
        onIonChange={(event) => handleChangeForm(event.target)}
      />
      {checkRequiredFields("state") && isEmpty(userInfoTemp.state) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full" className="ion-margin-top">
        <IonInput
          name="ubicacio"
          label={getLabel("ubicacio")}
          labelPlacement="floating"
          onIonInput={(event) => handleChangeForm(event.target)}
          type="text"
          value={userInfoTemp.ubicacio}
        />
      </IonItem>
      {checkRequiredFields("ubicacio") && isEmpty(userInfoTemp.ubicacio) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full" className="ion-margin-top">
        <IonInput
          name="poblacio"
          label={getLabel("poblacio")}
          labelPlacement="floating"
          onIonInput={(event) => handleChangeForm(event.target)}
          type="text"
          value={userInfoTemp.poblacio}
        />
      </IonItem>
      {checkRequiredFields("poblacio") && isEmpty(userInfoTemp.poblacio) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full" className="ion-margin-top">
        <IonInput
          name="cp"
          label={getLabel("cp")}
          labelPlacement="floating"
          onIonInput={(event) => handleChangeForm(event.target)}
          type="text"
          value={userInfoTemp.cp}
        />
      </IonItem>
      {checkRequiredFields("cp") && isEmpty(userInfoTemp.cp) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
    </div>
  );
};
