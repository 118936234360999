import keychainBanner from "@/assets/imgs/keychain-banner_2.png";
import { formatNumber } from "@/commons/utils/numberUtils";
import { MerchantProductModel } from "@/core/models/accountMerchantModel";
import { useAppSelector } from "@/core/store/store";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonText } from "@ionic/react";
import { ShopProductButtons } from "../ShopProductButtons/ShopProductButtons";
import "./ShopProduct.scss";

interface ShopProductProps {
  product: MerchantProductModel;
}

export const ShopProduct = ({ product }: ShopProductProps) => {
  const { user_id } = useAppSelector((state) => state.userReducer.user);
  const { shop } = useAppSelector((state) => state.shopReducer);

  const userCart = shop[user_id] || [];

  const price = product.price_ud / 100;
  const discount = product.discount / 100;
  const discountPrice = price - discount;

  const productCard = userCart.find((item) => item.id === product.id);

  return (
    <IonCard className="shop-product">
      <img src={product.image_url || keychainBanner} alt="shop product" />
      <div className="shop-product-content">
        <IonCardHeader mode="md">
          <IonCardTitle>{product.name}</IonCardTitle>
          <IonCardSubtitle className="shop-product-description ellipsis-4">{product.description}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent className="shop-product-info">
          {!product.discount ? (
            <IonText className="shop-product-price">
              {formatNumber(price, { minimumFractionDigits: 2 })} {product.currency}
            </IonText>
          ) : (
            <div className="shop-product-discount">
              <IonText color="primary">
                {formatNumber(discountPrice, { minimumFractionDigits: 2 })} {product.currency}
              </IonText>
              <IonText className="shop-product-price-out">
                {formatNumber(price, { minimumFractionDigits: 2 })} {product.currency}
              </IonText>
            </div>
          )}
          <ShopProductButtons product={product} productCart={productCard} size="medium" />
        </IonCardContent>
      </div>
    </IonCard>
  );
};
