import illustration1 from "@/assets/imgs/illustrations/illustration-1.svg";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { updateUserDomainRateApp } from "@/core/services/user/userDomainsServices";
import { createAppFeedbackService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { InAppReview } from "@capacitor-community/in-app-review";
import { IonIcon, IonLabel, IonTextarea } from "@ionic/react";
import { t } from "i18next";
import { star, starOutline } from "ionicons/icons";
import { useState } from "react";
import { Trans } from "react-i18next";
import Rating, { RatingComponentProps } from "react-rating";
import { AlertModal } from "../AlertModal/AlertModal";
import "./AppFeedbackModals.scss";

const { VITE_APP_NAME } = import.meta.env;

const RatingComponent = Rating as unknown as (props: RatingComponentProps) => JSX.Element;

export const AppFeedbackModals = ({ ...props }: RatingComponentProps) => {
  const dispatch = useAppDispatch();

  const { parameters } = useParametersContext();
  const { isRateAppModalOpen } = useAppSelector((state) => state.uiReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  const [openModals, setOpenModals] = useState({
    isOpenNegative: false,
    isOpenPositive: false,
  });

  const [rateStarts, setRateStarts] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [sendingFeedback, setSendingFeedback] = useState(false);

  const handleClickOption = (isPositive = true) => {
    setOpenModals({
      isOpenNegative: !isPositive,
      isOpenPositive: isPositive,
    });

    dispatch(uiActions.setIsRateAppModalOpen(false));
  };

  const handleSendFeedback = () => {
    if (rateStarts >= 4) {
      handleOpenRateApp();
    } else {
      handleClickOption(false);
    }
  };

  const handleSendInternalRate = async () => {
    try {
      setSendingFeedback(true);
      await createAppFeedbackService(feedback, parameters.id);
      dispatch(uiActions.setToast({ show: true, message: t("ALERT.FEEDBACK_SENT"), color: "success" }));
      setOpenModals({
        isOpenNegative: false,
        isOpenPositive: false,
      });

      updateUserDomainRateApp(user.user_id).catch((error) => {
        dispatch(uiActions.setToastError(error));
      });
    } catch (error) {
      setSendingFeedback(false);
      dispatch(uiActions.setToastError(error));
    }
  };

  const handleOpenRateApp = async () => {
    try {
      InAppReview.requestReview();
      await updateUserDomainRateApp(user.user_id);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    }
  };

  const handleDontSendFeedback = async () => {
    try {
      await updateUserDomainRateApp(user.user_id, true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AlertModal
        isOpen={isRateAppModalOpen}
        primaryAction={handleSendFeedback}
        primaryText={t("ALERT_BUTTONS.SEND")}
        primaryDisabled={!rateStarts}
        secondaryAction={handleDontSendFeedback}
        secondaryText={t("ALERT_BUTTONS.NO_THANKS")}
        handleDismiss={() => {
          dispatch(uiActions.setIsRateAppModalOpen(false));
          setRateStarts(0);
        }}
      >
        <>
          <img className="alert-modal-image" src={illustration1} alt="illustration" />
          <IonLabel class="alert-modal-title">{t("ALERT.APP_FEEDBACK_REQUEST", { appName: VITE_APP_NAME })}</IonLabel>
          <IonLabel>
            <Trans i18nKey={"ALERT.APP_FEEDBACK_REQUEST_TEXT"} />
          </IonLabel>

          <RatingComponent
            {...props}
            initialRating={rateStarts}
            onChange={(value: number) => setRateStarts(value)}
            emptySymbol={<IonIcon className="rate-start" icon={starOutline} />}
            fullSymbol={<IonIcon className="rate-start" icon={star} />}
          />
        </>
      </AlertModal>
      {openModals.isOpenNegative && (
        <AlertModal
          isOpen={openModals.isOpenNegative}
          primaryAction={handleSendInternalRate}
          primaryText={t("ALERT_BUTTONS.SEND")}
          primaryDisabled={!feedback}
          secondaryAction={handleOpenRateApp}
          secondaryText={t("ALERT_BUTTONS.NO_THANKS")}
          isLoadingProcess={sendingFeedback}
        >
          <>
            <img className="alert-modal-image" src={illustration1} alt="illustration" />
            <IonLabel class="alert-modal-title">{t("ALERT.APP_FEEDBACK")}</IonLabel>
            <IonLabel class="alert-modal-text">{t("ALERT.APP_FEEDBACK_TEXT")}</IonLabel>
            <IonTextarea
              className="alert-modal-textarea"
              mode="md"
              fill="outline"
              placeholder={t("ALERT.APP_FEEDBACK_PLACEHOLDER")}
              value={feedback}
              onIonInput={(e) => setFeedback(e.detail.value || "")}
            />
          </>
        </AlertModal>
      )}
    </>
  );
};
