import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { ErrorModel } from "@/core/models/apiModel";
import { sendSlackMessageService } from "@/core/services/slack/slackServices";
import { useAppSelector } from "@/core/store/store";

const { VITE_PACKAGE_VERSION, VITE_HOST, MODE, VITE_FORCE_SLACK } = import.meta.env;

const enableSlack = MODE === "production" || VITE_FORCE_SLACK === "true";

const ERROR_COLOR = "#E33A43";
const INFO_COLOR = "#3AA3E3";
const WARNING_COLOR = "#F0E73E";

export const useSlack = () => {
  const { parameters } = useParametersContext();
  const { user, userInfo } = useAppSelector((state) => state.userReducer);

  const sendMessage = async (message: string, color?: string) => {
    if (enableSlack) {
      try {
        await sendSlackMessageService(message, color);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getAppInfo = () =>
    `\t*User*: ${user.user_id} - ${userInfo?.email} \n` +
    `\t*Version*: ${VITE_PACKAGE_VERSION || "4.0"} \n` +
    `\t*Domain*: ${parameters.domain_id} - ${parameters.code} - ${VITE_HOST}\n`;

  const error = async (func: string, error: unknown) => {
    const errorPayload = error as ErrorModel;

    let errorMessage = errorPayload.message;

    if (errorPayload.jsonError) {
      const { status_code, error_code, status_message, error_message } = errorPayload.jsonError;
      const codeError = status_code || error_code;

      if (codeError === "-8") {
        return;
      }

      errorMessage = `${codeError ? codeError + " -> " : ""} ${status_message || error_message || errorMessage}`;
    }

    sendMessage(`Error: \n ${getAppInfo()} \t${func} | *${errorMessage || "No message"}*`, ERROR_COLOR);
  };

  const info = async (message: string) => {
    sendMessage(`Info: \n ${getAppInfo()} \t*${message}*`, INFO_COLOR);
  };

  const warning = async (message: string) => {
    sendMessage(`Warning: \n ${getAppInfo()} \t*${message}*`, WARNING_COLOR);
  };

  return {
    error,
    info,
    warning,
  };
};
