import { routes } from "@/router/routes";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Capacitor } from "@capacitor/core";
import { useMemo } from "react";
import { useSlack } from "./useSlack";

export const useFirebaseAnalytics = () => {
  const slack = useSlack();

  const validPlatform = useMemo(() => Capacitor.getPlatform() !== "web", [Capacitor.getPlatform()]);

  const setUserAnalytics = async (userId: number) => {
    if (validPlatform) {
      try {
        await FirebaseAnalytics.setUserId({
          userId: `${userId}`,
        });
      } catch (error) {
        slack.error("setUserAnalytics", error);
      }
    }
  };

  const setScreenAnalytics = async (currentPath: string) => {
    if (validPlatform) {
      try {
        const route = routes.find((route) => currentPath.includes(route.path));

        await FirebaseAnalytics.setScreenName({
          screenName: currentPath,
          nameOverride: route?.component.name || "unknown",
        });
      } catch (error) {
        slack.error("setScreenAnalytics", error);
      }
    }
  };

  const setLogEvent = (eventName: string, params?: unknown) => {
    if (validPlatform) {
      FirebaseAnalytics.logEvent({
        name: eventName,
        params: params || {},
      });
    }
  };

  const setLanguageAnalytics = async (language: string) => {
    if (validPlatform) {
      try {
        await FirebaseAnalytics.setUserProperty({
          name: "language",
          value: language,
        });
      } catch (error) {
        slack.error("setLanguageAnalytics", error);
      }
    }
  };

  return {
    setUserAnalytics,
    setScreenAnalytics,
    setLogEvent,
    setLanguageAnalytics,
  };
};
