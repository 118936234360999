import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { AccountMerchantModel } from "@/core/models/accountMerchantModel";
import { getAccountMerchantsService } from "@/core/services/shop/shopService";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonAccordionGroup, IonContent, IonIcon, IonLabel, IonList, IonLoading, IonModal, IonRow } from "@ionic/react";
import { t } from "i18next";
import { bagCheckOutline, chevronCollapseOutline, chevronExpandOutline } from "ionicons/icons";
import { orderBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import { ShopHistoryAccountMerchant } from "./ShopHistoryAccountMerchant";
import { ShopHistoryAccountMerchantSkeleton } from "./ShopHistoryAccountMerchantSkeleton";

export const ShopHistoryModal = () => {
  const dispatch = useAppDispatch();
  const { isShopHistoryOpen } = useAppSelector((state) => state.uiReducer);

  const accordionGroupRef = useRef<null | HTMLIonAccordionGroupElement>(null);

  const [accountMerchants, setAccountMerchants] = useState<AccountMerchantModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [collapseAll, setCollapseAll] = useState(true);

  useEffect(() => {
    isShopHistoryOpen && loadData();
  }, [isShopHistoryOpen]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const accountMerchantsResponse = await getAccountMerchantsService();

      setAccountMerchants(orderBy(accountMerchantsResponse.account_merchants, ["id"], ["desc"]));
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleAll = () => {
    if (accordionGroupRef.current) {
      const value = accordionGroupRef.current.value;

      if (value && value.length > 0) {
        setCollapseAll(true);
        return (accordionGroupRef.current.value = []);
      }

      setCollapseAll(false);
      accordionGroupRef.current.value = accountMerchants.map((accountMerchant) => `${accountMerchant.id}`);
    }
  };

  return (
    <IonModal isOpen={isShopHistoryOpen}>
      <AppHeader
        startIcon={
          <GoBackButton
            onClick={() => {
              dispatch(uiActions.setIsShopHistoryOpen(false));
            }}
          />
        }
        title={t("TITLES.ACCOUNT_MERCHANTS")}
        endIcon={
          <>
            {!!accountMerchants.length && (
              <IconButton
                icon={collapseAll ? chevronExpandOutline : chevronCollapseOutline}
                onClick={handleToggleAll}
                disabled={isLoading}
              />
            )}
          </>
        }
      />
      <IonContent>
        <div>
          <IonLoading isOpen={isLoadingDownload} message={t("LOADING.LOADING") ?? ""}></IonLoading>
          {isLoading ? (
            <IonList>
              {new Array(5).fill(1).map((_, index) => (
                <ShopHistoryAccountMerchantSkeleton key={index} />
              ))}
            </IonList>
          ) : (
            <div>
              {accountMerchants.length === 0 ? (
                <div className="empty-shop">
                  <IonRow>
                    <IonIcon src={bagCheckOutline} className="icon" />
                  </IonRow>
                  <IonRow>
                    <IonLabel className="no-location-text">{t("ERRORS.NO_ACCOUNT_MERCHANTS")}</IonLabel>
                  </IonRow>
                </div>
              ) : (
                <div>
                  <div>
                    <IonAccordionGroup
                      ref={accordionGroupRef}
                      className="account-merchant"
                      multiple
                      onIonChange={() => {
                        setCollapseAll(false);
                      }}
                    >
                      {accountMerchants.map((accountMerchant) => (
                        <ShopHistoryAccountMerchant
                          key={accountMerchant.id}
                          accountMerchant={accountMerchant}
                          setIsLoadingDownload={setIsLoadingDownload}
                        />
                      ))}
                    </IonAccordionGroup>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};
