import { ReactNode } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface ProgressBarCircularProps {
  value: number;
  text: ReactNode;
  className?: string;
}
export const ProgressBarCircular = ({ value, text, className }: ProgressBarCircularProps) => {
  return (
    <div className={className}>
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={10}
        styles={buildStyles({
          strokeLinecap: "round",
          pathTransitionDuration: 0.5,
          textSize: "24px",
          textColor: "var(--ion-color-focus)",
          pathColor: "var(--ion-color-primary)",
          trailColor: "#d6d6d6",
        })}
      >
        <div
          className={`${className}-children`}
          style={{
            textAlign: "center",
          }}
        >
          {text}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};
