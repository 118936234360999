import { useLocationsContext } from "@/commons/hooks/useLocationsContext";
import { ActiveChargeModel } from "@/core/models/chargeModel";
import { getChargerGraphService } from "@/core/services/charge/chargeServices";
import { chargesActions } from "@/core/store/slices/chargesSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { useEffect, useState } from "react";

const INTERVAL_TO_CHECK = 10000; // 10 seconds
const MAX_CHECKS = 12; // 10 * 12 -> 2 minutes

interface useCheckChargeFinishProps {
  activeCharge: ActiveChargeModel;
}

export const useCheckChargeFinish = ({ activeCharge }: useCheckChargeFinishProps) => {
  const dispatch = useAppDispatch();

  const { getLocations } = useLocationsContext();

  const [intevalCheck, setIntevalCheck] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      intevalCheck && clearInterval(intevalCheck);
    };
  }, []);

  const checkChargeFinish = () => {
    let checkCounter = 0;

    const interval = setInterval(() => {
      if (checkCounter >= MAX_CHECKS) {
        clearInterval(interval);

        dispatch(chargesActions.removeActivePayload(activeCharge.charge_id));

        return;
      }

      getChargerGraphService(activeCharge.charge_id).then(({ session_end_at }) => {
        if (session_end_at) {
          clearInterval(interval);

          getLocations(false);
          dispatch(chargesActions.removeActivePayload(activeCharge.charge_id));
          dispatch(uiActions.setToastType({ type: "chargedStopped" }));

          return;
        }

        checkCounter++;
      });
    }, INTERVAL_TO_CHECK);

    setIntevalCheck(interval);
  };

  return {
    checkChargeFinish,
  };
};
