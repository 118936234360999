import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { PaymentStep } from "@/commons/components/Payments/PaymentStep/PaymentStep";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { PaymentMethod } from "@/core/models/PaymentModel";
import { ReservationStepLabel, reservationStepsActions } from "@/core/store/slices/reservationStepsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { t } from "i18next";
import { checkmarkCircle } from "ionicons/icons";
import { ReactElement, useEffect, useState } from "react";
import { ReservationStepChoose } from "./ReservationStepChoose/ReservationStepChoose";
import { ReservationStepsFooter } from "./ReservationStepsFooter/ReservationStepsFooter";
import "./ReservationStepsPage.scss";

interface ReservationStep {
  label: ReservationStepLabel;
  component: ReactElement;
}

export const ReservationStepsPage = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();

  const { loadUserInfo } = useUserContext();
  const {
    parameters: { payment_type: paymentType },
  } = useParametersContext();

  const { selectedSocket, selectedCharger } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { currentStep } = useAppSelector((state) => state.reservationStepsReducer);
  const { userInfo, isUserLogged } = useAppSelector((state) => state.userReducer);

  const [steps, setsteps] = useState<ReservationStep[]>([]);

  useIonViewWillEnter(() => {
    loadUserInfo();
  });

  useIonViewWillLeave(() => {
    dispatch(reservationStepsActions.setClean());
  });

  useEffect(() => {
    if (selectedCharger && selectedSocket) {
      const newSteps: ReservationStep[] = [
        { label: ReservationStepLabel.CHOOSE, component: <ReservationStepChoose /> },
      ];

      const isFreeReservation = selectedSocket.price_time_min_reservation === "0";

      if (!isFreeReservation && selectedCharger.payment_methods) {
        setDefaultPaymentMethod(selectedCharger.payment_methods);

        newSteps.push({ label: ReservationStepLabel.PAYMENT, component: <PaymentStep stepsType="reservation" /> });
      }

      setsteps(newSteps);
      dispatch(reservationStepsActions.setStepsLabels(newSteps.map((step) => step.label)));
    }
  }, [userInfo, selectedCharger, selectedSocket]);

  const handleStepClick = (event: SegmentCustomEvent) => {
    const newValue = event.detail.value;
    dispatch(reservationStepsActions.setCurrentStep(Number(newValue)));
  };

  const setDefaultPaymentMethod = (payment_methods: PaymentMethod[]) => {
    const isBothPayment = paymentType.includes("both");

    if (!isUserLogged) {
      dispatch(reservationStepsActions.setPaymentStepPaymentMethod("CREDITCARD"));
      return;
    }

    if (isBothPayment && payment_methods.length > 1) {
      dispatch(reservationStepsActions.setPaymentStepCanChangePaymentMethod(true));
      const defaultPaymentMethod =
        userInfo?.default_charge_values.active_automatic_charge &&
        userInfo?.default_charge_values.default_payment_method;

      if (defaultPaymentMethod) {
        return dispatch(
          reservationStepsActions.setPaymentStepPaymentMethod(
            defaultPaymentMethod === "credit_card" ? "CREDITCARD" : "WALLET",
          ),
        );
      }

      if (payment_methods.includes("WALLET")) {
        return dispatch(reservationStepsActions.setPaymentStepPaymentMethod("WALLET"));
      }
    }

    if (isBothPayment && payment_methods.length === 1) {
      dispatch(reservationStepsActions.setPaymentStepPaymentMethod(payment_methods[0]));
      return;
    }

    if (payment_methods.length > 1) {
      dispatch(reservationStepsActions.setPaymentStepCanChangePaymentMethod(true));
    }

    if (paymentType.includes("wallet") && payment_methods.some((method) => method === "WALLET")) {
      dispatch(reservationStepsActions.setPaymentStepPaymentMethod("WALLET"));
    } else {
      dispatch(reservationStepsActions.setPaymentStepPaymentMethod("CREDITCARD"));
    }
  };

  return (
    <IonPage>
      <AppHeader hiddenMenu title={t("BUTTONS.BOOK")} startIcon={<GoBackButton onClick={() => router.goBack()} />} />
      <IonContent>
        {!!steps.length && (
          <IonSegment className="segment" value={currentStep} onIonChange={handleStepClick} mode="md">
            {steps.map((step, index) => (
              <IonSegmentButton key={step.label} value={index} disabled={index > currentStep}>
                {index < currentStep ? (
                  <IonIcon className="ion-no-margin" icon={checkmarkCircle} color="primary" />
                ) : (
                  <IonLabel class="ion-no-margin">{index + 1}</IonLabel>
                )}
                <IonLabel mode="ios" className="ion-no-margin">
                  {t(`INSTRUCTIONS_STEPS.${step.label}`)}
                </IonLabel>
              </IonSegmentButton>
            ))}
          </IonSegment>
        )}

        <div className="reservation-step-component">{steps.length > 0 && steps[currentStep].component}</div>
      </IonContent>
      <ReservationStepsFooter />
    </IonPage>
  );
};
