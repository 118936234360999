import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";

/**
 * Service to start a socket
 * @param chargeId
 * @param socketNumber
 * @param cardCode
 * @returns
 */
export const startSocketService = (
  chargeId: string,
  socketNumber: string,
  cardCode: string,
  accountId: number,
): Promise<any> => {
  return api
    .post("socket/start", { idcharger: chargeId, socket: socketNumber, iduser: cardCode, cardCode, accountId })
    .then(resolveResponse)
    .catch(rejectError);
};

/**
 * Service to stop a socket
 * @param idcharger
 * @param socket socket id
 * @returns
 */
export const stopSocketService = (
  chargeId: number,
  socketNumber: string,
): Promise<{ status_code: string; status_message: string }> => {
  return api
    .post("socket/stop", { idcharger: chargeId, socket: socketNumber })
    .then(resolveResponse)
    .catch(rejectError);
};
