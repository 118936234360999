import { PaymentMethod } from "@/core/models/PaymentModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface reservationStepsSlice {
  stepsLabels: ReservationStepLabel[];
  currentStep: number;
  currentStepLabel: ReservationStepLabel;
  nextStepDisabled: boolean;
  chooseStep: ChooseStep;
  paymentStep: {
    canChangePaymentMethod: boolean;
    paymentMethod?: PaymentMethod;
    selectPaymentId?: string;
    extraField?: string;
    cardCode?: string;
  };
}

export enum ReservationStepLabel {
  CHOOSE = "CHOOSE",
  PAYMENT = "PAYMENT",
}

interface ChooseStep {
  time: number;
  price: number;
  isLoadingPrice: boolean;
}

export const reservationStepsSliceInitialState: reservationStepsSlice = {
  stepsLabels: [],
  currentStep: 0,
  currentStepLabel: ReservationStepLabel.CHOOSE,
  nextStepDisabled: true,
  chooseStep: {
    time: 0,
    price: 0,
    isLoadingPrice: false,
  },
  paymentStep: {
    canChangePaymentMethod: false,
  },
};

export const reservationStepsSlice = createSlice({
  name: "reservationStepsReducer",
  initialState: reservationStepsSliceInitialState,
  reducers: {
    setStepsLabels: (state, { payload }: PayloadAction<ReservationStepLabel[]>) => {
      state.stepsLabels = payload;
    },
    setCurrentStep: (state, { payload }: PayloadAction<number>) => {
      state.currentStep = payload;
      state.currentStepLabel = state.stepsLabels[payload];
    },
    setNextStepDisabled: (state, { payload }: PayloadAction<boolean>) => {
      state.nextStepDisabled = payload;
    },

    setClean: (state) => {
      state.stepsLabels = reservationStepsSliceInitialState.stepsLabels;
      state.currentStep = reservationStepsSliceInitialState.currentStep;
      state.currentStepLabel = reservationStepsSliceInitialState.currentStepLabel;
      state.nextStepDisabled = reservationStepsSliceInitialState.nextStepDisabled;
      state.chooseStep = reservationStepsSliceInitialState.chooseStep;
      state.paymentStep = reservationStepsSliceInitialState.paymentStep;
    },
    setChooseStep: (state, { payload }: PayloadAction<Omit<ChooseStep, "isLoadingPrice">>) => {
      state.chooseStep = {
        price: payload.price,
        time: payload.time,
        isLoadingPrice: state.chooseStep.isLoadingPrice,
      };
    },
    setChooseStepIsLoadingPrice: (state, { payload }: PayloadAction<boolean>) => {
      state.chooseStep.isLoadingPrice = payload;
    },
    setPaymentStepCanChangePaymentMethod: (state, { payload }: PayloadAction<boolean>) => {
      state.paymentStep.canChangePaymentMethod = payload;
    },
    setPaymentStepPaymentMethod: (state, { payload }: PayloadAction<PaymentMethod | undefined>) => {
      state.paymentStep.paymentMethod = payload;
    },
    setPaymentStepSelectPaymentId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.paymentStep.selectPaymentId = payload;
    },
    setPaymentStepExtraField: (state, { payload }: PayloadAction<string | undefined>) => {
      state.paymentStep.extraField = payload;
    },
    setPaymentStepCardCode: (state, { payload }: PayloadAction<string | undefined>) => {
      state.paymentStep.cardCode = payload;
    },
  },
});

export const reservationStepsActions = reservationStepsSlice.actions;

export default reservationStepsSlice.reducer;
