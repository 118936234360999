import { IonAlert, IonicSafeString } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface Props {
  showErrorGetCG: boolean;
  showUserInfoError: boolean;
  showNoLoggedFavorites: boolean;
  handleAlertDismiss: any;
  handleAlertDissmissErrorUser: any;
  showLocationNotFound: boolean;
  showSuccessAlert: boolean;
  showAlertSocketNotAvailble: boolean;
}

export const MapAlerts = ({
  showErrorGetCG,
  showUserInfoError,
  showNoLoggedFavorites,
  handleAlertDismiss,
  handleAlertDissmissErrorUser,
  showLocationNotFound,
  showSuccessAlert,
  showAlertSocketNotAvailble,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <IonAlert
        isOpen={showSuccessAlert}
        onDidDismiss={handleAlertDismiss}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("SEND_NOTIFICATION.NOTIFY_SUBSCRIBED")}</ion-label>
                    </div>`,
          )
        }
        buttons={[`${t("BUTTONS.ACCEPT")}`]}
        cssClass="alertContainer"
      />

      <IonAlert
        isOpen={showErrorGetCG}
        onDidDismiss={handleAlertDismiss}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("ALERT.ERROR_CHARGE_INFO_TITLE")}</ion-label>
                    <ion-label class="alertText">${t("ALERT.ERROR_CHARGE_INFO_TEXT")}</ion-label>
                    </div>`,
          )
        }
        buttons={[`${t("BUTTONS.ACCEPT")}`]}
        cssClass="alertContainer"
      />

      <IonAlert
        isOpen={showLocationNotFound}
        onDidDismiss={handleAlertDismiss}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("ALERT.SCANNED_CHARGER_NOT_FOUND")}</ion-label>
                    </div>`,
          )
        }
        buttons={[`${t("BUTTONS.ACCEPT")}`]}
        cssClass="alertContainer"
      />

      <IonAlert
        isOpen={showUserInfoError}
        onDidDismiss={handleAlertDissmissErrorUser}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("ALERT.SOMETHING_WRONG_TITLE")}</ion-label>
                    <ion-label class="alertText">${t("ALERT.SOMETHING_WRONG_TEXT")}</ion-label>
                    </div>`,
          )
        }
        buttons={[`${t("ALERT_BUTTONS.CANCEL")}`]}
        cssClass="alertContainer"
      />

      {/* Error charge  */}
      {/* <IonAlert
                isOpen={true}
                message={new IonicSafeString(
                    `<div class="alert">
                    <img src="${alertError}" alt="g-maps" style="border-radius: 2px">
                    <ion-label class="titleH3">${t('ALERT.ERROR_CONNECTION_TITLE')}</ion-label>
                    <ion-label class="alertText">${t('ALERT.ERROR_CONNECTION_TEXT')}</ion-label>
                    </div>`
                )}
                buttons={
                    chargerHasSupport ? [
                        {
                            text: `${t("ALERT_BUTTONS.GO_HELP")}`,
                            handler: handleGoHelp
                        },{
                            text: `${t("ALERT_BUTTONS.GO_CHAT")}`,
                            handler: handleGoChat
                        }
                    ]
                    :[
                        {
                            text: `${t("ALERT_BUTTONS.GO_HELP")}`,
                            handler: handleGoHelp
                        }
                    ]
                }
                cssClass="alertContainer"
            /> */}
      <IonAlert
        isOpen={showUserInfoError}
        onDidDismiss={handleAlertDissmissErrorUser}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("ALERT.SOMETHING_WRONG_TITLE")}</ion-label>
                    <ion-label class="alertText">${t("ALERT.SOMETHING_WRONG_TEXT")}</ion-label>
                    </div>`,
          )
        }
        buttons={[`${t("ALERT_BUTTONS.CANCEL")}`]}
        cssClass="alertContainer"
      />
      <IonAlert
        isOpen={showNoLoggedFavorites}
        onDidDismiss={handleAlertDismiss}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("ALERT.NO_LOGGED_FAV")}</ion-label>
                    </div>`,
          )
        }
        buttons={[`${t("BUTTONS.ACCEPT")}`]}
        cssClass="alertContainer"
      />
      <IonAlert
        isOpen={showAlertSocketNotAvailble}
        onDidDismiss={handleAlertDismiss}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("SEND_NOTIFICATION.SOCKET_INFO")}</ion-label>
                    </div>`,
          )
        }
        buttons={[`${t("BUTTONS.ACCEPT")}`]}
        cssClass="alertContainer"
      />
    </>
  );
};
