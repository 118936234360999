import { IonPosition } from "@/commons/types/ionicTypes";
import { Color } from "@ionic/core";
import { ToastButton } from "@ionic/react";
import { t as translate } from "i18next";
import { closeOutline } from "ionicons/icons";

export type ToastType =
  | "error"
  | "workingProgress"
  | "chargedStopped"
  | "reservationFinished"
  | "successTicket"
  | "successUserUpdate"
  | "addedVehicle"
  | "updatedVehicle"
  | "deletedVehicle"
  | "successDeleteFavoriteCharger"
  | "success";

interface ToastTypeComponent {
  message: string;
  color: Color;
  duration?: number;
  position?: IonPosition;
  buttons?: (string | ToastButton)[];
}

export interface ToastTypeOptions {
  primaryAction: () => void;
}

export const toastTypesComponents = (
  t: typeof translate,
  // options?: ToastTypeOptions,
): Record<ToastType, ToastTypeComponent> => ({
  error: {
    color: "danger",
    message: t("ALERT.ERROR_ADD_WALLET_TEXT"),
    duration: 5000,
    buttons: [
      {
        icon: closeOutline,
        role: "cancel",
      },
    ],
  },
  workingProgress: {
    color: "warning",
    message: t("APP_ERRORS.WORKING"),
  },

  chargedStopped: {
    color: "success",
    message: t("TOAST.ENDED_CHARGE"),
    duration: 5000,
    buttons: [
      // {
      //   text: "Show more",
      //   htmlAttributes: {
      //     color: "primary",
      //   },
      //   handler: () => options?.primaryAction(),
      // },
      {
        icon: closeOutline,
        role: "cancel",
      },
    ],
    position: "top",
  },
  reservationFinished: {
    color: "secondary",
    message: t("BOOKING_INFO.RESERVATION_FINISHED"),
  },
  successTicket: {
    color: "success",
    message: t("TOAST.SUCCESS_TICKET"),
  },
  successUserUpdate: {
    color: "success",
    message: t("TOAST.SUCCESS_USER_UPDATE"),
  },
  addedVehicle: {
    color: "success",
    message: t("TOAST.ADDED_VEHICLE"),
  },
  updatedVehicle: {
    color: "success",
    message: t("TOAST.UPDATED_VEHICLE"),
  },
  deletedVehicle: {
    color: "success",
    message: t("TOAST.DELETED_VEHICLE"),
  },
  successDeleteFavoriteCharger: {
    color: "success",
    message: t("TOAST.DELETED_CHARGER"),
  },
  success: {
    color: "success",
    message: t("TOAST.SUCCESS"),
  },
});
