import i18n from "@/assets/i18n/i18n";
import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { ChargerModel } from "@/core/models/chargerModel";
import { FacilityModel, LocationMapModel, LocationModel } from "@/core/models/locationModel";

/**
 * Service to get the locations list for the map
 */
export const getLocationsService = async (
  tokenApi: string,
  tokenUser: string,
  params?: string,
): Promise<{ locations: LocationMapModel[] }> => {
  const paramsUrl = params ? `?${params}` : "";
  return api
    .get(
      `locations/list_map${paramsUrl}${!paramsUrl ? "?" : ""}token_user=${tokenUser}`,
      undefined,
      {
        customToken: tokenApi,
        abortIfFetching: params?.includes("return_count=true") ? "endpoint" : undefined,
      },
      `locations/list_map${params?.includes("return_count=true") ? "?return_count=true" : ""}`,
    )
    .then(resolveResponse)
    .catch(rejectError);
};

/**
 * Service to get the location info
 * @param locationId
 * @param lat Latitude user
 * @param lon Longitude user
 * @returns
 */
export const getLocationService = async (
  tokenApi: string,
  tokenUser: string,
  locationId: number,
  lat: number,
  lon: number,
  activeFilters: string,
): Promise<LocationModel> => {
  const extraParams = activeFilters ? `&${activeFilters}` : "";
  return api
    .get(
      `location/show/${locationId}?lat=${lat}&lon=${lon}&token_user=${tokenUser}&language=${i18n.language}${extraParams}`,
      undefined,
      {
        customToken: tokenApi,
      },
    )
    .then(resolveResponse)
    .catch(rejectError);
};

/**
 * Service to get the location info from charger id
 * @param chargerId
 */
export const getLocationByChargerService = async (
  chargerId: string,
  tokenApi: string,
  tokenUser: string,
): Promise<{ location_id: number }> => {
  return api
    .get(
      "locations/get_location_by_charger",
      { chargerId, token_user: tokenUser },
      {
        customToken: tokenApi,
      },
    )
    .then(resolveResponse)
    .catch(rejectError);
};

export const getNearChargersService = (
  lat: number,
  lon: number,
  token_user: string,
  token_admin: string,
): Promise<{ chargers: ChargerModel[] }> => {
  return api
    .get(
      `charger/list_distance.json`,
      {
        new_api: true,
        lat: lat,
        lon: lon,
        limit: 2,
        token_user: token_user,
      },
      { customToken: token_admin },
    )
    .then(resolveResponse)
    .catch(rejectError);
};

export const getFavoriteChargersService = (
  lat: number,
  lon: number,
  token_user: string,
  token_admin: string,
): Promise<{ fav_chargers: ChargerModel[] }> => {
  return api
    .get(
      `charger/list_distance.json`,
      {
        new_api: true,
        lat: lat,
        lon: lon,
        only_fav: true,
        token_user: token_user,
      },
      { customToken: token_admin },
    )
    .then(resolveResponse)
    .catch(rejectError);
};

export const getLocationFacilitiesService = (): Promise<FacilityModel[]> => {
  return api.get(`locations/facilities`).then(resolveResponse).catch(rejectError);
};
