import { putSeenNotification } from "@/core/services/user/userServices";
import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { incidenceActions } from "@/core/store/slices/incidenceSlice";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch } from "@/core/store/store";
import { buildPath } from "@/router/routerUtils";
import { APP_PATH } from "@/router/routes";
import { PushNotificationSchema } from "@capacitor/push-notifications";
import { useHistory } from "react-router-dom";
import { useQuery } from "./useQuery";

export type NotificationActionType =
  | "rateApp"
  | "showPrice"
  | "showPriceReservation"
  | "parcialCharged"
  | "energyCharged"
  | "showPubli"
  | "endSession"
  | "startCharge"
  | "ticketMessage"
  | "ticketClosed"
  | "balanceSpent"
  | "balanceNext"
  | "socketAvailable"
  | "touch"
  | "authorizeRefused";

export const useNotificationActions = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { searchParams } = useQuery();

  const exectNotificationActionReceived = (notification: PushNotificationSchema) => {
    dispatch(userActions.setLastNotification(notification.data.action));

    const { action, id } = notification.data;

    const actions: Record<NotificationActionType | string, (notification: PushNotificationSchema) => void> = {
      rateApp: showRateApp,
      startCharge: () => dispatch(chargeStepsActions.setChargeStepIsChargeCreated(true)),
      ticketMessage: () => reloadCurrentTicket(id),
      ticketClosed: () => reloadCurrentTicket(id),
    };

    actions[action] && actions[action](notification);
  };

  const execNotificationActionTap = (notification: PushNotificationSchema) => {
    const { action, id, notification_id } = notification.data;

    notification_id && handlePutSeenNotification(notification_id);
    const actions: Record<NotificationActionType | string, (notification: PushNotificationSchema) => void> = {
      rateApp: showRateApp,
      showPrice: () => goToCharge(id),
      showPriceReservation: () => history.replace(APP_PATH.RESERVATIONS, { state: id }),
      parcialCharged: () => goToCharge(id),
      energyCharged: () => goToCharge(id),
      showPubli: () => showMarketingModal(id),
      endSession: () => goToCharge(id),
      startCharge: () => goToCharge(id),
      ticketMessage: () => goToTicket(id),
      ticketClosed: () => goToTicket(id),
      balanceSpent: () => history.replace(APP_PATH.WALLET),
      balanceNext: () => history.replace(APP_PATH.WALLET),
      socketAvailable: () => handleGoToLocation(id),
      touch: () => handleGoToLocation(id),
      authorizeRefused: () => history.replace(APP_PATH.WALLET),
    };
    actions[action] && actions[action](notification);
  };

  const handlePutSeenNotification = async (notification_id: string) => {
    await putSeenNotification(Number(notification_id));
  };

  // Actions

  const showRateApp = () => {
    dispatch(uiActions.setIsRateAppModalOpen(true));
  };

  const goToCharge = (chargerGraphId: number) => {
    history.push(APP_PATH.CHARGES, { state: chargerGraphId });
  };

  const goToTicket = (ticketId: number) => {
    history.push(buildPath(APP_PATH.INCIDENCE, { id: ticketId }));
  };

  const handleGoToLocation = (locationId: number) => {
    dispatch(mapActions.setSelectedLocationId(locationId));

    searchParams.set("locationId", locationId.toString());

    history.push({
      pathname: APP_PATH.HOME,
      search: searchParams.toString(),
    });
  };

  const showMarketingModal = (id: number) => {
    dispatch(uiActions.setIsMarketingModalOpen(true));
    dispatch(uiActions.setMarketingNotificationId(id));
  };

  const reloadCurrentTicket = (id: string) => {
    const path = window.location.pathname;
    const regex = /\/incidences\/\d+/;

    if (regex.test(path)) {
      dispatch(incidenceActions.setLastUpdateIncidence({ id, date: new Date() }));
    }
  };

  return { exectNotificationActionReceived, execNotificationActionTap };
};
