import { RatePeriodConditionalModel, RatePeriodModel } from "@/core/models/rateModel";
import { IonCol, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";

interface RatesConditionalsProps {
  rateId: string;
  period: RatePeriodModel;
}

const OPERATORS_TRANSLATES = {
  "greater than": "GREATER_THAN",
  "lesser than": "LESSER_THAN",
};

const CONDITIONAL_TYPES_UNITS = {
  energy: "kWh",
  time: "min",
};

export const RatesConditionals = ({ rateId, period }: RatesConditionalsProps) => {
  const getCoditionalValue = (conditional: RatePeriodConditionalModel) => {
    const operator = OPERATORS_TRANSLATES[conditional.operator];
    const unit = CONDITIONAL_TYPES_UNITS[conditional.conditional_type];

    return `${t(`RATES.${operator}`)} ${conditional.value} ${unit}`;
  };

  return (
    <>
      {period.conditionals.map((conditional) => (
        <IonRow
          key={`rate${rateId}period${period.id_period}conditional${conditional.id_conditional}`}
          className="container-rates-condition"
        >
          <IonCol>
            <IonRow>
              <IonLabel>
                <IonLabel className="bold">{t("RATES.CONDITIONS") + ": "}</IonLabel>
                <IonLabel className="container-rates-condition-title">{getCoditionalValue(conditional)}</IonLabel>
              </IonLabel>
            </IonRow>
            {conditional.rate_conditional_join_consumptions && (
              <IonCol className="join">
                {conditional.rate_conditional_join_consumptions.map((consumption, index) => (
                  <IonRow key={index}>
                    <IonLabel className="price">
                      {consumption.price_base} {consumption.conditional_consumption}
                    </IonLabel>
                  </IonRow>
                ))}
              </IonCol>
            )}
          </IonCol>
        </IonRow>
      ))}
    </>
  );
};
