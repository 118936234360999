const { VITE_SLACK_WEBHOOK_URL } = import.meta.env;

export const sendSlackMessageService = async (message: string, color = "#3AA3E3") => {
  if (!VITE_SLACK_WEBHOOK_URL) {
    return;
  }

  return fetch(VITE_SLACK_WEBHOOK_URL, {
    method: "POST",
    body: JSON.stringify({
      attachments: [
        {
          color: color,
          text: message,
        },
      ],
    }),
  });
};
