import {
  PaymentFrame,
  PaymentFrameRespose,
  PaymentFrameType,
} from "@/commons/components/Payments/PaymentFrame/PaymentFrame";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { usePayment } from "@/commons/hooks/usePayment";
import { formatNumber } from "@/commons/utils/numberUtils";
import { getUserAppCard } from "@/commons/utils/userUtils/userUtils";
import { createReservationWalletService } from "@/core/services/payment/paymentServices";
import { mapActions } from "@/core/store/slices/mapSlice";
import { ReservationStepLabel, reservationStepsActions } from "@/core/store/slices/reservationStepsSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { IonButton, IonFooter, IonIcon, IonLoading, IonRow, IonToolbar } from "@ionic/react";
import { t } from "i18next";
import { calendarOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./ReservationStepsFooter.scss";

export const ReservationStepsFooter = () => {
  const dispatch = useAppDispatch();
  const { preCaptureReservationCreditCard } = usePayment();
  const { parameters } = useParametersContext();
  const history = useHistory();

  const { nextStepDisabled, currentStep, currentStepLabel, stepsLabels, chooseStep, paymentStep } = useAppSelector(
    (state) => state.reservationStepsReducer,
  );
  const { selectedCharger, selectedSocket } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { isKeyboardOpen } = useAppSelector((state) => state.uiReducer);
  const { user, userInfo } = useAppSelector((state) => state.userReducer);

  const isLastStep = currentStep === stepsLabels.length - 1;

  const [isLoading, setIsLoading] = useState(false);
  const [paymentFrame, setPaymentFrame] = useState<{
    isOpen: boolean;
    type: PaymentFrameType;
  }>({
    isOpen: false,
    type: "redsysPayment",
  });
  const [response, setResponse] = useState<PaymentFrameRespose>();

  const goNextStep = () => {
    const newStep = currentStep + 1;
    stepsLabels.length - 1 <= newStep && dispatch(reservationStepsActions.setCurrentStep(newStep));
  };

  const handleNextStep = async () => {
    if (isLastStep) {
      try {
        setIsLoading(true);

        if (!selectedSocket) {
          throw new Error(t("APP_ERRORS.SELECTED_SOCKET"));
        }
        if (!selectedCharger) {
          throw new Error(t("APP_ERRORS.SELECTED_CHARGER"));
        }

        const card = await getUserAppCard(user.user_id, userInfo);
        dispatch(reservationStepsActions.setPaymentStepCardCode(card.code_card));

        // const isFreeCharge = false
        const { paymentMethod, selectPaymentId } = paymentStep;
        const { time } = chooseStep;
        const cardCode = card.code_card;
        const currencyCode = parameters.currency_code;

        if (paymentMethod === "CREDITCARD") {
          if (!selectPaymentId) {
            throw new Error(t("APP_ERRORS.PAYMENT_DATA"));
          }

          await preCaptureReservationCreditCard(
            cardCode,
            selectedCharger,
            selectedSocket.id_socket,
            time,
            selectPaymentId,
            setResponse,
            currencyCode,
          );

          if (parameters.payment_type.includes("redsys")) {
            return setPaymentFrame({
              isOpen: true,
              type: "redsysPayment",
            });
          }
        }

        if (paymentMethod === "WALLET") {
          await createReservationWalletService(currencyCode, cardCode, selectedSocket.id_socket, time);
        }

        handleGoToHome();

        return; // Important to avoid goNextStep
      } catch (error) {
        return dispatch(uiActions.setToastError(error));
      } finally {
        setIsLoading(false);
      }
    }

    goNextStep();
  };

  const handleGoToHome = () => {
    history.push(APP_PATH.HOME);
    dispatch(uiActions.setAlertType({ type: "successReservation", options: { message: selectedCharger?.name } }));
    dispatch(mapActions.cleanSelectedLocation());
  };

  const setIsOpenPaymentFrame = (isOpen: boolean) => setPaymentFrame({ ...paymentFrame, isOpen });

  return (
    <>
      {currentStepLabel === ReservationStepLabel.PAYMENT && !isKeyboardOpen && (
        <IonRow className="reservation-steps-total-price-info">
          <span>{t("TITLES.TOTAL")}</span>
          <span>
            {formatNumber(chooseStep.price, { minimumFractionDigits: 2 })}
            {parameters.currency}
          </span>
        </IonRow>
      )}
      <PaymentFrame
        isOpen={paymentFrame.isOpen}
        setIsOpenPaymentFrame={setIsOpenPaymentFrame}
        response={response}
        callback={(values) => {
          if (!values?.success) {
            return dispatch(uiActions.setToastError(t("TOAST.ERROR_ACCOUNT_VALIDATION")));
          }

          handleGoToHome();
        }}
        type={paymentFrame.type}
      />
      <IonFooter className="reservation-steps-footer ion-no-border">
        <IonLoading isOpen={isLoading} />

        <IonToolbar className="">
          <IonButton color="primary" disabled={nextStepDisabled} onClick={handleNextStep}>
            {isLastStep && <IonIcon slot="start" icon={calendarOutline} />}
            {t(`BUTTONS.${isLastStep ? "BOOK" : "NEXT"}`)}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
