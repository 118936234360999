import { ChargesPage } from "@/pages/ChargesPage/ChargesPage";
import { FavoritesPage } from "@/pages/FavoritesPage/FavoritesPage";
import { HomePage } from "@/pages/HomePage/HomePage";
import { IncidencePage } from "@/pages/IncidencePage/IncidencePage";
import { IncidencesPage } from "@/pages/IncidencesPage/IncidencesPage";
import { KeychainsPage } from "@/pages/KeychainPage/KeychainsPage";
import { LoginPage } from "@/pages/LoginPage/LoginPage";
import { NotificationsPage } from "@/pages/NotificationsPage/NotificationsPage";
import { OnboardingPage } from "@/pages/OnboardingPage/OnboardingPage";
import { PersonalDataPage } from "@/pages/PersonalDataPage/PersonalDataPage";
import { PromotionalCodesPage } from "@/pages/PromotionalCodesPage/PromotionalCodesPage";
import { RegisterPage } from "@/pages/RegisterPage/RegisterPage";
import { ReservationStepsPage } from "@/pages/ReservationStepsPage/ReservationStepsPage";
import { ReservationsPage } from "@/pages/ReservationsPage/ReservationsPage";
import { ShopPage } from "@/pages/ShopPage/ShopPage";
import { TestPage } from "@/pages/TestPage/TestPage";
import { VehiclesPage } from "@/pages/VehiclesPage/VehiclesPage";
import { WalletAddBalancePage } from "@/pages/WalletAddBalancePage/WalletAddBalancePage";
import { WalletAddCreditCardPage } from "@/pages/WalletAddCreditCardPage/WalletAddCreditCardPage";
import { WalletPage } from "@/pages/WalletPage/WalletPage";

export enum APP_PATH {
  ONBOARDING = "/onboarding",
  REGISTER = "/register",
  LOGIN = "/login",
  HOME = "/home",
  NOTIFICATIONS = "/notifications",
  FAVORITES = "/favorites",
  CHARGES = "/charges",
  RESERVATIONS = "/reservations",
  RESERVATION_STEPS = "/reservation-steps",
  INCIDENCES = "/incidences",
  INCIDENCE = "/incidences/:id",
  PERSONAL_DATA = "/personal-data",
  WALLET = "/wallet",
  WALLET_ADD_CREDITCARD = "/wallet/add-creditcard",
  WALLET_ADD_BALANCE = "/wallet/add-balance",
  WALLET_PROMOTIONAL_CODES = "/wallet/promotional-codes",
  KEYCHAIN = "/keychain",
  SHOP = "/shop",
  VEHICLES = "/vehicles",
  TEST = "/test",
}

export interface Route {
  path: APP_PATH;
  component: () => JSX.Element;
}

export const routes: Route[] = [
  {
    path: APP_PATH.ONBOARDING,
    component: OnboardingPage,
  },
  // #region REGISTER/LOGIN
  {
    path: APP_PATH.REGISTER,
    component: RegisterPage,
  },
  {
    path: APP_PATH.LOGIN,
    component: LoginPage,
  },
  // #endregion

  // #region HOME
  {
    path: APP_PATH.HOME,
    component: HomePage,
  },
  // #endregion
  {
    path: APP_PATH.NOTIFICATIONS,
    component: NotificationsPage,
  },
  {
    path: APP_PATH.FAVORITES,
    component: FavoritesPage,
  },
  {
    path: APP_PATH.CHARGES,
    component: ChargesPage,
  },
  // #region RESERVATIONS
  {
    path: APP_PATH.RESERVATIONS,
    component: ReservationsPage,
  },
  {
    path: APP_PATH.RESERVATION_STEPS,
    component: ReservationStepsPage,
  },
  // #endregion
  {
    path: APP_PATH.INCIDENCES,
    component: IncidencesPage,
  },
  {
    path: APP_PATH.INCIDENCE,
    component: IncidencePage,
  },
  {
    path: APP_PATH.PERSONAL_DATA,
    component: PersonalDataPage,
  },
  // #region WALLET
  {
    path: APP_PATH.WALLET,
    component: WalletPage,
  },
  {
    path: APP_PATH.WALLET_ADD_CREDITCARD,
    component: WalletAddCreditCardPage,
  },
  {
    path: APP_PATH.WALLET_ADD_BALANCE,
    component: WalletAddBalancePage,
  },
  {
    path: APP_PATH.WALLET_PROMOTIONAL_CODES,
    component: PromotionalCodesPage,
  },
  // #endregion
  {
    path: APP_PATH.KEYCHAIN,
    component: KeychainsPage,
  },
  {
    path: APP_PATH.SHOP,
    component: ShopPage,
  },
  {
    path: APP_PATH.VEHICLES,
    component: VehiclesPage,
  },
  {
    path: APP_PATH.TEST,
    component: TestPage,
  },
];
