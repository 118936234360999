import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AlertModal } from "@/commons/components/UI/AlertModal/AlertModal";
import { activePromotionalCodeService } from "@/core/services/promotionalCode/promotionalCodeServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonInput, IonLabel, IonLoading, IonRow } from "@ionic/react";
import { t } from "i18next";
import { qrCodeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import "./PromotionalCodeAddModal.scss";

interface PromotionalCodeAddModalProps {
  isOpen: boolean;
  handleDismiss: () => void;
  callbackSuccess: () => void;
  openCamera: () => void;
}

export const PromotionalCodeAddModal = ({
  isOpen,
  handleDismiss,
  callbackSuccess,
  openCamera,
}: PromotionalCodeAddModalProps) => {
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLIonInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [newCode, setNewCode] = useState<string | number>("");

  useEffect(() => {
    setNewCode("");

    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.setFocus();
      }, 100);
    }
  }, [isOpen, inputRef]);

  const handleAddPromotionalCode = async () => {
    try {
      setIsLoading(true);
      await activePromotionalCodeService(`${newCode}`);
      callbackSuccess();
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setToastError(t("APP_ERRORS.WRONG_PROMOTIONAL_CODE")));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AlertModal
      isOpen={isOpen}
      handleDismiss={handleDismiss}
      primaryText={t("BUTTONS.ADD")}
      primaryAction={handleAddPromotionalCode}
      primaryDisabled={!newCode}
      hideSecondary
    >
      <IonLoading isOpen={isLoading} />
      <IonRow className="new-promotional-code">
        <IonLabel className="new-promotional-code-title">
          {t("BUTTONS.ADD")} {t("TITLES.PROMOTIONAL_CODE")}
        </IonLabel>

        <IonInput
          ref={inputRef}
          className="new-promotional-code-input"
          label={t("TITLES.PROMOTIONAL_CODE")}
          onIonInput={(event) => setNewCode(event.target.value || "")}
          value={newCode}
          labelPlacement="stacked"
          placeholder={t("TITLES.ENTER_PROMOTIONAL_CODE")}
          type="text"
          mode="md"
        >
          <IconButton slot="end" icon={qrCodeOutline} onClick={openCamera} fragment />
        </IonInput>
      </IonRow>
    </AlertModal>
  );
};
