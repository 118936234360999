import chargeIcon from "@/assets/imgs/icons/charge.svg";
import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { useIsSocketAvailable } from "@/commons/hooks/useIsSocketAvailable";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useQuery } from "@/commons/hooks/useQuery";
import { buildURLFilters } from "@/commons/utils/locationUtils/locationUtil";
import { getSocketInfoPrice } from "@/commons/utils/socketUtils/socketUtils";
import { LocationContext } from "@/context/locationContext";
import { CHARGER_SOCKET_RUNTIME_STATUS, ChargerSocketModel } from "@/core/models/chargerModel";
import { LocationModel } from "@/core/models/locationModel";
import { getLocationService } from "@/core/services/location/locationServices";
import { getUserChargeLimitsService, getUserChargesService } from "@/core/services/user/userServices";
import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { IonButton, IonFooter, IonIcon, IonLoading, useIonRouter } from "@ionic/react";
import { t } from "i18next";
import { arrowRedoCircleOutline, calendarOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./LocationDetailFooter.scss";

const { PROD: isProductionEnvironment } = import.meta.env;

export const LocationDetailFooter = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const router = useIonRouter();
  const { query, searchParams } = useQuery();
  const { isSocketAvailable } = useIsSocketAvailable();
  const { setLogEvent } = useFirebaseAnalytics();

  const { parameters } = useParametersContext();
  const { userLocation } = useContext(LocationContext);

  const { isUserLogged, user, deviceId, userInfo } = useAppSelector((state) => state.userReducer);
  const { locationId, locationInfo, selectedSocket, selectedCharger, options } = useAppSelector(
    (state) => state.mapReducer.selectedLocation,
  );
  const { activeFilters } = useAppSelector((state) => state.mapReducer.filters);

  const socketReservationsAllowed =
    selectedSocket?.reservation_allowed === "true" &&
    selectedSocket.runtime_status_id !== CHARGER_SOCKET_RUNTIME_STATUS.RESERVED;

  const [isLoading, setIsLoading] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    // Set first render when open location detail
    options.showLocationDetail && setFirstRender(options.showLocationDetail);
  }, [options.showLocationDetail]);

  useEffect(() => {
    // open charge automatically if query param is true
    if (firstRender && selectedCharger && selectedSocket && query.charge === "true") {
      handleChargeClick(selectedCharger.id_charger, selectedSocket);

      setFirstRender(false);

      // Remove query param to avoid open charge again when close
      searchParams.delete("charge");
      history.replace({ search: searchParams.toString() });
    }
  }, [query, firstRender, selectedCharger, selectedSocket]);

  const handleGoLocationMaps = (locationInfo: LocationModel) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${locationInfo.latitude},${locationInfo.longitude}`;
    window.open(url, "_blank");
  };

  const reloadData = async (chargerId: string, socket: ChargerSocketModel) => {
    const socketId = socket.id_socket;
    const { isFreeCharge } = getSocketInfoPrice(socket);

    if (!locationId || userLocation.latitude === null || userLocation.longitude === null) {
      dispatch(uiActions.setToastError(t("APP_ERRORS.CHARGE_DATA")));
      return { valid: false };
    }

    // Guest checks
    if (!isUserLogged) {
      const isOnlyWalletPayment =
        parameters.payment_type.includes("wallet") ||
        (selectedCharger?.payment_methods?.length === 1 && selectedCharger?.payment_methods.includes("WALLET"));

      if ((isOnlyWalletPayment && !isFreeCharge) || parameters.max_anonymous_charges === 0) {
        dispatch(uiActions.setAlertType({ type: "alertRegister" }));
        return { valid: false };
      }

      // Get number of guest user charges
      const { charges } = await getUserChargesService(user.user_id, 0, deviceId);

      if (charges.length >= parameters.max_anonymous_charges) {
        dispatch(uiActions.setAlertType({ type: "maxCharges" }));
        return { valid: false };
      }
    }

    const newLocationInfo = await getLocationService(
      parameters.token_api,
      user.auth_token,
      locationId,
      userLocation.latitude,
      userLocation.longitude,
      buildURLFilters(activeFilters),
    );

    // Update info selected charger and socket
    let newSelectedCharger = newLocationInfo.chargers.find((charger) => charger.id_charger === chargerId);

    if (!newSelectedCharger) {
      dispatch(uiActions.setToastError(t("APP_ERRORS.SELECTED_CHARGER")));
      return { valid: false };
    }

    newSelectedCharger = {
      ...newSelectedCharger,
      payment_methods: newSelectedCharger.payment_methods?.map(
        (paymentMethod) => (paymentMethod === "CARD" ? "CREDITCARD" : paymentMethod), // replace CARD by CREDITCARD
      ),
    };

    const newSelectedSocket = newSelectedCharger.charger_sockets.find((socket) => socket.id_socket === socketId);

    if (!newSelectedSocket) {
      dispatch(uiActions.setToastError(t("APP_ERRORS.SELECTED_SOCKET")));
      return { valid: false };
    }

    dispatch(mapActions.setSelectedSocket({ selectedCharger: newSelectedCharger, selectedSocket: newSelectedSocket }));

    if (parameters.only_b2b && !newSelectedSocket.is_b2b_user) {
      dispatch(uiActions.setAlertType({ type: "alertB2BOnly" }));
      return { valid: false };
    }

    // Check if user exceed rate limits
    if (newSelectedSocket.rates.length > 0) {
      const userRatesLimits = await getUserChargeLimitsService(socketId, user.user_id);

      const firstRateId = newSelectedSocket.rates[0].id_rate;
      const rateLimits = userRatesLimits[firstRateId];

      if (rateLimits) {
        // If is guest user and rate have limit
        if (!isUserLogged) {
          dispatch(uiActions.setAlertType({ type: "alertRegister" }));
          return { valid: false };
        }

        dispatch(chargeStepsActions.setRateLimits(rateLimits));

        // Check remaining limit
        for (const { limit, consumed } of Object.values(rateLimits)) {
          const remaining = limit - consumed;

          if (remaining < 0) {
            dispatch(uiActions.setAlertType({ type: "rateLimitsExceeded" }));
            return { valid: false };
          }
        }
      }
    }
    dispatch(mapActions.setLocationInfo(newLocationInfo));

    // Check if socket is busy
    const isSocketReserved = newSelectedSocket.runtime_status_id === CHARGER_SOCKET_RUNTIME_STATUS.RESERVED;
    const isAvailable = isSocketAvailable(newSelectedSocket);

    if ((isSocketReserved && !newSelectedSocket.reservation_id) || (!isAvailable && !isSocketReserved)) {
      dispatch(mapActions.setLocationOptions({ ...options, showSocketBusy: true }));
      return { valid: false };
    }

    return { valid: true, newLocationInfo };
  };

  const handleChargeClick = async (chargerId: string, selectedSocket: ChargerSocketModel) => {
    try {
      setIsLoading(true);

      const { valid, newLocationInfo } = await reloadData(chargerId, selectedSocket);

      if (!valid) return;

      if (isProductionEnvironment && !newLocationInfo?.near) {
        dispatch(uiActions.setAlertType({ type: "tooFarCharger" }));
        return;
      }

      const automaticCharge = !!userInfo?.default_charge_values.active_automatic_charge;

      dispatch(
        mapActions.setLocationOptions({
          showLocationDetail: automaticCharge,
          showRates: false,
          showSocketBusy: false,
          showChargeSteps: !automaticCharge,
          showChargeAutomatically: automaticCharge,
        }),
      );

      setLogEvent("charge_click", {
        charger_id: chargerId,
        socket_id: selectedSocket.id_socket,
        location_id: locationId,
      });
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoReservation = async (chargerId: string, socket: ChargerSocketModel) => {
    try {
      setIsLoading(true);

      const { valid } = await reloadData(chargerId, socket);

      if (!valid) return;

      router.push(APP_PATH.RESERVATION_STEPS);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {options.showLocationDetail && (
        <IonFooter className="location-detail-footer ion-no-border">
          <IonLoading isOpen={isLoading} />
          {((selectedSocket &&
            !isSocketAvailable(selectedSocket) &&
            selectedSocket.runtime_status_id !== CHARGER_SOCKET_RUNTIME_STATUS.RESERVED) ||
            locationInfo?.not_visible_private) && <div className="location-detail-footer-empty" />}

          {locationInfo && !locationInfo.not_visible_private && (
            <div className="location-detail-footer-buttons">
              {selectedSocket && selectedCharger && (
                <>
                  {socketReservationsAllowed && (
                    <IonButton
                      mode="ios"
                      color="primary"
                      fill="outline"
                      style={{ width: "50%" }}
                      onClick={() => handleGoReservation(selectedCharger.id_charger, selectedSocket)}
                    >
                      <IonIcon src={calendarOutline} slot="start" />
                      {t("BUTTONS.BOOK")}
                    </IonButton>
                  )}
                  <IonButton
                    mode="ios"
                    color="primary"
                    onClick={() => handleChargeClick(selectedCharger.id_charger, selectedSocket)}
                    style={{
                      width: socketReservationsAllowed ? "50%" : "100%",
                    }}
                  >
                    <IonIcon src={chargeIcon} slot="start" />
                    {t("BUTTONS.CHARGE")}
                  </IonButton>
                </>
              )}

              {!selectedSocket && !locationInfo.near && (
                <IonButton color="primary" onClick={() => handleGoLocationMaps(locationInfo)}>
                  <IonIcon icon={arrowRedoCircleOutline} slot="start" />
                  {locationInfo.distance >= 1 ? (
                    <>{Math.round(locationInfo.distance)} km </>
                  ) : (
                    <>{Math.round(locationInfo.distance * 1000)} m</>
                  )}
                </IonButton>
              )}
            </div>
          )}
        </IonFooter>
      )}
    </>
  );
};
