import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { useQuery } from "@/commons/hooks/useQuery";
import { SetState } from "@/core/models/globalModel";
import { VehicleBrand, VehicleBrandModel, VehicleFormModel } from "@/core/models/userModel";
import { createUserVehicleService, updateVehicleUserService } from "@/core/services/user/vehicleUserServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { t } from "i18next";
import { addCircleOutline, save } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

interface FormVehicleModalProps {
  vehicleBrands: VehicleBrand[];
  vehicleModels: Record<string, VehicleBrandModel[]>;
  modalType: "ADD" | "EDIT" | undefined;
  setModalType: SetState<"ADD" | "EDIT" | undefined>;
  vehicleEdit: VehicleFormModel;
  handleSuccess: () => void;
}

export const FormVehicleModal = ({
  vehicleBrands,
  vehicleModels,
  modalType,
  setModalType,
  vehicleEdit,
  handleSuccess,
}: FormVehicleModalProps) => {
  const dispatch = useAppDispatch();
  const { isEmpty } = useIsValidUser();
  const history = useHistory();
  const { searchParams } = useQuery();

  const { user, userInfo } = useAppSelector((state) => state.userReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const [vehicle, setVehicle] = useState<VehicleFormModel>(vehicleEdit);

  useEffect(() => {
    setVehicle(vehicleEdit);
  }, [vehicleEdit]);

  const handleGoBack = () => {
    setModalType(undefined);
    searchParams.delete("createVehicle");
    history.replace({ search: searchParams.toString() });
  };

  const handleChangeForm = (target: HTMLIonInputElement | HTMLIonSelectElement) => {
    const { name, value } = target;

    if (name === "vehicleBrandId") {
      return setVehicle({ ...vehicle, [name]: value, vehicle: "", vehicleModelId: undefined, model: "" });
    }

    if (name === "vehicleModelId") {
      return setVehicle({ ...vehicle, [name]: value, model: "" });
    }

    setVehicle({
      ...vehicle,
      [name]: value,
    });
  };

  const handlesSubmit = async () => {
    try {
      setIsLoading(true);

      const haveBrandId = vehicle.vehicleBrandId;
      const haveModelId = vehicle.vehicleModelId;
      const haveBrand = vehicle.vehicle;
      const haveModel = vehicle.model;

      if (!haveBrandId && (!haveBrand || !haveModel)) return setShowErrors(true);

      if (!haveModelId && !haveModel) return setShowErrors(true);

      if (modalType === "ADD") {
        await createUserVehicleService(user.user_id, vehicle);
        dispatch(uiActions.setToastType({ type: "addedVehicle" }));
      } else {
        await updateVehicleUserService(user.user_id, vehicle);
        dispatch(uiActions.setToastType({ type: "updatedVehicle" }));
      }

      handleSuccess();
      handleGoBack();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IonModal isOpen={!!modalType} onDidDismiss={handleGoBack}>
      {!!modalType && (
        <>
          <AppHeader
            title={t(`TITLES.${modalType === "ADD" ? "NEW" : "EDIT"}_VEHICLE`)}
            hiddenMenu
            startIcon={userInfo?.vehicle_id && <GoBackButton onClick={handleGoBack} />}
          />
          <IonContent className="ion-padding">
            <IonList className="ion-padding">
              <IonLoading isOpen={isLoading} />
              <IonItem lines="full" className="ion-margin-top">
                <IonInput
                  name="carId"
                  label={`${t("PROFILE.CAR_ID")} *`}
                  labelPlacement="floating"
                  className="number"
                  onIonInput={(event) => handleChangeForm(event.target)}
                  type="text"
                  value={vehicle.carId}
                />
              </IonItem>
              {isEmpty(vehicle.carId) && showErrors && (
                <IonLabel className="formError">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
              )}

              <IonItem lines="full" className="ion-margin-top">
                <IonSelect
                  label={`${t("CAR.BRAND")} *`}
                  labelPlacement="floating"
                  name="vehicleBrandId"
                  value={vehicle.vehicleBrandId}
                  onIonChange={(event) => handleChangeForm(event.target)}
                >
                  {vehicleBrands &&
                    vehicleBrands.map((brand) => (
                      <IonSelectOption key={brand.id} value={brand.id}>
                        {brand.name}
                      </IonSelectOption>
                    ))}
                  <IonSelectOption value={0}>{t("CAR.OTHER")}</IonSelectOption>
                </IonSelect>
              </IonItem>
              {isEmpty(vehicle.vehicleBrandId) && showErrors && (
                <IonLabel className="formError">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
              )}
              {vehicle.vehicleBrandId === 0 ? (
                <>
                  <IonItem lines="full" className="ion-margin-top">
                    <IonInput
                      name="vehicle"
                      type="text"
                      fill="solid"
                      label={`${t("CAR.BRAND")} *`}
                      labelPlacement="stacked"
                      value={vehicle.vehicle}
                      placeholder={t("PLACEHOLDERS.BRAND")}
                      onIonInput={(event) => handleChangeForm(event.target)}
                    />
                  </IonItem>
                  {isEmpty(vehicle.vehicle) && showErrors && (
                    <IonLabel className="formError">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
                  )}
                </>
              ) : (
                <>
                  <IonItem lines="full" className="ion-margin-top">
                    <IonSelect
                      label={`${t("CAR.MODEL")} *`}
                      labelPlacement="floating"
                      value={vehicle.vehicleModelId}
                      name="vehicleModelId"
                      onIonChange={(event) => handleChangeForm(event.target)}
                    >
                      {vehicleModels?.[vehicle.vehicleBrandId || 0]?.map((model) => (
                        <IonSelectOption key={model.id} value={model.id}>
                          {model.name}
                        </IonSelectOption>
                      ))}
                      <IonSelectOption value={0}>{t("CAR.OTHER")}</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  {isEmpty(vehicle.vehicleModelId) && showErrors && (
                    <IonLabel className="formError">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
                  )}
                </>
              )}
              {(vehicle.vehicleBrandId === 0 || vehicle.vehicleModelId === 0) && (
                <>
                  <IonItem lines="full" className="ion-margin-top">
                    <IonInput
                      name="model"
                      type="text"
                      fill="solid"
                      label={`${t("CAR.MODEL")} *`}
                      labelPlacement="stacked"
                      value={vehicle.model}
                      placeholder={t("PLACEHOLDERS.MODEL")}
                      onIonInput={(event) => handleChangeForm(event.target)}
                    />
                  </IonItem>
                  {isEmpty(vehicle.model) && showErrors && (
                    <IonLabel className="formError">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
                  )}
                </>
              )}
              <IonButton className="ion-margin-top" mode="ios" onClick={handlesSubmit}>
                <IonIcon icon={modalType === "ADD" ? addCircleOutline : save} slot="start"></IonIcon>
                {t(`BUTTONS.${modalType === "ADD" ? "ADD_VEHICLE" : "SAVE"}`)}
              </IonButton>
            </IonList>{" "}
          </IonContent>
        </>
      )}
    </IonModal>
  );
};
