import { Divider } from "@/commons/components/Divider/Divider";
import { IonContent, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";
import "./ActiveChargeDetail.scss";

export const ActiveChargeDetailSkeleton = () => {
  return (
    <IonContent className="ion-padding">
      <>
        <IonSkeletonText animated={true} style={{ width: "180px", height: "30px" }} />
        <div className="ion-margin-vertical">
          <div>
            <IonSkeletonText animated={true} style={{ width: "150px", height: "10px" }} />
          </div>
          <div>
            <IonSkeletonText animated={true} style={{ width: "150px", height: "10px" }} />
          </div>
        </div>
        <IonRow className="ion-margin-vertical">
          <IonSkeletonText animated={true} style={{ width: "180px", height: "40px" }} />
        </IonRow>
        <IonRow>
          <IonSkeletonText animated={true} style={{ width: "20px", height: "20px" }} />
          <IonSkeletonText animated={true} style={{ width: "120px" }} />
        </IonRow>
        <Divider />
        <div className="ion-padding-vertical">
          <IonSkeletonText animated={true} style={{ width: "120px" }} />
          <IonSkeletonText animated={true} style={{ width: "280px" }} />
          <IonSkeletonText animated={true} style={{ width: "80px" }} />
          <IonSkeletonText animated={true} style={{ width: "80px" }} />
        </div>
        <Divider />
        <IonSkeletonText animated={true} style={{ width: "190px" }} />
        <IonSkeletonText animated={true} style={{ width: "190px" }} />
        <IonRow className="ion-margin-vertical ">
          <IonLabel>
            <IonSkeletonText animated={true} style={{ width: "190px" }} />
          </IonLabel>
        </IonRow>

        <Divider />
        <div className="active-charge-detail-chart" style={{ height: 300 }}>
          <IonSkeletonText animated={true} style={{ width: "120px" }} />
        </div>
      </>
    </IonContent>
  );
};
