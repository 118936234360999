import i18n from "@/assets/i18n/i18n";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useIsSocketAvailable } from "@/commons/hooks/useIsSocketAvailable";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { CHARGER_SOCKET_RUNTIME_STATUS, ChargerModel, ChargerSocketModel } from "@/core/models/chargerModel";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { pricetagOutline } from "ionicons/icons";
import { SocketChargeType } from "./SocketChargeType/SocketChargeType";
import { SocketConnector } from "./SocketConnector/SocketConnector";
import { SocketRates } from "./SocketRates/SocketRates";
import "./SocketsInfo.scss";

interface SocketsInfoProps {
  charger: ChargerModel;
  socket: ChargerSocketModel;
  canSelectSocket?: boolean;
}

export const SocketsInfo = ({ charger, socket, canSelectSocket = true }: SocketsInfoProps) => {
  const dispatch = useAppDispatch();

  const { isSocketAvailable } = useIsSocketAvailable();

  const { selectedSocket, options } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { isUserLogged } = useAppSelector((state) => state.userReducer);

  const isActiveSocket = selectedSocket?.id_socket === socket.id_socket;
  const maxElectricPower = Number(socket.max_electric_power);
  const socketRuntimeStatusId = socket.runtime_status_id;
  const isAvailableSocket = isSocketAvailable(socket);

  const handleSelectedSocket = () => {
    if (!canSelectSocket) return;

    if (charger.app_disable_temporal && isAvailableSocket) {
      return dispatch(
        uiActions.setAlertType({
          type: "disabledCharger",
          options: {
            message: charger.app_disable_temporal_text?.[i18n.language],
          },
        }),
      );
    }

    if (isActiveSocket) {
      dispatch(mapActions.setSelectedSocket({ selectedCharger: null, selectedSocket: null }));
      return;
    }

    if (socketRuntimeStatusId === CHARGER_SOCKET_RUNTIME_STATUS.RESERVED) {
      if (socket.reservation_id) {
        return dispatch(mapActions.setSelectedSocket({ selectedCharger: charger, selectedSocket: socket }));
      }
    }

    if (!isAvailableSocket) {
      if (!isUserLogged) {
        return dispatch(uiActions.setAlertType({ type: "alertRegister" }));
      }

      dispatch(mapActions.setLocationOptions({ ...options, showSocketBusy: true }));
    }

    dispatch(mapActions.setSelectedSocket({ selectedCharger: charger, selectedSocket: socket }));
  };

  return (
    <div className={`socket-info ${classIf(isActiveSocket, "active-socket")}`} onClick={handleSelectedSocket}>
      <IonRow className="socket-info-detail-row">
        <IonCol size="5" className="socket-info-col-connector">
          <div className="socket-info-number">
            <IonLabel>{socket.socket_number}</IonLabel>
          </div>
          <SocketConnector connectorTypeCode={socket.connector_type_code} />
        </IonCol>
        <IonCol className="socket-info-col-charge-type">
          <SocketChargeType maxElectricPower={maxElectricPower} />
        </IonCol>
        <IonCol className="socket-info-rates" size="3">
          <SocketRates socket={socket} charger={charger} />
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-between">
        <StatusLabel active={isAvailableSocket}>{socket.runtime_status}</StatusLabel>
        {socket.physical_reference && (
          <IonLabel className="socket-info-physical-reference">
            <IonIcon icon={pricetagOutline}></IonIcon>
            <span>{socket.physical_reference}</span>
          </IonLabel>
        )}
      </IonRow>
    </div>
  );
};
