import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { ParamsApi } from "@/core/models/apiModel";
import { ReservationModel } from "@/core/models/reservationModel";

export const getReservationsService = async (cardCode: string, active?: boolean): Promise<ReservationModel[]> => {
  const params: ParamsApi = { cardCode };

  if (active) {
    params.active = active;
  }

  return api.get(`payments/get-list-reservations`, params).then(resolveResponse).catch(rejectError);
};

export const cancelReservationService = async (reservationId: string, cardCode: string): Promise<ReservationModel> => {
  return api
    .get(`payments/cancel-reservation-id`, { reservation_id: reservationId, cardCode })
    .then(resolveResponse)
    .catch(rejectError);
};

export const getPriceReservationService = async (socketId: string, time: number): Promise<number> => {
  return api
    .get("reservations/calculate_price", { socket_id: socketId, time })
    .then(resolveResponse)
    .catch(rejectError);
};
