import { IonCol, IonItem, IonLabel, IonList, IonRow, IonSkeletonText } from "@ionic/react";

export const NotificationsSkeleton = () => {
  return (
    <IonList>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonItem>
        <IonRow className="notification-row">
          <IonCol>
            <IonLabel className="notification-title">
              <IonSkeletonText animated={true} style={{ width: "200px", height: "15px" }} />
            </IonLabel>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "70px", height: "15px" }} />
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonItem>
    </IonList>
  );
};
