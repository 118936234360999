import { SelectInput, SelectOption } from "@/commons/components/Form/SelectInput/SelectInput";
import { SetState } from "@/core/models/globalModel";
import { getLocationFacilitiesService } from "@/core/services/location/locationServices";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ActionMeta, MultiValue, SingleValue } from "react-select";

export const FiltersServices = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { selectedFilters } = useAppSelector((state) => state.mapReducer.filters);

  const getFacilities = async (setLoading: SetState<boolean>) => {
    try {
      setLoading(true);
      const newFacilities = await getLocationFacilitiesService();

      return newFacilities.map((country) => ({
        value: `${country.id}`,
        label: country.name,
        icon: country.icon,
      }));
    } catch (error) {
      dispatch(uiActions.setToastError(error));
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    newValues: MultiValue<SelectOption> | SingleValue<SelectOption>,
    { action, removedValues }: ActionMeta<SelectOption>,
  ) => {
    newValues = newValues as MultiValue<SelectOption>;

    let newFilterNumber = selectedFilters.filterNumber;

    if (action === "select-option") {
      newFilterNumber++;
    }

    if (action === "remove-value") {
      newFilterNumber--;
    }

    if (action === "clear") {
      newFilterNumber -= removedValues.length;
    }

    dispatch(
      mapActions.setSelectedFilters({
        ...selectedFilters,
        facilities: newValues.map((value) => value.value),
        filterNumber: newFilterNumber,
      }),
    );
  };

  return (
    <div>
      <IonLabel className="filter-title">{t("TITLES.SERVICES")}</IonLabel>
      <SelectInput
        className="ion-margin-top ion-margin-bottom"
        label=""
        isMulti
        loadData={getFacilities}
        onChange={handleChange}
        closeMenuOnSelect={false}
        placeholder={t("TITLES.SELECT_SERVICE")}
        currentValues={selectedFilters.facilities}
      />
    </div>
  );
};
