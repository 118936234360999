import chargeIcon from "@/assets/imgs/icons/charge.svg";
import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { Illustration16 } from "@/commons/constants/imgs";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useQuery } from "@/commons/hooks/useQuery";
import { getUserAppCard } from "@/commons/utils/userUtils/userUtils";
import { getReservationsService } from "@/core/services/reservation/reservationServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IncidenceCardSkeleton } from "@/pages/IncidencesPage/IncidenceCard/IncidenceCardSkeleton";
import { IncidencesStepsSkeleton } from "@/pages/IncidencesPage/IncidencesStepsSkeleton";
import { APP_PATH } from "@/router/routes";
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  SegmentChangeEventDetail,
  useIonViewWillEnter,
} from "@ionic/react";
import { t } from "i18next";
import { calendarOutline, cashOutline, playOutline, stopOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReservationCard } from "./ReservationCard";
import "./ReservationsPage.scss";

export const ReservationsPage = () => {
  const dispatch = useAppDispatch();
  const { searchParams } = useQuery();

  const { parameters } = useParametersContext();
  const { user, userInfo } = useAppSelector((state) => state.userReducer);

  const [isLoading, setIsLoading] = useState(true);
  const [userReservations, setUserReservations] = useState<any[]>([]);
  const [activeReservations, setActiveReservations] = useState<any[]>([]);
  const [completedReservations, setCompletedReservations] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedReservation, setSelectedReservation] = useState<any>(null);
  const [showModalReservation, setShowModalReservation] = useState(false);
  const [startReservation, setStartReservaion] = useState("");
  const [timeStartReservation, setTimeStartReservation] = useState("");
  const [timeEndReservation, setTimeEndReservation] = useState("");
  const [priceReservation, setPriceReservation] = useState("");

  const history = useHistory();

  useIonViewWillEnter(() => {
    loadData();
  });

  useEffect(() => {
    if (activeReservations.length > 0) setCurrentStep(1);
    else setCurrentStep(0);
  }, [activeReservations]);

  useEffect(() => {
    if (selectedReservation) {
      const currentDate = selectedReservation.start_reservation;
      const dateFormat = new Date(currentDate);
      const monthName = t(`MONTH.${dateFormat.getMonth()}`);
      const stringDate = `${dateFormat.getDate()} de ${monthName} de ${dateFormat.getFullYear()}`;
      setStartReservaion(stringDate);

      const stringInitDate = selectedReservation.start_reservation;
      const dateInit = new Date(stringInitDate);
      const hourInit = dateInit.getHours().toString().padStart(2, "0");
      const minutesInit = dateInit.getMinutes().toString().padStart(2, "0");
      const formattedTimeStart = `${hourInit}:${minutesInit}`;
      setTimeStartReservation(formattedTimeStart);

      const stringEndDate = selectedReservation.end_reservation;
      const dateEnd = new Date(stringEndDate);
      const hourEnd = dateEnd.getHours().toString().padStart(2, "0");
      const minutesEnd = dateEnd.getMinutes().toString().padStart(2, "0");
      const formattedEndDate = `${hourEnd}:${minutesEnd}`;
      setTimeEndReservation(formattedEndDate);

      const amount = Number(selectedReservation.amount);
      const amountE = (amount / 100).toFixed(2);
      setPriceReservation(amountE);
    }
  }, [selectedReservation]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const card = await getUserAppCard(user.user_id, userInfo);

      const reservations = await getReservationsService(card.code_card);

      setUserReservations(reservations);
      const activeReservationsFound = reservations.filter((reservation: any) => !reservation.finished);
      if (activeReservationsFound) setActiveReservations(activeReservationsFound);
      const completedReservationsFound = reservations.filter((reservation: any) => reservation.finished);
      if (completedReservationsFound) setCompletedReservations(completedReservationsFound);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };
  interface SegmentCustomEvent extends CustomEvent {
    target: HTMLIonSegmentElement;
    detail: SegmentChangeEventDetail;
  }
  const handleStepClick = (event: SegmentCustomEvent) => {
    const index = parseInt(event.detail.value!.toString());
    setCurrentStep(index);
  };

  const handleClickReservation = (reservationInfo: any) => {
    if (reservationInfo) {
      setShowModalReservation(true);
      setSelectedReservation(reservationInfo);
    }
  };

  const dismissModal = () => {
    setShowModalReservation(false);
  };

  const handleGoLocation = (charge = false) => {
    searchParams.set("locationId", selectedReservation.location_id);
    searchParams.set("chargerId", selectedReservation.charger_id);
    searchParams.set("socketNumber", selectedReservation.socket_number);
    charge && searchParams.set("charge", "true");

    history.replace({
      pathname: APP_PATH.HOME,
      search: searchParams.toString(),
    });
  };

  const refresh = () => {
    window.location.reload();
  };

  const handleGoHome = () => {
    history.push(APP_PATH.HOME);
  };

  const steps = [
    `${t("RESERVATION_STEPS.ALL")}`,
    `${t("RESERVATION_STEPS.ACTIVE")}`,
    `${t("RESERVATION_STEPS.COMPLETED")}`,
  ];

  return (
    <IonPage>
      <AppHeader title={t("MENU.BOOKINGS")} />
      <IonContent>
        {isLoading ? (
          <>
            {/* TODO: make skeleton to reservations */}
            <IncidencesStepsSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
          </>
        ) : (
          <>
            {userReservations.length === 0 && (
              <IonList className="listReservationsContainer ion-padding">
                <Illustration16 className="imageReservations" />
                <IonList className="listReservationsContainer textReservation">
                  <IonLabel className="titleKeychain titleReservations">
                    {t("BOOKING_INFO.ANY_RESERVATION_TITLE")}
                  </IonLabel>
                  <IonText>{t("BOOKING_INFO.ANY_RESERVATION_TEXT")}</IonText>
                  <IonText>{t("BOOKING_INFO.ANY_RESERVATION_TEXT_2")}</IonText>
                </IonList>
                <IonButton style={{ height: "124px" }} onClick={handleGoHome}>
                  {t("BUTTONS.SEARCH_CHARGE_POINT")}
                </IonButton>
              </IonList>
            )}
            {userReservations.length > 0 && (
              <>
                <IonSegment className="segment" mode="md" value={currentStep.toString()} onIonChange={handleStepClick}>
                  {steps.map((step, index) => (
                    <IonSegmentButton key={index} value={index.toString()}>
                      <IonLabel>{step}</IonLabel>
                    </IonSegmentButton>
                  ))}
                </IonSegment>
              </>
            )}
            {currentStep === 0 &&
              userReservations.map((reservation) => (
                <ReservationCard onClick={handleClickReservation} reservationInfo={reservation} key={reservation.id} />
              ))}
            {currentStep === 1 &&
              activeReservations &&
              activeReservations.map((reservation: any, index) => (
                <ReservationCard
                  key={index}
                  onClick={handleClickReservation}
                  reservationInfo={reservation}
                  onFinish={refresh}
                />
              ))}
            {currentStep === 1 && activeReservations.length === 0 && (
              <IonList className="listReservationsContainerActiveTab ion-padding">
                <Illustration16 className="imageReservations" />
                <IonList className="listReservationsContainer textReservation">
                  <IonLabel className="titleKeychain titleReservations">
                    {t("BOOKING_INFO.ANY_ACTIVE_RESERVATIONS")}
                  </IonLabel>
                </IonList>
              </IonList>
            )}
            {currentStep === 2 &&
              completedReservations &&
              completedReservations.map((reservation: any) => (
                <ReservationCard onClick={handleClickReservation} reservationInfo={reservation} key={reservation.id} />
              ))}
            {currentStep === 2 && completedReservations.length === 0 && (
              <IonList className="listReservationsContainerActiveTab ion-padding">
                <Illustration16 className="imageReservations" />
                <IonList className="listReservationsContainer textReservation">
                  <IonLabel className="titleKeychain titleReservations">
                    {t("BOOKING_INFO.ANY_COMPLETED_RESERVATIONS")}
                  </IonLabel>
                </IonList>
              </IonList>
            )}
            {selectedReservation && (
              <IonModal
                isOpen={showModalReservation}
                className="selected-reservation-modal"
                onWillDismiss={dismissModal}
              >
                <AppHeader startIcon={<GoBackButton onClick={dismissModal} />} title={t("RESERVATION_STEPS.BOOK")} />
                <IonContent className="contentModalShowMore ion-margin-top" style={{ padding: "16px" }}>
                  <IonList className="contentModalShowMore ion-margin-top" style={{ padding: "16px" }}>
                    <IonCol className="ion-margin-bottom">
                      <IonLabel>{selectedReservation.charger}</IonLabel>
                      <IonLabel className="addressCharge">{selectedReservation.charger_address}</IonLabel>
                    </IonCol>
                    <img
                      style={{ marginTop: "35px" }}
                      className="chargerImage ion-margin-top"
                      src={selectedReservation.charger_logo}
                      alt=""
                    />
                    <IonRow className="colModal ion-margin-bottom ion-margin-top labelRow">
                      <IonIcon icon={calendarOutline} />
                      <IonLabel>{startReservation}</IonLabel>
                    </IonRow>
                    <div className="separatorLineModal">
                      <span className="lineModal clear" />
                    </div>
                    <IonRow className="ion-margin-top">
                      <IonCol className="colModal ion-align-items-center">
                        <IonIcon icon={playOutline} />
                        <IonLabel className="number">{timeStartReservation}</IonLabel>
                      </IonCol>
                      <IonCol className="colModal ion-align-items-center">
                        <IonIcon icon={stopOutline} />
                        <IonLabel className="number">{timeEndReservation}</IonLabel>
                      </IonCol>
                      <IonCol className="colModal ion-align-items-center">
                        {Number(priceReservation) ? (
                          <>
                            <IonIcon icon={cashOutline} />
                            <IonLabel className="number">
                              {priceReservation} {parameters.currency}
                            </IonLabel>
                          </>
                        ) : (
                          <StatusLabel>{t("CHARGER.FREE")}</StatusLabel>
                        )}
                      </IonCol>
                    </IonRow>
                    <div className="separatorLineModal">
                      <span className="lineModal clear" />
                    </div>
                    {!selectedReservation.finished && (
                      <IonButton className="ion-margin-top" onClick={() => handleGoLocation(true)}>
                        <IonIcon src={chargeIcon} slot="start" />
                        {t("BUTTONS.CHARGE")}
                      </IonButton>
                    )}
                  </IonList>
                </IonContent>
              </IonModal>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};
