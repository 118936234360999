import { CreditCard } from "@/commons/components/Payments/CreditCard/CreditCard";
import {
  PaymentFrame,
  PaymentFrameRespose,
  PaymentFrameType,
} from "@/commons/components/Payments/PaymentFrame/PaymentFrame";
import { WalletView } from "@/commons/components/Payments/WalletView/WalletView";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { AlertModal } from "@/commons/components/UI/AlertModal/AlertModal";
import { VehicleCard } from "@/commons/components/Vehicle/VehicleCard/VehicleCard";
import { DEFAULT_MAX_ELECTRIC_POWER } from "@/commons/constants/charger";
import { useChargeStart } from "@/commons/hooks/useChargeStart";
import { useCreateChargeAccount } from "@/commons/hooks/useCreateChargeAccount";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { formatNumber } from "@/commons/utils/numberUtils";
import { getSocketInfoPrice, getSocketMaxEnergy, getSocketTimeByEnergy } from "@/commons/utils/socketUtils/socketUtils";
import { getUserAppCard } from "@/commons/utils/userUtils/userUtils";
import { AccountModel } from "@/core/models/accountModel";
import { ChargerModel } from "@/core/models/chargerModel";
import { getPaymentPriceService } from "@/core/services/payment/paymentServices";
import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonLabel, IonLoading, IonSpinner } from "@ionic/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { ChargeStepChoose } from "../ChargeStepChoose/ChargeStepChoose";
import "./ChargeAutomatic.scss";

export const ChargeAutomatic = () => {
  const dispatch = useAppDispatch();
  const { parameters } = useParametersContext();
  const { handleStartCharge } = useChargeStart();

  const { selectedSocket, selectedCharger } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { chooseStep, activeStep, rateLimits, paymentStep, nextStepDisabled } = useAppSelector(
    (state) => state.chargeStepsReducer,
  );
  const { user, userInfo } = useAppSelector((state) => state.userReducer);
  const {
    selectedLocation: { options },
  } = useAppSelector((state) => state.mapReducer);

  const { energy, price, currency, isLoadingPrice } = chooseStep;
  const { paymentMethod } = paymentStep;

  const defaulVehicle = userInfo?.vehicles?.find((vehicle) => vehicle.vehicle_id === activeStep.vehicleId);

  // Search default select and if not exist, search predetermined
  const defaultCreditCard = userInfo?.stripe_payment_methods.find((creditCard) => creditCard.predetermined);

  const [isFreeCharge, setIsFreeCharge] = useState(false);
  const [haveRequiredBalance, setHaveRequiredBalance] = useState(false);
  const [paymentFrame, setPaymentFrame] = useState<{
    isOpen: boolean;
    type: PaymentFrameType;
  }>({
    isOpen: false,
    type: "redsysPayment",
  });
  const [response, setResponse] = useState<PaymentFrameRespose>();

  const setIsOpenPaymentFrame = (isOpen: boolean) => setPaymentFrame({ ...paymentFrame, isOpen });

  useEffect(() => {
    options.showChargeAutomatically && loadData();
  }, [options.showChargeAutomatically, userInfo]);

  useEffect(() => {
    if (paymentMethod === "WALLET") {
      calculateHaveRequiredBalance(price);
    }
  }, [price, paymentMethod]);

  const loadData = async () => {
    try {
      if (!selectedSocket) throw new Error(t("APP_ERRORS.SELECTED_SOCKET"));
      if (!selectedCharger) throw new Error(t("APP_ERRORS.SELECTED_CHARGER"));

      setIsLoading(true);

      // Choose step
      const { isFreeCharge } = getSocketInfoPrice(selectedSocket);
      setIsFreeCharge(isFreeCharge);

      const parametersCurrency = { symbol: parameters.currency, code: parameters.currency_code };

      const maxEnergy = getSocketMaxEnergy(selectedSocket, rateLimits);
      const defaultKWh = (userInfo?.default_charge_values.default_wh || 0) / 1000;
      const newEnergy = defaultKWh > maxEnergy ? maxEnergy : defaultKWh;

      const newTime = getSocketTimeByEnergy(selectedSocket.max_electric_power || DEFAULT_MAX_ELECTRIC_POWER, newEnergy);
      const priceObject = await getPaymentPriceService(selectedSocket.id_socket, newEnergy * 1000);
      const newPrice = priceObject.price / 100;
      const currency = priceObject.currency || parametersCurrency;

      dispatch(
        chargeStepsActions.setChooseStep({
          time: newTime,
          energy: newEnergy,
          price: newPrice,
          currency: currency,
        }),
      );

      // Activate step
      if (userInfo?.vehicle_id) {
        dispatch(chargeStepsActions.setActiveVehicleId(userInfo.vehicle_id));
      } else {
        dispatch(chargeStepsActions.setNextStepDisabled(true));
      }

      // Payment step
      setDefaultPaymentMethod(selectedCharger);

      const card = await getUserAppCard(user.user_id, userInfo);
      dispatch(chargeStepsActions.setPaymentStepCardCode(card.code_card));

      if (!card.code_card) {
        dispatch(chargeStepsActions.setNextStepDisabled(true));
      }
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const calculateHaveRequiredBalance = (newPrice: number) => {
    const wallerAmount = (userInfo?.wallet_amount || 0) / 100;
    const newHaveRequiredBalance = wallerAmount - newPrice >= 0;
    setHaveRequiredBalance(newHaveRequiredBalance);
  };

  const handleCreateAccount = (account: AccountModel | null, error?: any) => {
    if (error) return;

    dispatch(chargeStepsActions.setChargeStepOpenInLastStep(true));

    handleStartCharge(account);

    dispatch(
      mapActions.setLocationOptions({
        showLocationDetail: false,
        showRates: false,
        showSocketBusy: false,
        showChargeSteps: true,
        showChargeAutomatically: false,
      }),
    );
  };

  const { isLoading, setIsLoading, handleNextStep } = useCreateChargeAccount({
    callback: handleCreateAccount,
    setPaymentFrame,
    setResponse,
  });

  const handleCancelCharge = () => {
    dispatch(mapActions.setLocationOptionsKey({ key: "showChargeAutomatically", value: false }));
  };

  const handleEditCharge = () => {
    dispatch(
      mapActions.setLocationOptions({
        showLocationDetail: false,
        showRates: false,
        showSocketBusy: false,
        showChargeSteps: true,
        showChargeAutomatically: false,
      }),
    );
  };

  const setDefaultPaymentMethod = (selectedCharger: ChargerModel) => {
    const { payment_type } = parameters;
    const paymentMethods = selectedCharger.payment_methods || [];

    defaultCreditCard && dispatch(chargeStepsActions.setPaymentStepSelectPaymentId(defaultCreditCard.payment_method));

    const isBothPayment = payment_type.includes("both");

    if (isBothPayment && paymentMethods.length > 1) {
      dispatch(chargeStepsActions.setPaymentStepCanChangePaymentMethod(true));
      const defaultPaymentMethod =
        userInfo?.default_charge_values.active_automatic_charge &&
        userInfo?.default_charge_values.default_payment_method;

      if (defaultPaymentMethod) {
        return dispatch(
          chargeStepsActions.setPaymentStepPaymentMethod(
            defaultPaymentMethod === "credit_card" ? "CREDITCARD" : "WALLET",
          ),
        );
      }

      if (paymentMethods.includes("WALLET")) {
        return dispatch(chargeStepsActions.setPaymentStepPaymentMethod("WALLET"));
      }
    }

    if (isBothPayment && paymentMethods.length === 1) {
      dispatch(chargeStepsActions.setPaymentStepPaymentMethod(paymentMethods[0]));
      return;
    }

    if (paymentMethods.length > 1) {
      dispatch(chargeStepsActions.setPaymentStepCanChangePaymentMethod(true));
    }

    if (payment_type.includes("wallet") && paymentMethods.some((method) => method === "WALLET")) {
      dispatch(chargeStepsActions.setPaymentStepPaymentMethod("WALLET"));
    } else {
      dispatch(chargeStepsActions.setPaymentStepPaymentMethod("CREDITCARD"));
    }
  };

  return (
    <AlertModal
      className="charge-automatic"
      isOpen={options.showChargeAutomatically}
      handleDismiss={handleCancelCharge}
      primaryAction={() => handleNextStep(true)}
      primaryText={t("BUTTONS.CONFIRM")}
      primaryCloseOnClicked={false}
      primaryDisabled={(paymentMethod === "WALLET" && !haveRequiredBalance) || nextStepDisabled}
      secondaryAction={handleCancelCharge}
      secondaryText={t("ALERT_BUTTONS.CANCEL")}
    >
      <IonLoading isOpen={isLoading} />

      <PaymentFrame
        isOpen={paymentFrame.isOpen}
        setIsOpenPaymentFrame={setIsOpenPaymentFrame}
        response={response}
        callback={(values) => {
          if (!values?.success || !values?.account) {
            return dispatch(uiActions.setToastError(t("TOAST.ERROR_ACCOUNT_VALIDATION")));
          }

          dispatch(chargeStepsActions.setChargeStepAccount(values.account));

          handleCreateAccount(values.account, null);
        }}
        type={paymentFrame.type}
      />

      <div className="charge-automatic-content">
        <ChargeStepChoose isAutomaticCharge />

        <div className="charge-automatic-vehicle">
          <IonLabel className="title-h4">{t("TITLES.VEHICLE")}</IonLabel>
          {defaulVehicle ? (
            <VehicleCard vehicle={defaulVehicle} />
          ) : (
            <IonLabel color="danger">{t("VEHICLES.NO_DEFAULT_VEHICLE")}</IonLabel>
          )}
        </div>

        <div className="charge-automatic-payment">
          {!isFreeCharge && (
            <>
              <IonLabel className="title-h4">{t("INSTRUCTIONS_STEPS.PAYMENT")}</IonLabel>
              <div>
                {paymentMethod === "CREDITCARD" && (
                  <>
                    {defaultCreditCard ? (
                      <CreditCard creditCard={defaultCreditCard} />
                    ) : (
                      <IonLabel color="danger">{t("CREDIT_CARD.NO_DEFAULT_CARD")}</IonLabel>
                    )}
                  </>
                )}
                {paymentMethod === "WALLET" && <WalletView haveRequiredBalance={haveRequiredBalance} size="small" />}
              </div>
            </>
          )}
          <div className="price-info">
            <IonLabel>{t("TITLES.TOTAL")}</IonLabel>
            {isFreeCharge || (!isLoadingPrice && energy && price === 0) ? (
              <StatusLabel contrast>{t("CHARGER.FREE")}</StatusLabel>
            ) : isLoadingPrice ? (
              <IonSpinner className="price-loading" name="dots" />
            ) : (
              <IonLabel>
                {formatNumber(price, { minimumFractionDigits: 2 })} {currency?.symbol || parameters.currency}
              </IonLabel>
            )}
          </div>
        </div>

        <IonLabel className="charge-automatic-edit" onClick={handleEditCharge} color="primary">
          {t("BUTTONS.EDIT_DATA")}
        </IonLabel>
      </div>
    </AlertModal>
  );
};
