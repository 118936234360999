import { useOpenLocation } from "@/commons/hooks/useOpenLocation";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { getLocationIdFromScanner } from "@/commons/utils/scannerUtils";
import { useAppSelector } from "@/core/store/store";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { IonLoading } from "@ionic/react";
import { useEffect, useState } from "react";

export const DeepLinkContext = () => {
  const { openLocation } = useOpenLocation();
  const { parameters } = useParametersContext();

  const { user } = useAppSelector((state) => state.userReducer);

  const [isLoading, setIsLoading] = useState(false);

  // To add new path need to add on AndroidManifest.xml and apple-app-site-association (backend)
  useEffect(() => {
    App.addListener("appUrlOpen", async ({ url }: URLOpenListenerEvent) => {
      if (url) {
        try {
          setIsLoading(true);

          const locatioData = await getLocationIdFromScanner(url, parameters.token_api, user.auth_token);

          if (locatioData) {
            openLocation(locatioData.locationId, locatioData.chargeId, locatioData.socketNumber);
          }
        } catch (error) {
          console.error(error);
          openLocation(-1);
        } finally {
          setIsLoading(false);
        }
      }
    });
  }, []);

  return <IonLoading isOpen={isLoading} />;
};
