import { format } from "date-fns";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { ChargeChartTooltip } from "./ChargeChartTooltip";

export interface ChargeChartData {
  date: Date;
  time: string;
  power: number;
  percentBattery?: number;
}

interface ChargeChartProps {
  data: ChargeChartData[];
}

// This component need a parent with width and height

export const ChargeChart = ({ data }: ChargeChartProps) => {
  const dataToShow: ChargeChartData[] = data.length
    ? data
    : [{ power: 0, time: format(new Date(), "HH:mm"), date: new Date() }];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={dataToShow}
        margin={{
          top: 5,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis type="category" dataKey="time" dy={8} allowDuplicatedCategory={false} />
        <YAxis
          axisLine={false}
          label={{
            value: "kWh",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip content={<ChargeChartTooltip />} />
        <Line type="linear" strokeWidth={3} dataKey="power" stroke="var(--ion-color-primary)" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};
