import { CHARGER_SOCKET_CONNECTOR_ICON, CHARGE_SOCKET_CONECTOR_PERSONAL_DATA } from "@/commons/constants/charger";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { CHARGER_SOCKET_CONNECTOR_TYPE } from "@/core/models/chargerModel";
import { IonIcon, IonLabel } from "@ionic/react";
import { t } from "i18next";
import "./ConnectorInput.scss";

interface ConnectorInputProps {
  showErrors?: boolean;
  value: string;
  handleChange: (value: string) => void;
}

export const ConnectorInput = ({ showErrors, value, handleChange }: ConnectorInputProps) => {
  const { isEmpty, getLabel, checkRequiredFields } = useIsValidUser();

  const handleSelectedConnector = (socketName: string) => {
    const newValue = value === socketName ? "" : socketName;
    handleChange(newValue);
  };

  const sockets = Object.entries(CHARGE_SOCKET_CONECTOR_PERSONAL_DATA).filter(([, value]) => value.show);
  return (
    <>
      <div className="connector-input">
        <IonLabel className="title-h5">{getLabel("app_connector_default")}</IonLabel>
        <div className="sockets-list">
          {sockets.map((socket) => {
            const key = socket[0] as CHARGER_SOCKET_CONNECTOR_TYPE;
            const { name } = socket[1];
            return (
              <div
                key={key}
                className={`socket-default ${classIf(value === name, "active-connector")}`}
                onClick={() => handleSelectedConnector(name)}
              >
                <IonIcon src={CHARGER_SOCKET_CONNECTOR_ICON[key]} />
                <IonLabel>{name}</IonLabel>
              </div>
            );
          })}
        </div>
        {showErrors && checkRequiredFields("app_connector_default") && isEmpty(value) && (
          <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
        )}
      </div>
    </>
  );
};
