import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { t } from "i18next";
import { closeOutline } from "ionicons/icons";

interface ModalHeaderProps {
  title: string;
  hiddenCloseButton?: boolean;
  handleDismissModal: () => void;
}

export const ModalHeader = ({ title, hiddenCloseButton, handleDismissModal }: ModalHeaderProps) => {
  return (
    <IonHeader className="modal-header ion-no-border">
      <IonToolbar mode="ios">
        <IonTitle>{t(title)}</IonTitle>
        <IonButtons slot="end">
          {!hiddenCloseButton && (
            <IonButton color="dark" fill="clear" onClick={handleDismissModal}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};
