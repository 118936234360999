import { IonIcon, IonLabel } from "@ionic/react";
import { ReactNode } from "react";
import "./IconLabel.scss";

interface IconLabelProps {
  icon: string;
  children: ReactNode;
}

export const IconLabel = ({ icon, children }: IconLabelProps) => {
  return (
    <div className="icon-label">
      <IonIcon className="icon" icon={icon} />
      <IonLabel>{children}</IonLabel>
    </div>
  );
};
