import i18n from "@/assets/i18n/i18n";
import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { SPAIN_CODE } from "@/commons/constants/personalData";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { ParametersContext } from "@/context/appParametersContext";
import {
  CountryModel,
  DocumentTypeModel,
  VehicleBrand,
  VehicleBrandModel,
  VehicleFormModel,
} from "@/core/models/userModel";
import {
  checkExistEmailUserService,
  createUserService,
  getCountriesUserService,
  getDocuementTypesUserService,
} from "@/core/services/user/userServices";
import { getVehicleBrandsService } from "@/core/services/user/vehicleUserServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { LoginSocial } from "@/pages//LoginPage/LoginSocial/LoginSocial";
import { APP_PATH } from "@/router/routes";
import { Capacitor } from "@capacitor/core";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSpinner,
  IonToggle,
  IonicSafeString,
  useIonViewWillEnter,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./RegisterPage.scss";
import { FifthStep } from "./components/FifthStep";
import { FinalScreen } from "./components/FinalScreen";
import { FirstStep } from "./components/FirstStep";
import { FourthStep } from "./components/FourthStep";
import { SecondStep } from "./components/SecondStep";
import { ThirdStep } from "./components/ThirdStep";

export interface DefaultValues {
  default_kwh?: number;
  app_connector_default: string;
}

export const RegisterPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const platform = Capacitor.getPlatform();
  const { checkRequiredFields, isEmpty, isValidSpanishDNI } = useIsValidUser();

  const isIosPlatform = platform === "ios";
  const language = i18n.language;

  const { parameters } = useContext(ParametersContext);

  const { guestInfo } = useAppSelector((state) => state.userReducer);

  const [currentPage, setCurrentPage] = useState(1);
  const [isFilled, setIsFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCode, setResponseCode] = useState(0);

  const [vehicleBrands, setVehicleBrands] = useState<VehicleBrand[]>([]);
  const [vehicleModels, setVehicleModels] = useState<Record<string, VehicleBrandModel[]>>({});
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeModel[]>([]);

  // Step 1
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  // Step 2
  const [name, setName] = useState("");
  const [surname1, setSurname1] = useState("");
  const [surname2, setSurname2] = useState("");
  const [telf, setTelf] = useState("");
  const [documentType, setDocumentType] = useState<string>();
  const [dni, setDni] = useState("");
  const [documentCountry, setDocumentCountry] = useState("");
  const [isPublicAdministration, setIsPublicAdministration] = useState(false);
  const [dir3, setDir3] = useState("");
  const [businessData, setBusinessData] = useState<any>();

  // Step 3
  const [matricula, setMatricula] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState<number>();
  const [selectedModelId, setSelectedModelId] = useState<number>();
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");

  // Step 4
  const [countryId, setCountryId] = useState("");
  const [provinceId, setProvinceId] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // Step 5
  const [defaultChargeValues, setDefaultChargeValues] = useState<DefaultValues>({
    default_kwh: undefined,
    app_connector_default: "",
  });

  // Step 6
  const [notifyCommercial, setNotifyCommercial] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [acceptTermsUse, setAcceptTermsUse] = useState(false);

  const [showRecoverGuestDataAlert, setShowRecoverGuestDataAlert] = useState(false);
  const [guestEmail, setGuestEmail] = useState("");
  const [guestVehicle, setGuestVehicle] = useState<VehicleFormModel>();

  useIonViewWillEnter(() => {
    getCountries();
    getBrandsVehicles();
    getDocumentTypes();
  });

  useEffect(() => {
    checkFieldFilled();
  }, [
    email,
    password,
    confirmPassword,
    name,
    surname1,
    surname2,
    telf,
    documentType,
    dni,
    documentCountry,
    isPublicAdministration,
    dir3,
    businessData,
    matricula,
    selectedBrandId,
    selectedModelId,
    selectedBrand,
    selectedModel,
    countryId,
    provinceId,
    address,
    city,
    postalCode,
    defaultChargeValues,
    notifyCommercial,
    privacyPolicy,
    acceptTermsUse,
    currentPage,
  ]);

  useEffect(() => {
    const { email, vehicle } = guestInfo;
    if (email || vehicle) {
      setShowRecoverGuestDataAlert(true);
    }
  }, [guestInfo]);
  t;
  const handleRecieveNoifications = () => {
    setNotifyCommercial(!notifyCommercial);
  };

  const handlePrivacyPolicy = () => {
    setPrivacyPolicy(!privacyPolicy);
  };

  const handleAcceptTermsUse = () => {
    setAcceptTermsUse(!acceptTermsUse);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setIsFilled(false);
  };

  const handlePreviuosPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleToLogin = () => {
    history.replace(APP_PATH.LOGIN);
  };

  const handleSkipStep = () => {
    if (currentPage === 4) {
      setCountryId("");
      setProvinceId("");
      setAddress("");
      setCity("");
      setPostalCode("");
    } else if (currentPage === 5) {
      setDefaultChargeValues({
        default_kwh: undefined,
        app_connector_default: "",
      });
    }
    handleNextPage();
  };

  const checkFieldFilled = () => {
    if (currentPage === 1) {
      if (
        !isEmpty(email) &&
        !isEmpty(password) &&
        !isEmpty(confirmPassword) &&
        password === confirmPassword &&
        password.length >= 8
      ) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
      }
    } else if (currentPage === 2) {
      if (
        (checkRequiredFields("name") && isEmpty(name)) ||
        (checkRequiredFields("surname1") && isEmpty(surname1)) ||
        (checkRequiredFields("surname2") && isEmpty(surname2)) ||
        (checkRequiredFields("telf") && isEmpty(telf)) ||
        (checkRequiredFields("document_type") && isEmpty(documentType)) ||
        (checkRequiredFields("dni_nif") && isEmpty(dni)) ||
        (checkRequiredFields("document_country") && isEmpty(documentCountry)) ||
        (dni && documentCountry === SPAIN_CODE && !isValidSpanishDNI(dni))
      ) {
        return setIsFilled(false);
      }

      if (isPublicAdministration) {
        if (checkRequiredFields("dir3") && isEmpty(dir3)) {
          return setIsFilled(false);
        }
      }
      if (businessData?.active) {
        if (
          (checkRequiredFields("billing_user.reg_company") && isEmpty(businessData.reg_company)) ||
          (checkRequiredFields("billing_user.nif") && isEmpty(businessData.nif)) ||
          (checkRequiredFields("billing_user.fiscal_name") && isEmpty(businessData.fiscal_name)) ||
          (checkRequiredFields("billing_user.fiscal_address") && isEmpty(businessData.fiscal_address)) ||
          (checkRequiredFields("billing_user.city") && isEmpty(businessData.city)) ||
          (checkRequiredFields("billing_user.cp") && isEmpty(businessData.cp)) ||
          (checkRequiredFields("billing_user.billing_type") && isEmpty(businessData.billing_type))
        ) {
          return setIsFilled(false);
        }
      }

      setIsFilled(true);
    } else if (currentPage === 3) {
      if (!matricula.trim()) return setIsFilled(false);

      if (!selectedBrandId && (!selectedBrand.trim() || !selectedModel.trim())) return setIsFilled(false);

      if (!selectedModelId && !selectedModel.trim()) return setIsFilled(false);

      return setIsFilled(true);
    } else if (currentPage === 4) {
      if (
        (checkRequiredFields("country") && !countryId) ||
        (checkRequiredFields("state") && !provinceId) ||
        (checkRequiredFields("cp") && isEmpty(postalCode)) ||
        (checkRequiredFields("poblacio") && isEmpty(city)) ||
        (checkRequiredFields("ubicacio") && isEmpty(address))
      ) {
        setIsFilled(false);
      } else {
        setIsFilled(true);
      }
    }
  };

  const getCountries = async () => {
    try {
      const newCountries = await getCountriesUserService();
      setCountries(newCountries);
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setToastType({ type: "error" }));
    }
  };

  const getBrandsVehicles = async () => {
    try {
      const newVehiclesBrands = await getVehicleBrandsService();
      const newVehicleModels: Record<string, VehicleBrandModel[]> = {};
      newVehiclesBrands.forEach((brand) => {
        newVehicleModels[brand.id] = brand.models;
      });

      setVehicleBrands(newVehiclesBrands);
      setVehicleModels(newVehicleModels);
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setToastType({ type: "error" }));
    }
  };

  const getDocumentTypes = async () => {
    try {
      const newDocumentTypes = await getDocuementTypesUserService();
      setDocumentTypes(newDocumentTypes);
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setToastType({ type: "error" }));
    }
  };

  const handleReceiveData = (email: string, password: string, confirmPassword: string) => {
    setEmail(email);
    setPassword(password);
    setConfirmPassword(confirmPassword);
  };

  const handleReceiveDataStep2 = (
    name: string,
    surname1: string,
    surname2: string,
    telf: string,
    documentType: string | undefined,
    dni: string,
    documentCountry: string,
    isPublicAdministration: boolean,
    dir3: string,
    businessData: any,
  ) => {
    setName(name);
    setSurname1(surname1);
    setSurname2(surname2);
    setTelf(telf);
    setDocumentType(documentType);
    setDni(dni);
    setDocumentCountry(documentCountry);
    setIsPublicAdministration(isPublicAdministration);
    setDir3(dir3);
    setBusinessData(businessData);
  };

  const handleReceiveDataStep3 = (
    matricula: string,
    brand: string,
    model: string,
    brandId?: number,
    modelId?: number,
  ) => {
    setSelectedBrand(brand);
    setSelectedModel(model);
    setMatricula(matricula);
    setSelectedBrandId(brandId);
    setSelectedModelId(modelId);
  };

  const handleReceiveDataStep4 = (
    countryId: string,
    province: string,
    address: string,
    city: string,
    postalCode: string,
  ) => {
    setCountryId(countryId);
    setProvinceId(province);
    setCity(city);
    setAddress(address);
    setPostalCode(postalCode);
  };

  const handleRecoverGuestData = () => {
    const { email, vehicle } = guestInfo;

    email && setGuestEmail(email);

    vehicle && setGuestVehicle(vehicle);
  };

  const handleAlertDismiss = () => {
    setShowRecoverGuestDataAlert(false);
  };

  const handleOpenPolicy = () => {
    parameters.url_privacy && window.open(parameters.url_privacy);
  };

  const handleOpenTerms = () => {
    parameters.url_terms && window.open(parameters.url_terms);
  };

  const handleNextPageFirstStep = async () => {
    try {
      if (isCheckingEmail) return;

      setIsCheckingEmail(true);
      const { exist } = await checkExistEmailUserService(email);

      if (exist) {
        return setEmailExist(true);
      }

      handleNextPage();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const handleSubmitFinal = async () => {
    try {
      setIsLoading(true);

      let params: any = {
        email,
        password,
        name,
        surname1,
        surname2,
        document_type: documentType,
        dni_nif: documentType === "dni" && documentCountry === SPAIN_CODE ? `${"0".repeat(9 - dni.length)}${dni}` : dni,
        document_country: documentCountry,
        is_public_administration: isPublicAdministration,
        dir3,
        telf,
        ubicacio: address,
        country: countryId,
        state: provinceId,
        poblacio: city,
        cp: postalCode,
        idioma: language,
        default_wh: defaultChargeValues.default_kwh
          ? defaultChargeValues.default_kwh * 1000
          : defaultChargeValues.default_kwh,
        app_connector_default: defaultChargeValues.app_connector_default,
        car_id: matricula,
        vehicle_brand: `${selectedBrandId}`,
        vehicle_model: `${selectedModelId}`,
        vehicle: selectedBrand,
        model: selectedModel,
        notify_commercial: notifyCommercial,
        privacy_policy: privacyPolicy,
        active: "true",
        domain_id: parameters.domain_id,
      };

      if (businessData?.active) {
        params = {
          ...params,
          billing_user: "true",
          billing_fiscal_name: businessData.fiscal_name,
          billing_nif: businessData.nif,
          billing_reg_company: businessData.reg_company,
          billing_fiscal_address: businessData.fiscal_address,
          billing_city: businessData.city,
          billing_cp: businessData.cp,
          billing_type: businessData.billing_type,
        };
      } else {
        params = {
          ...params,
          billing_user: "false",
        };
      }

      const response = await createUserService(params, parameters);
      setResponseCode(response);
    } catch (error) {
      console.error(error);
      setResponseCode(-1);
    } finally {
      handleNextPage();
      setIsLoading(false);
    }
  };

  return (
    <IonPage>
      <AppHeader
        hiddenMenu
        startIcon={
          currentPage < 7 && (
            <GoBackButton onClick={() => (currentPage === 1 ? handleToLogin() : handlePreviuosPage())} />
          )
        }
      />
      <IonContent>
        <IonLoading isOpen={isLoading} message={t("LOADING.LOADING")} duration={4000} />
        {currentPage === 1 && (
          <IonList className="stepPage" mode="md">
            <FirstStep
              values={{ email, password, confirmPassword }}
              emailGuest={guestEmail}
              emailExist={emailExist}
              setEmailExist={setEmailExist}
              onStringsReceived={handleReceiveData}
            />
            <IonButton
              onClick={handleNextPageFirstStep}
              disabled={!isFilled}
              className="ion-margin-bottom ion-margin-top"
            >
              {isCheckingEmail ? <IonSpinner /> : t("BUTTONS.NEXT")}
            </IonButton>

            <div className="separatorLine">
              <span className="line" />
              <IonLabel>o</IonLabel>
              <span className="line" />
            </div>

            <LoginSocial showIosButton={isIosPlatform} />
          </IonList>
        )}
        {currentPage === 2 && (
          <IonList className="stepPage" mode="md">
            <SecondStep
              values={{
                name,
                surname1,
                surname2,
                telf,
                documentType,
                dni,
                documentCountry,
                isPublicAdministration,
                dir3,
                businessData,
              }}
              onStringsReceived={handleReceiveDataStep2}
              countries={countries}
              documentTypes={documentTypes}
            />
            <IonButton onClick={handleNextPage} disabled={!isFilled} className="ion-margin-bottom ion-margin-top">
              {t("BUTTONS.NEXT")}
            </IonButton>
          </IonList>
        )}

        {currentPage === 3 && (
          <IonList className="stepPage" mode="md">
            <ThirdStep
              values={{
                cardId: matricula,
                brand: selectedBrand,
                model: selectedModel,
                brandId: selectedModelId,
                modelId: selectedModelId,
              }}
              guestVehicle={guestVehicle}
              vehicleBrands={vehicleBrands}
              vehicleModels={vehicleModels}
              onStringsReceived={handleReceiveDataStep3}
            />
            <IonButton disabled={!isFilled} onClick={handleNextPage} className="ion-margin-bottom ion-margin-top">
              {t("BUTTONS.NEXT")}
            </IonButton>
          </IonList>
        )}

        {currentPage === 4 && (
          <IonList className="stepPage" mode="md">
            <FourthStep
              values={{ countryId, provinceId, address, city, postalCode }}
              onStringsReceived={handleReceiveDataStep4}
              checkFieldFilled={checkFieldFilled}
              countries={countries}
            />

            {!checkRequiredFields("country") &&
              !checkRequiredFields("state") &&
              !checkRequiredFields("cp") &&
              !checkRequiredFields("poblacio") &&
              !checkRequiredFields("ubicacio") && (
                <IonButton
                  disabled={!isFilled}
                  onClick={handleSkipStep}
                  className="ion-margin-top"
                  color="primary"
                  fill="outline"
                >
                  {t("BUTTONS.SKIP_STEPS")}
                </IonButton>
              )}
            <IonButton disabled={!isFilled} onClick={handleNextPage} className="ion-margin-bottom ion-margin-top">
              {t("BUTTONS.NEXT")}
            </IonButton>
          </IonList>
        )}

        {currentPage === 5 && (
          <IonList className="stepPage" mode="md">
            <FifthStep defaultChargeValues={defaultChargeValues} setDefaultChargeValues={setDefaultChargeValues} />
            <IonButton onClick={handleSkipStep} className="ion-margin-top" color="primary" fill="outline">
              {t("BUTTONS.SKIP_STEPS")}
            </IonButton>
            <IonButton onClick={handleNextPage} className="ion-margin-bottom ion-margin-top">
              {t("BUTTONS.NEXT")}
            </IonButton>
          </IonList>
        )}

        {currentPage === 6 && (
          <IonList className="stepPage" mode="md">
            <IonRow className="ion-margin-bottom">
              <IonLabel className="neutralBlack70">
                {t("STEPS.STEP")} <IonLabel className="number number-bold">6</IonLabel> {t("STEPS.OUT_OF")}{" "}
                <IonLabel className="number number-bold">6</IonLabel>
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="titleH3">{t("TITLES.PRIVACY_TERMS")}</IonLabel>
            </IonRow>

            <IonCol className="policy-container">
              <div>
                <IonLabel>{t("POLICIES.RECIEVE_PROMOCIONAL_INFO")}</IonLabel>
                <IonToggle slot="end" onClick={handleRecieveNoifications} mode="ios" checked={notifyCommercial} />
              </div>
              <div>
                <IonLabel onClick={handleOpenPolicy}>
                  <span className="underline">{t("POLICIES.PRIVACY_POLICY")}</span>
                  <span>{" *"}</span>
                </IonLabel>
                <IonToggle slot="end" onClick={handlePrivacyPolicy} mode="ios" checked={privacyPolicy} />
              </div>
              <div>
                <IonLabel onClick={handleOpenTerms}>
                  <span className="underline">{t("POLICIES.TERMS_USE")}</span>
                  <span>{" *"}</span>
                </IonLabel>
                <IonToggle slot="end" onClick={handleAcceptTermsUse} mode="ios" checked={acceptTermsUse} />
              </div>
            </IonCol>

            <IonButton
              onClick={handleSubmitFinal}
              className="ion-margin-bottom ion-margin-top"
              disabled={!privacyPolicy || !acceptTermsUse}
            >
              {t("BUTTONS.END")}
            </IonButton>
          </IonList>
        )}

        {currentPage === 7 && <FinalScreen responseCode={responseCode} name={name} />}

        <IonAlert
          isOpen={showRecoverGuestDataAlert}
          onDidDismiss={handleAlertDismiss}
          message={
            new IonicSafeString(
              `<div class="alert">
                        <ion-label class="titleH3">Datos de usuario detectados</ion-label>
                        <ion-label class="alertText">Quieres hacer el registro con los datos que usaste en tu carga anterior?</ion-label>
                      </div>`,
            )
          }
          buttons={[
            `${t("ALERT_BUTTONS.CANCEL")}`,
            {
              text: `${t("BUTTONS.ACCEPT")}`,
              handler: handleRecoverGuestData,
            },
          ]}
          cssClass="alertContainer"
        />
      </IonContent>
    </IonPage>
  );
};
