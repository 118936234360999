import { useOpenLocation } from "@/commons/hooks/useOpenLocation";
import { useAppSelector } from "@/core/store/store";
import { IonButton, IonIcon, IonRow } from "@ionic/react";
import { t } from "i18next";
import { navigate } from "ionicons/icons";
import L, { LatLng, Map } from "leaflet";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { RouterMarker } from "../RouteMarker/RouteMarker";

const GOOGLE_MAPS_URL = "https://www.google.com/maps/dir/?api=1";

export const RouteMap = () => {
  const { openLocation } = useOpenLocation();

  const { waypoints, originIsUser } = useAppSelector((state) => state.mapReducer.routeInfo);

  const [mapRef, setMapRef] = useState<Map | null>(null);

  useEffect(() => {
    if (!mapRef) return;

    const waypointsPlan = waypoints.map((waypoint) => {
      const latLng = new LatLng(waypoint.lat, waypoint.lng);
      return new L.Routing.Waypoint(latLng, waypoint.id.toString(), {});
    });

    const plan = L.Routing.plan(waypointsPlan, {
      createMarker: (waypointIndex, waypoint, numberOfWaypoints) =>
        RouterMarker(waypointIndex, waypoint, numberOfWaypoints, openLocation),
    });

    const routingControl = L.Routing.control({
      waypoints: waypointsPlan,
      routeWhileDragging: true,
      show: false,
      lineOptions: {
        styles: [
          {
            color: "var(--ion-color-routing-line)",
            opacity: 0.5,
            weight: 5,
          },
        ],
        extendToWaypoints: false,
        missingRouteTolerance: 0,
      },
      plan,
    }).addTo(mapRef);

    const lastWaypoint = waypoints.at(-1) || new LatLng(0, 0);
    mapRef.setView(lastWaypoint, 13);

    return () => {
      if (mapRef) {
        mapRef.removeControl(routingControl);
      }
    };
  }, [mapRef, waypoints]);

  const formatWaypoints = (deleteFirst = false) => {
    return `waypoints=${waypoints
      .slice(deleteFirst ? 1 : 0, waypoints.length - 1)
      .map((waypoint) => `${waypoint.lat},${waypoint.lng}`)
      .join("|")}`;
  };

  const createRoute = () => {
    const firstWaypoint = waypoints[0];
    const lastWaypoint = waypoints[waypoints.length - 1];
    const destination = `destination=${lastWaypoint.lat},${lastWaypoint.lng}`;

    const url = originIsUser
      ? `${destination}&${formatWaypoints()}`
      : `origin=${firstWaypoint.lat},${firstWaypoint.lng}&${destination}&${formatWaypoints(true)}`;

    window.open(`${GOOGLE_MAPS_URL}&${url}`, "_blank");
  };

  return (
    <>
      <div className="map-container">
        <MapContainer ref={setMapRef} center={waypoints.at(-1) || new LatLng(0, 0)} zoom={13}>
          <TileLayer
            className="map-tiles"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution=""
            noWrap
            bounds={[
              [-90, -180],
              [90, 180],
            ]}
          />
        </MapContainer>
      </div>
      <IonRow>
        <IonButton onClick={createRoute} className="ion-margin">
          <IonIcon icon={navigate} slot="start" />
          {t("BUTTONS.SEE_ROUTE")}
        </IonButton>
      </IonRow>
    </>
  );
};
