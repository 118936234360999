import { ActiveChargeDetail, ActiveChargeModel } from "@/core/models/chargeModel";
import { CHARGER_STATUS_IDS, ChargerModel } from "@/core/models/chargerModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { differenceInDays } from "date-fns";

interface chargesSlice {
  activeCharges: ActiveChargeModel[];
  activeChargeDetail: ActiveChargeDetail;
}

const chargeSliceInitialState: chargesSlice = {
  activeCharges: [],
  activeChargeDetail: {
    showDetail: false,
    activeChargeId: -1,
  },
};

const chargesSlice = createSlice({
  name: "chargesReducer",
  initialState: chargeSliceInitialState,
  reducers: {
    setActiveCharges: (state, { payload: activeCharges }: PayloadAction<ActiveChargeModel[]>) => {
      state.activeCharges = activeCharges;
    },
    addActiveCharge: (state, { payload: newActiveCharge }: PayloadAction<ActiveChargeModel>) => {
      const { charge_id } = newActiveCharge;
      const existingPayload = state.activeCharges.find((payload) => payload.charge_id === charge_id);

      if (!existingPayload) {
        state.activeCharges = [...state.activeCharges, newActiveCharge];
      }
    },
    removeActivePayload: (state, { payload: charge_id }: PayloadAction<number>) => {
      state.activeCharges = state.activeCharges.filter((charge) => charge.charge_id !== charge_id);
    },
    // Active charge detail
    setActiveChargeDetail: (state, { payload: activeChargeDetail }: PayloadAction<ActiveChargeDetail>) => {
      state.activeChargeDetail = activeChargeDetail;
    },
    clearActiveChargeDetail: (state) => {
      state.activeChargeDetail = chargeSliceInitialState.activeChargeDetail;
    },
    checkVisibleCharge: (
      state,
      { payload: { chargeId, charger } }: PayloadAction<{ chargeId: number; charger: ChargerModel }>,
    ) => {
      const activeCharge = state.activeCharges.find((charge) => charge.charge_id === chargeId);

      if (activeCharge) {
        // Check if it has been 2 days since the charge started
        const isTwoDays = differenceInDays(activeCharge.started_at, new Date()) >= 2;

        activeCharge.hidden = isTwoDays && charger.id_status === CHARGER_STATUS_IDS.OUT_OF_SERVICE;
      }
    },
  },
});

export const chargesActions = chargesSlice.actions;

export default chargesSlice.reducer;
