import { CHARGER_INSTRUCTION_TYPES } from "@/commons/constants/charger";
import { useAppSelector } from "@/core/store/store";
import { useEffect, useState } from "react";
import "./ChargeStepCharge.scss";
import { InstructionCharge } from "./InstructionCharge/InstructionCharge";

export const ChargeStepCharge = () => {
  const [instructions, setInstructions] = useState<CHARGER_INSTRUCTION_TYPES[]>([]);
  const [instructionsDisabled, setInstructionsDisabled] = useState([false, false, false]);

  const { selectedSocket } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { chargeStep } = useAppSelector((state) => state.chargeStepsReducer);

  useEffect(() => {
    const instructionType = selectedSocket?.instructions_type;
    if (instructionType) {
      const newInstructions: Record<string, { type: CHARGER_INSTRUCTION_TYPES; disabled: boolean }[]> = {
        "1": [
          { type: CHARGER_INSTRUCTION_TYPES.CONNECT_VEHICLE, disabled: false },
          { type: CHARGER_INSTRUCTION_TYPES.PRESS_START, disabled: false },
          { type: CHARGER_INSTRUCTION_TYPES.CONNECT_WAIT, disabled: true },
        ],
        "2": [
          { type: CHARGER_INSTRUCTION_TYPES.CONNECT_VEHICLE, disabled: false },
          { type: CHARGER_INSTRUCTION_TYPES.PRESS_START, disabled: false },
          { type: CHARGER_INSTRUCTION_TYPES.WAIT_START, disabled: true },
        ],
        "3": [
          { type: CHARGER_INSTRUCTION_TYPES.PRESS_UNLOCK, disabled: false },
          { type: CHARGER_INSTRUCTION_TYPES.CONNECT_VEHICLE, disabled: true },
          { type: CHARGER_INSTRUCTION_TYPES.WAIT_START, disabled: true },
        ],
        "4": [
          { type: CHARGER_INSTRUCTION_TYPES.PRESS_START, disabled: false },
          { type: CHARGER_INSTRUCTION_TYPES.CONNECT_VEHICLE, disabled: true },
          { type: CHARGER_INSTRUCTION_TYPES.WAIT_START, disabled: true },
        ],
      };

      setInstructions(newInstructions[instructionType].map((instruction) => instruction.type));
      setInstructionsDisabled(newInstructions[instructionType].map((instruction) => instruction.disabled));
    }
  }, [selectedSocket]);

  useEffect(() => {
    chargeStep.isChargeInit && onInitCharge();
  }, [chargeStep.isChargeInit]);

  const onInitCharge = () => {
    const newInstructionsDisabled = instructionsDisabled.map((instruction) => !instruction);
    setInstructionsDisabled(newInstructionsDisabled);
  };

  return (
    <div className="charge-step-charge">
      {selectedSocket && (
        <div className="charge-step-charge-instructions-list">
          {instructions.map((instruction, index) => (
            <InstructionCharge key={index} chargeInstructionType={instruction} disabled={instructionsDisabled[index]} />
          ))}
        </div>
      )}
    </div>
  );
};
