import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { PromotionalCodeModel } from "@/core/models/promotionalCodeModel";
import { useAppSelector } from "@/core/store/store";
import { IonIcon, IonItem, IonLabel, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { calendarClearOutline } from "ionicons/icons";
import "./PromotionalCodeCard.scss";

interface PromotionalCodeCardProps {
  promotionalCode: PromotionalCodeModel;
}

export const PromotionalCodeCard = ({ promotionalCode }: PromotionalCodeCardProps) => {
  const { parameters } = useAppSelector((state) => state.parametersReducer);
  const { amount, description, active, expired, activation, expiration } = promotionalCode;

  const isActive = active && !expired;

  return (
    <IonItem className="promotional-code">
      <div className="promotional-code-content">
        <IonRow className="promotional-code-title">
          <IonLabel>{description}</IonLabel>
          <h3>
            {amount / 100} {parameters.currency}
          </h3>
        </IonRow>
        <IonRow className="dates">
          <IonIcon className="icon" icon={calendarClearOutline} />
          <IonLabel>
            {format(activation, "dd/MM/yyyy")} - {format(expiration, "dd/MM/yyyy")}
          </IonLabel>
        </IonRow>
        <IonRow>
          <StatusLabel active={isActive}>{isActive ? "Activo" : "Caducado"}</StatusLabel>
        </IonRow>
      </div>
    </IonItem>
  );
};
