import { Illustration2, Illustration8 } from "@/commons/constants/imgs";
import { ParametersContext } from "@/context/appParametersContext";
import { APP_PATH } from "@/router/routes";
import { IonAlert, IonButton, IonLabel, IonList, IonRow, IonicSafeString } from "@ionic/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface FinalScreenProps {
  responseCode: number;
  name: string;
}

export const FinalScreen = ({ name, responseCode }: FinalScreenProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { parameters } = useContext(ParametersContext);

  const handleGoLogin = () => {
    history.replace(APP_PATH.LOGIN);
  };

  return (
    <>
      {responseCode === 422 && (
        <IonList className="stepPage" mode="md">
          <IonRow>
            <Illustration2 className="registerFailed" />
          </IonRow>
          <IonRow>
            <IonLabel className="titleH3">{t("ALERT.REGISTER_ERROR_TITLE")}</IonLabel>
          </IonRow>
          <IonRow>
            <IonLabel className="ion-margin-top">{t("ALERT.REGISTER_ERROR_MESSAGE")}</IonLabel>
          </IonRow>
          <IonButton className="ion-margin-top" onClick={handleGoLogin}>
            {t("BUTTONS.GO_START")}
          </IonButton>
        </IonList>
      )}
      {responseCode !== 422 && responseCode !== -1 && (
        <>
          <IonAlert
            isOpen={true}
            message={
              new IonicSafeString(
                `<div class="alert">
                    <ion-label class="alertText">${t("ALERT.USER_SUCCESS_REGISTER")}</ion-label>
                </div>`,
              )
            }
            buttons={["OK"]}
            cssClass="alertContainer"
          />
          <IonList className="stepPage" mode="md">
            <IonRow>
              <Illustration8 className="registerFailed" />
            </IonRow>
            <IonRow>
              <IonLabel className="titleH3">
                {name}
                {t("STEPS.ACCOUNT_CREATED")}
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel className="ion-margin-top">{t("STEPS.INFO_FINAL", { name: parameters.name })}</IonLabel>
            </IonRow>
            <IonButton className="ion-margin-top" onClick={handleGoLogin}>
              {t("BUTTONS.GO_START")}
            </IonButton>
          </IonList>
        </>
      )}
      {responseCode === -1 && (
        <IonList className="stepPage" mode="md">
          <IonRow>
            <Illustration2 className="registerFailed" />
          </IonRow>
          <IonRow>
            <IonLabel className="titleH3">{t("ALERT.REGISTER_ERROR_TITLE")}</IonLabel>
          </IonRow>
          <IonRow>
            <IonLabel className="ion-margin-top">{t("ALERT.SERVER_ERROR_TEXT")}</IonLabel>
          </IonRow>
          <IonButton className="ion-margin-top" onClick={handleGoLogin}>
            {t("BUTTONS.GO_START")}
          </IonButton>
        </IonList>
      )}
    </>
  );
};
