import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { RatesUserLimits } from "@/core/models/rateModel";
import { getUserChargeLimitsService } from "@/core/services/user/userServices";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonCol, IonContent, IonLabel, IonModal, IonRow } from "@ionic/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { RateLimits } from "../../RateLimits/RateLimits";
import "./Rates.scss";
import { RatesPeriods } from "./RatesPeriods/RatesPeriods";

export const Rates = () => {
  const dispatch = useAppDispatch();

  const { parameters } = useParametersContext();

  const [ratesUserLimits, setRatesUserLimits] = useState<RatesUserLimits>();

  const { locationInfo, selectedSocket, options } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { user, theme } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    options.showRates && getLimits();
  }, [selectedSocket]);

  const handleClose = () => {
    // Hidden rates modal
    dispatch(
      mapActions.setLocationOptions({
        ...options,
        showRates: false,
      }),
    );

    // Clean selected socket
    dispatch(mapActions.setSelectedSocket({ selectedCharger: null, selectedSocket: null }));
  };

  const getLimits = async () => {
    if (selectedSocket && selectedSocket.rates.length > 0) {
      try {
        const userRatesLimits = await getUserChargeLimitsService(selectedSocket.id_socket, user.user_id);
        setRatesUserLimits(userRatesLimits);
      } catch (error) {
        dispatch(uiActions.setToastError(error));
      }
    }
  };

  return (
    <IonModal isOpen={options.showRates}>
      {locationInfo && (
        <>
          <AppHeader startIcon={<GoBackButton onClick={handleClose} />} title={t("BUTTONS.RATES")} />
          <IonContent className="rates-page">
            <IonLabel className="rates-page-title-page">{`${t("RATES.TARIFS_OF")} ${locationInfo.address}`}</IonLabel>
            {selectedSocket?.rates.map((rate) => {
              const penalty = rate.rate_join_consumptions.find(
                (consumption) => consumption.code === "OCCUPANCY_PENALTY",
              );
              return (
                <div key={`rate-${rate.id_rate}`} className="container-rates">
                  <IonCol className="container-rates-rate">
                    <IonLabel className="container-rates-title">{rate.title}</IonLabel>
                  </IonCol>
                  {rate.rate_join_consumptions && (
                    <IonCol className="container-rates-rate-join">
                      {rate.rate_join_consumptions
                        .filter((consumption) => consumption.code !== "OCCUPANCY_PENALTY")
                        .map((consumption, index) => (
                          <IonRow key={index}>
                            <IonLabel className="price">
                              {consumption.price_base} {consumption.rate_consumption} {penalty ? "*" : ""}
                            </IonLabel>
                          </IonRow>
                        ))}
                    </IonCol>
                  )}
                  <IonCol className="container-rates-rate">
                    <IonRow className="info-rate">
                      <IonCol className="info-rate-schedule-col">
                        <IonRow className="field">
                          <IonLabel className="field-title">{t("RATES.INIT_DATE") + ": "}</IonLabel>
                          <div className="field-value">
                            <IonLabel>{rate.start_date}</IonLabel>
                            <span>-</span>
                            <IonLabel>{rate.end_date}</IonLabel>
                          </div>
                        </IonRow>

                        {!!Number(rate.min_price) && (
                          <IonRow className="field">
                            <IonLabel className="field-title">{t("RATES.MIN_IMPORT") + ":"}</IonLabel>
                            <IonLabel className="field-value">
                              {rate.min_price} {parameters.currency}
                            </IonLabel>
                          </IonRow>
                        )}
                        {!!Number(rate.max_price) && (
                          <IonRow className="field">
                            <IonLabel className="field-title">{t("RATES.MAX_IMPORT") + ":"}</IonLabel>
                            <IonLabel className="field-value">
                              {rate.max_price} {parameters.currency}
                            </IonLabel>
                          </IonRow>
                        )}
                        {penalty && (
                          <div className={`penalty ${theme}`}>
                            <Trans
                              i18nKey="RATES.PENALTY"
                              values={{
                                price_base: penalty.price_base,
                                period_consumption: penalty.rate_consumption,
                                time_limit: penalty.occupancy_penalty_time_limit,
                                max_penalty_amount: penalty.max_penalty_amount,
                                currency: parameters.currency,
                              }}
                            />
                          </div>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  {rate.periods.length > 0 && <RatesPeriods rate={rate} />}
                  <div className="additional-info">
                    <div dangerouslySetInnerHTML={{ __html: rate.additional_info }} />
                    {ratesUserLimits && (
                      <RateLimits
                        selectedSocket={selectedSocket}
                        rateLimits={ratesUserLimits[rate.id_rate]}
                        hideSocketLimits
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </IonContent>
        </>
      )}
    </IonModal>
  );
};
