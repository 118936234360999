import { RemoveButton } from "@/commons/components/Buttons/RemoveButton/RemoveButton";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { CHARGER_STATUS_TRANSLATE } from "@/commons/constants/charger";
import { useLocationContext } from "@/commons/hooks/useLocationContext";
import { useOpenLocation } from "@/commons/hooks/useOpenLocation";
import { CHARGER_STATUS, ChargerModel } from "@/core/models/chargerModel";
import { IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import { navigateCircleOutline } from "ionicons/icons";
import { MouseEvent } from "react";
interface FavoriteCardProps {
  charger: ChargerModel;
  showDelete: boolean;
  onDeleteCharger: (chargerId: string) => void;
}

export const FavoriteCard = ({ charger, showDelete, onDeleteCharger }: FavoriteCardProps) => {
  const { openLocation } = useOpenLocation();
  const { userLocation } = useLocationContext();

  let chargerDistance = charger.distance || 0;
  chargerDistance = chargerDistance >= 1 ? Math.round(chargerDistance) : chargerDistance;

  const handleClickDelete = (event: MouseEvent<HTMLIonColElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    onDeleteCharger(charger.id_charger);
  };

  const handleNavigateClick = (event: MouseEvent<HTMLIonColElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    const from = `${userLocation.latitude},${userLocation.longitude}`;
    const to = `${charger.latitude},${charger.longitude}`;
    const mapUrl = `https://www.google.com/maps/dir/${from}/${to}`;
    window.open(mapUrl, "_blank");
  };

  const handleGoToModal = () => {
    openLocation(Number(charger.location_id));
  };

  return (
    <>
      {charger && (
        <IonRow
          onClick={handleGoToModal}
          className={"rowContainerFavCharger extended"}
          style={{
            boxShadow: "0px 1px 1px var(--ion-color-focus)",
          }}
        >
          <IonCol className="chargerInfoColRight">
            <IonLabel className="chargerNameTitle">
              <div>{charger.name}</div>
            </IonLabel>
            <IonLabel className="chargerAddressTitle">
              <div>{charger.address}</div>
            </IonLabel>
            <StatusLabel active={charger.status === CHARGER_STATUS.AVAILABLE}>
              {t(`CHARGER.${CHARGER_STATUS_TRANSLATE[charger.status]}`)}
            </StatusLabel>
          </IonCol>
          <IonCol className="chargerInfoColLeft">
            <IonCol className="chargerInfoColLeft" onClick={handleNavigateClick}>
              <IonIcon icon={navigateCircleOutline} size="large"></IonIcon>
              <IonLabel className="number distanceLabel">{chargerDistance} Km</IonLabel>
            </IonCol>
          </IonCol>
          {showDelete && <RemoveButton onClick={handleClickDelete} />}
        </IonRow>
      )}
    </>
  );
};
