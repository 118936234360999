import i18n from "@/assets/i18n/i18n";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { Illustration11 } from "@/commons/constants/imgs";
import { AppNotificationModel } from "@/core/models/appNotificationModel";
import { getUserAppNotificationsService, putSeenAllNotification } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { IonInfiniteScrollCustomEvent } from "@ionic/core";
import {
  IonButton,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import { t } from "i18next";
import { mailOpenOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { NotificationCard } from "./NotificationCard/NotificationCard";
import { NotificationsSkeleton } from "./NotificationsSkeleton";

export const NotificationsPage = () => {
  const { user } = useAppSelector((state) => state.userReducer);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [notifications, setNotifications] = useState<AppNotificationModel[]>([]);
  const [showLoadingData, setShowLoadingData] = useState(false);
  const [showMoreData, setShowMoreData] = useState(true);
  const [page, setPage] = useState<number>(1);
  const currentLanguage = i18n.language;

  useIonViewWillEnter(() => {
    // Only show loading first time
    setShowLoadingData(true);

    getUserAppNotifications();
  });

  const handleGoHome = () => {
    history.replace(APP_PATH.HOME);
  };

  const getUserAppNotifications = async (event?: IonInfiniteScrollCustomEvent<void>) => {
    if (!showMoreData) {
      return event?.target.complete();
    }

    try {
      const newNotifications = await getUserAppNotificationsService(user.user_id, currentLanguage, page);
      if (newNotifications) {
        setNotifications([...notifications, ...newNotifications]);

        if (newNotifications.length === 0) setShowMoreData(false);
        setPage(page + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      event?.target.complete();
      setShowLoadingData(false);
    }
  };

  const handleSeenAllAlert = () => {
    dispatch(
      uiActions.setAlertType({
        type: "notificationSeenAll",
        options: {
          primaryAction: () => {
            handleSeenAll();
          },
        },
      }),
    );
  };

  const handleSeenAll = async () => {
    try {
      setShowLoadingData(true);
      await putSeenAllNotification();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setNotifications(markAllNotificationsAsSeen(notifications));
      setShowLoadingData(false);
    }
  };

  const markAllNotificationsAsSeen = (notifications: AppNotificationModel[]): AppNotificationModel[] => {
    return notifications.map((notification) => {
      return {
        ...notification,
        seen: true,
      };
    });
  };

  return (
    <IonPage>
      <AppHeader
        title={t("MENU.NOTIFICATIONS")}
        endIcon={<IconButton icon={mailOpenOutline} onClick={handleSeenAllAlert} />}
      />
      <IonContent>
        <IonLoading isOpen={showLoadingData} message={t("LOADING.LOADING") ?? ""}></IonLoading>
        {showLoadingData ? (
          <NotificationsSkeleton />
        ) : (
          <>
            {notifications.length === 0 && (
              <IonList className="listReservationsContainer ion-padding">
                <Illustration11 className="imageReservations" />
                <IonList className="listReservationsContainer textReservation">
                  <IonLabel className="titleKeychain titleReservations">{t("NOTIFICATIONS.NO_NOTIFICATIONS")}</IonLabel>
                  <IonText>{t("NOTIFICATIONS.IN_ORDER")}</IonText>
                </IonList>
                <IonButton style={{ height: "124px" }} onClick={handleGoHome}>
                  {t("BUTTONS.GO_START")}
                </IonButton>
              </IonList>
            )}
            {notifications.length > 0 && (
              <>
                <IonList style={{ padding: "20px" }}>
                  {notifications.map((notification) => (
                    <div key={notification.id}>
                      <NotificationCard notificationInfo={notification}></NotificationCard>
                    </div>
                  ))}
                </IonList>
                <IonInfiniteScroll
                  onIonInfinite={(ev) => {
                    getUserAppNotifications(ev);
                  }}
                >
                  {showMoreData && <IonInfiniteScrollContent />}
                </IonInfiniteScroll>
              </>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};
