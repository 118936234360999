import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { ProgressBarCircular } from "@/commons/components/ProgressBarCircular/ProgressBarCircular";
import { useIsSocketAvailable } from "@/commons/hooks/useIsSocketAvailable";
import { formatToMinutes } from "@/commons/utils/dateUtils/dateUtils";
import { CHARGER_SOCKET_RUNTIME_STATUS, ChargerModel, ChargerSocketModel } from "@/core/models/chargerModel";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { useApi } from "@/services/apiService";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonIcon,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
  IonicSafeString,
} from "@ionic/react";
import { closeOutline, notifications } from "ionicons/icons";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./SocketBusy.scss";

export const SocketBusy = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isSocketAvailable } = useIsSocketAvailable();

  const { locationInfo, selectedCharger, selectedSocket } = useAppSelector(
    (state) => state.mapReducer.selectedLocation,
  );

  const isCharging = selectedSocket?.runtime_status_id === CHARGER_SOCKET_RUNTIME_STATUS.CHARGING;
  const isReserved = selectedSocket?.runtime_status_id === CHARGER_SOCKET_RUNTIME_STATUS.RESERVED;

  const { userInfo, isUserLogged } = useAppSelector((state) => state.userReducer);
  const { options } = useAppSelector((state) => state.mapReducer.selectedLocation);

  const { sendTouch, subscribeNotifyStatus } = useApi();

  const [showConfirmTouch, setShowConfirmTouch] = useState<boolean>(false);
  const [showErrorCantTouchAlert, setShowErrorCantTouchAlert] = useState<boolean>(false);
  const [showAlreadyActive, setShowAlreadyActive] = useState<boolean>(false);
  const [chargingInfo, setChargingInfo] = useState({
    consumedEnergy: 0,
    remainingEnergy: 0,
    totalEnergy: 0,
    remainingTime: 0,
    remainingTimeFormatted: "",
    consumedEnergyPercentage: 0,
  });

  const [reservationInfo, setReservationInfo] = useState({
    completeTime: -1,
    remainingTime: -1,
    totalTime: -1,
    remainingTimeFormatted: "",
    completeTimePercentage: 0,
  });

  useEffect(() => {
    if (selectedSocket && options.showSocketBusy) {
      if (userInfo?.listSubscribeAvailable?.includes(parseInt(selectedSocket.id_socket))) {
        setShowAlreadyActive(true);
      }
    }
  }, [selectedSocket, options.showSocketBusy]);

  useEffect(() => {
    if (!options.showSocketBusy || !selectedSocket) return;

    // First init
    if (isCharging) {
      const { charger_graph_energy } = selectedSocket;

      if (charger_graph_energy) {
        setChargingInfo({
          consumedEnergy: charger_graph_energy.energy_consumed,
          remainingEnergy: charger_graph_energy.energy_remaining,
          totalEnergy: charger_graph_energy.energy_total,
          remainingTime: (selectedSocket.charger_graph_rest || 0) * 60,
          remainingTimeFormatted: formatToMinutes((selectedSocket.charger_graph_rest || 0) * 60),
          consumedEnergyPercentage: (charger_graph_energy.energy_consumed * 100) / charger_graph_energy.energy_total,
        });
      }
    }

    if (isReserved) {
      const { reservation_times } = selectedSocket;
      if (reservation_times) {
        setReservationInfo({
          completeTime: reservation_times.complete_time,
          remainingTime: reservation_times.remaining_time,
          totalTime: reservation_times.total_time,
          remainingTimeFormatted: formatToMinutes(reservation_times.remaining_time),
          completeTimePercentage: (reservation_times.complete_time * 100) / reservation_times.total_time,
        });
      }
    }

    const intervalId = setInterval(() => {
      if (isCharging) {
        setChargingInfo((prevChargingInfo) => {
          const { consumedEnergy } = prevChargingInfo;

          const remainingTime = prevChargingInfo.remainingTime - 1;

          if (remainingTime <= 0) {
            clearInterval(intervalId);
            return {
              consumedEnergy: -1,
              remainingEnergy: -1,
              totalEnergy: -1,
              remainingTime: -1,
              remainingTimeFormatted: "",
              consumedEnergyPercentage: 0,
            };
          }

          return {
            ...prevChargingInfo,
            remainingTime,
            consumedEnergy,
            remainingTimeFormatted: formatToMinutes(remainingTime),
          };
        });

        return;
      }

      if (isReserved) {
        setReservationInfo((prevReservationInfo) => {
          let { remainingTime, completeTime } = prevReservationInfo;

          remainingTime = remainingTime - 1;
          completeTime = completeTime + 1;

          if (remainingTime === 0) {
            clearInterval(intervalId);

            handleCloseModal();

            return {
              completeTime: -1,
              remainingTime: -1,
              totalTime: -1,
              remainingTimeFormatted: "",
              completeTimePercentage: 0,
            };
          }

          return {
            ...prevReservationInfo,
            remainingTime,
            remainingTimeFormatted: formatToMinutes(remainingTime),
            completeTime: completeTime,
            completeTimePercentage: (completeTime * 100) / prevReservationInfo.totalTime,
          };
        });

        return;
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [options.showSocketBusy]);

  const confirmTouch = async () => {
    if (isUserLogged && selectedSocket) {
      const touchSended = await sendTouch(selectedSocket.id_socket);
      if (touchSended && touchSended.length !== 0) {
        dispatch(uiActions.setToast({ show: true, message: t("SEND_NOTIFICATION.TOUCH_SENT"), color: "success" }));
      } else {
        dispatch(uiActions.setToast({ show: true, message: t("SEND_NOTIFICATION.TOUCH_NOT_SENT"), color: "warning" }));
      }
      handleDismissBusySocketModal();
    }
  };
  const handleSubscribeNotifyStatus = async (charger: ChargerModel, socket: ChargerSocketModel) => {
    if (userInfo && userInfo.listSubscribeAvailable?.includes(parseInt(socket.id_socket))) {
      setShowAlreadyActive(true);
    } else {
      const response: any = await subscribeNotifyStatus(charger.id_charger, socket.id_socket);
      if (response) {
        dispatch(userActions.setUserInfo(response.user));
        dispatch(
          uiActions.setToast({ show: true, message: t("SEND_NOTIFICATION.NOTIFY_SUBSCRIBED"), color: "success" }),
        );
        handleAlertDismiss();
      }
    }
  };

  const checkDisabledSocket = (charger: ChargerModel, socket: ChargerSocketModel, notify = false) => {
    let disabled = true;
    if (
      (charger.near || notify) &&
      socket &&
      socket.id_socket &&
      socket.charger_graph_id &&
      socket.runtime_status_id &&
      socket.reservation_allowed &&
      socket.id_user_reservation &&
      socket.id_user_charge
    ) {
      const isAvailable = isSocketAvailable(socket);

      if (isCharging && !socket.charger_graph_id && socket.id_user_charge === userInfo?.id) disabled = false;
      else if (socket.reservation_allowed && isAvailable) disabled = false;
      else if (isReserved && socket.id_user_reservation && socket.id_user_reservation === userInfo?.id)
        disabled = false;
      else if (isAvailable) disabled = false;
    }
    return disabled;
  };

  const handleDismissBusySocketModal = () => {
    setShowAlreadyActive(false);
    handleCloseModal();
  };

  const handleAlertDismiss = () => {
    setShowErrorCantTouchAlert(false);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    dispatch(mapActions.setLocationOptions({ ...options, showSocketBusy: false }));
    dispatch(mapActions.setSelectedSocket({ selectedCharger: null, selectedSocket: null }));
  };

  return (
    <IonModal className="socket-busy-modal" isOpen={options.showSocketBusy} onDidDismiss={handleDismissBusySocketModal}>
      <AppHeader
        hiddenMenu
        hiddenTitle
        size="small"
        endIcon={<IconButton icon={closeOutline} color="dark" onClick={handleDismissBusySocketModal} />}
      />
      <IonAlert
        isOpen={showConfirmTouch}
        onDidDismiss={() => setShowConfirmTouch(false)}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("SEND_NOTIFICATION.CONFIRM_TOUCH")}</ion-label>
                    </div>`,
          )
        }
        buttons={[
          {
            text: `${t("ALERT_BUTTONS.CANCEL")}`,
            handler: () => setShowConfirmTouch(false),
          },
          {
            text: `${t("CHAT_BUTTONS.SEND")}`,
            handler: () => confirmTouch(),
          },
        ]}
        cssClass="alertContainer"
      />
      <IonAlert
        isOpen={showErrorCantTouchAlert}
        onDidDismiss={handleAlertDismiss}
        message={
          new IonicSafeString(
            `<div class="alert">
                    <ion-label class="titleH3">${t("SEND_NOTIFICATION.CANT_TOUCH")}</ion-label>
                    </div>`,
          )
        }
        buttons={[`${t("BUTTONS.ACCEPT")}`]}
        cssClass="alertContainer"
      />
      <div>
        {selectedSocket && selectedCharger && locationInfo && userInfo && (
          <IonCol
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "8px",
              paddingTop: "0",
            }}
          >
            {isCharging &&
              !selectedSocket.charger_graph_id &&
              !!selectedSocket.charger_graph_rest &&
              chargingInfo.remainingTime > 0 && (
                <ProgressBarCircular
                  className="socket-busy-modal-progress-bar"
                  text={`${t("SEND_NOTIFICATION.TIME_REMAINING")} ${chargingInfo.remainingTimeFormatted}`}
                  value={chargingInfo.consumedEnergyPercentage}
                />
              )}
            {isReserved && (
              <ProgressBarCircular
                className="socket-busy-modal-progress-bar"
                text={`${t("SEND_NOTIFICATION.TIME_REMAINING")} ${reservationInfo.remainingTimeFormatted}`}
                value={reservationInfo.completeTimePercentage}
              />
            )}
            <IonRow style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <IonLabel style={{ marginBottom: "10px", fontWeight: "bold" }}>
                {t("SEND_NOTIFICATION.SOCKET_INFO")}
              </IonLabel>
              <IonLabel>
                <IonText>
                  <Trans
                    i18nKey="SEND_NOTIFICATION.SOCKET_INFO_2"
                    values={{ socketNumber: selectedSocket.socket_number, idCharger: "" }}
                  />
                </IonText>
              </IonLabel>
            </IonRow>
            <div style={{ display: "flex", justifyContent: "space-around", padding: "10px" }}>
              <IonRow style={{ display: "flex", justifyContent: "space-around" }}>
                {checkDisabledSocket(selectedCharger, selectedSocket, true) && isUserLogged && (
                  <>
                    {showAlreadyActive ? (
                      <IonButton disabled>
                        <IonIcon src={notifications} slot="start"></IonIcon>
                        {t("SEND_NOTIFICATION.NOTIFY_ALREADY_ACTIVE")}
                      </IonButton>
                    ) : (
                      <IonButton
                        shape="round"
                        onClick={() => handleSubscribeNotifyStatus(selectedCharger, selectedSocket)}
                      >
                        <IonIcon src={notifications} slot="start"></IonIcon>
                        {t("BUTTONS.NOTIFY_ME")}
                      </IonButton>
                    )}
                  </>
                )}
                {selectedSocket.runtime_status_id === CHARGER_SOCKET_RUNTIME_STATUS.CHARGING &&
                  !selectedSocket.charger_graph_id &&
                  (selectedSocket.charger_graph_rest === 0 || selectedSocket.charger_graph_rest === -2) && (
                    <IonButton
                      color="primary"
                      fill="outline"
                      onClick={() =>
                        selectedSocket.charger_graph_rest === 0
                          ? setShowConfirmTouch(true)
                          : setShowErrorCantTouchAlert(true)
                      }
                    >
                      {t("SEND_NOTIFICATION.TOUCH")}
                    </IonButton>
                  )}
              </IonRow>
            </div>
          </IonCol>
        )}
      </div>
    </IonModal>
  );
};
