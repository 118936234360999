import { IonCol, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";

export const ChargeCardSkeleton = () => {
  return (
    <IonRow
      className={"chargeInfoContainer"}
      style={{ boxShadow: "0px 1px 1px var(--ion-color-focus)", width: "100%" }}
    >
      <IonRow className="rowChargeHistory">
        <IonCol className="colCharge">
          <IonLabel className="subtitleInfoCharge">
            <IonSkeletonText animated={true} style={{ width: "80px" }} />
          </IonLabel>
          <IonLabel className="labelInfoCharge">
            <IonSkeletonText animated={true} style={{ width: "120px" }} />
          </IonLabel>
        </IonCol>
      </IonRow>

      <IonRow className="rowChargeHistory">
        <IonCol className="colCharge">
          <IonLabel className="subtitleInfoCharge">
            <IonSkeletonText animated={true} style={{ width: "50px" }} />
          </IonLabel>
          <IonLabel className="labelInfoCharge number">
            <IonSkeletonText animated={true} style={{ width: "100px" }} />
          </IonLabel>
        </IonCol>
        <IonCol className="colCharge">
          <IonLabel className="subtitleInfoCharge">
            <IonSkeletonText animated={true} style={{ width: "75px" }} />
          </IonLabel>
          <IonLabel className="labelInfoCharge number">
            <IonSkeletonText animated={true} style={{ width: "80px" }} />
          </IonLabel>
        </IonCol>
      </IonRow>

      <IonRow className="rowChargeHistory">
        <IonCol className="colCharge">
          <IonLabel className="subtitleInfoCharge">
            <IonSkeletonText animated={true} style={{ width: "50px" }} />
          </IonLabel>
          <IonLabel className="labelInfoCharge number">
            <IonSkeletonText animated={true} style={{ width: "100px" }} />
          </IonLabel>
        </IonCol>
        <IonCol className="colCharge">
          <IonLabel className="subtitleInfoCharge">
            <IonSkeletonText animated={true} style={{ width: "75px" }} />
          </IonLabel>
          <IonLabel className="labelInfoCharge number">
            <IonSkeletonText animated={true} style={{ width: "70px" }} />
          </IonLabel>
        </IonCol>
      </IonRow>

      <IonLabel className="labelDisponible">
        <IonSkeletonText animated={true} style={{ width: "100px" }} />
      </IonLabel>
    </IonRow>
  );
};
