import { FacilityModel } from "@/core/models/locationModel";
import { IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
interface LocationMoreInfoExceptionalsProps {
  facilities: FacilityModel[];
}

export const LocationMoreInfoServices = ({ facilities }: LocationMoreInfoExceptionalsProps) => {
  return (
    <IonCol>
      <IonLabel className="location-more-info-title">{t("BOOKING_INFO.SERVICES")}</IonLabel>
      <IonRow>
        {facilities.map(({ id, icon }) => (
          <div key={id} className="location-more-info-service-icon">
            <IonIcon icon={icon} className="location-more-info-icon" />
          </div>
        ))}
      </IonRow>
    </IonCol>
  );
};
