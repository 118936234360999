import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { ActiveEntities } from "@/commons/components/Map/ActiveEntities/ActiveEntities";
import { ChargeAutomatic } from "@/commons/components/Map/ChargeSteps/ChargeAutomatic/ChargeAutomatic";
import { ChargeSteps } from "@/commons/components/Map/ChargeSteps/ChargeSteps";
import { CustomClusterMarker } from "@/commons/components/Map/CustomClusterMarker/CustomClusterMarker";
import { CustomMarker } from "@/commons/components/Map/CustomMarker/CustomMarker";
import { CustomPinIcon } from "@/commons/components/Map/CustomPinIcon/CustomPinIcon";
import { LocationDetail } from "@/commons/components/Map/LocationDetail/LocationDetail";
import { LocationDetailFooter } from "@/commons/components/Map/LocationDetail/LocationDetailFooter/LocationDetailFooter";
import { MapFilters } from "@/commons/components/Map/MapFilters/MapFilters";
import { RouteMap } from "@/commons/components/Map/RouteMap/RouteMap";
import { UserMarker } from "@/commons/components/Map/UserMarker/UserMarker";
import { AlertModal } from "@/commons/components/UI/AlertModal/AlertModal";
import { useLocationsContext } from "@/commons/hooks/useLocationsContext";
import { useLogoApp } from "@/commons/hooks/useLogoApp";
import { useNearLocation } from "@/commons/hooks/useNearLocation";
import { useOpenLocation } from "@/commons/hooks/useOpenLocation";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useQuery } from "@/commons/hooks/useQuery";
import { getUserAppCard } from "@/commons/utils/userUtils/userUtils";
import { LocationContext } from "@/context/locationContext";
import { ActiveChargeModel } from "@/core/models/chargeModel";
import { CHARGER_STATUS_IDS } from "@/core/models/chargerModel";
import { getReservationsService } from "@/core/services/reservation/reservationServices";
import { getUserActiveChargesService } from "@/core/services/user/userServices";
import { chargesActions } from "@/core/store/slices/chargesSlice";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { App } from "@capacitor/app";
import { PluginListenerHandle } from "@capacitor/core";
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonToast,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { differenceInDays } from "date-fns";
import { alertCircleOutline, bookmarkOutline, locateOutline, optionsOutline } from "ionicons/icons";
import { LatLng, latLng, Map } from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.js";
import "leaflet/dist/leaflet.css";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useHistory } from "react-router-dom";
import "swiper/css";
import "./Map.scss";
import { MapAlerts } from "./MapAlerts";
import { MapFooter } from "./MapFooter/MapFooter";
import { SearchBar } from "./SearchBar/SearchBar";

export const MapPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { query } = useQuery();
  const { openLocation } = useOpenLocation();
  const { appLogo } = useLogoApp();

  const {
    parameters: { refresh_map },
  } = useParametersContext();
  const { userLocation } = useContext(LocationContext);
  const { isLoadingLocations, locationsCx, getLocations } = useLocationsContext();
  const { nearLocationId, nearLocation, setNearLocation, checkNearLocation, getNearLocation } = useNearLocation();

  const { locationId: selectedLocationId, options } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { searchSelectedCoord, activeReservations } = useAppSelector((state) => state.mapReducer);
  const { activeCharges } = useAppSelector((state) => state.chargesReducer);
  const { deviceId, user, userInfo, lastNotification, settings } = useAppSelector((state) => state.userReducer);
  const {
    routeInfo: { showRoute },
    filters: { activeFilters },
  } = useAppSelector((state) => state.mapReducer);

  const mapRef = useRef<Map>(null);

  // Map states
  const isLocationLoad = useMemo(
    () => userLocation.latitude !== null && userLocation.longitude !== null,
    [userLocation],
  );
  const [isCenter, setIsCenter] = useState(true);

  const [willEnter, setWillEnter] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [listenerResume, setListenerResume] = useState<Promise<PluginListenerHandle>>();
  const [listenerResume2, setListenerResume2] = useState<Promise<PluginListenerHandle>>();

  const [isLoadingActiveCharges, setIsLoadingActiveCharges] = useState(false);
  const [showLocationNotFound, setShowLocationNotFound] = useState(false);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showNoLoggedFavorites, setShowNoLoggedFavorites] = useState(false);
  const [showErrorGetCG, setShowErrorGetCG] = useState(false);
  const [showToastUnMarkedAsFavorite] = useState(false);
  const [showToastMarkFailed] = useState(false);
  const [showUserInfoError] = useState(false);
  const [showAlertSocketNotAvailble, setShowAlertSocketNotAvailble] = useState(false);

  useIonViewWillEnter(() => {
    // Fix map grey zones when returning from other page
    window.dispatchEvent(new Event("resize"));

    setWillEnter((prev) => prev + 1);
  }, []);

  useEffect(() => {
    // Fix map grey zones when returning from other page
    window.dispatchEvent(new Event("resize"));
  }, [selectedLocationId]);

  useEffect(() => {
    // Load data first time and every time showChargeSteps change
    willEnter && !options.showChargeSteps && loadData();

    // Add listener resume

    const listenerPromise = App.addListener("resume", () => {
      if (!options.showChargeSteps) {
        loadData(false);
      }
    });

    setListenerResume(listenerPromise);

    const interval = setInterval(() => {
      !options.showChargeSteps && loadData(false, false);
    }, refresh_map * 1000);

    setIntervalId(interval);

    return () => {
      clearInterval(interval);

      listenerPromise.then((listener) => listener.remove());
    };
  }, [willEnter, options.showChargeSteps, user.user_id, activeFilters]);

  useEffect(() => {
    const listenerPromise2 = App.addListener("resume", () => {
      // sleep 3 second to get the new location
      setTimeout(() => {
        getNearLocation();
      }, 3000);
    });

    setListenerResume2(listenerPromise2);

    return () => {
      listenerPromise2.then((listener) => listener.remove());
    };
  }, [userLocation]);

  useIonViewWillLeave(() => {
    // Close select location modal
    dispatch(mapActions.setLocationOptions({ ...options, showLocationDetail: false }));

    // Clear interval when leave
    intervalId && clearInterval(intervalId);

    // Remove listener resume
    listenerResume && listenerResume.then((listener) => listener.remove());
    listenerResume2 && listenerResume2.then((listener) => listener.remove());
  }, [intervalId, listenerResume, listenerResume2]);

  // Open location modal from query params
  useEffect(() => {
    const { locationId, chargerId, socketNumber } = query;

    if (locationId) {
      // Reopen select location modal
      openLocation(Number(locationId), Number(chargerId), Number(socketNumber));
    } else {
      const { pathname } = history.location;

      // Dont clean if is in reservation steps
      if (pathname === APP_PATH.RESERVATION_STEPS) return;

      dispatch(mapActions.cleanSelectedLocation());
    }
  }, [query]);

  // Init map evets
  useEffect(() => {
    mapRef.current?.on("movestart", () => {
      setIsCenter(false);
    });
  }, [mapRef.current]);

  // Update map center when user center location
  useEffect(() => {
    if (isCenter && userLocation.latitude !== null && userLocation.longitude !== null) {
      mapRef.current?.flyTo(new LatLng(userLocation.latitude, userLocation.longitude), 13, { noMoveStart: true });
    }
  }, [isCenter, userLocation]);

  useEffect(() => {
    !willEnter && getNearLocation();
  }, []);

  useEffect(() => {
    nearLocationId && !isLoadingLocations && checkNearLocation();
  }, [nearLocationId, isLoadingLocations, userLocation]);

  useEffect(() => {
    if (lastNotification === "startCharge" || lastNotification === "endSession") {
      getUserActiveCharges();
    }
  }, [lastNotification]);

  const loadData = (showLoading = true, loadActiveData = true) => {
    getLocations(showLoading);

    if (loadActiveData) {
      getUserActiveCharges();
      getActiveReservations();
    }
  };

  const getUserActiveCharges = async () => {
    try {
      if (isLoadingActiveCharges) return;

      setIsLoadingActiveCharges(true);
      const { charges } = await getUserActiveChargesService(deviceId);

      if (!charges?.length) {
        return dispatch(chargesActions.setActiveCharges([]));
      }

      const newActiveCharges = charges.map<ActiveChargeModel>((charge) => {
        const isTwoDays = differenceInDays(charge.session_start_at, new Date()) >= 2;

        return {
          charge_id: parseInt(charge.charge_id),
          account_id: parseInt(charge.account_id),
          user_code: charge.user_code,
          started_at: charge.session_start_at,
          ended_at: charge.session_end_at,
          socket_id: charge.charger_socket,
          charger_id: parseInt(charge.charger_id),
          hidden: isTwoDays && charge.charger_status_id === CHARGER_STATUS_IDS.OUT_OF_SERVICE,
        };
      });

      dispatch(chargesActions.setActiveCharges(newActiveCharges));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingActiveCharges(false);
    }
  };

  const getActiveReservations = async () => {
    try {
      const { code_card } = await getUserAppCard(user.user_id, userInfo);
      if (code_card) {
        const newActiveReservations = await getReservationsService(code_card, true);
        dispatch(mapActions.setActiveReservations(newActiveReservations || []));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlertDismiss = () => {
    setShowErrorGetCG(false);
    setShowNoLoggedFavorites(false);
    setShowLocationNotFound(false);
    setShowSuccessAlert(false);
    setShowAlertSocketNotAvailble(false);
  };

  const handleAlertDissmissErrorUser = () => {
    window.location.reload();
  };

  const handleCloseRouteGenerated = () => {
    dispatch(mapActions.setShowRoute(false));
    dispatch(mapActions.cleanRouteInfo());
  };

  return (
    <IonPage className="map-page">
      <AppHeader
        startIcon={showRoute && <GoBackButton onClick={handleCloseRouteGenerated} />}
        endIcon={
          !showRoute && (
            <IconButton
              icon={optionsOutline}
              badge={activeFilters.filterNumber}
              onClick={() => dispatch(mapActions.setShowFilters(true))}
            />
          )
        }
      />

      <IonLoading isOpen={isLoadingLocations && !selectedLocationId} message={t("LOADING.LOADING")} />
      <MapAlerts
        showErrorGetCG={showErrorGetCG}
        showUserInfoError={showUserInfoError}
        showNoLoggedFavorites={showNoLoggedFavorites}
        showSuccessAlert={showSuccessAlert}
        handleAlertDismiss={handleAlertDismiss}
        handleAlertDissmissErrorUser={handleAlertDissmissErrorUser}
        showLocationNotFound={showLocationNotFound}
        showAlertSocketNotAvailble={showAlertSocketNotAvailble}
      />
      <IonToast
        isOpen={showToastUnMarkedAsFavorite}
        duration={3000}
        message={t("TOAST.DELETED_CHARGER") ?? ""}
        icon={bookmarkOutline}
        position="top"
        color="success"
      />
      <IonToast
        isOpen={showToastMarkFailed}
        duration={3000}
        message={t("TOAST.NOT_SAVED_CHARGER") ?? ""}
        icon={alertCircleOutline}
        position="top"
        color="danger"
      />

      {/* Modals */}
      <LocationDetail />
      <ChargeSteps />
      <ChargeAutomatic />
      <MapFilters locationsInit={locationsCx} />
      {nearLocation && (
        <AlertModal
          isOpen={true}
          primaryText={t("ALERT_BUTTONS.YES")}
          primaryAction={() => openLocation(nearLocation.locationId)}
          secondaryText={t("ALERT_BUTTONS.DISABLED")}
          secondaryAction={() =>
            dispatch(
              uiActions.setAlertType({
                type: "disableNearLocation",
                options: {
                  primaryAction: () => {
                    dispatch(
                      userActions.setSettings({ ...settings, alerts: { ...settings.alerts, nearLocation: false } }),
                    );
                    dispatch(uiActions.setToastType({ type: "success" }));
                  },
                },
              }),
            )
          }
          handleDismiss={() => setNearLocation(undefined)}
          closingTime={15}
        >
          <img src={nearLocation.img || appLogo} alt="location img" />
          <IonLabel className="alert-global-title">{nearLocation.name}</IonLabel>
          <IonLabel className="alert-global-text">{t("ALERT.NEAR_LOCATION_TITLE")}</IonLabel>
          <IonLabel className="alert-global-text">{t("ALERT.NEAR_LOCATION_TEXT")}</IonLabel>
        </AlertModal>
      )}

      {showRoute ? (
        <RouteMap />
      ) : (
        <div className="map-container">
          <MapContainer
            ref={mapRef}
            center={new LatLng(userLocation.latitude || 40.0405073, userLocation.longitude || -3.9152757)}
            zoom={isLocationLoad ? 13 : 5}
            scrollWheelZoom
            dragging
            maxBounds={[
              [-90, -180],
              [90, 180],
            ]}
            minZoom={2}
          >
            <TileLayer
              className="map-tiles"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              // url="https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png"
              attribution=""
              noWrap
              bounds={[
                [-90, -180],
                [90, 180],
              ]}
            />

            {/* UserMarker */}
            {userLocation.latitude !== null && userLocation.longitude !== null && (
              <Marker position={latLng(userLocation.latitude, userLocation.longitude)} icon={UserMarker()} />
            )}

            {searchSelectedCoord && (
              <Marker position={latLng(searchSelectedCoord.lat, searchSelectedCoord.lon)} icon={CustomPinIcon()} />
            )}

            {/* Locations Markers */}
            <MarkerClusterGroup
              maxClusterRadius={50}
              spiderfyOnMaxZoom={true}
              showCoverageOnHover={true}
              iconCreateFunction={(cluster) => CustomClusterMarker({ cluster })}
            >
              {locationsCx.map((location) => (
                <div key={location.location_id}>
                  <Marker
                    position={location.latLng}
                    icon={CustomMarker({
                      number: location.location_sockets_count,
                      selectedLocation: selectedLocationId === location.location_id,
                      locationStatus: location.location_status,
                    })}
                    eventHandlers={{ click: () => openLocation(location.location_id) }}
                    title={`${location.location_sockets_count}`}
                  />
                </div>
              ))}
            </MarkerClusterGroup>

            {(activeCharges.length > 0 || activeReservations.length > 0) && (
              <ActiveEntities activeCharges={activeCharges} activeReservations={activeReservations} mapRef={mapRef} />
            )}
            {isLocationLoad && (
              <IonFab
                onClick={() => setIsCenter(true)}
                vertical="bottom"
                horizontal="end"
                style={{ bottom: "24px" }}
                hidden={isCenter}
              >
                <IonFabButton>
                  <IonIcon icon={locateOutline} />
                </IonFabButton>
              </IonFab>
            )}
          </MapContainer>
        </div>
      )}
      <SearchBar showRoute={showRoute} setIsCenter={setIsCenter} mapRef={mapRef} />
      {!options.showLocationDetail ? <MapFooter /> : <LocationDetailFooter />}
    </IonPage>
  );
};
