import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { formatNumber } from "@/commons/utils/numberUtils";
import { ParametersContext } from "@/context/appParametersContext";
import { useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { IonButton, IonIcon, IonLabel, useIonRouter } from "@ionic/react";
import { t } from "i18next";
import { addCircleOutline, ticketOutline } from "ionicons/icons";
import { useContext } from "react";
import "./WalletView.scss";

interface WalletViewProps {
  haveRequiredBalance?: boolean;
  size?: "small" | "large";
}

export const WalletView = ({ haveRequiredBalance = true, size = "large" }: WalletViewProps) => {
  const router = useIonRouter();
  const { parameters } = useContext(ParametersContext);

  const { userInfo, theme } = useAppSelector((state) => state.userReducer);

  const amount = (userInfo?.wallet_amount || 0) / 100;

  const handleAddBalance = () => {
    router.push(APP_PATH.WALLET_ADD_BALANCE);
  };
  const handleGoPromotionalCodes = () => {
    router.push(APP_PATH.WALLET_PROMOTIONAL_CODES);
  };

  return (
    <div className={`wallet-view ${classIf(theme === "dark", "dark")}`}>
      <IonLabel className="strong-text">{t("TITLES.CURRENT_BALANCE")}</IonLabel>
      <div className={`wallet-view-amount ${classIf(!haveRequiredBalance, "required-balance")}`}>
        <IonLabel className={`wallet-view-amount-text ${size}`}>
          {formatNumber(amount, { minimumFractionDigits: 2 })} {parameters.currency}
        </IonLabel>
        {!haveRequiredBalance && (
          <IonLabel className="required-balance-text">{t("TITLES.INSUFFICIENT_BALANCE")}</IonLabel>
        )}
      </div>
      <IonButton onClick={handleAddBalance}>
        <IonIcon slot="start" icon={addCircleOutline} />
        {t("TITLES.ADD_BALANCE")}
      </IonButton>
      {parameters.promotional_codes && (
        <IonButton onClick={handleGoPromotionalCodes}>
          <IonIcon slot="start" icon={ticketOutline} />
          {t("TITLES.PROMOTIONAL_CODES")}
        </IonButton>
      )}
    </div>
  );
};
