import { divIcon, MarkerCluster, point } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import "./CustomClusterMarker.scss";

interface CustomClusterMarkerProps {
  cluster: MarkerCluster;
}

export const CustomClusterMarker = ({ cluster }: CustomClusterMarkerProps) => {
  const markers = cluster.getAllChildMarkers();

  const totalSum = markers.reduce((sum, marker) => {
    return sum + Number(marker.options.title) || 0; // customValue es el número que has mencionado
  }, 0);

  return divIcon({
    className: "custom-cluster-marker",
    iconSize: point(40, 40, true),
    html: renderToStaticMarkup(<div>{totalSum}</div>),
  });
};
