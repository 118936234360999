import { RemoveButton } from "@/commons/components/Buttons/RemoveButton/RemoveButton";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { VehicleModel } from "@/core/models/userModel";
import { deleteVehicleUserService, updateVehicleUserService } from "@/core/services/user/vehicleUserServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonCol, IonIcon, IonLabel, IonLoading, IonRow } from "@ionic/react";
import { t } from "i18next";
import { checkmarkCircleOutline, pencilOutline } from "ionicons/icons";
import { useState } from "react";

interface VehicleCardProps {
  vehicle: VehicleModel;
  edit: boolean;
  allowDelete: boolean;
  handleEditVehicleClick: (vehicle: VehicleModel) => void;
  handleSuccess: () => void;
}

export const VehicleCard = ({
  vehicle,
  edit,
  allowDelete,
  handleEditVehicleClick,
  handleSuccess,
}: VehicleCardProps) => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userReducer);

  const {
    car_id,
    vehicle_id,
    vehicle_brand_id,
    vehicle_brand_name,
    vehicle_model_id,
    vehicle_model_name,
    model,
    predetermined,
  } = vehicle;

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmDelete = () => {
    dispatch(
      uiActions.setAlertType({
        type: "deleteVehicle",
        options: {
          primaryAction: handleDeleteVehicle,
        },
      }),
    );
  };

  const handleDeleteVehicle = async () => {
    try {
      setIsLoading(true);
      await deleteVehicleUserService({ id: user.user_id, vehicle_id });
      dispatch(uiActions.setToastType({ type: "deletedVehicle" }));
      handleSuccess();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(true);
    }
  };

  const handleChangePredetermined = async () => {
    try {
      setIsLoading(true);
      await updateVehicleUserService(user.user_id, {
        vehicle_id,
        vehicleBrandId: Number(vehicle_brand_id),
        vehicleModelId: Number(vehicle_model_id),
        vehicle: vehicle.vehicle,
        model,
        carId: car_id,
        predetermined: "true",
      });
      dispatch(uiActions.setToastType({ type: "updatedVehicle" }));
      handleSuccess();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="vehicleContainer">
      <IonLoading isOpen={isLoading} />
      <IonRow className="ion-justify-content-between ion-align-items-center ion-padding-start ion-margin-start socketInfo">
        <IonCol className="ion-align-items-center modalColumn">
          <IonLabel className="vehicleInfoLabel">{t("CAR.LICENSE_PLATE")}</IonLabel>
          <IonLabel className="labelData">{car_id}</IonLabel>
        </IonCol>
        <IonCol className="ion-align-items-center modalColumn">
          <IonLabel className="vehicleInfoLabel">{t("CAR.BRAND")}</IonLabel>
          <IonLabel className="labelData">{vehicle_brand_id ? vehicle_brand_name : vehicle.vehicle}</IonLabel>
        </IonCol>
        <IonCol className="ion-align-items-center modalColumn">
          <IonLabel className="vehicleInfoLabel">{t("CAR.MODEL")}</IonLabel>
          <IonLabel className="labelData">{vehicle_model_id ? vehicle_model_name : model}</IonLabel>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-start ion-margin-start byDefaultTag ion-padding-bottom containerVehicle">
        {predetermined && (
          <StatusLabel active>
            <IonLabel>{t("VEHICLES.DEFAULT_VEHICLE")}</IonLabel>
          </StatusLabel>
        )}
      </IonRow>
      {edit && (
        <IonRow>
          {allowDelete && <RemoveButton onClick={handleConfirmDelete} />}
          <IonCol className="vehicle-card-button" onClick={() => handleEditVehicleClick(vehicle)}>
            <IonIcon icon={pencilOutline} color="primary"></IonIcon>
            <IonLabel className="filtrosLabel" color="primary">
              {t("BUTTONS.MODIFY_DATA")}
            </IonLabel>
          </IonCol>
          {!predetermined && (
            <IonCol className="vehicle-card-button" onClick={handleChangePredetermined}>
              <IonIcon icon={checkmarkCircleOutline} color="primary"></IonIcon>
              <IonLabel className="filtrosLabel" color="primary" style={{ fontSize: "11px" }}>
                {t("BUTTONS.MARK_DEFAULT")}
              </IonLabel>
            </IonCol>
          )}
        </IonRow>
      )}
    </div>
  );
};
