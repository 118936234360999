import { useAppSelector } from "@/core/store/store";
import { IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import "./ChargeInitTimeout.scss";

const TIMEOUT_SECONDS = 180;

export const ChargeInitTimeout = () => {
  const { chargeStep } = useAppSelector((state) => state.chargeStepsReducer);

  const { isCargerConnected } = chargeStep;

  const [timeRemaining, setTimeRemaining] = useState(TIMEOUT_SECONDS);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isCargerConnected && timeRemaining > 0) {
      interval = setInterval(() => {
        if (interval && timeRemaining === 0) {
          return clearInterval(interval);
        }

        setTimeRemaining(timeRemaining - 1);
      }, 1000);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [isCargerConnected, timeRemaining]);

  return (
    <>
      {isCargerConnected && (
        <IonRow className="charge-init-timeout">
          <IonLabel>{t("TITLES.TIME_REMAIN")}</IonLabel>
          <IonLabel>{timeRemaining}</IonLabel>
        </IonRow>
      )}
    </>
  );
};
