import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useAppSelector } from "@/core/store/store";
import { t } from "i18next";

export const useApi = () => {
  const { VITE_PROTOCOL, VITE_HOST, VITE_PORT, VITE_CODE_APP } = import.meta.env;
  const { parameters } = useParametersContext();

  const { user } = useAppSelector((state) => state.userReducer);

  const hostApp = VITE_HOST && VITE_PORT ? `${VITE_HOST}:${VITE_PORT}` : VITE_HOST;
  const rootPath = `${VITE_PROTOCOL}://${hostApp}`;
  const mainPath = `${rootPath}/api/v1/etecnic/`;
  const mainPathChatbot = `${VITE_PROTOCOL}://${hostApp}/api/lex/`;

  const tokenUser = user.auth_token;
  const token = parameters.token_api;
  const guest_token = parameters.token_api_guest;

  const sendRequest = async (
    endpoint: string,
    params: string,
    method: string,
    token: string | undefined,
    urlGet = "",
  ): Promise<any> => {
    if (!token) token = guest_token; // Anonymous;
    if (!urlGet) urlGet = mainPath + endpoint + params;

    try {
      const response = await fetch(urlGet, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
          "APP-ETECNIC": "EvCharge_Etecnic",
          "API-VERSION": "5",
          "APP-CODE": `${VITE_CODE_APP}`,
        },
      });

      if (!response.ok) {
        if (response.url.includes("api/v1/etecnic/support_tickets/create") && response.status === 400) {
          const responseData = await response.json();
          return responseData;
        }
        console.error(response);
        throw new Error(t("APP_ERRORS.API"));
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const postRegisterUser = async (urlParams: URLSearchParams): Promise<[]> => {
    const endpoint = "users/create?";
    const params = String(urlParams);
    const method = "POST";
    return sendRequest(endpoint, params, method, token);
  };

  const postSocketStopCharge = async (chargerId: string, socketNumber: string): Promise<[]> => {
    const endpoint = "socket/stop?";
    const method = "POST";
    const params = new URLSearchParams();

    params.append("idcharger", chargerId);
    params.append("socket", socketNumber);

    return await sendRequest(endpoint, params.toString(), method, token);
  };

  const startConversation = async (id: number, latitude: any, longitude: any, language: string): Promise<[]> => {
    const endpoint = "start_conversation?";
    const method = "POST";
    const params = new URLSearchParams({
      id: id.toString(),
      latitude,
      longitude,
      language,
    });
    const tempUrl = mainPathChatbot + "start_conversation?" + params.toString();

    return await sendRequest(endpoint, params.toString(), method, tokenUser, tempUrl);
  };

  const sendMessageChatbotUser = async (
    id: any,
    language: any,
    sessionAttributes: any,
    message: string,
  ): Promise<[]> => {
    const endpoint = "send_message_chatbot_user?";
    const method = "POST";
    const params = new URLSearchParams();

    params.append("id", id);
    params.append("language", language);
    params.append("latitude", sessionAttributes.latitude);
    params.append("longitude", sessionAttributes.longitude);
    params.append("app_domain_id", sessionAttributes.app_domain_id);
    params.append("menuOption", sessionAttributes.menuOption);
    params.append("page", sessionAttributes.page);
    params.append("IDCharger", sessionAttributes.IDCharger);
    params.append("IDAccount", sessionAttributes.IDAccount);
    params.append("businessAccount", sessionAttributes.businessAccount);
    params.append("inputHowToCharge", sessionAttributes.inputHowToCharge);
    params.append("inputStopCharge", sessionAttributes.inputStopCharge);
    params.append("inputPaymentProblems", sessionAttributes.inputPaymentProblems);
    params.append("inputPowerProblems", sessionAttributes.inputPowerProblems);
    params.append("invoiceOrTicket", sessionAttributes.invoiceOrTicket);
    params.append("inputAux", sessionAttributes.inputAux);
    params.append("chargerPowerProblems", sessionAttributes.chargerPowerProblems);
    params.append("message", message);
    params.append("API_URL_LD", sessionAttributes.API_URL_LD);
    params.append("API_URL_SHOW", sessionAttributes.API_URL_SHOW);
    params.append("API_URL_RESET_HARD", sessionAttributes.API_URL_RESET_HARD);
    params.append("TOKEN", sessionAttributes.TOKEN);
    params.append("APPETECNIC", sessionAttributes.APPETECNIC);
    params.append("CHATBOT", sessionAttributes.CHATBOT);

    const tempUrl = mainPathChatbot + endpoint + params.toString();

    return await sendRequest(endpoint, params.toString(), method, tokenUser, tempUrl);
  };

  const deleteChatSession = async (id: number, language: string): Promise<[]> => {
    const endpoint = "delete_chat_session?";
    const method = "POST";
    const params = new URLSearchParams();

    params.append("id", id.toString());
    params.append("language", language);

    const tempUrl = mainPathChatbot + endpoint + params.toString();

    return await sendRequest(endpoint, params.toString(), method, tokenUser, tempUrl);
  };

  const showInvoice = async (invoice_id: any, invoice_type: any) => {
    const endpoint = "payments/show_invoice.pdf?";
    const method = "GET";
    const params = new URLSearchParams();

    params.append("invoice_id", invoice_id.toString());

    //const response = await sendRequest(endpoint, params.toString(), method, tokenUser);

    const urlGet = mainPath + endpoint + params;
    try {
      const response = await fetch(urlGet, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
          "APP-ETECNIC": "EvCharge_Etecnic",
          "API-VERSION": "5",
          "APP-CODE": `${VITE_CODE_APP}`,
        },
      });

      if (!response.ok) {
        console.error(response);
        throw new Error(t("APP_ERRORS.API"));
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.href = blobUrl;
      if (invoice_type === "0") a.download = "ticket.pdf";
      if (invoice_type === "1") a.download = "invoice.pdf";
      if (invoice_type === "2") a.download = "invoice_exchange.pdf";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return true;
    } catch (error) {
      console.error("Error fetching invoice:", error);
      return false;
    }
  };

  const getUserInvoicesByChargerGraph = async (chargerGraphId: any, authToken: any): Promise<[]> => {
    const endpoint = "checkUserInvoicesByChargerGraph?";
    const method = "GET";
    const params = new URLSearchParams();

    params.append("charger_graph_id", chargerGraphId);
    params.append("auth_token", authToken);

    const tempUrl = mainPathChatbot + endpoint + params.toString();

    return await sendRequest(endpoint, params.toString(), method, tokenUser, tempUrl);
  };

  const ticketToInvoice = async (chargerGraphId: any): Promise<[]> => {
    const endpoint = "ticket_to_invoice?";
    const method = "POST";
    const params = new URLSearchParams();

    params.append("charger_graph_id", chargerGraphId);

    const tempUrl = mainPathChatbot + "ticket_to_invoice?" + params.toString();

    return await sendRequest(endpoint, params.toString(), method, tokenUser, tempUrl);
  };

  const sendSupportTicketChatbot = async (
    id: any,
    language: any,
    sessionAttributes: any,
    ticketReason: any,
    account: any,
    type_support_ticket_id: any,
  ): Promise<[]> => {
    const endpoint = "send_support_ticket_api?";
    const method = "POST";
    const params = new URLSearchParams();

    params.append("id", id);
    params.append("language", language);
    params.append("latitude", sessionAttributes.latitude);
    params.append("longitude", sessionAttributes.longitude);
    params.append("app_domain_id", sessionAttributes.app_domain_id);
    params.append("menuOption", sessionAttributes.menuOption);
    params.append("page", sessionAttributes.page);
    params.append("IDCharger", sessionAttributes.IDCharger);
    params.append("IDAccount", sessionAttributes.IDAccount);
    params.append("businessAccount", sessionAttributes.businessAccount);
    params.append("inputHowToCharge", sessionAttributes.inputHowToCharge);
    params.append("inputStopCharge", sessionAttributes.inputStopCharge);
    params.append("inputPaymentProblems", sessionAttributes.inputPaymentProblems);
    params.append("inputPowerProblems", sessionAttributes.inputPowerProblems);
    params.append("invoiceOrTicket", sessionAttributes.invoiceOrTicket);
    params.append("inputAux", sessionAttributes.inputAux);
    params.append("chargerPowerProblems", sessionAttributes.chargerPowerProblems);
    params.append("ticketReason", ticketReason);
    params.append("account", account);
    params.append("type_support_ticket_id", type_support_ticket_id);
    params.append("API_URL_LD", sessionAttributes.API_URL_LD);
    params.append("API_URL_SHOW", sessionAttributes.API_URL_SHOW);
    params.append("API_URL_RESET_HARD", sessionAttributes.API_URL_RESET_HARD);
    params.append("TOKEN", sessionAttributes.TOKEN);
    params.append("APPETECNIC", sessionAttributes.APPETECNIC);
    params.append("CHATBOT", sessionAttributes.CHATBOT);

    const tempUrl = mainPathChatbot + "send_support_ticket_api?" + params.toString();

    return await sendRequest(endpoint, params.toString(), method, tokenUser, tempUrl);
  };

  const generateRedemptionInvoice = async (chargerGraphId: any): Promise<[]> => {
    const endpoint = "payments/generate_redemption_invoice_method?";
    const method = "POST";
    const params = new URLSearchParams();
    params.append("charger_graph_id", chargerGraphId);

    return await sendRequest(endpoint, params.toString(), method, tokenUser);
  };

  const getUserInvoicesTypes = async (chargerGraphId: any): Promise<[]> => {
    const endpoint = "payments/check_user_invoices?";
    const method = "GET";
    const params = new URLSearchParams();

    params.append("charger_graph_id", chargerGraphId);

    return await sendRequest(endpoint, params.toString(), method, tokenUser);
  };

  const validateUser = async (userToken: string, userId: number): Promise<{ valid: boolean }> => {
    const endpoint = "users/validate_user?";
    const method = "GET";
    const params = new URLSearchParams();

    params.append("user_token", userToken);
    params.append("user_id", userId.toString());

    return await sendRequest(endpoint, params.toString(), method, token);
  };

  const getLoginGmail = async (email: string, token: string): Promise<[]> => {
    const endpoint = "users/check-authenticated_gmail?";
    const method = "GET";
    const params = new URLSearchParams();

    params.append("email", email);
    params.append("token", token);

    return await sendRequest(endpoint, params.toString(), method, tokenUser);
  };

  const getLoginApple = async (email: string, token: string, id_apple_user: any): Promise<[]> => {
    const endpoint = "users/check-authenticated_apple?";
    const method = "GET";
    const params = new URLSearchParams();

    params.append("email", email);
    params.append("token", token);
    params.append("id_apple_user", id_apple_user);

    return await sendRequest(endpoint, params.toString(), method, tokenUser);
  };

  const subscribeNotifyStatus = async (charger: any, socket: any): Promise<[]> => {
    const endpoint = "users/notify-socket-available?";
    const method = "GET";
    const params = new URLSearchParams();
    params.append("chargerId", charger);
    params.append("socketId", socket);

    return await sendRequest(endpoint, params.toString(), method, tokenUser);
  };

  const sendTouch = async (socketId: any) => {
    const endpoint = "payments/send_touch?";
    const method = "POST";
    const params = new URLSearchParams();
    params.append("socketId", socketId);

    return await sendRequest(endpoint, params.toString(), method, tokenUser);
  };

  return {
    postRegisterUser,
    postSocketStopCharge,
    startConversation,
    sendMessageChatbotUser,
    deleteChatSession,
    sendSupportTicketChatbot,
    ticketToInvoice,
    getUserInvoicesByChargerGraph,
    showInvoice,
    generateRedemptionInvoice,
    getUserInvoicesTypes,
    getLoginGmail,
    getLoginApple,
    validateUser,
    subscribeNotifyStatus,
    sendTouch,
  };
};
