import { SelectInputElement } from "@/commons/components/Form/SelectInput/SelectInput";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { HIDDEN_FIELDS_DOCUMENT_TYPE, SPAIN_CODE } from "@/commons/constants/personalData";
import { useBlockNavigation } from "@/commons/hooks/useBlockNavigation";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useQuery } from "@/commons/hooks/useQuery";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { formatUserInfo } from "@/commons/utils/userUtils/userUtils";
import { CountryModel, DocumentTypeModel, UserInfoModel } from "@/core/models/userModel";
import {
  getCountriesUserService,
  getDocuementTypesUserService,
  updateUserService,
} from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userFormActions } from "@/core/store/slices/userFormSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonLoading,
  IonPage,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { t } from "i18next";
import { cloneDeep, set } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ActionsInputs } from "./ActionsInputs/ActionsInputs";
import { AddressInputs } from "./AddressInputs/AddressInputs";
import { BillingUserInputs } from "./BillingUserInputs/BillingUserInputs";
import { DefaultValuesInputs } from "./DefaultValuesInputs/DefaultValuesInputs";
import { NotificationsInputs } from "./NotificationsInputs/NotificationsInputs";
import "./PersonalDataPage.scss";
import { PersonalDataSkeleton } from "./PersonalDataSkeleton";
import { PoliciesInputs } from "./PoliciesInputs/PoliciesInputs";
import { PublicAdministration } from "./PublicAdministration/PublicAdministration";
import { UserInfoInputs } from "./UserInfoInputs/UserInfoInputs";

export type TargetFormType =
  | HTMLIonInputElement
  | HTMLIonSelectElement
  | HTMLIonCheckboxElement
  | HTMLIonToggleElement
  | SelectInputElement;

export const PersonalDataPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { query } = useQuery();
  const { checkValidUser } = useIsValidUser();

  const { unsavedChange, setUnsavedChange } = useBlockNavigation();

  const { parameters } = useParametersContext();
  const { loadUserInfo } = useUserContext();

  const { user, userInfo, isUserLogged } = useAppSelector((state) => state.userReducer);

  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeModel[]>([]);

  const [userInfoTemp, setUserInfoTemp] = useState<UserInfoModel>();

  const [isLoading, setIsLoading] = useState(false);
  const [showLoadingUpdate, setShowLoadingUpdate] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  useIonViewWillEnter(() => {
    if (user.user_id === parameters.user_guest_id || Number(userInfo?.id) === parameters.user_guest_id) {
      history.replace(APP_PATH.HOME);
    }

    loadData();
  }, [userInfo, isUserLogged]);

  useIonViewWillLeave(() => {
    setUserInfoTemp(undefined);
  });

  // Set temp user info

  useEffect(() => {
    if (!userInfoTemp && userInfo) {
      const newUserInfo = formatUserInfo(userInfo);
      setUserInfoTemp(newUserInfo);

      if (userInfo.billing_user && userInfo.billing_user.billing_type !== "TICKET") {
        setUserInfoTemp({ ...newUserInfo, isBillingUser: true });
      }

      if (!userInfo.billing_user) {
        setUserInfoTemp({
          ...newUserInfo,
          billing_user: {
            id: "",
            nif: "",
            reg_company: "",
            fiscal_name: "",
            fiscal_address: "",
            city: "",
            cp: "",
            title: "",
            predetermined: false,
            billing_type: "",
          },
        });
      }
    }
  }, [userInfo]);

  // Set show errors
  useEffect(() => {
    if (query.showError) {
      setShowErrors(true);
      dispatch(uiActions.setAlertType({ type: "requiredFields" }));
    }
  }, [query]);

  // Redirect to login if user is same as guest
  useEffect(() => {
    if (
      (user.user_id === parameters.user_guest_id || Number(userInfo?.id) === parameters.user_guest_id) &&
      window.location.pathname === APP_PATH.PERSONAL_DATA
    ) {
      history.replace(APP_PATH.HOME);
    }
  }, [window.location.pathname, user, parameters]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      await Promise.all([loadUserInfo(), getCountries(), getDocumentTypes()]);
    } finally {
      setIsLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      const newCountries = await getCountriesUserService();
      setCountries(newCountries);
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setToastType({ type: "error" }));
    }
  };

  const getDocumentTypes = async () => {
    try {
      const newDocumentTypes = await getDocuementTypesUserService();
      setDocumentTypes(newDocumentTypes);
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setToastType({ type: "error" }));
    }
  };

  const handleChangeForm = (target: TargetFormType) => {
    if (userInfoTemp) {
      setUnsavedChange(true);

      const name = target.name;
      let value = target.value || "";

      if (name === "document_country") {
        const hiddenFields = HIDDEN_FIELDS_DOCUMENT_TYPE[value];

        let document_type = userInfoTemp.document_type;

        if (hiddenFields) {
          setDocumentTypes(
            documentTypes.map((documentType) => ({ ...documentType, hidden: hiddenFields.includes(documentType.id) })),
          );

          document_type = hiddenFields.includes(userInfoTemp.document_country) ? "" : document_type;
        } else {
          setDocumentTypes(documentTypes.map((documentType) => ({ ...documentType, hidden: false })));
        }

        return setUserInfoTemp({ ...userInfoTemp, [name]: value, document_type });
      }

      if (name === "country") {
        return setUserInfoTemp({ ...userInfoTemp, [name]: value, state: "" });
      }

      if (name === "default_charge_values.default_payment_method") {
        dispatch(userFormActions.setRequiredFieldsForm({ name, value: value === "credit_card" }));
      }

      target = target as HTMLIonCheckboxElement | HTMLIonToggleElement;
      if (typeof target.checked === "boolean") {
        value = target.checked;
      }

      // Add or remove new required fields
      if (
        name === "is_public_administration" ||
        name === "default_charge_values.active_automatic_charge" ||
        name === "isBillingUser"
      ) {
        dispatch(userFormActions.setRequiredFieldsForm({ name, value }));
      }

      if (name === "isBillingUser") {
        // set default billing user
        let newBillingUser = userInfo?.billing_user || {
          id: "",
          nif: "",
          reg_company: "",
          fiscal_name: "",
          fiscal_address: "",
          city: "",
          cp: "",
          title: "",
          predetermined: false,
          billing_type: "",
        };

        if (newBillingUser.billing_type === "TICKET") {
          newBillingUser = {
            ...newBillingUser,
            billing_type: "",
          };
        }

        return setUserInfoTemp({
          ...userInfoTemp,
          billing_user: newBillingUser,
          isBillingUser: value,
        });
      }

      // Deep set not special fields
      const newUserInfo = cloneDeep(userInfoTemp);
      set(newUserInfo, name, value);
      setUserInfoTemp(newUserInfo);
    }
  };

  const handleSaveChangesClick = async () => {
    try {
      setUnsavedChange(false);
      setShowErrors(true);
      setShowLoadingUpdate(true);

      if (!userInfoTemp || !checkValidUser(userInfoTemp)) {
        return dispatch(uiActions.setAlertType({ type: "requiredFields" }));
      }
      const newUserInfoTemp = cloneDeep(userInfoTemp);

      if (!newUserInfoTemp.isBillingUser) {
        newUserInfoTemp.billing_user = undefined;
      }

      if (newUserInfoTemp.document_type === "dni" && newUserInfoTemp.document_country === SPAIN_CODE) {
        let newDni = newUserInfoTemp.dni_nif;
        newDni = `${"0".repeat(9 - newDni.length)}${newDni}`;
        newUserInfoTemp.dni_nif = newDni.toUpperCase();
      }

      const { user: newUserInfo } = await updateUserService(
        newUserInfoTemp,
        parameters.domain_id,
        parameters.payment_gateway,
      );

      if (!newUserInfo) throw new Error(t("APP_ERRORS.LOAD_USER_INFO"));

      dispatch(userFormActions.initRequiredFields(newUserInfo));
      dispatch(uiActions.setToastType({ type: "successUserUpdate" }));
      dispatch(userActions.setUserInfo(newUserInfo));

      history.replace(APP_PATH.HOME);
    } catch (error) {
      setUnsavedChange(true);
      dispatch(uiActions.setToastError(error));
    } finally {
      setShowLoadingUpdate(false);
    }
  };

  return (
    <IonPage className="personal-data-page">
      <AppHeader title={t("MENU.PERSONAL_DATA")} />
      <IonLoading isOpen={showLoadingUpdate} />
      <IonContent>
        {isLoading || !userInfoTemp ? (
          <PersonalDataSkeleton />
        ) : (
          <div className="personal-data-content">
            <UserInfoInputs
              showErrors={showErrors}
              userInfoTemp={userInfoTemp}
              documentTypes={documentTypes}
              countries={countries}
              handleChangeForm={handleChangeForm}
            />
            <AddressInputs
              showErrors={showErrors}
              userInfoTemp={userInfoTemp}
              countries={countries}
              handleChangeForm={handleChangeForm}
            />
            <BillingUserInputs
              showErrors={showErrors}
              userInfoTemp={userInfoTemp}
              handleChangeForm={handleChangeForm}
            />
            <PublicAdministration
              showErrors={showErrors}
              userInfoTemp={userInfoTemp}
              handleChangeForm={handleChangeForm}
            />
            <NotificationsInputs />
            <DefaultValuesInputs
              showErrors={showErrors}
              userInfoTemp={userInfoTemp}
              handleChangeForm={handleChangeForm}
              setUserInfoTemp={setUserInfoTemp}
              setUnsavedChange={setUnsavedChange}
            />
            <PoliciesInputs showErrors={showErrors} userInfoTemp={userInfoTemp} handleChangeForm={handleChangeForm} />
            <ActionsInputs unsavedChange={unsavedChange} setUnsavedChange={setUnsavedChange} />
          </div>
        )}
      </IonContent>
      <IonFooter className="footer ion-no-border">
        <IonButton style={{ width: "90%", justifyContent: "center" }} onClick={handleSaveChangesClick}>
          {t("BUTTONS.SAVE_CHANGES")}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
