import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { MarketingNotification } from "@/core/models/appNotificationModel";

/**
 * Service to get a marketing notification
 */

export const getMarketingNotification = (notificationId: number): Promise<MarketingNotification> => {
  return api.get(`marketing_notifications/${notificationId}`, {}).then(resolveResponse).catch(rejectError);
};
