import { PaymentFrameRespose } from "@/commons/components/Payments/PaymentFrame/PaymentFrame";
import { ParametersContext } from "@/context/appParametersContext";
import { UserContext } from "@/context/userContext";
import { AccountModel } from "@/core/models/accountModel";
import { ChargerModel } from "@/core/models/chargerModel";
import { SetState } from "@/core/models/globalModel";
import {
  checkPaymentWalletService,
  confirmPaymentIntentReservationService,
  confirmPaymentIntentService,
  createAccountWalletService,
  createPaymentIntentService,
  createPaymentReservationService,
  createPaymentWalletService,
  deleteStripeCardService,
} from "@/core/services/payment/paymentServices";
import {
  createPaymentIntentPayuService,
  createPaymentReservationPayuService,
  createPaymentWalletPayuService,
  deleteCardPayuPaymentService,
} from "@/core/services/payment/payuPaymentServices";
import {
  createPaymentIntentRedsysService,
  createPaymentReservationRedsysService,
  createPaymentWalletRedsysService,
  deleteCardRedsysPaymentService,
} from "@/core/services/payment/redsysPaymentServices";
import { useAppSelector } from "@/core/store/store";
import { t } from "i18next";
import { useContext } from "react";
import { useSlack } from "./useSlack";
import { useStripeInfo } from "./useStripeInfo";

type SetResponse = SetState<PaymentFrameRespose | undefined>;

export const usePayment = () => {
  const { loadStripeInfo } = useStripeInfo();
  const { parameters } = useContext(ParametersContext);
  const { payment_type: paymentType, currency_code, payment_gateway } = parameters;
  const { loadUserInfo } = useContext(UserContext);
  const slack = useSlack();

  const {
    user: { user_id },
  } = useAppSelector((state) => state.userReducer);

  // #region Charge payment
  const preCapturePaymentCreditCard = async (
    cardCode: string,
    selectedCharger: ChargerModel,
    socketId: string,
    chargingEnergy: number,
    paymentMethod: string,
    deviceId: string,
    vehicleId: string,
    price: number,
    setResponse: SetResponse,
    extraField?: string,
    currency: string = currency_code,
  ) => {
    if (payment_gateway === "stripe") {
      return await createPaymentStripe(
        cardCode,
        selectedCharger,
        socketId,
        chargingEnergy,
        paymentMethod,
        deviceId,
        vehicleId,
        extraField,
        currency,
      );
    }

    if (payment_gateway === "payu") {
      return await createPaymentPayu(
        cardCode,
        socketId,
        chargingEnergy,
        paymentMethod,
        deviceId,
        vehicleId,
        extraField,
        currency,
      );
    }

    if (payment_gateway === "redsys") {
      return await createPaymentIntentRedsys(
        cardCode,
        socketId,
        chargingEnergy,
        paymentMethod,
        deviceId,
        vehicleId,
        price,
        setResponse,
        extraField,
        currency,
      );
    }

    return Promise.reject(t("APP_ERRORS.SUPPORT_PAYMENT"));
  };

  const createPaymentStripe = async (
    cardCode: string,
    selectedCharger: ChargerModel,
    socketId: string,
    chargingEnergy: number,
    paymentMethod: string,
    deviceId: string,
    vehicleId: string,
    extraField?: string,
    currency: string = "EUR",
  ): Promise<AccountModel> => {
    const { stripe } = await loadStripeInfo(selectedCharger.stripe_account);

    const { intent, account } = await createPaymentIntentService(
      currency,
      cardCode,
      socketId,
      chargingEnergy,
      paymentMethod,
      deviceId,
      vehicleId,
      extraField,
    );

    const clientSecret = intent.client_secret;

    if (!clientSecret) {
      throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));
    }

    const { error } = await stripe.confirmCardPayment(clientSecret);

    if (error) {
      slack.error("createPaymentStripe", `SocketId; ${socketId} ${error}`);
      throw new Error(t("APP_ERRORS.CONFIRM_PAYMENT"));
    }

    const accountJson: AccountModel = JSON.parse(account);
    const result = await confirmPaymentIntentService(socketId, accountJson.id);

    return JSON.parse(result.account);
  };
  const createPaymentPayu = async (
    cardCode: string,
    socketId: string,
    chargingEnergy: number,
    paymentMethod: string,
    deviceId: string,
    vehicleId: string,
    extraField?: string,
    currency: string = "EUR",
  ): Promise<AccountModel> => {
    const { intent, account } = await createPaymentIntentPayuService(
      currency,
      cardCode,
      socketId,
      chargingEnergy,
      paymentMethod,
      deviceId,
      vehicleId,
      extraField,
    );

    if (!intent || !account) {
      throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));
    }
    return JSON.parse(account);
  };

  const createPaymentIntentRedsys = async (
    cardCode: string,
    socketId: string,
    chargingEnergy: number,
    paymentMethod: string,
    deviceId: string,
    vehicleId: string,
    price: number,
    setResponse: SetResponse,
    extraField?: string,
    currency: string = currency_code,
  ) => {
    const response = await createPaymentIntentRedsysService(
      currency,
      cardCode,
      socketId,
      chargingEnergy,
      paymentMethod,
      deviceId,
      vehicleId,
      price,
      extraField,
    );

    setResponse(response);

    return;
  };

  const payChargeWithWalletBalance = async (
    socketId: string,
    chargingEnergy: number,
    cardCode: string,
    vehicleId: string,
    currencyCode: string = currency_code,
    deviceId: string,
  ): Promise<AccountModel> => {
    const response = await createAccountWalletService(
      socketId,
      chargingEnergy,
      cardCode,
      vehicleId,
      currencyCode,
      deviceId,
    );

    if (response && response.account) {
      await loadUserInfo();
      return JSON.parse(response.account);
    }

    throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));
  };
  // #endregion

  // #region Add balance wallet
  const isWallet = () => {
    return paymentType.includes("wallet") || paymentType.includes("both");
  };

  const addBalanceWallet = async (
    cardCode: string,
    paymentMethod: string,
    amount: number,
    setResponse: SetResponse,
    currency: string = "EUR",
  ) => {
    if (isWallet()) {
      if (payment_gateway === "stripe") {
        return createPaymentWalletStripe(paymentMethod, amount, currency);
      }

      if (payment_gateway === "payu") {
        return createPaymentWalletPayuService(paymentMethod, amount, currency);
      }

      if (payment_gateway === "redsys") {
        return createPaymentWalletRedsys(paymentMethod, amount, currency, cardCode, setResponse);
      }
      return Promise.reject(t("APP_ERRORS.SUPPORT_PAYMENT"));
    }

    return Promise.reject(t("APP_ERRORS.WALLET_SUPPORT"));
  };

  const createPaymentWalletStripe = async (paymentMethod: string, amount: number, currency: string) => {
    const { stripe } = await loadStripeInfo();

    const { intent, transaction } = await createPaymentWalletService(currency, paymentMethod, amount);

    if (!intent) {
      throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));
    }

    const clientSecret = intent.client_secret;

    if (!clientSecret || !stripe) {
      throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));
    }

    const { error } = await stripe.confirmCardPayment(clientSecret);

    if (error) {
      slack.error("createPaymentWalletStripe", error);
      throw new Error(t("APP_ERRORS.CONFIRM_PAYMENT"));
    }

    const paymentConfirm = await checkPaymentWalletService(transaction.id);
    return paymentConfirm;
  };

  const createPaymentWalletRedsys = async (
    paymentMethod: string,
    amount: number,
    currency: string,
    cardCode: string,
    setResponse: SetResponse,
  ) => {
    const response = await createPaymentWalletRedsysService(paymentMethod, amount, currency, cardCode, user_id);

    return setResponse(response);
  };
  // #endregion

  // #region Reservation payment
  const preCaptureReservationCreditCard = async (
    cardCode: string,
    selectedCharger: ChargerModel,
    socketId: string,
    reserveTime: number,
    paymentMethod: string,
    setResponse: SetResponse,
    currency: string = currency_code,
  ) => {
    if (payment_gateway === "stripe") {
      return await createReservationStripe(cardCode, selectedCharger, socketId, reserveTime, paymentMethod, currency);
    }

    if (payment_gateway === "payu") {
      return await createPaymentReservationPayu(cardCode, socketId, reserveTime, paymentMethod, currency);
    }

    if (payment_gateway === "redsys") {
      return await createPaymentReservationRedsys(
        cardCode,
        socketId,
        reserveTime,
        paymentMethod,
        setResponse,
        currency,
      );
    }

    return Promise.reject(t("APP_ERRORS.SUPPORT_PAYMENT"));
  };

  const createReservationStripe = async (
    cardCode: string,
    selectedCharger: ChargerModel,
    socketId: string,
    reserveTime: number,
    paymentMethod: string,
    currency: string = currency_code,
  ) => {
    const { stripe } = await loadStripeInfo(selectedCharger.stripe_account);

    const { intent, account, reservation } = await createPaymentReservationService(
      currency,
      cardCode,
      socketId,
      reserveTime,
      paymentMethod,
    );

    if (!intent) {
      if (!reservation) throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));

      return reservation;
    }

    const clientSecret = intent.client_secret;

    if (!clientSecret) {
      throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));
    }

    const { error } = await stripe.confirmCardPayment(clientSecret);

    if (error) {
      slack.error("createReservationStripe", error);
      throw new Error(t("APP_ERRORS.CONFIRM_PAYMENT"));
    }

    const result = await confirmPaymentIntentReservationService(socketId, account.id, cardCode, reserveTime);

    return result.reservation;
  };

  const createPaymentReservationPayu = async (
    cardCode: string,
    socketId: string,
    reserveTime: number,
    paymentMethod: string,
    currency: string = currency_code,
  ) => {
    const { intent, reservation } = await createPaymentReservationPayuService(
      currency,
      cardCode,
      socketId,
      reserveTime,
      paymentMethod,
    );

    if (!intent || !reservation) {
      throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));
    }

    return reservation;
  };

  const createPaymentReservationRedsys = async (
    cardCode: string,
    socketId: string,
    reserveTime: number,
    paymentMethod: string,
    setResponse: SetResponse,
    currency: string = currency_code,
  ) => {
    const response = await createPaymentReservationRedsysService(
      currency,
      cardCode,
      socketId,
      reserveTime,
      paymentMethod,
      user_id,
    );

    setResponse(response);

    return;
  };

  // #endregion

  // #region Delete card payment
  const deleteCreditCard = async (paymentMethod: string) => {
    if (payment_gateway === "stripe") {
      return await deleteStripeCardService(paymentMethod);
    }

    if (payment_gateway === "payu") {
      return await deleteCardPayuPaymentService(paymentMethod);
    }

    if (payment_gateway === "redsys") {
      return await deleteCardRedsysPaymentService(paymentMethod);
    }

    throw new Error(t("APP_ERRORS.SUPPORT_PAYMENT"));
  };
  return {
    preCapturePaymentCreditCard,
    payChargeWithWalletBalance,
    addBalanceWallet,
    isWallet,
    preCaptureReservationCreditCard,
    deleteCreditCard,
  };
};
