import { PaymentMethod } from "@/core/models/PaymentModel";
import { AccountModel } from "@/core/models/accountModel";
import { RateTypeLimits } from "@/core/models/rateModel";
import { VehicleBrand } from "@/core/models/userModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ChargeStepsSlice {
  stepsLabels: ChargeStepLabel[];
  currentStep: number;
  currentStepLabel: ChargeStepLabel;
  nextStepDisabled: boolean;
  rateLimits?: RateTypeLimits;
  chooseStep: ChooseStep;
  activeStep: ChargeStepActiveForm;
  openInLastStep: boolean;
  paymentStep: {
    canChangePaymentMethod: boolean;
    paymentMethod?: PaymentMethod;
    selectPaymentId?: string;
    extraField?: string;
    cardCode?: string;
  };
  chargeStep: {
    account: AccountModel | null;
    isChargeInit: boolean; // is clicked on start charge
    isCargerConnected: boolean; // have connection with charger
    isChargeCreated: boolean; // charge is created with push notification
    isStoppingCharge: boolean;
    loadingMessage: string;
  };
}

export enum ChargeStepLabel {
  CHOOSE = "CHOOSE",
  ACTIVATE = "ACTIVATE",
  PAYMENT = "PAYMENT",
  CHARGE = "CHARGE",
}

interface ChooseStep {
  time: number;
  energy: number;
  price: number;
  currency?: { code: string; symbol: string };
  isLoadingPrice: boolean;
}
export interface ChargeStepActiveForm {
  email: string;
  vehicleId: string | null;
  vehicleBrand: VehicleBrand | null;
  vehicleModel: {
    id: number;
    name: string;
  } | null;
  otherBrand?: string;
  otherModel?: string;
  carId: string;
}

export const chargeStepsSliceInitialState: ChargeStepsSlice = {
  stepsLabels: [],
  currentStep: 0,
  currentStepLabel: ChargeStepLabel.CHOOSE,
  nextStepDisabled: true,
  rateLimits: undefined,
  openInLastStep: false,
  chooseStep: {
    time: 0,
    energy: 0,
    price: 0,
    isLoadingPrice: false,
  },
  activeStep: {
    email: "",
    vehicleId: null,
    vehicleBrand: null,
    otherBrand: "",
    vehicleModel: null,
    otherModel: "",
    carId: "",
  },
  paymentStep: {
    canChangePaymentMethod: false,
  },
  chargeStep: {
    account: null,
    isChargeInit: false,
    isCargerConnected: false,
    isChargeCreated: false,
    isStoppingCharge: false,
    loadingMessage: "",
  },
};

export const chargeStepsSlice = createSlice({
  name: "chargeStepsReducer",
  initialState: chargeStepsSliceInitialState,
  reducers: {
    setStepsLabels: (state, { payload }: PayloadAction<ChargeStepLabel[]>) => {
      state.stepsLabels = payload;
    },
    setCurrentStep: (state, { payload }: PayloadAction<number>) => {
      state.currentStep = payload;
      state.currentStepLabel = state.stepsLabels[payload];
    },
    setNextStepDisabled: (state, { payload }: PayloadAction<boolean>) => {
      state.nextStepDisabled = payload;
    },
    setRateLimits: (state, { payload }: PayloadAction<RateTypeLimits>) => {
      state.rateLimits = payload;
    },
    setChargeStepOpenInLastStep: (state, { payload }: PayloadAction<boolean>) => {
      state.openInLastStep = payload;
    },
    setCleanChargeSteps: (state) => {
      state.stepsLabels = chargeStepsSliceInitialState.stepsLabels;
      state.currentStep = chargeStepsSliceInitialState.currentStep;
      state.currentStepLabel = chargeStepsSliceInitialState.currentStepLabel;
      state.nextStepDisabled = chargeStepsSliceInitialState.nextStepDisabled;
      state.rateLimits = chargeStepsSliceInitialState.rateLimits;
      state.openInLastStep = chargeStepsSliceInitialState.openInLastStep;
      state.chooseStep = chargeStepsSliceInitialState.chooseStep;
      state.activeStep = chargeStepsSliceInitialState.activeStep;
      state.paymentStep = chargeStepsSliceInitialState.paymentStep;
      state.chargeStep = chargeStepsSliceInitialState.chargeStep;
    },
    setChooseStep: (state, { payload }: PayloadAction<Omit<ChooseStep, "isLoadingPrice">>) => {
      state.chooseStep = {
        energy: payload.energy,
        price: payload.price,
        time: payload.time,
        currency: state.chooseStep.currency || payload.currency,
        isLoadingPrice: state.chooseStep.isLoadingPrice,
      };
    },
    setChooseStepIsLoadingPrice: (state, { payload }: PayloadAction<boolean>) => {
      state.chooseStep.isLoadingPrice = payload;
    },
    setActiveStep: (state, { payload }: PayloadAction<ChargeStepActiveForm>) => {
      state.activeStep = payload;
    },
    cleanChargeStepsActiveStep: (state) => {
      state.activeStep = chargeStepsSliceInitialState.activeStep;
    },
    setActiveVehicleId: (state, { payload }: PayloadAction<string | null>) => {
      state.activeStep.vehicleId = payload;
    },
    setPaymentStepCanChangePaymentMethod: (state, { payload }: PayloadAction<boolean>) => {
      state.paymentStep.canChangePaymentMethod = payload;
    },
    setPaymentStepPaymentMethod: (state, { payload }: PayloadAction<PaymentMethod | undefined>) => {
      state.paymentStep.paymentMethod = payload;
    },
    setPaymentStepSelectPaymentId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.paymentStep.selectPaymentId = payload;
    },
    setPaymentStepExtraField: (state, { payload }: PayloadAction<string | undefined>) => {
      state.paymentStep.extraField = payload;
    },
    setPaymentStepCardCode: (state, { payload }: PayloadAction<string | undefined>) => {
      state.paymentStep.cardCode = payload;
    },
    setChargeStepAccount: (state, { payload }: PayloadAction<AccountModel | null>) => {
      state.chargeStep.account = payload;
    },
    setChargeStepIsChargeInit: (state, { payload }: PayloadAction<boolean>) => {
      state.chargeStep.isChargeInit = payload;
    },
    setChargeStepIsCargerConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.chargeStep.isCargerConnected = payload;
    },
    setChargeStepIsChargeCreated: (state, { payload }: PayloadAction<boolean>) => {
      state.chargeStep.isChargeCreated = payload;
    },
    setChargeStepIsStoppingCharge: (state, { payload }: PayloadAction<boolean>) => {
      state.chargeStep.isStoppingCharge = payload;
    },
    setChargeStepLoadingMessage: (state, { payload }: PayloadAction<string>) => {
      state.chargeStep.loadingMessage = payload;
    },
    setResetChargeStep: (state) => {
      state.chargeStep = chargeStepsSliceInitialState.chargeStep;
      state.currentStep = state.currentStep - 1;
      state.currentStepLabel = state.stepsLabels[state.currentStep];
    },
  },
});

export const chargeStepsActions = chargeStepsSlice.actions;

export default chargeStepsSlice.reducer;
