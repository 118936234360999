import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { CartProductModel, MerchantProductModel } from "@/core/models/accountMerchantModel";
import { shopActions } from "@/core/store/slices/shopSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButton, IonText } from "@ionic/react";
import { t } from "i18next";
import { addOutline, removeOutline, trashOutline } from "ionicons/icons";
import "./ShopProductButtons.scss";

interface ShopProductButtonsProps {
  product: MerchantProductModel;
  productCart?: CartProductModel;
  size?: "small" | "medium" | "large";
}

export const ShopProductButtons = ({ product, productCart, size }: ShopProductButtonsProps) => {
  const dispatch = useAppDispatch();

  const { user_id } = useAppSelector((state) => state.userReducer.user);

  const handleAddToCart = () => {
    if (productCart && productCart.uds >= 99) {
      return;
    }

    dispatch(shopActions.addProductToShop({ newProductId: product.id, user_id: user_id }));
  };

  const handleRemoveFromCart = () => {
    dispatch(shopActions.removeProductFromShop({ productToRemove: product.id, user_id: user_id }));
  };

  return (
    <div className="shop-product-buttons">
      {productCart ? (
        <div className={`action-buttons ${classIf(size === "small", "header-small")}`}>
          <IconButton
            icon={productCart.uds > 1 ? removeOutline : trashOutline}
            fill="solid"
            onClick={handleRemoveFromCart}
            size={size}
          />
          <IonText>{productCart.uds}</IonText>
          <IconButton
            icon={addOutline}
            fill="solid"
            disabled={productCart.uds >= 99}
            onClick={handleAddToCart}
            size={size}
          />
        </div>
      ) : (
        <IonButton onClick={handleAddToCart}>{t("BUTTONS.ADD")}</IonButton>
      )}
    </div>
  );
};
