import { SupportTicketModel } from "@/core/models/incidenceModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IncidenceSlice {
  incidences: SupportTicketModel[];
  isLoadingIncidences: boolean;
  incidence?: SupportTicketModel; // Used to show the incidence detail
  isLoadingIncidence: boolean;
  lastUpdateIncidence?: {
    id: string;
    date: Date;
  };
  newIncidenceComment: NewIncidenceComment;
}

interface NewIncidenceComment {
  comment: string;
  image: {
    id: number;
    data: File;
    file_name: string;
  } | null;
}

export const incidenceInitialState: IncidenceSlice = {
  incidences: [],
  isLoadingIncidences: false,
  incidence: undefined,
  isLoadingIncidence: false,
  lastUpdateIncidence: undefined,
  newIncidenceComment: {
    comment: "",
    image: null,
  },
};

export const incidenceSlice = createSlice({
  name: "uiReducer",
  initialState: incidenceInitialState,
  reducers: {
    setIncidences: (state, action: PayloadAction<SupportTicketModel[]>) => {
      state.incidences = action.payload;
    },
    setIsLoadingIncidences: (state, action: PayloadAction<boolean>) => {
      state.isLoadingIncidences = action.payload;
    },
    setIncidence: (state, action: PayloadAction<SupportTicketModel | undefined>) => {
      state.incidence = action.payload;
    },
    setIsLoadingIncidence: (state, action: PayloadAction<boolean>) => {
      state.isLoadingIncidence = action.payload;
    },
    setNewIncidenceComment: (state, action: PayloadAction<NewIncidenceComment>) => {
      state.newIncidenceComment = action.payload;
    },
    setLastUpdateIncidence: (state, action: PayloadAction<{ id: string; date: Date }>) => {
      state.lastUpdateIncidence = action.payload;
    },
    clearIncidence: (state) => {
      state.incidence = incidenceInitialState.incidence;
      state.isLoadingIncidence = incidenceInitialState.isLoadingIncidence;
      state.lastUpdateIncidence = incidenceInitialState.lastUpdateIncidence;
      state.newIncidenceComment = incidenceInitialState.newIncidenceComment;
    },
  },
});

export const incidenceActions = incidenceSlice.actions;

export default incidenceSlice.reducer;
