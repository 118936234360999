/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "@/theme/variables.scss";

import "./App.scss";

import { Menu } from "@/commons/components/Menu/Menu";
import { Toast } from "@/commons/components/Toast/Toast";
import { ignoreSentryErrors } from "@/commons/constants/sentry";
import { ParametersProvider } from "@/context/appParametersContext";
import { DeepLinkContext } from "@/context/deepLinkContext";
import { LocationProvider } from "@/context/locationContext";
import { LocationsProvider } from "@/context/locationsContext";
import { NotificationsContext } from "@/context/notificationsContext";
import { UserProvider } from "@/context/userContext";
import { persistor, store } from "@/core/store/store";
import { Router } from "@/router/Router";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import * as Sentry from "@sentry/capacitor";
import { init as sentryReactInit } from "@sentry/react";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Alert } from "./commons/components/Alert/Alert";
import { HelpModal } from "./commons/components/Help/HelpModal";
import { AppFeedbackModals } from "./commons/components/UI/AppFeedbackModals/AppFeedbackModals";
import { AppMarketingModal } from "./commons/components/UI/AppMarketingModal/AppMarketingModal";
import { NfcScanner } from "./pages/MapPage/ScanButton/NfcScanner/NfcScanner";

const { VITE_APP_NAME, VITE_PACKAGE_VERSION, VITE_FORCE_ANALYTICS, VITE_FORCE_SENTRY, VITE_SENTRY_DSN, MODE } =
  import.meta.env;

const MODE_APP = VITE_PACKAGE_VERSION?.includes("dev") ? "development" : MODE;
const enableSentry = MODE_APP === "production" || VITE_FORCE_SENTRY === "true";
const enableAnalytics = MODE_APP === "production" || VITE_FORCE_ANALYTICS === "true";

if (enableSentry) {
  Sentry.init(
    {
      dsn: VITE_SENTRY_DSN,
      environment: MODE_APP,
      dist: "1",
      denyUrls: [/node_modules/],
      beforeSend(event, hint: any) {
        const errorMessage = hint.originalException && hint.originalException.message;

        if (errorMessage && ignoreSentryErrors.some((error) => errorMessage.includes(error))) {
          return null;
        }

        return event;
      },
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === "ui.click" && hint) {
          const target = hint.event?.target;
          const parent = target?.parentElement;
          breadcrumb.message = `Click event`;

          breadcrumb.data = {
            target: {
              id: target?.id || null,
              className: target?.className || null,
              innerText: target?.innerText || null,
              page: window.location.pathname,
              pageParams: window.location.search,
            },
            parent: {
              id: parent?.id || null,
              className: parent?.className || null,
            },
          };
        }

        return breadcrumb;
      },
    },
    sentryReactInit,
  );
}

setupIonicReact({
  innerHTMLTemplatesEnabled: true,
});

export const App = () => {
  useEffect(() => {
    // flush the persistor to make sure the store is updated with the latest data
    persistor.flush();

    setHeadHTMLData();

    saveVersionLocalStorage();

    enableSentry && setVersionSentry();

    if (Capacitor.getPlatform() !== "web") {
      // Firebase
      FirebaseAnalytics.setCollectionEnabled({
        enabled: enableAnalytics,
      });
    }
  }, []);

  const setHeadHTMLData = () => {
    const meta = document.createElement("meta");
    meta.name = "apple-mobile-web-app-title";
    meta.content = VITE_APP_NAME || "";
    document.getElementsByTagName("head")[0].appendChild(meta);

    document.title = VITE_APP_NAME || "";
  };

  const setVersionSentry = async () => {
    let version = VITE_PACKAGE_VERSION;

    if (Capacitor.getPlatform() !== "web") {
      version = (await CapacitorApp.getInfo()).version;
    }

    Sentry.setExtra("release", `${VITE_APP_NAME?.toLowerCase()}@${version}`);
  };

  const saveVersionLocalStorage = async () => {
    let appVersion = VITE_PACKAGE_VERSION || "";

    if (Capacitor.getPlatform() !== "web") {
      appVersion = (await CapacitorApp.getInfo()).version;
    }

    localStorage.setItem("appVersion", appVersion);
  };
  return (
    <IonApp>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <IonReactRouter>
            <ParametersProvider>
              <UserProvider>
                <LocationsProvider>
                  <LocationProvider>
                    <DeepLinkContext />
                    <NotificationsContext />
                    <Menu />
                    <HelpModal />
                    <Alert />
                    <Toast />
                    <AppFeedbackModals />
                    <AppMarketingModal />
                    <Router />
                    <NfcScanner />
                  </LocationProvider>
                </LocationsProvider>
              </UserProvider>
            </ParametersProvider>
          </IonReactRouter>
        </PersistGate>
      </Provider>
    </IonApp>
  );
};
