import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { IonLabel } from "@ionic/react";
import { ReactNode } from "react";
import "./StatusLabel.scss";

interface StatusLabelProps {
  className?: string;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLIonLabelElement>) => void;
  children?: ReactNode;
  contrast?: boolean;
}

export const StatusLabel = ({ className, active, onClick, children, contrast }: StatusLabelProps) => {
  return (
    <IonLabel
      className={`status-label 
      ${classIf(!!active, "status-label-active")} 
      ${classIf(!!contrast, "contrast")} 
      ${classIf(!!className, className || "")}`}
      onClick={(e) => onClick && onClick(e)}
    >
      {children}
    </IonLabel>
  );
};
