import { getPaymentSetupIntentService } from "@/core/services/payment/paymentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { StripeConstructorOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { t } from "i18next";

export const useStripeInfo = () => {
  const dispatch = useAppDispatch();

  const loadStripeInfo = async (stripeAccount?: string, useStripeAccount = true) => {
    try {
      const paymentIntent = await getPaymentSetupIntentService();

      const stripeOptions: StripeConstructorOptions = {};

      const newStripeAccount = stripeAccount ?? paymentIntent.stripe_account;

      if (useStripeAccount && newStripeAccount) {
        stripeOptions.stripeAccount = newStripeAccount;
      }

      const stripe = await loadStripe(paymentIntent.public_key, stripeOptions);

      if (!stripe) {
        throw new Error(t("APP_ERRORS.INIT_PAYMENT"));
      }

      return {
        paymentIntent,
        stripe,
      };
    } catch (error) {
      dispatch(uiActions.setToastError(error));
      throw error;
    }
  };

  return {
    loadStripeInfo,
  };
};
