import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { UserContext } from "@/context/userContext";
import { setUserFavoriteChargerService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { t } from "i18next";
import { bookmark, bookmarkOutline } from "ionicons/icons";
import { useContext, useState } from "react";

interface FavoriteButtonProps {
  chargerId: string;
}

export const FavoriteButton = ({ chargerId }: FavoriteButtonProps) => {
  const dispatch = useAppDispatch();

  const { loadUserInfo } = useContext(UserContext);

  const { userInfo, isUserLogged } = useAppSelector((state) => state.userReducer);

  const isFavorite = userInfo?.fav_chargers.includes(Number(chargerId));

  const [isLoadingFav, setIsLoadingFav] = useState(false);

  const handleSetFavoriteCharger = async () => {
    if (!isUserLogged) {
      return dispatch(uiActions.setAlertType({ type: "alertRegister" }));
    }

    try {
      const newIsFavorite = !isFavorite;
      setIsLoadingFav(true);

      await setUserFavoriteChargerService(chargerId, newIsFavorite);

      await loadUserInfo();

      if (newIsFavorite) {
        dispatch(uiActions.setToast({ show: true, message: t("TOAST.ADDED_FAV"), color: "success" }));
      }
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoadingFav(false);
    }
  };

  return (
    <IconButton
      icon={isFavorite ? bookmark : bookmarkOutline}
      loading={isLoadingFav}
      onClick={handleSetFavoriteCharger}
    />
  );
};
