import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { formatNumberDecimals } from "@/commons/utils/numberUtils";
import { ChargeModel } from "@/core/models/chargeModel";
import { CHARGER_STATUS_IDS, ChargerModel } from "@/core/models/chargerModel";
import { IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { checkmarkDoneCircleOutline, timeOutline } from "ionicons/icons";
import { useMemo } from "react";

interface ChargeCardProps {
  charge: ChargeModel;
  chargerInfo?: ChargerModel;
}

export const ChargeCard = ({ charge, chargerInfo }: ChargeCardProps) => {
  const { parameters } = useParametersContext();

  const isActive = useMemo(() => !charge.session_end_at, [charge.session_end_at]);

  return (
    <>
      {charge && (
        <IonRow className="chargeInfoContainer">
          <IonRow className="rowChargeHistory">
            <IonCol className="colCharge">
              <IonLabel className="subtitleInfoCharge">{t("INFO_CHARGE.CHARGER")}</IonLabel>
              <IonLabel className="labelInfoCharge">
                {charge.charger_id} - {charge.charger_name}
              </IonLabel>
              {isActive && chargerInfo?.id_status === CHARGER_STATUS_IDS.OUT_OF_SERVICE && (
                <IonRow style={{ marginTop: "8px" }}>
                  <StatusLabel> {t("CHARGER.NO_COMMUNICATION")} </StatusLabel>
                </IonRow>
              )}
            </IonCol>
          </IonRow>
          <IonRow className="rowChargeHistory">
            <IonCol className="colCharge">
              <IonLabel className="subtitleInfoCharge">{t("INFO_CHARGE.DATE")}</IonLabel>
              <IonLabel className="labelInfoCharge number">{format(charge.session_start_at, "dd/MM/yyyy")}</IonLabel>
            </IonCol>
            <IonCol className="colCharge">
              <IonLabel className="subtitleInfoCharge">{t("INFO_CHARGE.ENERGY")}</IonLabel>
              <IonLabel className="labelInfoCharge number">{charge.energy_Wh} Wh</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow className="rowChargeHistory">
            {!isActive && (
              <IonCol className="colCharge">
                <IonLabel className="subtitleInfoCharge">{t("INFO_CHARGE.HOUR")}</IonLabel>
                <IonLabel className="labelInfoCharge number">
                  {format(charge.session_start_at, "HH:mm")} - {format(charge.session_end_at, "HH:mm")}
                </IonLabel>
              </IonCol>
            )}
            <IonCol className="colCharge">
              <IonLabel className="subtitleInfoCharge">{t("INFO_CHARGE.PRICE")}</IonLabel>
              {isActive ? (
                <IonLabel className="labelInfoCharge number">-</IonLabel>
              ) : (
                <>
                  {charge.payment_captured ? (
                    <>
                      {charge.amount !== "0" ? (
                        <IonLabel className="labelInfoCharge number">
                          {formatNumberDecimals(Number(charge.amount) / 100)} {parameters.currency}
                        </IonLabel>
                      ) : (
                        <IonLabel className="labelInfoCharge number">{t("INFO_CHARGE.FREE_CHARGE")}</IonLabel>
                      )}
                    </>
                  ) : (
                    <>
                      {charge.amount !== "0" ? (
                        <IonLabel className="labelInfoCharge number">{t("LOADING.PROCESSING_PAYMENT")}...</IonLabel>
                      ) : (
                        <IonLabel className="labelInfoCharge number">{t("INFO_CHARGE.FREE_CHARGE")}</IonLabel>
                      )}
                    </>
                  )}
                </>
              )}
            </IonCol>
          </IonRow>
          <StatusLabel active={isActive}>
            <IonIcon icon={isActive ? timeOutline : checkmarkDoneCircleOutline} />
            {isActive ? t("BOOKING_INFO.ACTIVE") : t("INFO_CHARGE.FINISHED")}
          </StatusLabel>
        </IonRow>
      )}
    </>
  );
};
