import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface WalletSlice {
  showHistory: boolean;
  addBalanceSteps: AddBalanceSteps;
}

interface AddBalanceSteps {
  currentStep: number;
  amount: number;
}

export const walletSliceInitialState: WalletSlice = {
  showHistory: false,
  addBalanceSteps: {
    currentStep: 1,
    amount: 0,
  },
};

export const walletSlice = createSlice({
  name: "walletReducer",
  initialState: walletSliceInitialState,
  reducers: {
    setShowHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.showHistory = payload;
    },
    setAddBalanceSteps: (state, { payload }: PayloadAction<AddBalanceSteps>) => {
      state.addBalanceSteps = payload;
    },
  },
});

export const walletActions = walletSlice.actions;

export default walletSlice.reducer;
