import loginImage from "@/assets/imgs/loginImage.png";
import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { getURLDomain } from "@/commons/utils/apiUtils/apiUtils";
import { ErrorModel } from "@/core/models/apiModel";
import { createTokenUserService, loginUserService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch } from "@/core/store/store";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonToolbar,
} from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface LoginEmailProps {
  handleGoBack: () => void;
}

export const LoginEmail = ({ handleGoBack }: LoginEmailProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setLogEvent } = useFirebaseAnalytics();
  const { parameters } = useParametersContext();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await loginUserService({ email, password });

      const userId = response.user_id;
      let authToken = response.auth_token;
      const authenticated = response.authenticated === "true";

      if (!authenticated) {
        dispatch(uiActions.setAlertType({ type: "badCredentials" }));
        console.error("Credentials are wrong");
        return;
      }

      if (!authToken) {
        const { new_token } = await createTokenUserService(userId, parameters.token_api);
        authToken = new_token;
      }

      // if userToken is still after trying to create a new one
      if (!authToken) {
        dispatch(uiActions.setToastError(t("APP_ERRORS.ADD_PAYMENT")));
        return;
      }

      dispatch(
        userActions.setUser({
          user: {
            user_id: userId,
            auth_token: authToken,
          },
          userType: "logged",
        }),
      );

      setLogEvent("login", { method: "email" });
    } catch (error) {
      const errorModel = error as ErrorModel;
      console.error("Error login email", error, errorModel.jsonError);

      if (errorModel.jsonError?.error_code === "-18") {
        return dispatch(uiActions.setAlertType({ type: "badCredentials" }));
      }

      dispatch(uiActions.setAlertType({ type: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e: CustomEvent) => {
    setEmail(e.detail.value);
  };

  const handlePasswordChange = (e: CustomEvent) => {
    setPassword(e.detail.value);
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <AppHeader startIcon={<GoBackButton onClick={handleGoBack} />} />
      <IonContent>
        <div className="loginImageContainer">
          <IonLabel>{t("TITLES.WELCOME")}</IonLabel>
          <img src={loginImage} alt="login background" />
        </div>

        <IonList lines="none" className="loginPageContent">
          <IonLabel className="titleH4">{t("TITLES.ENTER_USER_DATA")}</IonLabel>
          <IonItem lines="full">
            <IonInput
              label={t("PROFILE.EMAIL")}
              labelPlacement="floating"
              onIonInput={handleEmailChange}
              type="text"
              value={email}
            />
          </IonItem>
          <IonItem className="ion-margin-top ion-margin-bottom" lines="full">
            <IonInput
              label={t("PROFILE.PASSWORD")}
              labelPlacement="floating"
              onIonInput={handlePasswordChange}
              type={showPassword ? "text" : "password"}
              value={password}
            />
            <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} onClick={toggleShowPassword} slot="end" />
          </IonItem>
          <IonItem>
            <IonLabel className="forgotPassword">
              <a href={getURLDomain("users/password/new", undefined, { isRootPath: true })}>
                {t("BUTTONS.FORGOT_PASSWORD")}
              </a>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>

      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={handleLogin} style={{ padding: "0 16px", margin: "16px 0" }}>
            {t("ALERT_BUTTONS.LOGIN")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
