import { CreditCard } from "@/commons/components/Payments/CreditCard/CreditCard";
import { PaymentSelector } from "@/commons/components/Payments/PaymentSelector/PaymentSelector";
import { WalletView } from "@/commons/components/Payments/WalletView/WalletView";
import { PaymentMethod } from "@/core/models/PaymentModel";
import { StripePaymentMethod } from "@/core/models/userModel";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButton, IonIcon } from "@ionic/react";
import { t } from "i18next";
import { arrowBack } from "ionicons/icons";
import { ReactElement, useEffect, useState } from "react";
import { AddCreditCardGateway } from "../../AddCreditCardGateway/AddCreditCardGateway";
import { StepReducer, StepsActions } from "../PaymentStep";
import "./PaymentStepLogged.scss";

interface PaymentStepLoggedProps {
  stepsReducer: StepReducer;
  stepsActions: StepsActions;
}

export const PaymentStepLogged = ({ stepsReducer, stepsActions }: PaymentStepLoggedProps) => {
  const dispatch = useAppDispatch();

  const { chooseStep, paymentStep } = useAppSelector((state) => state[stepsReducer]);

  const { canChangePaymentMethod, paymentMethod, selectPaymentId } = paymentStep;
  const { price, isLoadingPrice } = chooseStep;

  const { userInfo } = useAppSelector((state) => state.userReducer);

  const [haveRequiredBalance, setHaveRequiredBalance] = useState(true);

  useEffect(() => {
    if (paymentMethod === "CREDITCARD") {
      // Select the first credit card if the user have only one and not pred
      if (userInfo?.stripe_payment_methods.length === 1) {
        dispatch(stepsActions.setPaymentStepSelectPaymentId(userInfo.stripe_payment_methods[0].payment_method));
        return;
      }
      if (userInfo?.stripe_payment_methods.length || 0 > 1) {
        const defaultCreditCard = userInfo?.stripe_payment_methods.find((creditCard) => creditCard.predetermined);

        if (defaultCreditCard) {
          dispatch(stepsActions.setPaymentStepSelectPaymentId(defaultCreditCard.payment_method));
          return;
        }
      }
    }

    if (paymentMethod === "WALLET") {
      // Calculate if the user have the required balance
      const wallerAmount = (userInfo?.wallet_amount || 0) / 100;
      const newHaveRequiredBalance = wallerAmount - price >= 0;
      setHaveRequiredBalance(newHaveRequiredBalance);

      dispatch(stepsActions.setNextStepDisabled(!newHaveRequiredBalance || isLoadingPrice));
      return;
    }
  }, [paymentMethod, price, isLoadingPrice, userInfo?.wallet_amount]);

  const handleMethodClick = (method: PaymentMethod) => {
    dispatch(stepsActions.setPaymentStepPaymentMethod(method));
  };

  const handleBackClick = () => {
    dispatch(stepsActions.setPaymentStepPaymentMethod(undefined));
  };

  const handleCreditCardClick = (creditCard: StripePaymentMethod) => {
    const newSelectPaymentId = creditCard.payment_method === selectPaymentId ? undefined : creditCard.payment_method;
    dispatch(stepsActions.setPaymentStepSelectPaymentId(newSelectPaymentId));
  };

  const callbackAddCard = (newSelectPaymentId: string, extraField: string) => {
    dispatch(stepsActions.setPaymentStepCanChangePaymentMethod(false));
    dispatch(stepsActions.setPaymentStepSelectPaymentId(newSelectPaymentId));
    dispatch(stepsActions.setPaymentStepExtraField(extraField));
  };

  const paymentMethodComponent: Record<PaymentMethod, ReactElement> = {
    CREDITCARD: (
      <div className="credit-card-list">
        {userInfo?.stripe_payment_methods.length ? (
          <>
            {userInfo.stripe_payment_methods.map((creditCard) => (
              <CreditCard
                key={creditCard.id}
                creditCard={creditCard}
                isSelected={creditCard.payment_method === selectPaymentId}
                onClick={handleCreditCardClick}
              />
            ))}
          </>
        ) : (
          <AddCreditCardGateway callbackAddCard={callbackAddCard} selectPaymentId={selectPaymentId} />
        )}
      </div>
    ),
    WALLET: <WalletView haveRequiredBalance={haveRequiredBalance} />,
    CARD: <div />,
  };

  return (
    <div className="payment-step-logged">
      {!paymentMethod ? (
        <PaymentSelector handleMethodClick={handleMethodClick} />
      ) : (
        <div>
          {canChangePaymentMethod && (
            <IonButton onClick={handleBackClick} color="dark" fill="clear">
              <IonIcon slot="start" icon={arrowBack} />
              <span style={{ marginRight: "auto" }}>{t("BUTTONS.CHANGE_PAYMENT_METHOD")}</span>
            </IonButton>
          )}
          {paymentMethodComponent[paymentMethod]}
        </div>
      )}
    </div>
  );
};
