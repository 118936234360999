import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { RotateImageIcon } from "@/commons/constants/imgs";
import { SupportTicketCommentModel } from "@/core/models/incidenceModel";
import { getDocumentDownloadUrlService } from "@/core/services/document/documentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButtons, IonLabel, IonLoading, IonRow, IonText } from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { downloadOutline } from "ionicons/icons";
import { useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "./IncidenceComment.scss";

interface IncidenceCommentProps {
  comment: SupportTicketCommentModel;
}

export const IncidenceComment = ({ comment }: IncidenceCommentProps) => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userReducer);

  const isAdmin = comment.user_id !== user.user_id;
  const formattedDate = format(comment.date, "dd/MM/yyyy HH:mm");

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadImage = async () => {
    setIsDownloading(true);
    try {
      comment.img_filename &&
        comment.img_url &&
        (await getDocumentDownloadUrlService(comment.img_filename, comment.img_url));
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setToastType({ type: "error" }));
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className={`incidence-comment ${isAdmin ? "admin" : "user"}`}>
      <IonLoading isOpen={isDownloading} message={t("LOADING.DOWNLOADING")} />
      <IonText className="comment-text">{comment.comment}</IonText>
      {comment.img_url && (
        <PhotoProvider
          photoClosable={false}
          maskOpacity={0.8}
          loop={false}
          toolbarRender={({ rotate, onRotate }) => (
            <IonButtons>
              <IconButton icon={downloadOutline} color="clear" onClick={handleDownloadImage} />
              <IconButton
                icon=""
                color="clear"
                onClick={() => {
                  onRotate(rotate + 90);
                }}
              >
                <RotateImageIcon style={{ width: "20px", height: "20px" }} />
              </IconButton>
            </IonButtons>
          )}
        >
          <PhotoView src={comment.img_url}>
            <img className="comment-image" src={comment.img_url} alt=" incidence-image" />
          </PhotoView>
        </PhotoProvider>
      )}
      <IonRow class="comment-footer">
        <IonLabel className="comment-date">{formattedDate}</IonLabel>
      </IonRow>
    </div>
  );
};
