import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { PaymentCreateIntentModel, ResponseCardPayuModel, SetupIntentPayu } from "@/core/models/PaymentModel";
import { AccountModel } from "@/core/models/accountModel";
import { ReservationModel } from "@/core/models/reservationModel";

export const getSetupIntentPayuPaymentService = async (): Promise<SetupIntentPayu> => {
  return api.get("payments/setup_intent_payu").then(resolveResponse).catch(rejectError);
};

export const createCardPayuPaymentService = async (
  response: string,
): Promise<{ result: boolean; customer?: ResponseCardPayuModel }> => {
  return api
    .post("api/v1/create_payu_card", { response }, undefined, { isRootPath: true })
    .then(resolveResponse)
    .catch(rejectError);
};

export const deleteCardPayuPaymentService = async (card_token: string): Promise<{ result: true }> => {
  return api
    .post("api/v1/delete_payu_card", { token: card_token }, undefined, { isRootPath: true })
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentWalletPayuService = async (
  paymentMethod: string,
  amount: number,
  currency: string,
): Promise<{ result: true }> => {
  return api
    .post(
      "api/v1/create_payment_wallet_payu",
      { paymentMethod, amount, type: "wallet", mode: "APP", currency },
      undefined,
      { isRootPath: true },
    )
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentIntentPayuService = async (
  currency: string,
  cardCode: string,
  socketId: string,
  chargingEnergy: number,
  paymentMethod: string,
  deviceId: string,
  vehicleId: string,
  extraField?: string,
): Promise<{ intent: PaymentCreateIntentModel; account: string }> => {
  return api
    .post(
      "api/v1/create_payment_payu",
      {
        currency,
        cardCode,
        socketId,
        chargingEnergy,
        paymentMethod,
        vehicleId,
        encryptedCvv: extraField,
        deviceId,
      },
      undefined,
      { isRootPath: true },
    )
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentReservationPayuService = async (
  currency: string,
  cardCode: string,
  socketId: string,
  reserveTime: number,
  paymentMethod: string,
): Promise<{ intent?: PaymentCreateIntentModel; account: AccountModel; reservation: ReservationModel }> => {
  return api
    .post(
      "api/v1/create_reservation_payment_payu",
      {
        currency,
        cardCode,
        socketId,
        reserveTime,
        paymentMethod,
      },
      undefined,
      { isRootPath: true },
    )
    .then(resolveResponse)
    .catch(rejectError);
};
