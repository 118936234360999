import { UserCardModel, UserInfoModel } from "@/core/models/userModel";
import { createUserCardService } from "@/core/services/user/userServices";
import { t } from "i18next";

export const getUserAppCard = async (userId: number, userInfo: UserInfoModel | null): Promise<UserCardModel> => {
  if (userId === -1) throw new Error(t("APP_ERRORS.VALID_USER"));

  if (!userInfo) throw new Error(t("APP_ERRORS.LOAD_USER_INFO"));

  const userAppCard = userInfo.user_cards.find((card) => card.app_card === "true");

  if (userAppCard) {
    return userAppCard;
  }

  const { card } = await createUserCardService(`${userId}`);

  return card;
};

/**
 * Format user to update info
 * @override default_wh to kwh
 */
export const formatUserInfo = (userInfo: UserInfoModel): UserInfoModel => ({
  ...userInfo,
  default_charge_values: {
    ...userInfo.default_charge_values,
    default_wh: userInfo.default_charge_values?.default_wh
      ? userInfo.default_charge_values.default_wh / 1000
      : userInfo.default_charge_values.default_wh,
  },
});
