import { AccountModel } from "@/core/models/accountModel";
import { ErrorModel } from "@/core/models/apiModel";
import { getPaymentAccountService, getPaymentPriceService } from "@/core/services/payment/paymentServices";
import { startSocketService, stopSocketService } from "@/core/services/socket/socketServices";
import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirebaseAnalytics } from "./useFirebaseAnalytics";
import { useQuery } from "./useQuery";
import { useSlack } from "./useSlack";

const INTERVAL_TO_CHECK = 10 * 1000; // 10 seconds
const MAX_CHECKS = 18; // 10 s * 18 -> 3 minutes

export const useChargeStart = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { searchParams } = useQuery();
  const slack = useSlack();
  const { setLogEvent } = useFirebaseAnalytics();

  const { isStoppingCharge } = useAppSelector((state) => state.chargeStepsReducer.chargeStep);
  const { selectedCharger, selectedSocket } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { paymentStep, chargeStep, chooseStep } = useAppSelector((state) => state.chargeStepsReducer);

  const [intevalCheck, setIntevalCheck] = useState<NodeJS.Timeout | null>(null);

  // Go home when charge is created with push notification
  useEffect(() => {
    if (chargeStep.isChargeCreated) {
      dispatch(uiActions.setAlertType({ type: "successInitCharge" }));
      handleGoHome();
      intevalCheck && clearInterval(intevalCheck);
    }
  }, [intevalCheck, chargeStep.isChargeCreated]);

  // clear interval when component is unmounted
  useEffect(() => {
    return () => {
      intevalCheck && clearInterval(intevalCheck);
    };
  }, [intevalCheck]);

  const handleGoHome = () => {
    searchParams.delete("locationId");
    history.replace({ search: searchParams.toString() });

    dispatch(chargeStepsActions.setCleanChargeSteps());
    dispatch(mapActions.cleanSelectedLocation());
  };

  const handleStartCharge = async (account: AccountModel | null) => {
    account = account || chargeStep.account;
    if (!selectedCharger || !selectedSocket || !paymentStep.cardCode || !account) {
      dispatch(uiActions.setToastError(t("APP_ERRORS.CHARGE_DATA")));
      slack.error("handleStartCharge", "No necessary data to start charge");
      return;
    }

    dispatch(chargeStepsActions.setChargeStepIsChargeInit(true));

    const MAX_ERROR_COUNTER = 6;
    let errorCounter = 0;

    dispatch(chargeStepsActions.setChargeStepLoadingMessage(t("LOADING.STARTING_CONNECTION")));

    while (errorCounter < MAX_ERROR_COUNTER) {
      try {
        await startSocketService(
          selectedCharger.id_charger,
          selectedSocket.socket_number,
          paymentStep.cardCode,
          account.id,
        );

        checkChargeStart(account.id);
        dispatch(chargeStepsActions.setChargeStepIsCargerConnected(true));
        dispatch(chargeStepsActions.setChargeStepLoadingMessage(""));

        setLogEvent("init_charge", {
          charger_id: selectedCharger.id_charger,
          socket_id: selectedSocket.id_socket,
          account_id: account.id,
        });

        break;
      } catch (error) {
        const newError = error as ErrorModel;

        if (newError.jsonError?.error_code === "-25") {
          errorCounter = MAX_ERROR_COUNTER;

          dispatch(chargeStepsActions.setResetChargeStep());
          dispatch(uiActions.setToastError(error));

          try {
            dispatch(chargeStepsActions.setChooseStepIsLoadingPrice(true));
            dispatch(chargeStepsActions.setNextStepDisabled(true));

            const priceObject = await getPaymentPriceService(selectedSocket.id_socket, chooseStep.energy * 1000);
            const newPrice = priceObject.price;

            if (newPrice <= 0) {
              dispatch(chargeStepsActions.setCurrentStep(0));
            }

            dispatch(
              chargeStepsActions.setChooseStep({
                time: chooseStep.time,
                energy: chooseStep.energy,
                price: newPrice / 100,
                currency: chooseStep.currency,
              }),
            );

            dispatch(chargeStepsActions.setNextStepDisabled(false));
          } catch (error) {
            console.error(error);
            dispatch(chargeStepsActions.setCurrentStep(0));
          } finally {
            dispatch(chargeStepsActions.setChooseStepIsLoadingPrice(false));
          }
          return;
        }

        errorCounter++;
        dispatch(
          chargeStepsActions.setChargeStepLoadingMessage(
            t("LOADING.ERROR_CONNECTION_CHARGER", { errorCounter, maxErrorCounter: MAX_ERROR_COUNTER }),
          ),
        );

        if (errorCounter === MAX_ERROR_COUNTER) {
          dispatch(chargeStepsActions.setChargeStepLoadingMessage(""));
          dispatch(uiActions.setToastError(error));
          slack.error("handleStartCharge", error);
          dispatch(chargeStepsActions.setChargeStepIsChargeInit(false));
          break;
        }

        // Wait 2 second before retry
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  };

  const checkChargeStart = (accountId: number) => {
    let checkCounter = 1;

    const interval = setInterval(() => {
      if (checkCounter >= MAX_CHECKS) {
        clearInterval(interval);

        handleStopCharge();
        return;
      }

      getPaymentAccountService(accountId)
        .then((newAccount: AccountModel) => {
          if (newAccount.charger_graph && !isStoppingCharge) {
            dispatch(uiActions.setAlertType({ type: "successInitCharge" }));

            handleGoHome();

            clearInterval(interval);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          checkCounter++;
        });
    }, INTERVAL_TO_CHECK);

    setIntevalCheck(interval);
  };

  const handleStopCharge = async () => {
    dispatch(chargeStepsActions.setChargeStepIsStoppingCharge(true));

    const MAX_ERROR_COUNTER = 6;
    let errorCounter = 0;

    dispatch(chargeStepsActions.setChargeStepLoadingMessage(t("INFO_CHARGE.PROCESSING_STOP")));

    while (errorCounter < MAX_ERROR_COUNTER) {
      try {
        if (!selectedSocket) {
          throw new Error(t("APP_ERRORS.SELECTED_SOCKET"));
        }
        if (!selectedCharger) {
          throw new Error(t("APP_ERRORS.SELECTED_CHARGER"));
        }

        await stopSocketService(Number(selectedCharger.id_charger), selectedSocket.socket_number);

        dispatch(chargeStepsActions.setCleanChargeSteps());
        dispatch(
          mapActions.setLocationOptions({
            showLocationDetail: true,
            showChargeSteps: false,
            showRates: false,
            showSocketBusy: false,
            showChargeAutomatically: false,
          }),
        );
        dispatch(uiActions.setToastType({ type: "chargedStopped" }));
        dispatch(chargeStepsActions.setChargeStepLoadingMessage(""));
        dispatch(chargeStepsActions.setChargeStepIsStoppingCharge(false));
        break;
      } catch (error) {
        errorCounter++;

        dispatch(
          chargeStepsActions.setChargeStepLoadingMessage(
            t("LOADING.ERROR_CONNECTION_CHARGER", { errorCounter, maxErrorCounter: MAX_ERROR_COUNTER }),
          ),
        );

        if (errorCounter === MAX_ERROR_COUNTER) {
          dispatch(chargeStepsActions.setChargeStepLoadingMessage(""));

          handleGoHome();
          dispatch(uiActions.setToastError(t("APP_ERRORS.CHARGE_STOP")));
          slack.error("handleStopCharge", error);
          break;
        }

        // Wait 2 second before retry
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  };

  return { handleStartCharge, handleStopCharge };
};
