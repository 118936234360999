import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { Divider } from "@/commons/components/Divider/Divider";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { IconLabel } from "@/commons/components/IconLabel/IconLabel";
import { useLocationContext } from "@/commons/hooks/useLocationContext";
import { RecentSearchesModel } from "@/core/models/recentSearchesModel";
import { mapActions } from "@/core/store/slices/mapSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { t } from "i18next";
import { locationOutline, locationSharp } from "ionicons/icons";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { useState } from "react";
import { SearchTerm } from "./SearchTerm/SearchTerm";
import "./SearchTermModal.scss";

export const SearchTermModal = () => {
  const dispatch = useAppDispatch();

  const { userLocation } = useLocationContext();

  const { recentSearchs } = useAppSelector((state) => state.userReducer);
  const { form, showSearchModal, searchType } = useAppSelector((state) => state.mapReducer.routeInfo.modal);

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchResult<RawResult>[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSelectRecentSearch = (recentSearchSelected: RecentSearchesModel) => {
    if (searchType === "from") {
      dispatch(mapActions.setRouteForm({ ...form, from: recentSearchSelected }));
    }
    if (searchType === "to") {
      dispatch(mapActions.setRouteForm({ ...form, to: recentSearchSelected }));
    }

    dispatch(userActions.addRecentSearches(recentSearchSelected));
    dispatch(mapActions.setShowSearchModal({ showSearchModal: false, searchType: "" }));

    handleClearSearch();
  };

  const handleDeleteRecentSearch = (recentSearchSelected: RecentSearchesModel) => {
    dispatch(userActions.removeSearch(recentSearchSelected.name));
  };

  const handleThisLocation = () => {
    if (userLocation.latitude !== null && userLocation.longitude !== null) {
      dispatch(
        mapActions.setRouteForm({
          ...form,
          from: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
            isUserLocation: true,
            name: t("ROUTE.LOCATION_NOW"),
          },
        }),
      );
      dispatch(mapActions.setShowSearchModal({ showSearchModal: false, searchType: "" }));

      handleClearSearch();
    }
  };

  const handleSearch = async (event: CustomEvent) => {
    const newSearchTerm = event.detail.value;
    setSearchTerm(newSearchTerm);

    if (!newSearchTerm) {
      return handleClearSearch();
    }

    getResults(newSearchTerm);
  };

  const getResults = async (searchTerm: string) => {
    const provider = new OpenStreetMapProvider();

    try {
      setSearchLoading(true);
      const result = await provider.search({ query: searchTerm });
      setSearchSuggestions(result);
    } catch (error) {
      console.error(error);
      setSearchSuggestions([]);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchSuggestions([]);
  };

  return (
    <IonModal isOpen={showSearchModal}>
      <AppHeader
        startIcon={
          <GoBackButton
            onClick={() =>
              dispatch(
                mapActions.setShowSearchModal({
                  showSearchModal: false,
                  searchType: "",
                }),
              )
            }
          />
        }
        title={searchType && t(`TITLES.SEARCH_ROUTE_${searchType.toUpperCase()}`)}
        hiddenTitle={!searchType}
      />
      <IonContent className="">
        <div className="search-term-modal">
          {!!recentSearchs.length && (
            <>
              <IonLabel className="recent-title">{t("TITLES.RECENTS")}</IonLabel>
              <div className="recent-container">
                {recentSearchs.slice(0, 5).map((recentSearch) => (
                  <SearchTerm
                    key={recentSearch.name}
                    recentSearch={recentSearch}
                    onDelete={handleDeleteRecentSearch}
                    onClick={handleSelectRecentSearch}
                  />
                ))}
              </div>
              <Divider />
            </>
          )}

          <div className="search-info">
            {searchLoading && (
              <>
                <IonSpinner class="ion-margin-end" />
                <IonText className="noResultsSearch">{t("LOADING.SEARCH")}</IonText>
              </>
            )}
            {!searchLoading && searchTerm && searchSuggestions.length === 0 && (
              <IonText className="noResultsSearch">{t("ALERT.SEARCH_NOT_FOUND")}</IonText>
            )}
          </div>

          <div className="suggestions-list">
            {searchSuggestions.map((search) => (
              <IonList key={search.raw.place_id}>
                <IonItem
                  lines="none"
                  onClick={() =>
                    handleSelectRecentSearch({
                      isUserLocation: false,
                      latitude: search.y,
                      longitude: search.x,
                      name: searchTerm.toLocaleLowerCase(),
                    })
                  }
                >
                  <IconLabel icon={locationOutline}>
                    <IonLabel>{search.label}</IonLabel>
                  </IconLabel>
                </IonItem>
              </IonList>
            ))}
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          {searchType === "from" && (
            <div className="actual-location">
              <IonButton color="primary" fill="outline" onClick={handleThisLocation}>
                <IonIcon icon={locationSharp} slot="start" />
                {t("ROUTE.LOCATION_NOW")}
              </IonButton>
            </div>
          )}
          <IonSearchbar onIonInput={handleSearch} debounce={300} placeholder={t("PLACEHOLDERS.SEARCH")} />
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
