import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { VehicleBrand, VehicleFormModel } from "@/core/models/userModel";

export const getVehicleBrandsService = (): Promise<VehicleBrand[]> => {
  return api.get(`get_brands_ajax`, undefined, { isRootPath: true }).then(resolveResponse).catch(rejectError);
};

export const createUserVehicleService = (
  userId: number,
  newVehicle: VehicleFormModel,
): Promise<{
  status_code: string;
  status_message: string;
  vehicle_id: number;
}> => {
  const body = {
    id: userId,
    car_id: newVehicle.carId,
    vehicle_brand: `${newVehicle.vehicleBrandId || 0}`,
    vehicle_model: `${newVehicle.vehicleModelId || 0}`,
    vehicle: newVehicle.vehicle,
    model: newVehicle.model,
  };

  return api.post(`users/vehicle_user_add`, undefined, body).then(resolveResponse).catch(rejectError);
};

export const updateVehicleUserService = async (
  userId: number,
  vehicle: VehicleFormModel,
): Promise<{
  status_code: string;
  status_message: string;
}> => {
  const body = {
    id: userId,
    ...vehicle,
    vehicle_brand: `${vehicle.vehicleBrandId}`,
    vehicle_model: `${vehicle.vehicleModelId}`,
    car_id: vehicle.carId,
  };

  delete body.vehicleBrandId;
  delete body.vehicleModelId;

  return api.post(`users/vehicle_user_update`, undefined, body).then(resolveResponse).catch(rejectError);
};
export const deleteVehicleUserService = async (params: {
  id: number;
  vehicle_id: string;
}): Promise<{
  status_code: string;
  status_message: string;
}> => {
  return api.del(`users/vehicle_user/delete`, params).then(resolveResponse).catch(rejectError);
};
