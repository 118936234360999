import { CHARGE_SOCKET_CONECTOR_FILTER } from "@/commons/constants/charger";
import { CHARGER_SOCKET_CONNECTOR_TYPE } from "@/core/models/chargerModel";
import { useAppSelector } from "@/core/store/store";
import { IonButton, IonIcon, IonLabel, IonList } from "@ionic/react";
import { t } from "i18next";
import { chevronDown, chevronUp } from "ionicons/icons";
import { useState } from "react";
import { FilterConnector } from "./FilterConnector/FilterConnector";
import "./FiltersConnectorType.scss";

export const FiltersConnectorType = () => {
  const connectors = Object.entries(CHARGE_SOCKET_CONECTOR_FILTER).filter(([, value]) => value.show);

  const {
    activeFilters: { connectorTypes },
  } = useAppSelector((state) => state.mapReducer.filters);

  const firstConnectors = connectors.slice(0, 4);
  const restConnectors = connectors.slice(4);

  const haveRestConnectorsActive = restConnectors.some((connector) =>
    connectorTypes.includes(connector[0] as CHARGER_SOCKET_CONNECTOR_TYPE),
  );
  const [showMore, setShowMore] = useState(haveRestConnectorsActive);

  return (
    <div className="filters-connector-type">
      <IonLabel className="filter-title">{t("TITLES.CONNECTOR_TYPE")}</IonLabel>
      <IonList className="filter-list first-connectors">
        {firstConnectors.map((connector) => (
          <FilterConnector key={connector[0]} connector={connector} />
        ))}
      </IonList>
      <IonList className={`filter-list rest-connectors ${showMore ? "open" : "closed"}`}>
        {restConnectors.map((connector) => (
          <FilterConnector key={connector[0]} connector={connector} />
        ))}
      </IonList>

      <IonButton fill="outline" color="primary" onClick={() => setShowMore(!showMore)}>
        {showMore ? t("BUTTONS.SHOW_LESS") : t("BUTTONS.SHOW_MORE")}
        <IonIcon icon={showMore ? chevronUp : chevronDown} />
      </IonButton>
    </div>
  );
};
