import { useSlack } from "@/commons/hooks/useSlack";
import { useStripeInfo } from "@/commons/hooks/useStripeInfo";
import { PaymentIntentModel } from "@/core/models/PaymentModel";
import { IonLabel, IonSpinner } from "@ionic/react";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { AddCreditCard } from "./AddCreditCard/AddCreditCard";
import "./AddCreditCardStripe.scss";

interface AddCreditCardStripeProps {
  selectPaymentId?: string;
  callbackAddCard: (paymentMethod: string, extraField: string) => void;
}

export const AddCreditCardStripe = ({ selectPaymentId, callbackAddCard }: AddCreditCardStripeProps) => {
  const { loadStripeInfo } = useStripeInfo();
  const slack = useSlack();

  const [loadingStripeInfo, setLoadingStripeInfo] = useState(true);
  const [stripeInfo, setStripeInfo] = useState<{ paymentIntent: PaymentIntentModel; stripe: Stripe }>();
  const [stripe, setStripe] = useState<Stripe>();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    // Make sure to only create a new Stripe instance once.
    stripeInfo && !stripe && setStripe(stripeInfo.stripe);
  }, [stripeInfo]);

  const loadData = async () => {
    try {
      const newStripeInfo = await loadStripeInfo(undefined, false);
      loadingStripeInfo && setStripeInfo(newStripeInfo);
    } catch (error) {
      slack.error("loadStripeInfo", error);
    } finally {
      setLoadingStripeInfo(false);
    }
  };
  return (
    <>
      {loadingStripeInfo ? (
        <div className="add-credit-card-stripe-loading">
          <IonSpinner />
        </div>
      ) : (
        <>
          {stripe && stripeInfo ? (
            <Elements stripe={stripe}>
              <AddCreditCard
                stripe={stripe}
                paymentIntent={stripeInfo.paymentIntent}
                selectPaymentId={selectPaymentId}
                callbackAddCard={callbackAddCard}
              />
            </Elements>
          ) : (
            <IonLabel>{t("ERRORS.SETUP_INTENT")}</IonLabel>
          )}
        </>
      )}
    </>
  );
};
