import { IonCol, IonIcon, IonLabel } from "@ionic/react";
import { t } from "i18next";
import { removeCircleOutline } from "ionicons/icons";
import { MouseEvent } from "react";
import "./RemoveButton.scss";

interface RemoveButtonProps {
  onClick: (event: MouseEvent<HTMLIonColElement, globalThis.MouseEvent>) => void;
}

export const RemoveButton = ({ onClick }: RemoveButtonProps) => {
  return (
    <IonCol className="remove-button" onClick={onClick}>
      <IonIcon icon={removeCircleOutline} color="danger" />
      <IonLabel color="danger">{t("ALERT_BUTTONS.DELETE")}</IonLabel>
    </IonCol>
  );
};
