import { PaymentMethod } from "./PaymentModel";
import { RateModel } from "./rateModel";
import { ReservationTimes } from "./reservationModel";

export interface ChargerModel {
  id_charger: string;
  id_domain: string;
  name: string;
  charger_box_identity: string;
  domain_name: string;
  mobile_app_private: boolean;
  domain_logo_url: string;
  domain_logo_link: string;
  domain_logo_url2: string;
  domain_logo_link2: string;
  mobile_app_manage: boolean;
  id_status: CHARGER_STATUS_IDS;
  status: CHARGER_STATUS;
  intensity_max_amp: string;
  intensity_current_amp: string;
  power_max_kW: string;
  power_current_kW: string;
  voltage: string;
  phases: string;
  country: string;
  address: string;
  city: string;
  cp: string;
  latitude: string;
  longitude: string;
  location_id: string;
  location_name?: string;
  location_ocpi_id?: string;
  location_external_code: string;
  meter_values_interval: string;
  distance?: number;
  stripe_account?: string;
  stripe_public_key?: string;
  is_stripe_secret_key?: boolean;
  payment_methods?: PaymentMethod[];
  sat_available?: boolean;
  charger_type?: string;
  app_disable_temporal?: true | "";
  app_disable_temporal_text?: Record<string, string>;
  near?: boolean;
  additional_info: string;
  support_tickets_active: boolean;
  images_charger: string[];
  network_info: string;
  charger_sockets: ChargerSocketModel[];
  physical_reference?: string;
}

export enum CHARGER_STATUS_IDS {
  AVAILABLE = 0,
  CHARGING = 1,
  RESERVED = 2,
  OUT_OF_SERVICE = 3,
  ERROR = 9,
}

export enum CHARGER_SOCKET_CONNECTOR_TYPE {
  "TYPE2F" = "TYPE-2-F",
  "TYPE2M" = "TYPE-2-M",
  "3PIN" = "3-PIN",
  "AC" = "AC",
  "CCS" = "CCS",
  "CCS_DC" = "CCS_DC",
  "CHADEMO" = "CHADEMO",
  "DC" = "DC",
  "GB" = "GB",
  "GB-T_AC" = "GB-T_AC",
  "SCHUKO" = "SCHUKO",
  "TESLA_R" = "TESLA_R",
  "TESLA_S" = "TESLA_S",
  "YAZAKI" = "YAZAKI",
}
export enum CHARGER_STATUS {
  AVAILABLE = "Available", // 0
  CHARGING = "Charging", // 1
  OUT_OF_SERVICE = "Out of service", // 3
  RESERVED = "Reserved", // 2
  UNKNOWN = "Unknown",
}

export enum CHARGER_SOCKET_RUNTIME_STATUS {
  AVAILABLE = 1,
  RESERVED = 2,
  UNAVAILABLE = 3,
  OCUPPIED = 4,
  FAULTED = 9,
  PREPARING = 20,
  CHARGING = 21,
  SUSPENDEDEVSE = 22,
  SUSPENDEDEV = 23,
  FINISHING = 24,
}

export interface ChargerSocketModel {
  id_socket: string;
  socket_number: string;
  physical_reference: string;
  connector_type_name: string;
  connector_type_code: CHARGER_SOCKET_CONNECTOR_TYPE;
  connector_power_type_name: string;
  connector_power_type_code: string;
  max_amperage: string;
  max_voltage: string;
  max_electric_power: string; // kW
  multiple_connectors: any[];
  instructions_type?: string;
  id_user_charge?: string;
  charger_graph_id?: string;
  charger_graph_rest?: number;
  charger_graph_energy?: ChargeGrapEnergy;
  socket_authorize_name: string;
  socket_authorize_code: string;
  socket_payment_name: string;
  socket_payment_code: string;
  socket_consumption_name: string;
  socket_consumption_code: string;
  stripe_platform: boolean;
  price_time_min: string;
  price_energy_kwh: string;
  max_time_min: string;
  max_energy_wh: string;
  max_time_min_reservation: string;
  price_time_min_reservation: string;
  reservations: string;
  reservation_allowed: string;
  id_user_reservation: string;
  runtime_status_id: CHARGER_SOCKET_RUNTIME_STATUS;
  runtime_status: string;
  socket_image: string;
  rates: RateModel[];
  reservation_id: string;
  reservation_rest?: any;
  reservation_times?: ReservationTimes;
  is_b2b_user?: boolean;
}

interface ChargeGrapEnergy {
  energy_total: number;
  energy_consumed: number;
  energy_remaining: number;
}
export interface ChargerGraphModel {
  charger_graph_id: string;
  charger_id: string;
  toma: string;
  user_card: string;
  charger_name: string;
  charger_address: string;
  charger_city: string;
  charger_logo: string;
  payment_captured: string;
  title: string;
  payment_type: string;
  reserve_time: string;
  socket_number: number;
  charging_time: string;
  charging_energy: string;
  account_id: string;
  energy: string;
  runtime_status: string;
  runtime_power: string;
  runtime_current: string;
  session_start_at: string;
  charging_end_at: string;
  session_end_at: string;
  created_at: string;
  updated_at: string;
  charger_periods?: ChargerPeriod[];
  amount_consumed: string;
  limit_time: string;
  limit_energy: string;
  amount: string;
  ticket_url: string;
  percent_battery?: number | undefined;
  unlocked_at: string | null;
}

interface ChargerPeriod {
  charger_period_id: string;
  charger_id: string;
  toma: string;
  user_card: string;
  energy: string;
  runtime_status: string;
  runtime_power: string;
  runtime_current: string;
  session_start_at: string;
  session_end_at: string;
  created_at: string;
  updated_at: string;
  percent_battery: number | undefined;
}
