import { SelectInput, SelectInputElement } from "@/commons/components/Form/SelectInput/SelectInput";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { SetState } from "@/core/models/globalModel";
import { CountryModel } from "@/core/models/userModel";
import { getCountryStatesUserService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface FourthStepProps {
  values: {
    countryId: string;
    provinceId: string;
    address: string;
    city: string;
    postalCode: string;
  };
  onStringsReceived: (countryId: string, province: string, address: string, city: string, postalCode: string) => void;
  checkFieldFilled: any;
  countries: CountryModel[];
}

export const FourthStep = ({ values, onStringsReceived, countries }: FourthStepProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getLabel } = useIsValidUser();

  const [selectedCountryId, setSelectedCountryId] = useState(values.countryId);
  const [selectedProvinceId, setSelectedProvinceId] = useState(values.provinceId);
  const [address, setAddress] = useState(values.address);
  const [city, setCity] = useState(values.city);
  const [postalCode, setPostalCode] = useState(values.postalCode);

  useEffect(() => {
    onStringsReceived(selectedCountryId, selectedProvinceId, address, city, postalCode);
  }, [selectedCountryId, selectedProvinceId, address, city, postalCode]);

  const handleCountryChange = (target: SelectInputElement<string>) => {
    const newCountryId = target.value || "";
    setSelectedCountryId(newCountryId);
  };

  const handleProvinceChange = (target: SelectInputElement<string>) => {
    setSelectedProvinceId(target.value || "");
  };

  const handleAddressChange = (e: CustomEvent) => {
    setAddress(e.detail.value);
  };

  const handleCityChange = (e: CustomEvent) => {
    setCity(e.detail.value);
  };

  const handlePostalCodeChange = (e: CustomEvent) => {
    setPostalCode(e.detail.value);
  };

  const getCountryStates = async (setLoading: SetState<boolean>) => {
    if (selectedCountryId) {
      try {
        setLoading(true);
        const newStates = await getCountryStatesUserService(selectedCountryId);
        return newStates.map((state) => ({ value: `${state.id || ""}`, label: state.name }));
      } catch (error) {
        console.error(error);
        dispatch(uiActions.setToastType({ type: "error" }));
        return [];
      } finally {
        setLoading(false);
      }
    }
    return [];
  };

  return (
    <>
      <IonRow className="ion-margin-bottom">
        <IonLabel className="neutralBlack70">
          {t("STEPS.STEP")} <IonLabel className="number number-bold">4</IonLabel> {t("STEPS.OUT_OF")}{" "}
          <IonLabel className="number number-bold">6</IonLabel>
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel className="titleH3">{t("TITLES.PERSONAL_DATA")}</IonLabel>
      </IonRow>
      <SelectInput
        name="country"
        className="ion-margin-top"
        label={getLabel("country")}
        placeholder={getLabel("country", "placeholder")}
        isClearable
        loadData={async () =>
          countries.map((country) => ({ value: country.id, label: `${country.flag} ${country.name}` }))
        }
        currentValue={selectedCountryId}
        onIonChange={(event) => handleCountryChange(event.target as SelectInputElement<string>)}
        outSideMarginLeft
      />
      <SelectInput
        name="state"
        className="ion-margin-top"
        label={getLabel("state")}
        placeholder={getLabel("state", "placeholder")}
        isClearable
        loadData={getCountryStates}
        loadDependencies={[selectedCountryId]}
        currentValue={selectedProvinceId}
        onIonChange={(event) => handleProvinceChange(event.target as SelectInputElement<string>)}
        outSideMarginLeft
      />
      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("ubicacio")}
          labelPlacement="floating"
          type="text"
          value={address}
          onIonInput={handleAddressChange}
        />
      </IonItem>

      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("poblacio")}
          labelPlacement="floating"
          type="text"
          value={city}
          onIonInput={handleCityChange}
        />
      </IonItem>

      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("cp")}
          labelPlacement="floating"
          type="number"
          value={postalCode}
          onIonInput={handlePostalCodeChange}
        />
      </IonItem>
    </>
  );
};
