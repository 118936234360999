import { AmexLogo, DiscoverLogo, MasterCardLogo, VisaLogo, dinnersLogo } from "./imgs";

export const CREDIT_CARD_LOGOS: Record<string, string> = {
  amex: AmexLogo,
  "american express": AmexLogo,
  dinners: dinnersLogo,
  discover: DiscoverLogo,
  mastercard: MasterCardLogo,
  visa: VisaLogo,
};
