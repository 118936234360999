import { CHARGER_SOCKET_CONNECTOR_TYPE, ChargerModel, ChargerSocketModel } from "@/core/models/chargerModel";
import { LocationModel } from "@/core/models/locationModel";
import { RecentSearchesModel } from "@/core/models/recentSearchesModel";
import { ReservationModel } from "@/core/models/reservationModel";
import { RouteWaypointModel } from "@/core/models/routeModel";
import { VehicleModel } from "@/core/models/userModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface MapSlice {
  locations: any[];
  selectedLocation: {
    locationId: number | null;
    locationInfo: LocationModel | null;
    selectedCharger: ChargerModel | null;
    selectedSocket: ChargerSocketModel | null;
    options: MapLocationOptions;
  };
  filters: {
    showFilters: boolean;
    activeFilters: MapFiltersModel;
    selectedFilters: MapFiltersModel;
  };
  routeInfo: RouteInfo;
  showSearch: boolean;
  searchSelectedCoord: Coords | null;
  activeReservations: ReservationModel[];
}

export interface MapFiltersModel {
  available: boolean;
  free: boolean;
  facilities: string[];
  chargeTypes: string[];
  connectorTypes: CHARGER_SOCKET_CONNECTOR_TYPE[];
  filterNumber: number;
}

export interface Coords {
  lat: number;
  lon: number;
}
export interface RouteForm {
  vehicle: VehicleModel | null;
  rangePercentage: number;
  rangeKm: number;
  from: RecentSearchesModel | null;
  to: RecentSearchesModel | null;
}

interface RouteInfo {
  showRoute: boolean;
  showRouteMenu: boolean;
  waypoints: RouteWaypointModel[];
  originIsUser: boolean;
  modal: {
    form: RouteForm;
    showSearchModal: boolean;
    searchType: "from" | "to" | "";
  };
}

interface MapLocationOptions {
  showLocationDetail: boolean;
  showRates: boolean;
  showChargeSteps: boolean;
  showChargeAutomatically: boolean;
  showSocketBusy: boolean;
}

export const mapSliceInitialState: MapSlice = {
  locations: [],
  selectedLocation: {
    locationId: null,
    locationInfo: null,
    selectedCharger: null,
    selectedSocket: null,
    options: {
      showLocationDetail: false,
      showRates: false,
      showChargeSteps: false,
      showChargeAutomatically: false,
      showSocketBusy: false,
    },
  },
  filters: {
    showFilters: false,
    activeFilters: {
      available: false,
      free: false,
      facilities: [],
      chargeTypes: [],
      connectorTypes: [],
      filterNumber: 0,
    },
    selectedFilters: {
      available: false,
      free: false,
      facilities: [],
      chargeTypes: [],
      connectorTypes: [],
      filterNumber: 0,
    },
  },
  routeInfo: {
    showRoute: false,
    showRouteMenu: false,
    waypoints: [],
    originIsUser: true,
    modal: {
      form: {
        vehicle: null,
        rangePercentage: 100,
        rangeKm: 500,
        from: null,
        to: null,
      },
      showSearchModal: false,
      searchType: "",
    },
  },
  showSearch: false,
  searchSelectedCoord: null,
  activeReservations: [],
};

export const mapSlice = createSlice({
  name: "mapReducer",
  initialState: mapSliceInitialState,
  reducers: {
    setLocations: (state, { payload }: PayloadAction<any[]>) => {
      state.locations = payload;
    },
    setSelectedLocationId: (state, { payload }: PayloadAction<number>) => {
      state.selectedLocation.locationId = payload;
      state.selectedLocation.options.showLocationDetail = true;
    },
    setLocationInfo: (state, { payload }: PayloadAction<LocationModel>) => {
      state.selectedLocation.locationInfo = payload;
    },
    setSelectedSocket: (
      state,
      { payload }: PayloadAction<{ selectedCharger: ChargerModel | null; selectedSocket: ChargerSocketModel | null }>,
    ) => {
      state.selectedLocation.selectedCharger = payload.selectedCharger;
      state.selectedLocation.selectedSocket = payload.selectedSocket;
    },
    setLocationOptions: (state, { payload }: PayloadAction<MapLocationOptions>) => {
      state.selectedLocation.options = payload;
    },
    setLocationOptionsKey: (state, { payload }: PayloadAction<{ key: string; value: boolean }>) => {
      state.selectedLocation.options = { ...state.selectedLocation.options, [payload.key]: payload.value };
    },
    cleanSelectedLocation: (state) => {
      state.selectedLocation = mapSliceInitialState.selectedLocation;
    },

    // filters
    setShowFilters: (state, { payload }: PayloadAction<boolean>) => {
      state.filters.showFilters = payload;
    },
    setActiveFilters: (state, { payload }: PayloadAction<MapFiltersModel>) => {
      state.filters.activeFilters = payload;
    },
    deleteActiveFilters: (state) => {
      state.filters = mapSliceInitialState.filters;
    },
    setSelectedFilters: (state, { payload }: PayloadAction<MapFiltersModel>) => {
      state.filters.selectedFilters = payload;
    },

    // Route info
    setShowRoute: (state, { payload }: PayloadAction<boolean>) => {
      state.routeInfo.showRoute = payload;
    },
    setShowRouteMenu: (state, { payload }: PayloadAction<boolean>) => {
      state.routeInfo.showRouteMenu = payload;
      state.routeInfo.modal = mapSliceInitialState.routeInfo.modal;
    },
    setWaypoints: (
      state,
      {
        payload,
      }: PayloadAction<{
        waypoints: RouteWaypointModel[];
        originIsUser: boolean;
      }>,
    ) => {
      state.routeInfo.waypoints = payload.waypoints;
      state.routeInfo.originIsUser = payload.originIsUser;
    },
    cleanRouteInfo: (state) => {
      state.routeInfo = mapSliceInitialState.routeInfo;
    },
    setRouteForm: (state, { payload }: PayloadAction<RouteForm>) => {
      state.routeInfo.modal.form = payload;
    },
    setShowSearchModal: (
      state,
      { payload }: PayloadAction<{ showSearchModal: boolean; searchType: "from" | "to" | "" }>,
    ) => {
      state.routeInfo.modal.showSearchModal = payload.showSearchModal;
      state.routeInfo.modal.searchType = payload.searchType;
    },

    // Search
    setShowSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.showSearch = payload;
    },
    setSearchSelectedCoord: (state, { payload }: PayloadAction<Coords | null>) => {
      state.searchSelectedCoord = payload;
    },
    clearSearchSelectedCoord: (state) => {
      state.searchSelectedCoord = mapSliceInitialState.searchSelectedCoord;
    },
    // Active Reservations
    setActiveReservations: (state, { payload }: PayloadAction<ReservationModel[]>) => {
      state.activeReservations = payload;
    },
  },
});

export const mapActions = mapSlice.actions;

export default mapSlice.reducer;
