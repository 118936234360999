import NfcIcon from "@/assets/imgs/icons/nfc.svg?react";
import NoNfcIcon from "@/assets/imgs/icons/noNfc.svg?react";
import { AlertModal } from "@/commons/components/UI/AlertModal/AlertModal";
import { useOpenLocation } from "@/commons/hooks/useOpenLocation";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { checkUrlScanner, getLocationIdFromScanner } from "@/commons/utils/scannerUtils";
import { keychainLinkService } from "@/core/services/shop/shopService";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { NFC, NfcTag } from "@awesome-cordova-plugins/nfc";
import { Capacitor } from "@capacitor/core";
import { IonIcon, IonInput, IonItem, IonLabel, IonLoading } from "@ionic/react";
import { t } from "i18next";
import { checkmarkCircle, closeCircle, settingsOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import "./NfcScanner.scss";

const platform = Capacitor.getPlatform();

export const NfcScanner = () => {
  const { openLocation } = useOpenLocation();
  const dispatch = useAppDispatch();
  const { loadUserInfo } = useUserContext();
  const { parameters } = useParametersContext();

  const { user } = useAppSelector((state) => state.userReducer);
  const { isNfcOpen, isAddManualKeychainOpen, showManualKeychain } = useAppSelector((state) => state.uiReducer.nfc);

  const [isLoading, setIsLoading] = useState(false);
  const [enabledNFC, setEnabledNFC] = useState(false);
  const [badgeType, setBadgeType] = useState<"success" | "danger" | null>(null);
  const [isOpenDomainError, setIsOpenDomainError] = useState(false);
  const [newKeyChainNumber, setNewKeyChainNumber] = useState("");

  useEffect(() => {
    platform !== "web" && checkNfcStatus();
    isNfcOpen && setBadgeType(null);
  }, [isNfcOpen]);

  useEffect(() => {
    let subscription: Subscription;

    if (enabledNFC) {
      if (platform === "android") {
        subscription = startAndroidNfc();
      }

      if (platform === "ios" && isNfcOpen) {
        startIosNfc();
      }
    }

    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [enabledNFC, isNfcOpen]);

  const handleOpenURLTag = async (tag: NfcTag) => {
    const url = tag.ndefMessage ? NFC.bytesToString(tag.ndefMessage[0].payload) : "";

    const serialNumber = tag.id ? NFC.bytesToHexString(tag.id) : "";

    if (url) {
      try {
        const isValid = checkUrlScanner(url);

        if (!isValid) {
          setIsOpenDomainError(true);
          return;
        }

        setIsLoading(true);

        const locatioData = await getLocationIdFromScanner(url, parameters.token_api, user.auth_token);

        if (locatioData) {
          openLocation(locatioData.locationId, locatioData.chargeId, locatioData.socketNumber);
        }
      } catch (error) {
        console.error(error);
        openLocation(-1, -1);
      } finally {
        setIsLoading(false);
        dispatch(uiActions.setNfc({ isNfcOpen: false }));
      }
      return;
    }

    handleAddKeychain(serialNumber);
  };

  const startAndroidNfc = () => {
    const flags = NFC.FLAG_READER_NFC_A | NFC.FLAG_READER_NFC_V;
    const observable = NFC.readerMode(flags);

    return observable.subscribe(
      (tag) => {
        setBadgeType("success");
        handleOpenURLTag(tag);
      },
      (error) => {
        setBadgeType("danger");
        error !== "NFC_DISABLED" && dispatch(uiActions.setToastError(error));
      },
    );
  };

  const startIosNfc = async () => {
    try {
      const tag = await NFC.scanNdef();
      setBadgeType("success");
      handleOpenURLTag(tag);
    } catch (error) {
      setBadgeType("danger");
      showManualKeychain && dispatch(uiActions.setIsAddManualKeychainOpen(true));
      const showError = error !== "NFC_DISABLED" && error !== "Session invalidated by user";
      showError && dispatch(uiActions.setToastError(error));
    } finally {
      dispatch(uiActions.setNfc({ isNfcOpen: false }));
    }
  };

  const checkNfcStatus = async () => {
    try {
      // Throws error if NFC is not enabled
      await NFC.enabled();

      setEnabledNFC(true);
    } catch (error) {
      console.error(error);
      setEnabledNFC(false);
    }
  };

  const handleOpenSettings = () => {
    if (platform === "web") {
      return;
    }

    NFC.showSettings();
  };

  const handleAddKeychain = async (id: string) => {
    try {
      setIsLoading(true);
      await keychainLinkService(id);
      await loadUserInfo();
      dispatch(uiActions.setNfc({ isNfcOpen: false }));
      dispatch(uiActions.setToast({ show: true, message: t("KEYCHAIN.ADDED"), color: "success" }));
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeNumber = (target: HTMLIonInputElement) => {
    setNewKeyChainNumber(`${target.value}`);
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <AlertModal
        isOpen={isOpenDomainError}
        className="nfc-scanner"
        handleDismiss={() => {
          setIsOpenDomainError(false);
        }}
        primaryText={
          <>
            <IonIcon icon={settingsOutline} slot="start" />
            {t("BUTTONS.CONFIGURATION")}
          </>
        }
      >
        <div className="nfc-scanner-enabled">
          <strong className="nfc-title">{t("SCAN_INSTRUCTIONS.NFC_DOMAIN_ERROR_TITLE")}</strong>
          <div className="icon-container">
            <IonIcon className="nfc-badge" color={"danger"} icon={closeCircle} />
            <NfcIcon className="icon" />
          </div>
          {t("SCAN_INSTRUCTIONS.NFC_DOMAIN_ERROR_TEXT")}
        </div>
      </AlertModal>
      {platform !== "ios" && (
        <AlertModal
          isOpen={isNfcOpen}
          className="nfc-scanner"
          handleDismiss={() => {
            dispatch(uiActions.setNfc({ isNfcOpen: false }));
          }}
          primaryAction={!enabledNFC ? handleOpenSettings : undefined}
          primaryText={
            <>
              <IonIcon icon={settingsOutline} slot="start" />
              {t("BUTTONS.CONFIGURATION")}
            </>
          }
        >
          {enabledNFC ? (
            <div className="nfc-scanner-enabled">
              <strong className="nfc-title">{t("SCAN_INSTRUCTIONS.NFC_TITLE")}</strong>
              <div className="icon-container">
                {badgeType && (
                  <IonIcon
                    className="nfc-badge"
                    color={badgeType}
                    icon={badgeType === "success" ? checkmarkCircle : closeCircle}
                  />
                )}
                <NfcIcon className="icon" />
              </div>
              {t("SCAN_INSTRUCTIONS.NFC_TEXT")}

              {showManualKeychain && (
                <IonLabel
                  onClick={() => {
                    dispatch(uiActions.setIsAddManualKeychainOpen(true));
                    dispatch(uiActions.setNfc({ isNfcOpen: false, showManualKeychain: false }));
                  }}
                  color="primary"
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  {t("SCAN_INSTRUCTIONS.NFC_MANUAL")}
                </IonLabel>
              )}
            </div>
          ) : (
            <div className="nfc-scanner-disabled">
              <strong className="nfc-title">{t("SCAN_INSTRUCTIONS.NFC_DISABLED")}</strong>
              <NoNfcIcon className="icon" />
              {t("SCAN_INSTRUCTIONS.NFC_DISABLED_TEXT")}
            </div>
          )}
        </AlertModal>
      )}
      <AlertModal
        isOpen={isAddManualKeychainOpen}
        handleDismiss={() => {
          dispatch(uiActions.setIsAddManualKeychainOpen(false));
          setNewKeyChainNumber("");
        }}
        primaryText={t("BUTTONS.ADD")}
        primaryAction={() => handleAddKeychain(newKeyChainNumber)}
        primaryDisabled={!newKeyChainNumber}
        hideSecondary
      >
        <div className="new-keychain">
          <IonLabel className="new-keychain-title">
            {t("BUTTONS.ADD")} {t("KEYCHAIN.TITLE")}
          </IonLabel>
          <IonItem>
            <IonInput
              label={t("KEYCHAIN.NUMBER")}
              onIonInput={(event) => handleChangeNumber(event.target)}
              value={newKeyChainNumber}
              labelPlacement="floating"
              type="text"
              mode="md"
            />
          </IonItem>
        </div>
      </AlertModal>
    </>
  );
};
