import { VehicleCard } from "@/commons/components/Vehicle/VehicleCard/VehicleCard";
import { VehicleModel } from "@/core/models/userModel";
import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonLabel } from "@ionic/react";
import { t } from "i18next";
import "./ChargeDataLoggedUser.scss";

export const ChargeDataLoggedUser = () => {
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.userReducer);
  const { activeStep } = useAppSelector((state) => state.chargeStepsReducer);

  const handleClickVehicle = (vehicle: VehicleModel) => {
    if (userInfo && activeStep.vehicleId !== vehicle.vehicle_id) {
      dispatch(chargeStepsActions.setActiveVehicleId(vehicle.vehicle_id));
    } else {
      dispatch(chargeStepsActions.cleanChargeStepsActiveStep());
    }
  };

  return (
    <div className="charge-data-logged-user">
      <IonLabel className="title-h3">{t("TITLES.WHICH_VEHICLE")}</IonLabel>
      {userInfo?.vehicles.map((vehicle) => (
        <VehicleCard
          key={vehicle.vehicle_id}
          vehicle={vehicle}
          active={activeStep.vehicleId === vehicle.vehicle_id}
          onClick={handleClickVehicle}
        />
      ))}
    </div>
  );
};
