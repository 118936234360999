import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { AddCreditCardModal } from "./AddCreditCardModal/AddCreditCardModal";
import { AddCreditCardPayU } from "./AddCreditCardPayU/AddCreditCardPayU";
import { AddCreditCardStripe } from "./AddCreditCardStripe/AddCreditCardStripe";

interface AddCreditCardGatewayProps {
  selectPaymentId?: string;
  callbackAddCard: (paymentMethod: string, extraField: string) => void;
}

export const AddCreditCardGateway = ({ selectPaymentId, callbackAddCard }: AddCreditCardGatewayProps) => {
  const {
    parameters: { payment_gateway },
  } = useParametersContext();

  return (
    <>
      {payment_gateway === "stripe" && (
        <AddCreditCardStripe selectPaymentId={selectPaymentId} callbackAddCard={callbackAddCard} />
      )}
      {payment_gateway === "payu" && (
        <AddCreditCardPayU selectPaymentId={selectPaymentId} callbackAddCard={callbackAddCard} />
      )}
      {payment_gateway === "redsys" && (
        <AddCreditCardModal selectPaymentId={selectPaymentId} callbackAddCard={callbackAddCard} />
      )}
    </>
  );
};
