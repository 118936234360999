import { uiActions } from "@/core/store/slices/uiSlice";
import { informationCircleOutline } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { IconButton } from "../IconButton/IconButton";
import "./InfoButton.scss";

interface InfoButtonProps {
  message: string;
  primaryAction?: () => void;
  showSecondaryButton?: boolean;
}

export const InfoButton = ({ message, primaryAction, showSecondaryButton = false }: InfoButtonProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      uiActions.setAlertType({
        type: "infoButton",
        options: { message, primaryAction, hiddenSecondary: !showSecondaryButton },
      }),
    );
  };

  return <IconButton className="info-button" color="light" icon={informationCircleOutline} onClick={handleClick} />;
};
