import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { IonContent, IonPage } from "@ionic/react";
import { useEffect } from "react";

export const TestPage = () => {
  useEffect(() => {}, []);

  return (
    <IonPage>
      <AppHeader />
      <IonContent>Test Page</IonContent>
    </IonPage>
  );
};
