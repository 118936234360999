import { MarkerIcon } from "@/commons/constants/imgs";
import { IonIcon } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import { divIcon, DivIconOptions, marker, Routing } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";

const defaultIcon: DivIconOptions = {
  iconSize: [28, 28],
  iconAnchor: [14, 28],
  popupAnchor: [1, -34],
  shadowSize: [28, 28],
  className: "custom-marker",
};

export const RouterMarker = (
  waypointIndex: number,
  waypoint: Routing.Waypoint,
  numberOfWaypoints: number,
  openLocation: (locationId: number) => void,
) => {
  // First waypoint or last waypoint
  if (waypointIndex === 0 || waypointIndex === numberOfWaypoints - 1) {
    const color = waypointIndex === 0 ? "primary" : "danger";
    return marker(waypoint.latLng, {
      icon: divIcon({
        ...defaultIcon,
        html: renderToStaticMarkup(<MarkerIcon style={{ fill: `var(--ion-color-${color})` }} />),
      }),
    });
  }

  // Location waypoint
  const markerPoint = marker(waypoint.latLng, {
    icon: divIcon({
      html: renderToStaticMarkup(<IonIcon icon={ellipse} size="large" style={{ color: "var(--ion-color-primary)" }} />),
      iconSize: [32, 32],
      iconAnchor: [15, 15],
      popupAnchor: [-3, -76],
      className: "custom-marker-icon",
    }),
  });

  markerPoint.on("click", () => {
    const locationId = Number(waypoint.name);
    openLocation(locationId);
  });

  return markerPoint;
};
