import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { formatNumberDecimals } from "@/commons/utils/numberUtils";
import { shopActions } from "@/core/store/slices/shopSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonCardContent,
  IonCol,
  IonContent,
  IonIcon,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRow,
} from "@ionic/react";
import { t } from "i18next";
import { cartOutline, trashOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { ShopCartFooter } from "./ShopCartFooter";
import { ShopCartProduct } from "./ShopCartProduct";

export const ShopCartModal = () => {
  const dispatch = useAppDispatch();
  const { parameters } = useParametersContext();
  const { shop, products } = useAppSelector((state) => state.shopReducer);
  const { user_id } = useAppSelector((state) => state.userReducer.user);
  const userCart = shop[user_id] || [];
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const keychainPriceShipment = (parameters.keychain_price_shipment || 0) / 100;
  const [showLoadingData, setShowLoadingData] = useState(false);
  const { isCartModalOpen } = useAppSelector((state) => state.uiReducer);

  useEffect(() => {
    let total = 0;

    userCart.forEach((item) => {
      const product = products.find((product) => product.id === item.id);

      if (product) {
        const price = product.price_ud / 100;
        const discount = product.discount / 100;
        total += (price - discount) * item.uds;
      }
    });
    setTotalPrice(total);
  }, [userCart]);

  const handleDismiss = () => {
    dispatch(uiActions.setIsCartModalOpen(false));
  };

  const handleClearCart = () => {
    dispatch(shopActions.cleanCart({ user_id: user_id }));
    handleDismiss();
  };

  return (
    <>
      <IonModal isOpen={isCartModalOpen} onDidDismiss={handleDismiss}>
        <AppHeader
          hiddenMenu
          title={t("TITLES.CART")}
          startIcon={<GoBackButton onClick={handleDismiss} />}
          endIcon={userCart.length > 0 && <IconButton icon={trashOutline} onClick={handleClearCart} />}
        />
        <IonLoading isOpen={showLoadingData} />
        <IonContent>
          {userCart.length > 0 ? (
            <IonCardContent>
              <IonList className="cart-products">
                {userCart.map((product) => (
                  <ShopCartProduct key={product.id} productCart={product} />
                ))}
              </IonList>
            </IonCardContent>
          ) : (
            <div className="empty-cart">
              <IonRow>
                <IonIcon src={cartOutline} className="icon"></IonIcon>
              </IonRow>
              <IonRow>
                <IonLabel className="no-location-text">{t("ERRORS.NO_CART_PRODUCTS")}</IonLabel>
              </IonRow>
            </div>
          )}
        </IonContent>
        {userCart.length > 0 && (
          <>
            <div className="cart-total-price-info">
              <IonRow>
                <IonCol>
                  <span className="subtotal">{t("TITLES.SUBTOTAL")}</span>
                </IonCol>
                <IonCol size="5">
                  <div className="price">
                    <span className="price-subtotal">
                      {formatNumberDecimals(totalPrice)} {parameters.currency}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <span className="shipping-cost">{t("BUTTONS.SHIPPING_COST")}: </span>
                </IonCol>
                <IonCol size="5">
                  <div className="price">
                    <span className="price-subtotal">
                      {formatNumberDecimals(keychainPriceShipment)} {parameters.currency}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="total">
                <IonCol>
                  <span>{t("TITLES.TOTAL")}</span>
                </IonCol>
                <IonCol size="5">
                  <span className="price">
                    {formatNumberDecimals(totalPrice + keychainPriceShipment)}
                    {parameters.currency}
                  </span>
                </IonCol>
              </IonRow>
            </div>
            <ShopCartFooter disabled={!totalPrice} setShowLoadingData={setShowLoadingData} />
          </>
        )}
      </IonModal>
    </>
  );
};
