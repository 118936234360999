import { useMemo } from "react";
import { useLocation } from "react-router";

export const useQuery = () => {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const query = useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);

  return {
    query,
    searchParams,
  };
};
