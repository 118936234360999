import i18n from "@/assets/i18n/i18n";
import { getAbortController, getURLDomain, removeEndpointFetching } from "@/commons/utils/apiUtils/apiUtils";
import { EndpointFetchingModel, MethodOptions, ParamsApi } from "@/core/models/apiModel";

const { VITE_CODE_APP } = import.meta.env;

let endpointFetching: EndpointFetchingModel[] = [];

export const getHeaders = (options?: MethodOptions) => {
  const token = options?.customToken || localStorage.getItem("token");
  const appVersion = localStorage.getItem("appVersion") || "";
  const domainId = localStorage.getItem("domainId") || "";

  return {
    Authorization: `Bearer ${token}`,
    "APP-ETECNIC": "EvCharge_Etecnic",
    "APP-VERSION": appVersion,
    "API-VERSION": "5",
    "APP-DOMAIN": domainId,
    "APP-CODE": `${VITE_CODE_APP}`,
    "Accept-Language": i18n.language,
    "Content-Type": "application/json",
    ...options?.extraHeader,
  };
};

/**
 * Fetch GET method
 * @param endpoint Endpoint to fetch
 * @param params params to fetch
 * @param options options to fetch
 * @param endpointClean Endpoint without params if have params on const endpoint
 */
const get = async (endpoint: string, params?: ParamsApi, options?: MethodOptions, endpointClean?: string) => {
  const { signal, id, newEndpointFetching } = getAbortController(
    endpointFetching,
    endpoint,
    params,
    options,
    endpointClean,
  );
  endpointFetching = newEndpointFetching;

  return fetch(getURLDomain(endpoint, params, options), {
    method: "GET",
    headers: getHeaders(options),
    signal,
  }).finally(() => (endpointFetching = removeEndpointFetching(endpointFetching, id)));
};

const post = async (
  endpoint: string,
  params?: ParamsApi,
  body?: any,
  options?: MethodOptions,
  endpointClean?: string,
) => {
  const { signal, id, newEndpointFetching } = getAbortController(
    endpointFetching,
    endpoint,
    params,
    options,
    endpointClean,
    body,
  );
  endpointFetching = newEndpointFetching;

  const haveFormData = options?.haveFormData;

  const extraHeader: Record<string, string> = {
    ...options?.extraHeader,
    "Content-Type": "application/json",
  };

  const headers: any = getHeaders({ ...options, extraHeader });

  if (haveFormData) {
    delete headers["Content-Type"];
  }

  return fetch(getURLDomain(endpoint, params, options), {
    method: "POST",
    headers,
    body: !haveFormData ? JSON.stringify(body) : body,
    signal,
  }).finally(() => (endpointFetching = removeEndpointFetching(endpointFetching, id)));
};

const put = async (
  endpoint: string,
  params?: ParamsApi,
  body?: any,
  options?: MethodOptions,
  endpointClean?: string,
) => {
  const { signal, id, newEndpointFetching } = getAbortController(
    endpointFetching,
    endpoint,
    params,
    options,
    endpointClean,
    body,
  );
  endpointFetching = newEndpointFetching;

  return fetch(getURLDomain(endpoint, params, options), {
    method: "PUT",
    headers: getHeaders({ ...options, extraHeader: { ...options?.extraHeader, "Content-Type": "application/json" } }),
    body: JSON.stringify(body),
    signal,
  }).finally(() => (endpointFetching = removeEndpointFetching(endpointFetching, id)));
};

const patch = async (
  endpoint: string,
  params?: ParamsApi,
  body?: any,
  options?: MethodOptions,
  endpointClean?: string,
) => {
  const { signal, id, newEndpointFetching } = getAbortController(
    endpointFetching,
    endpoint,
    params,
    options,
    endpointClean,
    body,
  );
  endpointFetching = newEndpointFetching;

  return fetch(getURLDomain(endpoint, params, options), {
    method: "PATCH",
    headers: getHeaders({ ...options, extraHeader: { ...options?.extraHeader, "Content-Type": "application/json" } }),
    body: JSON.stringify(body),
    signal,
  }).finally(() => (endpointFetching = removeEndpointFetching(endpointFetching, id)));
};

const del = async (endpoint: string, params?: ParamsApi, options?: MethodOptions, endpointClean?: string) => {
  const { signal, id, newEndpointFetching } = getAbortController(
    endpointFetching,
    endpoint,
    params,
    options,
    endpointClean,
  );
  endpointFetching = newEndpointFetching;

  return fetch(getURLDomain(endpoint, params, options), {
    method: "DELETE",
    headers: getHeaders(options),
    signal,
  }).finally(() => (endpointFetching = removeEndpointFetching(endpointFetching, id)));
};

export const api = {
  get,
  post,
  put,
  patch,
  del,
};
