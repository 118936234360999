import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { getURLDomain } from "@/commons/utils/apiUtils/apiUtils";
import { SetState } from "@/core/models/globalModel";
import { deleteUserService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButton, IonIcon, IonLabel, IonLoading } from "@ionic/react";
import { t } from "i18next";
import { repeatOutline, trashOutline } from "ionicons/icons";
import { useState } from "react";

interface ActionsInpuntsProps {
  unsavedChange: boolean;
  setUnsavedChange: SetState<boolean>;
}
export const ActionsInputs = ({ unsavedChange, setUnsavedChange }: ActionsInpuntsProps) => {
  const dispatch = useAppDispatch();

  const { logoutUser } = useUserContext();
  const { parameters } = useParametersContext();

  const { user } = useAppSelector((state) => state.userReducer);

  const [showDeleteLoading, setShowDeleteLoading] = useState(false);

  const handleResetPassword = () => {
    window.open(getURLDomain("users/password/new", undefined, { isRootPath: true }));
  };

  const deleteUserConfirm = async () => {
    const oldUnsavedChange = unsavedChange;
    try {
      setUnsavedChange(false);
      setShowDeleteLoading(true);

      await deleteUserService(user.user_id, parameters);
      logoutUser();
    } catch (error) {
      console.error(error);
      setUnsavedChange(oldUnsavedChange);
      dispatch(uiActions.setToastType({ type: "error" }));
    } finally {
      setShowDeleteLoading(false);
    }
  };

  return (
    <div className="actions">
      <IonLoading isOpen={showDeleteLoading} />
      <IonLabel className="title-h3">{t("TITLES.ACCOUNT_SETTINGS")}</IonLabel>
      <IonButton fill="outline" color="primary" onClick={handleResetPassword}>
        <IonIcon icon={repeatOutline} slot="start" />
        {t("BUTTONS.CHANGE_PASSWORD")}
      </IonButton>
      <IonButton
        fill="outline"
        color="danger"
        onClick={() =>
          dispatch(
            uiActions.setAlertType({
              type: "deleteUser",
              options: {
                primaryAction: deleteUserConfirm,
              },
            }),
          )
        }
      >
        <IonIcon icon={trashOutline} slot="start" />
        {t("BUTTONS.DELETE_ACCOUNT")}
      </IonButton>
    </div>
  );
};
