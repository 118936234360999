import loginImage from "@/assets/imgs/loginImage.png";
import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { ParametersContext } from "@/context/appParametersContext";
import { useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { Capacitor } from "@capacitor/core";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { IonButton, IonContent, IonIcon, IonLabel, IonList, IonPage } from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LoginEmail } from "./LoginEmail/LoginEmail";
import "./LoginPage.css";
import { LoginSocial } from "./LoginSocial/LoginSocial";

const { VITE_GOOGLE_AUTH_CLIENT_ID_ANDROID, VITE_GOOGLE_AUTH_CLIENT_ID_IOS } = import.meta.env;

export const LoginPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { parameters } = useContext(ParametersContext);

  const { isUserLogged, haveRequiredFields } = useAppSelector((state) => state.userReducer);

  const platform = Capacitor.getPlatform();
  const isIosPlatform = platform === "ios";

  const [showLoginEmail, setShowLoginEmail] = useState(false);

  useEffect(() => {
    const googleAuthClientId = isIosPlatform
      ? VITE_GOOGLE_AUTH_CLIENT_ID_IOS
      : VITE_GOOGLE_AUTH_CLIENT_ID_ANDROID || "";

    GoogleAuth.initialize({
      clientId: googleAuthClientId,
      grantOfflineAccess: true,
      scopes: ["profile", "email"],
    });
  }, []);

  useEffect(() => {
    // If user is logged and have required fields, redirect to home
    if (isUserLogged && haveRequiredFields) {
      setShowLoginEmail(false);
      history.replace(APP_PATH.HOME);
    }
  }, [isUserLogged, haveRequiredFields]);

  const handleGoBack = () => {
    !parameters.allow_register_users ? history.goBack() : setShowLoginEmail(false);
  };

  return (
    <IonPage>
      {!showLoginEmail && parameters.allow_register_users ? (
        <>
          <AppHeader startIcon={<GoBackButton />} />
          <IonContent>
            <div className="loginImageContainer">
              <IonLabel>{t("TITLES.WELCOME")}</IonLabel>
              <img src={loginImage} alt="login background" />
            </div>

            <IonList lines="none" className="loginPageContent">
              <IonLabel className="titleH4">{t("TITLES.NEW_USER", { name: parameters.name })}</IonLabel>
              <IonButton
                className="buttonSecondary createAccountBtn"
                onClick={() => {
                  history.push(APP_PATH.REGISTER);
                }}
              >
                {t("BUTTONS.CREATE_ACCOUNT")}
              </IonButton>
              <IonLabel className="titleH4">{t("TITLES.HAVE_ACCOUNT")}</IonLabel>
              <LoginSocial showIosButton={isIosPlatform} />
              <IonButton className="buttonSecondary" onClick={() => setShowLoginEmail(true)}>
                <IonIcon icon={mailOutline} slot="start" className="apple-icon"></IonIcon>
                {t("BUTTONS.LOGIN_EMAIL")}
              </IonButton>
            </IonList>
          </IonContent>
        </>
      ) : (
        <LoginEmail handleGoBack={handleGoBack} />
      )}
    </IonPage>
  );
};
