import chargeIcon from "@/assets/imgs/icons/charge.svg";
import { ChargerImages } from "@/commons/components/Map/ChargerImages/ChargerImages";
import { useQuery } from "@/commons/hooks/useQuery";
import { ReservationModel } from "@/core/models/reservationModel";
import { APP_PATH } from "@/router/routes";
import { IonAccordion, IonAccordionGroup, IonButton, IonIcon, IonItem, IonLabel, IonModal, IonRow } from "@ionic/react";
import { t } from "i18next";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { SocketsInfo } from "../../../LocationDetail/ChargerInfo/SocketsInfo/SocketsInfo";
import { ReservationInfo } from "../ActiveReservation";
import { ActiveReservationData } from "../ActiveReservationData/ActiveReservationData";
import "./ActiveRervationDetail.scss";

interface ActiveRervationDetailProps {
  showDetail: boolean;
  reservation: ReservationModel;
  reservationInfo: ReservationInfo;
  handleDissmisModal: () => void;
}

export const ActiveRervationDetail = ({
  showDetail,
  reservation,
  reservationInfo,
  handleDissmisModal,
}: ActiveRervationDetailProps) => {
  const { searchParams } = useQuery();
  const history = useHistory();

  const { charger_obj: charger } = reservation;
  const socket = charger?.charger_sockets.find((socket) => socket.id_socket === reservation.id_socket);
  const modalRef = useRef<HTMLIonModalElement>(null);

  // open full modal when when open more info
  const handleOpenMoreInfo = (e: CustomEvent) => {
    if (modalRef.current && e.detail.value === "open") {
      modalRef.current.setCurrentBreakpoint(1);
    }
  };

  const handleGoLocation = (charge = false) => {
    searchParams.set("locationId", reservation.location_id);
    searchParams.set("chargerId", reservation.charger_id);
    searchParams.set("socketNumber", reservation.socket_number);
    charge && searchParams.set("charge", "true");

    history.replace({
      pathname: APP_PATH.HOME,
      search: searchParams.toString(),
    });

    handleDissmisModal();
  };

  return (
    <IonModal
      ref={modalRef}
      isOpen={showDetail}
      className="active-reservation-detail-modal"
      initialBreakpoint={0.5}
      breakpoints={[0, 0.5, 1]}
      onDidDismiss={handleDissmisModal}
    >
      {charger && (
        <div className="ion-margin-top">
          <div className="ion-margin">
            <IonRow>
              <IonLabel>{reservation.charger}</IonLabel>
            </IonRow>

            <IonAccordionGroup onIonChange={handleOpenMoreInfo}>
              <IonAccordion value="open" toggleIconSlot="start">
                <IonItem slot="header">
                  <IonLabel>{t("BUTTONS.MORE_INFO")}</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <ChargerImages chargers={[charger]} />
                </div>
              </IonAccordion>
            </IonAccordionGroup>

            {charger && socket && <SocketsInfo charger={charger} socket={socket} canSelectSocket={false} />}
          </div>
          <ActiveReservationData reservation={reservation} reservationInfo={reservationInfo} isDetail />

          <div className="active-reservation-detail-modal-buttons">
            <IonButton color="primary" fill="outline" onClick={() => handleGoLocation()}>
              {t("BUTTONS.LOCATION")}
            </IonButton>
            <IonButton onClick={() => handleGoLocation(true)}>
              <IonIcon src={chargeIcon} slot="start" />
              {t("BUTTONS.CHARGE")}
            </IonButton>
          </div>
        </div>
      )}
    </IonModal>
  );
};
