import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { ChargerGraphModel, ChargerModel, ChargerSocketModel } from "@/core/models/chargerModel";

/**
 * Service to get the charge graph
 * @param chargerGraphId
 * @returns
 */
export const getChargerGraphService = (chargerGraphId: number): Promise<ChargerGraphModel> => {
  return api.get("payments/get-charger-graph", { chargerGraphId }).then(resolveResponse).catch(rejectError);
};

/**
 * Service to get the charge graph
 * @param chargerGraphId
 * @returns
 */
export const getChargerService = (chargeId: number): Promise<ChargerModel> => {
  return api.get(`charger/show/${chargeId}`).then(resolveResponse).catch(rejectError);
};

/**
 * Service to get socket model of a charger
 * @param idCharger
 * @param socket socket_number
 * @returns
 */
export const getChargerSocketService = (idcharger: string, socket: string): Promise<ChargerSocketModel> => {
  return api.get(`socket/status`, { idcharger, socket }).then(resolveResponse).catch(rejectError);
};
