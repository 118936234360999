import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSkeletonText } from "@ionic/react";

export const ShopProductSkeleton = () => {
  return (
    <IonCard>
      <IonSkeletonText animated={true} style={{ width: "200px", height: "200px", marginTop: "0" }} />
      <IonCardHeader>
        <IonCardTitle>
          <IonSkeletonText animated={true} style={{ width: "70px", height: "20px" }} />
        </IonCardTitle>
      </IonCardHeader>
      <IonSkeletonText animated={true} style={{ width: "70px", marginLeft: "20px" }} />
      <IonSkeletonText animated={true} style={{ width: "70px", marginLeft: "20px" }} />
      <IonCardContent>
        <IonSkeletonText animated={true} style={{ width: "70px" }} />
      </IonCardContent>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <IonSkeletonText animated={true} style={{ width: "100px", height: "40px", margin: "5" }} />
      </div>
    </IonCard>
  );
};
