import { CHARGE_ACTIVATE_FORM_SCHEMA } from "@/commons/constants/Charge";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { VehicleBrand, VehicleFormModel } from "@/core/models/userModel";
import { createUserVehicleService, getVehicleBrandsService } from "@/core/services/user/vehicleUserServices";
import { ChargeStepActiveForm, chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonSpinner,
} from "@ionic/react";
import { t } from "i18next";
import { addCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import "./ChargeDataGuestUser.scss";

export const ChargeDataGuestUser = () => {
  const dispatch = useAppDispatch();

  const { activeStep } = useAppSelector((state) => state.chargeStepsReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [brands, setBrands] = useState<VehicleBrand[]>([]);

  const [errors, setErrors] = useState<Record<keyof ChargeStepActiveForm, string>>({
    email: "",
    vehicleId: "",
    vehicleBrand: "",
    otherBrand: "",
    vehicleModel: "",
    otherModel: "",
    carId: "",
  });

  const [touched, setTouched] = useState<Record<keyof ChargeStepActiveForm, boolean>>({
    email: false,
    vehicleId: false,
    vehicleBrand: false,
    otherBrand: false,
    vehicleModel: false,
    otherModel: false,
    carId: false,
  });

  const [isValidForm, setIsValidForm] = useState(false);

  useEffect(() => {
    loadData();

    handleValidation(activeStep);
  }, []);

  const loadData = async () => {
    try {
      setIsLoadingData(true);
      const newBrands = await getVehicleBrandsService();
      setBrands(newBrands);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleChange = (target: HTMLIonInputElement | HTMLIonSelectElement) => {
    const { name, value } = target;

    let newActiveStep: ChargeStepActiveForm = {
      ...activeStep,
      [name]: value,
    };

    handleValidation(newActiveStep, name, value);

    // Clean othen brand and model if brand is changed
    if (newActiveStep.vehicleBrand?.id !== 0) {
      const isDiferentBrand = newActiveStep.vehicleBrand?.id !== activeStep.vehicleBrand?.id;

      newActiveStep = {
        ...newActiveStep,
        vehicleModel: isDiferentBrand ? null : newActiveStep.vehicleModel,
        otherBrand: "",
        otherModel: "",
      };
    }

    // Clean model if brand is other
    if (newActiveStep.vehicleBrand?.id === 0) {
      newActiveStep = {
        ...newActiveStep,
        vehicleModel: null,
      };
    }

    dispatch(chargeStepsActions.setActiveStep(newActiveStep));
  };

  const handleValidation = (activeStep: ChargeStepActiveForm, name?: string, value?: any) => {
    const valitation = CHARGE_ACTIVATE_FORM_SCHEMA(t).safeParse(activeStep);

    if (!valitation.success) {
      setIsValidForm(false);

      if (name && value) {
        const newError = valitation.error.errors.find((error) => error.path[0] === name);
        setErrors({
          ...errors,
          [name]: newError?.message || "",
        });
      }
    } else {
      setIsValidForm(true);

      name &&
        setErrors({
          ...errors,
          [name]: "",
        });
    }
  };

  const markTouched = (name: string) =>
    setTouched({
      ...touched,
      [name]: true,
    });

  const compareSelect = (a: { id: number } | null, b: { id: number } | null) => a?.id === b?.id;

  const handleAddVehicle = async () => {
    try {
      setIsLoadingForm(true);
      const vehicleForm: VehicleFormModel = {
        carId: activeStep.carId,
        vehicleBrandId: activeStep.vehicleBrand?.id,
        vehicleModelId: activeStep.vehicleModel?.id,
        vehicle: activeStep.otherBrand,
        model: activeStep.otherModel,
      };
      const { vehicle_id } = await createUserVehicleService(user.user_id, vehicleForm);
      const vehicleId = `${vehicle_id}`;

      if (!vehicleId) throw new Error(t("APP_ERRORS.CREATE_VEHICLE"));

      dispatch(chargeStepsActions.setActiveVehicleId(vehicleId));
      dispatch(userActions.setGuestInfo({ email: activeStep.email, vehicle: vehicleForm }));
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoadingForm(false);
    }
  };

  return (
    <div className="charge-data-guest-user">
      <IonLoading isOpen={isLoadingForm} />
      {!activeStep.vehicleId ? (
        <>
          <div>
            <IonLabel className="title-h3">{t("TITLES.ENTER_DATA")}</IonLabel>
            <IonInput
              className={`${classIf(!!errors?.email, "ion-invalid")} ${classIf(touched.email, "ion-touched")} `}
              type="email"
              fill="solid"
              label={t("PROFILE.EMAIL")}
              labelPlacement="floating"
              name="email"
              errorText={(touched.email && errors?.email) || ""}
              value={activeStep.email}
              onIonInput={(event) => handleChange(event.target)}
              onBlur={(e) => markTouched(e.target.name)}
            />
          </div>
          <div>
            <IonLabel className="title-h3">{t("TITLES.VEHICLE_DATA")}</IonLabel>
            {isLoadingData ? (
              <div className="charge-data-guest-user-loading">
                <IonSpinner />
              </div>
            ) : (
              <>
                <IonSelect
                  fill="solid"
                  label={t("CAR.BRAND")}
                  labelPlacement="floating"
                  name="vehicleBrand"
                  compareWith={compareSelect}
                  value={activeStep.vehicleBrand}
                  onIonChange={(event) => handleChange(event.target)}
                >
                  {brands.map((brand) => (
                    <IonSelectOption key={brand.id} value={brand}>
                      {brand.name}
                    </IonSelectOption>
                  ))}
                  <IonSelectOption value={{ id: 0, name: "other", models: [] }}>{t("CAR.OTHER")}</IonSelectOption>
                </IonSelect>
                {activeStep.vehicleBrand?.id !== 0 ? (
                  <IonSelect
                    fill="solid"
                    label={t("CAR.MODEL")}
                    labelPlacement="floating"
                    name="vehicleModel"
                    compareWith={compareSelect}
                    value={activeStep.vehicleModel}
                    onIonChange={(event) => handleChange(event.target)}
                  >
                    {activeStep.vehicleBrand?.models.map((model) => (
                      <IonSelectOption key={model.id} value={{ id: model.id, name: model.name }}>
                        {model.name}
                      </IonSelectOption>
                    ))}
                    <IonSelectOption value={{ id: 0, name: "other" }}>{t("CAR.OTHER")}</IonSelectOption>
                  </IonSelect>
                ) : (
                  <>
                    <IonInput
                      type="text"
                      fill="solid"
                      label={t("CAR.BRAND")}
                      labelPlacement="stacked"
                      name="otherBrand"
                      value={activeStep.otherBrand}
                      onIonInput={(event) => handleChange(event.target)}
                      placeholder={t("PLACEHOLDERS.BRAND")}
                    />
                    <IonInput
                      type="text"
                      fill="solid"
                      label={t("CAR.MODEL")}
                      labelPlacement="stacked"
                      name="otherModel"
                      value={activeStep.otherModel}
                      onIonInput={(event) => handleChange(event.target)}
                      placeholder={t("PLACEHOLDERS.MODEL")}
                    />
                  </>
                )}
                <IonInput
                  type="text"
                  fill="solid"
                  label={t("CAR.LICENSE_PLATE")}
                  labelPlacement="floating"
                  name="carId"
                  value={activeStep.carId}
                  onIonInput={(event) => handleChange(event.target)}
                />

                <IonButton className="add-vehicle" disabled={!isValidForm} onClick={handleAddVehicle}>
                  <IonIcon icon={addCircleOutline} />
                  {t("BUTTONS.ADD_VEHICLE")}
                </IonButton>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="charge-data-guest-user-success-message">
          <IonIcon color="primary" icon={checkmarkCircleOutline} />
          <IonLabel>{t("TOAST.ADDED_VEHICLE")}</IonLabel>
        </div>
      )}
    </div>
  );
};
