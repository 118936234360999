import charginStationIcon from "@/assets/imgs/icons/charging-station.svg";
import { useLocationsContext } from "@/commons/hooks/useLocationsContext";
import { SetState } from "@/core/models/globalModel";
import { LocationMapChargerModel } from "@/core/models/locationModel";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import {
  IonButton,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonSpinner,
  IonText,
  IonToolbar,
} from "@ionic/react";
import "@ionic/react/css/ionic-swiper.css";
import { t } from "i18next";
import { locationOutline } from "ionicons/icons";
import { LatLng, Map } from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { sortBy } from "lodash";
import { RefObject, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "swiper/css";
import "./SearchBar.scss";
import { SearchButtons } from "./SearchButtons/SearchButtons";

interface Props {
  showRoute: boolean;
  setIsCenter: SetState<boolean>;
  mapRef: RefObject<Map>;
}

export const SearchBar = ({ showRoute, mapRef, setIsCenter }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { locationsCx } = useLocationsContext();

  const { showSearch } = useAppSelector((state) => state.mapReducer);

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchChargersSuggestions, setSearchChargersSuggestions] = useState<LocationMapChargerModel[]>([]);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchResult<RawResult>[]>([]);
  const [chargersMaxElements, setChargersMaxElements] = useState(15);
  const [searchTypeCharger, setSearchTypeCharger] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    // Close suggestions when user is not in home page
    if (window.location.pathname !== APP_PATH.HOME) {
      dispatch(mapActions.setShowSearch(false));
      setSearchTerm("");
    }
  }, [window.location.pathname]);

  const handleSearch = async (event: CustomEvent) => {
    const newSearchTerm = event.detail.value;

    if (newSearchTerm.trim()) {
      dispatch(mapActions.setShowSearch(true));
      setSearchLoading(true);
      setChargersMaxElements(15);
      setSearchTerm(newSearchTerm);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = setTimeout(() => {
        getResults(newSearchTerm);
      }, 300);
      setTimeoutId(newTimeoutId);
    } else {
      setSearchTerm("");
      setSearchLoading(false);
      setSearchSuggestions([]);
      dispatch(mapActions.setShowSearch(false));
    }
  };

  const getFiltredLocations = async (searchTerm: string) => {
    const provider = new OpenStreetMapProvider();

    const result = await provider.search({ query: searchTerm });
    setSearchSuggestions(result);
  };

  const getFilteredChargers = (newSearchTerm: string) => {
    let newChargersFiltered: LocationMapChargerModel[] = [];
    locationsCx.forEach((location) => {
      location.chargers.forEach((charger) => {
        if (
          charger.id.toString().startsWith(newSearchTerm) ||
          normalizeString(charger.name.toLowerCase()).includes(normalizeString(newSearchTerm.toLowerCase()))
        ) {
          newChargersFiltered.push({ ...charger, location_id: location.location_id });
        }
      });
    });

    newChargersFiltered = sortBy(newChargersFiltered, "id");

    setSearchChargersSuggestions(newChargersFiltered);
  };

  function normalizeString(input: string) {
    return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const getResults = async (newSearchTerm: string) => {
    newSearchTerm = newSearchTerm.trim();
    try {
      getFilteredChargers(newSearchTerm);
      await getFiltredLocations(newSearchTerm);
    } catch {
      dispatch(uiActions.setToastType({ type: "error" }));
    } finally {
      setSearchLoading(false);
    }
  };

  const selectSuggestion = (search: SearchResult<RawResult>) => {
    setIsCenter(false);
    mapRef.current?.setView(new LatLng(search.y, search.x), 15);
    dispatch(mapActions.setSearchSelectedCoord({ lat: search.y, lon: search.x }));
    setSearchTerm(search.label);
    setSearchSuggestions([]);
    dispatch(mapActions.setShowSearch(false));
  };

  const selectCharger = (charger: LocationMapChargerModel) => {
    setIsCenter(false);
    const location = locationsCx.find((location) => location.location_id === charger.location_id);
    if (!location) return;

    history.replace(`${APP_PATH.HOME}?locationId=` + location.location_id);
    mapRef.current?.setView(location.latLng, 15);
    setSearchTerm("");
    setSearchSuggestions([]);
    dispatch(mapActions.setShowSearch(false));
  };

  return (
    <>
      {showSearch && (
        <div className="search-bar">
          <SearchButtons searchTypeCharger={searchTypeCharger} setSearchTypeCharger={setSearchTypeCharger} />

          {searchLoading && (
            <IonItem lines="none">
              <IonSpinner slot="start" />
              <IonText className="noResultsSearch">{t("LOADING.SEARCH")}</IonText>
            </IonItem>
          )}

          {!searchLoading && (
            <>
              {!searchTypeCharger ? (
                <>
                  {searchSuggestions.length ? (
                    <>
                      {searchSuggestions.map((search) => (
                        <IonList key={search.raw.place_id}>
                          <IonItem lines="none" onClick={() => selectSuggestion(search)}>
                            <IonIcon icon={locationOutline}></IonIcon>
                            <IonLabel className="labelSuggestion" style={{ marginLeft: "10px" }}>
                              {search.label}
                            </IonLabel>
                          </IonItem>
                        </IonList>
                      ))}
                    </>
                  ) : (
                    <IonItem lines="none">
                      <IonText className="noResultsSearch">{t("ALERT.SEARCH_NOT_FOUND")}</IonText>
                    </IonItem>
                  )}
                </>
              ) : (
                <>
                  {searchChargersSuggestions.length ? (
                    <IonList>
                      {searchChargersSuggestions.slice(0, chargersMaxElements).map((charger) => (
                        <IonItem key={charger.id} lines="none" onClick={() => selectCharger(charger)}>
                          <IonIcon icon={charginStationIcon} />
                          <IonLabel className="labelSuggestion" style={{ marginLeft: "10px" }}>
                            {charger.id} - {charger.name}
                          </IonLabel>
                        </IonItem>
                      ))}
                      {chargersMaxElements < searchChargersSuggestions.length && (
                        <IonButton
                          color="primary"
                          fill="outline"
                          mode="ios"
                          onClick={() => setChargersMaxElements(chargersMaxElements + 15)}
                        >
                          + {t("BUTTONS.MORE")}
                        </IonButton>
                      )}
                    </IonList>
                  ) : (
                    <IonItem lines="none">
                      <IonText className="noResultsSearch">{t("ALERT.SEARCH_NOT_FOUND")}</IonText>
                    </IonItem>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
      {!showRoute && (
        <IonFooter className="map-search ion-no-border">
          <IonToolbar>
            <IonSearchbar
              placeholder={t("ROUTE.SEARCH")}
              value={searchTerm}
              onIonInput={handleSearch}
              //onIonBlur={() => setShowSuggestions(false)}
              onIonClear={() => {
                dispatch(mapActions.setShowSearch(false));
                dispatch(mapActions.clearSearchSelectedCoord());
              }}
              autocapitalize="off"
            />
          </IonToolbar>
        </IonFooter>
      )}
    </>
  );
};
