import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { EmptyList } from "@/commons/components/EmptyList/EmptyList";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { QrScanner } from "@/commons/components/QrScaner/QrScanner";
import { PromotionalCodeModel } from "@/core/models/promotionalCodeModel";
import { getPromotionalCodesService } from "@/core/services/promotionalCode/promotionalCodeServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IncidenceCardSkeleton } from "@/pages/IncidencesPage/IncidenceCard/IncidenceCardSkeleton";
import { Capacitor } from "@capacitor/core";
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonList,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { t } from "i18next";
import { addCircleOutline } from "ionicons/icons";
import { orderBy } from "lodash";
import { useState } from "react";
import { PromotionalCodeAddModal } from "./PromotionalCodeAddModal/PromotionalCodeAddModal";
import { PromotionalCodeCard } from "./PromotionalCodeCard/PromotionalCodeCard";
import "./PromotionalCodesPage.scss";

export const PromotionalCodesPage = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [showCamera, setshowCamera] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [promotionalCodes, setPromotionalCodes] = useState<PromotionalCodeModel[]>([]);

  useIonViewWillEnter(() => {
    loadData();
  });

  const loadData = async () => {
    try {
      setIsLoading(true);

      const promotionalCodes = await getPromotionalCodesService();

      // Sort by active
      const sortedPromotionalCodes = orderBy(promotionalCodes, ["active", "expired"], ["desc", "asc"]);

      setPromotionalCodes(sortedPromotionalCodes);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPromotionalCode = async () => {
    setShowAddModal(false);

    if (Capacitor.getPlatform() === "web") {
      return setShowAddModal(true);
    }

    setshowCamera(true);
  };

  const callbackSuccess = () => {
    loadData();
    dispatch(uiActions.setAlertType({ type: "successActivePromotion" }));
    setshowCamera(false);
    setShowAddModal(false);
  };

  const handleDismissModal = () => {
    setShowAddModal(false);
  };

  return (
    <IonPage>
      <QrScanner
        showCamera={showCamera}
        setShowCamera={setshowCamera}
        callbackSuccess={callbackSuccess}
        setShowAddModal={setShowAddModal}
        type="promotionalCode"
      />
      <PromotionalCodeAddModal
        isOpen={showAddModal}
        handleDismiss={handleDismissModal}
        callbackSuccess={callbackSuccess}
        openCamera={handleAddPromotionalCode}
      />
      <AppHeader title={t("TITLES.PROMOTIONAL_CODES")} startIcon={<GoBackButton />} />
      <IonContent>
        {isLoading && <IncidenceCardSkeleton />}

        {!isLoading && (
          <>
            {promotionalCodes.length ? (
              <IonList>
                {promotionalCodes.map((promotionalCode) => (
                  <PromotionalCodeCard key={promotionalCode.id} promotionalCode={promotionalCode} />
                ))}
              </IonList>
            ) : (
              <EmptyList text={"TITLES.NO_PROMOTIONAL_CODE"} />
            )}
          </>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton className="button-footer" onClick={handleAddPromotionalCode}>
            <IonIcon slot="start" icon={addCircleOutline} />
            {t("BUTTONS.ADD_PROMOTIONAL_CODE")}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};
