import { minutesToHours } from "@/commons/utils/dateUtils/dateUtils";
import { getRatePeriodicityTranslation, getRateScopeTranslation } from "@/commons/utils/socketUtils/socketUtils";
import { ChargerSocketModel } from "@/core/models/chargerModel";
import { RateLimitPeriodicity, RateLimitScope, RateLimitType, RateTypeLimits } from "@/core/models/rateModel";
import { IonIcon, IonRow, IonText } from "@ionic/react";
import { alarmOutline, flashOutline } from "ionicons/icons";
import { sortBy } from "lodash";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import "./RateLimits.scss";

interface RateLimitsProps {
  rateLimits?: RateTypeLimits;
  selectedSocket: ChargerSocketModel;
  hideConsumed?: boolean;
  hideSocketLimits?: boolean;
}

interface Limit {
  scope: RateLimitScope;
  periodicity: RateLimitPeriodicity;
  type: RateLimitType;
  isRate: boolean;
  total: number;
  consumed?: number;
}

export const RateLimits = ({ rateLimits, selectedSocket, hideConsumed, hideSocketLimits = false }: RateLimitsProps) => {
  const [limits, setLimits] = useState<Limit[]>([]);

  useEffect(() => {
    const newLimits: Limit[] = [];

    if (!hideSocketLimits) {
      if (Number(selectedSocket.max_time_min) > 0) {
        newLimits.push({
          scope: "Socket",
          periodicity: "Charge",
          type: "Time",
          isRate: false,
          total: Number(selectedSocket.max_time_min),
        });
      }

      if (Number(selectedSocket.max_energy_wh) > 0) {
        newLimits.push({
          scope: "Socket",
          periodicity: "Charge",
          type: "Energy",
          isRate: false,
          total: (Number(selectedSocket.max_energy_wh) || 0) / 1000,
        });
      }
    }

    Object.entries(rateLimits || []).forEach(([key, value]) => {
      const type = key as RateLimitType;
      newLimits.push({
        scope: value.scope,
        periodicity: value.periodicity,
        type,
        isRate: true,
        total: value.limit,
        consumed: value.consumed,
      });
    });

    setLimits(sortBy(newLimits, "type"));
  }, [rateLimits, selectedSocket]);

  const getIcon = (limit: Limit) => {
    if (limit.type === "Energy") {
      return flashOutline;
    }

    if (limit.type === "Time") {
      return alarmOutline;
    }

    return "";
  };
  const getTotal = (limit: Limit) => {
    if (limit.type === "Time") {
      return minutesToHours(limit.total);
    }

    if (limit.type === "Energy") {
      return `${limit.total} kWh`;
    }

    return limit.total;
  };

  const getConsumed = (limit: Limit) => {
    if (limit.type === "Time") {
      return minutesToHours(limit.consumed || 0);
    }

    if (limit.type === "Energy") {
      return `${limit.consumed || 0} kWh`;
    }

    return limit.consumed;
  };

  return (
    <div className="rate-limit-list">
      {limits.map((limit, index) => (
        <IonRow key={index} className="">
          <IonText className="rate-limit">
            <div>
              <IonIcon icon={getIcon(limit)} />
            </div>
            <div>
              <Trans
                i18nKey={`INFO_CHARGE.SOCKET_RATE_TIME_LIMIT${hideConsumed || !limit.isRate ? "_SHORT" : ""}`}
                values={{
                  scope: getRateScopeTranslation(limit.scope),
                  value: getTotal(limit),
                  periodicity: getRatePeriodicityTranslation(limit.periodicity),
                  consumed: getConsumed(limit),
                }}
              />
            </div>
          </IonText>
        </IonRow>
      ))}
    </div>
  );
};
