import appLogoDark from "@/assets/imgs/app-logo-dark.png";
import appLogo from "@/assets/imgs/app-logo.png";
import logoToolbarDark from "@/assets/imgs/logo-toolbar-dark.png";
import logoToolbar from "@/assets/imgs/logo-toolbar.png";
import { useAppSelector } from "@/core/store/store";
import { useEffect, useState } from "react";

export const useLogoApp = () => {
  const { theme } = useAppSelector((state) => state.userReducer);

  const [appLogos, setAppLogos] = useState({
    appLogo: theme === "dark" ? appLogoDark : appLogo,
    logoToolbar: theme === "dark" ? logoToolbarDark : logoToolbar,
  });

  useEffect(() => {
    setAppLogos({
      appLogo: theme === "dark" ? appLogoDark : appLogo,
      logoToolbar: theme === "dark" ? logoToolbarDark : logoToolbar,
    });
  }, [theme]);

  return appLogos;
};
