import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { Illustration16 } from "@/commons/constants/imgs";
import { SupportTicketModel } from "@/core/models/incidenceModel";
import { getUserIncidencesService } from "@/core/services/user/ticketServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { IonInfiniteScrollCustomEvent } from "@ionic/core";
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonText,
  SegmentCustomEvent,
} from "@ionic/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IncidenceCard } from "./IncidenceCard/IncidenceCard";
import { IncidenceCardSkeleton } from "./IncidenceCard/IncidenceCardSkeleton";
import { IncidencesStepsSkeleton } from "./IncidencesStepsSkeleton";

export const IncidencesPage = () => {
  const steps = [`${t("INCIDENCE_STEPS.ALL")}`, `${t("INCIDENCE_STEPS.ACTIVE")}`, `${t("INCIDENCE_STEPS.CLOSED")}`];

  const dispatch = useDispatch();

  const [userIncidences, setUserIncidences] = useState<SupportTicketModel[]>([]);
  const [activeIncidences, setActiveIncidences] = useState<SupportTicketModel[]>([]);
  const [closedIncidences, setClosedIncidences] = useState<SupportTicketModel[]>([]);

  const [showLoadingTickets, setShowLoadingTickets] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [showMoreData, setShowMoreData] = useState(true);

  useEffect(() => {
    setUserTickets();
  }, []);

  const setUserTickets = async (event?: IonInfiniteScrollCustomEvent<void>) => {
    if (!showMoreData) {
      return event?.target.complete();
    }

    try {
      const { support_tickets } = await getUserIncidencesService(page);

      if (support_tickets) {
        const newUserIncidences = [...userIncidences, ...support_tickets];

        setUserIncidences(newUserIncidences);
        setActiveIncidences(newUserIncidences.filter((incidence) => incidence.status !== "CLOSED"));
        setClosedIncidences(newUserIncidences.filter((incidence) => incidence.status === "CLOSED"));

        if (support_tickets.length === 0) setShowMoreData(false);
        setPage(page + 1);
      }
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      event?.target.complete();
      setShowLoadingTickets(false);
    }
  };

  const handleStepClick = (event: SegmentCustomEvent) => {
    setCurrentStep(Number(event.detail.value || 0));
  };

  return (
    <IonPage>
      <AppHeader title={t("MENU.INCIDENCES")} />
      <IonContent>
        {showLoadingTickets ? (
          <>
            <IncidencesStepsSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
            <IncidenceCardSkeleton />
          </>
        ) : (
          <>
            {userIncidences.length === 0 && (
              <IonList className="listReservationsContainer ion-padding">
                <Illustration16 className="imageReservations" />
                <IonList className="listReservationsContainer textReservation">
                  <IonLabel className="titleKeychain titleReservations">{t("INCIDENCES.ANY_INCIDENCE")}</IonLabel>
                  <IonText>{t("INCIDENCES.ANY_INCIDENCE_TEXT")}</IonText>
                </IonList>
              </IonList>
            )}
            {userIncidences.length > 0 && (
              <IonSegment className="segment" mode="md" value={currentStep.toString()} onIonChange={handleStepClick}>
                {steps.map((step, index) => (
                  <IonSegmentButton key={index} value={index.toString()}>
                    <IonLabel>{step}</IonLabel>
                  </IonSegmentButton>
                ))}
              </IonSegment>
            )}

            {userIncidences && currentStep === 0 && (
              <>
                <IonList>
                  {userIncidences.map((incidence) => (
                    <IncidenceCard key={incidence.id} incidence={incidence} />
                  ))}
                </IonList>
                <IonInfiniteScroll
                  onIonInfinite={(ev) => {
                    setUserTickets(ev);
                  }}
                >
                  {showMoreData && <IonInfiniteScrollContent />}
                </IonInfiniteScroll>
              </>
            )}

            {activeIncidences && currentStep === 1 && (
              <>
                <IonList>
                  {activeIncidences.map((incidence) => (
                    <IncidenceCard key={incidence.id} incidence={incidence} />
                  ))}
                </IonList>
                <IonInfiniteScroll
                  onIonInfinite={(ev) => {
                    setUserTickets(ev);
                  }}
                >
                  {showMoreData && <IonInfiniteScrollContent />}
                </IonInfiniteScroll>
              </>
            )}

            {activeIncidences.length === 0 && currentStep === 1 && (
              <IonList className="listReservationsContainerActiveTab ion-padding">
                <Illustration16 className="imageReservations" />
                <IonList className="listReservationsContainer textReservation">
                  <IonLabel className="titleKeychain titleReservations">{t("INCIDENCES.NO_INCIDENCES_OPEN")}</IonLabel>
                </IonList>
              </IonList>
            )}

            {closedIncidences && currentStep === 2 && (
              <>
                <IonList>
                  {closedIncidences.map((incidence) => (
                    <IncidenceCard key={incidence.id} incidence={incidence} />
                  ))}
                </IonList>
                <IonInfiniteScroll
                  onIonInfinite={(ev) => {
                    setUserTickets(ev);
                  }}
                >
                  {showMoreData && <IonInfiniteScrollContent />}
                </IonInfiniteScroll>
              </>
            )}

            {closedIncidences.length === 0 && currentStep === 2 && (
              <IonList className="listReservationsContainerActiveTab ion-padding">
                <Illustration16 className="imageReservations" />
                <IonList className="listReservationsContainer textReservation">
                  <IonLabel className="titleKeychain titleReservations">
                    {t("INCIDENCES.NO_INCIDENCES_CLOSED")}
                  </IonLabel>
                </IonList>
              </IonList>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};
