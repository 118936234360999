import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { UserInfoModel } from "@/core/models/userModel";
import { useAppSelector } from "@/core/store/store";
import { IonCheckbox, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import { TargetFormType } from "../PersonalDataPage";

interface PoliciesInputsProps {
  showErrors: boolean;
  userInfoTemp: UserInfoModel;
  handleChangeForm: (target: TargetFormType) => void;
}
export const PoliciesInputs = ({ showErrors, userInfoTemp, handleChangeForm }: PoliciesInputsProps) => {
  const { parameters } = useParametersContext();

  const { userInfo } = useAppSelector((state) => state.userReducer);

  const handleOpenPolicy = () => {
    parameters.url_privacy && window.open(parameters.url_privacy);
  };

  const handleOpenTerms = () => {
    parameters.url_terms && window.open(parameters.url_terms);
  };

  return (
    <div className="policies">
      <IonRow className="billing-row">
        <IonLabel className="title-h3" style={{ fontSize: "90%" }}>
          {t("POLICIES.RECIEVE_PROMOCIONAL_INFO")}
        </IonLabel>
        <IonCheckbox
          name="notify_commercial"
          checked={!!userInfoTemp.notify_commercial}
          onIonChange={(event) => handleChangeForm(event.target)}
        />
      </IonRow>
      <div>
        <IonRow className="billing-row">
          <IonLabel onClick={handleOpenPolicy} className="title-h3" style={{ fontSize: "90%" }}>
            <a style={{ textDecoration: "underline var(--ion-color-primary)" }}>{t("HELP.PRIVACY_POLICY")}</a> *
          </IonLabel>
          <IonCheckbox
            name="privacy_policy"
            checked={!!userInfoTemp.privacy_policy}
            disabled={!!userInfo?.privacy_policy}
            onIonChange={(event) => handleChangeForm(event.target)}
          />
        </IonRow>
        {!userInfoTemp.privacy_policy && showErrors && (
          <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
        )}
      </div>
      <div>
        <IonRow className="billing-row">
          <IonLabel onClick={handleOpenTerms} className="title-h3" style={{ fontSize: "90%" }}>
            <a style={{ textDecoration: "underline var(--ion-color-primary)" }}>{t("HELP.TERMS_CONDITIONS")}</a> *
          </IonLabel>
          <IonCheckbox
            name="terms_conditions"
            checked={!!userInfoTemp.terms_conditions}
            disabled={!!userInfo?.terms_conditions}
            onIonChange={(event) => handleChangeForm(event.target)}
          />
        </IonRow>
        {!userInfoTemp.terms_conditions && showErrors && (
          <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
        )}
      </div>
    </div>
  );
};
