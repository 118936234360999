import { Illustration16 } from "@/commons/constants/imgs";
import { IonLabel } from "@ionic/react";
import { t } from "i18next";
import "./EmptyList.scss";

interface EmptyListProps {
  text: string;
}

export const EmptyList = ({ text }: EmptyListProps) => {
  return (
    <div className="empty-list">
      <Illustration16 className="illustration" />
      <IonLabel>{t(text)}</IonLabel>
    </div>
  );
};
