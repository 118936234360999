import { CHARGER_SOCKET_CHARGE_TYPE, CHARGER_SOCKET_CHARGE_TYPE_ICON } from "@/commons/constants/charger";
import { IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";

interface SocketChargeTypeProps {
  maxElectricPower: number;
}

export const SocketChargeType = ({ maxElectricPower }: SocketChargeTypeProps) => {
  let socketChargeType: CHARGER_SOCKET_CHARGE_TYPE = CHARGER_SOCKET_CHARGE_TYPE.SLOW;

  if (maxElectricPower > 7 && maxElectricPower <= 22) {
    socketChargeType = CHARGER_SOCKET_CHARGE_TYPE.SEMI_FAST;
  } else if (maxElectricPower > 22 && maxElectricPower <= 55) {
    socketChargeType = CHARGER_SOCKET_CHARGE_TYPE.FAST;
  } else if (maxElectricPower > 55) {
    socketChargeType = CHARGER_SOCKET_CHARGE_TYPE.SUPER_FAST;
  }

  return (
    <IonRow className="ion-align-items-center">
      <IonIcon className="socket-icon" src={CHARGER_SOCKET_CHARGE_TYPE_ICON[socketChargeType]} />
      <IonCol>
        <div>
          <IonLabel>{t(`CHARGES_TYPES.${socketChargeType}`)}</IonLabel>
        </div>
        <div>
          <IonLabel>{Math.ceil(maxElectricPower)} kW</IonLabel>
        </div>
      </IonCol>
    </IonRow>
  );
};
