import { divIcon, point } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import "./UserMarker.scss";

export const UserMarker = () => {
  return divIcon({
    className: `user-marker`,
    html: renderToStaticMarkup(<div className="container"></div>),
    iconSize: point(15, 15, true),
  });
};
