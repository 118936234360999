import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { timerOutline } from "ionicons/icons";
import { useEffect, useState } from "react";

interface ReservationCardProps {
  reservationInfo: any;
  onClick: (reservationInfo: any) => void;
  onFinish?: () => void;
}

export const ReservationCard = ({ reservationInfo, onClick }: ReservationCardProps) => {
  const { parameters } = useParametersContext();
  const [isActive, setIsActive] = useState(false);
  const [formattedStringStart, setFormattedStringStart] = useState("");
  const [formattedStrigEnd, setFormattedStringEnd] = useState("");
  const [formattedDate, setFormattedDate] = useState("");
  const [amountEuros, setAmountEuros] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    const formattedStart = format(reservationInfo.start_reservation, "HH:mm");
    const formattedEnd = format(reservationInfo.end_reservation, "HH:mm");
    if (reservationInfo && !reservationInfo.finished) {
      setIsActive(true);
      setFormattedStringStart(formattedStart);
    } else {
      setIsActive(false);
      setFormattedStringStart(formattedStart);
      setFormattedStringEnd(formattedEnd);
    }

    const date = new Date(reservationInfo.start_reservation);
    const formattedDate = format(date, "dd/MM/yyyy");
    setFormattedDate(formattedDate);

    const amount = reservationInfo.amount;
    setAmountEuros((amount / 100).toString());
  }, [reservationInfo, timeRemaining]);

  useEffect(() => {
    const startTime = new Date(reservationInfo.start_reservation);
    const endTime = new Date(startTime.getTime() + reservationInfo.reserve_time * 60000);
    const currentTime = new Date();

    const remaining = Math.max(Math.floor((endTime.getTime() - currentTime.getTime()) / 1000), 0);

    setTimeRemaining(remaining);

    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          return 0;
        } else if (prevTime > reservationInfo.duration * 60) {
          return reservationInfo.duration * 60;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (time: number): string => {
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handleClick = () => {
    onClick(reservationInfo);
  };

  return (
    <IonRow className="rowReservation rowContainerReservation" onClick={handleClick}>
      <IonCol>
        <IonRow className="ion-justify-content-between">
          <IonLabel className="chargerNameTitle">{reservationInfo.charger}</IonLabel>
          {Number(amountEuros) ? (
            <IonLabel className="number">
              {amountEuros} {parameters.currency}
            </IonLabel>
          ) : (
            <StatusLabel>{t("CHARGER.FREE")}</StatusLabel>
          )}
        </IonRow>
        <IonRow className="rowContainerTime">
          {isActive && (
            <>
              <IonIcon icon={timerOutline}></IonIcon>
              <IonLabel className="labelTime">{t("TITLES.TIME_REMAIN")}: </IonLabel>
              <IonLabel className="number labelTime strong-text">{formatTime(timeRemaining)}</IonLabel>
            </>
          )}
        </IonRow>
        <IonLabel className="number"> {formattedDate} </IonLabel>
        {formattedStringStart && formattedStrigEnd && (
          <IonLabel className="number">
            {" "}
            - {formattedStringStart} {t("BOOKING_INFO.TO")} {formattedStrigEnd}
          </IonLabel>
        )}
        {formattedStringStart && !formattedStrigEnd && (
          <IonLabel className="number">
            {" "}
            - {t("INFO_CHARGE.INIT")} {formattedStringStart}{" "}
          </IonLabel>
        )}
        <div className={isActive ? "containerDisponibilidad containerLabel" : "containerOcupado containerLabel"}>
          {isActive && <IonLabel className="labelDisponible">{t("BOOKING_INFO.ACTIVE")}</IonLabel>}
          {!isActive && <IonLabel className="labelOcupado">{t("BOOKING_INFO.COMPLETED")}</IonLabel>}
        </div>
      </IonCol>
    </IonRow>
  );
};
