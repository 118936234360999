import { VehicleBrand, VehicleBrandModel, VehicleFormModel } from "@/core/models/userModel";
import type { IonInputCustomEvent, IonSelectCustomEvent } from "@ionic/core";
import { IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ThirdStepProps {
  values: {
    cardId: string;
    brand: string;
    model: string;
    brandId?: number;
    modelId?: number;
  };
  vehicleBrands: VehicleBrand[];
  vehicleModels?: Record<string, VehicleBrandModel[]>;
  guestVehicle?: VehicleFormModel;
  onStringsReceived: (cardId: string, brand: string, model: string, brandId?: number, modelId?: number) => void;
}

export const ThirdStep = ({
  values,
  vehicleBrands,
  vehicleModels,
  onStringsReceived,
  guestVehicle,
}: ThirdStepProps) => {
  const { t } = useTranslation();

  const [carId, setCardId] = useState(values.cardId);
  const [selectedBrandId, setSelectedBrandId] = useState<number | undefined>(values.brandId);
  const [selectedModelId, setSelectedModelId] = useState<number | undefined>(values.modelId);
  const [selectedBrand, setSelectedBrand] = useState(values.brand);
  const [selectedModel, setSelectedModel] = useState(values.model);

  useEffect(() => {
    onStringsReceived(carId, selectedBrand, selectedModel, selectedBrandId, selectedModelId);
  }, [carId, selectedBrand, selectedModel, selectedBrandId, selectedModelId]);

  useEffect(() => {
    if (guestVehicle) {
      const { carId, vehicleBrandId, vehicleModelId, vehicle, model } = guestVehicle;
      setCardId(carId);
      setSelectedBrandId(vehicleBrandId);
      setSelectedModelId(vehicleModelId);
      setSelectedBrand(vehicle || "");
      setSelectedModel(model || "");
    }
  }, [guestVehicle]);

  const handleChange = (e: IonInputCustomEvent<any> | IonSelectCustomEvent<any>) => {
    const { name, value } = e.target;

    if (name === "selectedBrandId") {
      setSelectedBrandId(value);
      setSelectedModelId(undefined);
      setSelectedBrand("");
      setSelectedModel("");
      return;
    }

    if (name === "selectedModelId") {
      setSelectedModelId(value);
      setSelectedModel("");
      return;
    }

    if (name === "selectedBrand") {
      setSelectedBrand(value);
      return;
    }

    if (name === "selectedModel") {
      setSelectedModel(value);
      return;
    }

    if (name === "carId") {
      setCardId(value);
      return;
    }
  };

  return (
    <>
      <IonRow className="ion-margin-bottom">
        <IonLabel className="neutralBlack70">
          {t("STEPS.STEP")} <IonLabel className="number number-bold">3</IonLabel> {t("STEPS.OUT_OF")}{" "}
          <IonLabel className="number number-bold">6</IonLabel>
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel className="titleH3">{t("TITLES.VEHICLE")}</IonLabel>
      </IonRow>

      <IonItem>
        <IonInput
          name="carId"
          label={`${t("CAR.LICENSE_PLATE")} *`}
          labelPlacement="floating"
          type="text"
          value={carId}
          onIonInput={handleChange}
        />
      </IonItem>

      <IonItem className="ion-margin-top">
        <IonSelect
          name="selectedBrandId"
          value={selectedBrandId}
          onIonChange={handleChange}
          placeholder={selectedBrand ? "" : `${t("CAR.BRAND")} *`}
          label={`${t("CAR.BRAND")} *`}
          labelPlacement="floating"
        >
          {vehicleBrands.map((brand) => (
            <IonSelectOption key={brand.id} value={brand.id}>
              {brand.name}
            </IonSelectOption>
          ))}
          <IonSelectOption value={0}>Otro</IonSelectOption>
        </IonSelect>
      </IonItem>

      {selectedBrandId !== 0 && (
        <IonItem className="ion-margin-top">
          <IonSelect
            name="selectedModelId"
            label={`${t("CAR.MODEL")} *`}
            labelPlacement="floating"
            value={selectedModelId}
            onIonChange={handleChange}
          >
            {vehicleModels?.[selectedBrandId || 0]?.map((model) => {
              return (
                <IonSelectOption key={model.id} value={model.id}>
                  {model.name}
                </IonSelectOption>
              );
            })}
            <IonSelectOption value={0}>{t("CAR.OTHER")}</IonSelectOption>
          </IonSelect>
        </IonItem>
      )}
      {selectedBrandId === 0 && (
        <IonItem className="ion-margin-top">
          <IonInput
            name="selectedBrand"
            value={selectedBrand}
            onIonInput={handleChange}
            label={`${t("CAR.BRAND")} *`}
            labelPlacement="floating"
          />
        </IonItem>
      )}
      {(selectedBrandId === 0 || selectedModelId === 0) && (
        <IonItem className="ion-margin-top">
          <IonInput
            name="selectedModel"
            value={selectedModel}
            onIonInput={handleChange}
            label={`${t("CAR.MODEL")} *`}
            labelPlacement="floating"
            placeholder={t("PLACEHOLDERS.MODEL")}
          />
        </IonItem>
      )}
    </>
  );
};
