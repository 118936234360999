import i18n from "@/assets/i18n/i18n";
import NoLocation from "@/assets/imgs/icons/noLocation.svg?react";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { useIsSocketAvailable } from "@/commons/hooks/useIsSocketAvailable";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useQuery } from "@/commons/hooks/useQuery";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { buildURLFilters } from "@/commons/utils/locationUtils/locationUtil";
import { LocationContext } from "@/context/locationContext";
import { ErrorModel } from "@/core/models/apiModel";
import { CHARGER_SOCKET_RUNTIME_STATUS } from "@/core/models/chargerModel";
import { getLocationService } from "@/core/services/location/locationServices";
import { mapActions } from "@/core/store/slices/mapSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { App } from "@capacitor/app";
import { PluginListenerHandle } from "@capacitor/core";
import {
  IonAccordion,
  IonAccordionGroup,
  IonChip,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
} from "@ionic/react";
import { t } from "i18next";
import { closeCircle, closeOutline, funnel, lockClosed } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ChargerInfo } from "./ChargerInfo/ChargerInfo";
import "./LocationDetail.scss";
import { LocationDetailSkeleton } from "./LocationDetailSkeleton/LocationDetailSkeleton";
import { LocationMoreInfo } from "./LocationMoreInfo/LocationMoreInfo";
import { Rates } from "./Rates/Rates";
import { SocketBusy } from "./SocketBusy/SocketBusy";

export const LocationDetail = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { query, searchParams } = useQuery();
  const { isSocketAvailable } = useIsSocketAvailable();

  const { userLocation } = useContext(LocationContext);
  const {
    parameters: { token_api, refresh_location },
  } = useParametersContext();

  const { locationId, locationInfo, selectedCharger, selectedSocket, options } = useAppSelector(
    (state) => state.mapReducer.selectedLocation,
  );
  const { activeFilters } = useAppSelector((state) => state.mapReducer.filters);
  const { user } = useAppSelector((state) => state.userReducer);

  const { showLocationDetail, showSocketBusy } = options;

  const [listenerResume, setListenerResume] = useState<PluginListenerHandle | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState<number>();

  const modalRef = useRef<HTMLIonModalElement>(null);

  // Load location info
  useEffect(() => {
    showLocationDetail && !showSocketBusy && loadData();
  }, [locationId, showLocationDetail, showSocketBusy, query, activeFilters]);

  // Load location info on resume and interval
  useEffect(() => {
    // Remove previous listener
    listenerResume && listenerResume.remove();

    const getListener = async () => {
      const listener = await App.addListener("resume", () => {
        showLocationDetail && loadData();
      });

      setListenerResume(listener);
    };

    getListener();

    // Interval reload
    intervalId && clearInterval(intervalId);

    const interval = setInterval(() => {
      showLocationDetail && loadData(false);
    }, refresh_location * 1000);
    setIntervalId(interval);

    return () => {
      listenerResume && listenerResume.remove();
      interval && clearInterval(interval);
    };
  }, [showLocationDetail, locationId, query, selectedCharger, selectedSocket, activeFilters]);

  useEffect(() => {
    if (!showLocationDetail) {
      // Remove listener, interval and hide footer on unmount
      listenerResume && listenerResume.remove();
      intervalId && clearInterval(intervalId);
    }
  }, [showLocationDetail, listenerResume, intervalId]);

  const loadData = async (showLoading = true) => {
    if (locationId && userLocation.latitude !== null && userLocation.longitude !== null) {
      try {
        showLoading && setIsLoading(true);

        let newLocationInfo = await getLocationService(
          token_api,
          user.auth_token,
          locationId,
          userLocation.latitude,
          userLocation.longitude,
          buildURLFilters(activeFilters),
        );
        setErrorStatus(undefined);

        newLocationInfo = {
          ...newLocationInfo,
          chargers: newLocationInfo.chargers.map((charger) => ({
            ...charger,
            payment_methods: charger.payment_methods?.map(
              (paymentMethod) => (paymentMethod === "CARD" ? "CREDITCARD" : paymentMethod), // replace CARD by CREDITCARD
            ),
          })),
        };

        dispatch(mapActions.setLocationInfo(newLocationInfo));

        // Set selected charger and socket
        if (query.chargerId && query.socketNumber) {
          const newSelectedCharger = newLocationInfo.chargers.find((charger) => charger.id_charger === query.chargerId);
          const newSelectedSocket = newSelectedCharger?.charger_sockets.find(
            (socket) => socket.socket_number === query.socketNumber,
          );

          if (newSelectedCharger && newSelectedSocket) {
            const statusAvailable = isSocketAvailable(newSelectedSocket);
            const ownReservation =
              newSelectedSocket.runtime_status_id === CHARGER_SOCKET_RUNTIME_STATUS.RESERVED &&
              newSelectedSocket.reservation_id;
            if (statusAvailable || ownReservation) {
              dispatch(
                mapActions.setSelectedSocket({
                  selectedCharger: newSelectedCharger,
                  selectedSocket: newSelectedSocket,
                }),
              );
            }
          }
        }

        // Update selected charger and socket
        if (selectedCharger && selectedSocket) {
          const newSelectedCharger = newLocationInfo.chargers.find(
            (charger) => charger.id_charger === selectedCharger.id_charger,
          );
          const newSelectedSocket = newSelectedCharger?.charger_sockets.find(
            (socket) => socket.id_socket === selectedSocket.id_socket,
          );

          if (newSelectedCharger && newSelectedSocket) {
            dispatch(
              mapActions.setSelectedSocket({ selectedCharger: newSelectedCharger, selectedSocket: newSelectedSocket }),
            );
          }
        }
      } catch (error) {
        const newError = error as ErrorModel;
        console.error("Error loading location info", newError);
        setErrorStatus(newError.status);
      } finally {
        showLoading && setIsLoading(false);
      }
    }
  };

  // open full modal when when open more info
  const handleOpenMoreInfo = (e: CustomEvent) => {
    if (modalRef.current && e.detail.value === "open") {
      modalRef.current.setCurrentBreakpoint(1);
    }
  };

  const handleDismissModal = (cleanLocationParam?: boolean) => {
    dispatch(mapActions.setLocationOptions({ ...options, showLocationDetail: false, showRates: false }));

    if (cleanLocationParam) {
      searchParams.delete("locationId");
      searchParams.delete("chargerId");
      searchParams.delete("socketNumber");
      history.replace({ search: searchParams.toString() });
    }
  };

  const clearFilters = () => {
    dispatch(mapActions.deleteActiveFilters());
  };

  return (
    <IonModal
      ref={modalRef}
      initialBreakpoint={0.7}
      breakpoints={[0, 0.7, 1]}
      isOpen={showLocationDetail}
      onWillDismiss={(e) => handleDismissModal(["gesture", "backdrop"].includes(e.detail.role || ""))}
      className={`location-detail ${classIf(!!locationId, "is-open")}`}
      style={{
        marginBottom:
          (!selectedCharger && locationInfo?.near) || !locationInfo
            ? "0px"
            : `calc(76px + var(--ion-safe-area-bottom, 0))`,
      }}
    >
      <AppHeader
        hiddenMenu
        hiddenTitle
        endIcon={<IconButton color="dark" icon={closeOutline} onClick={() => handleDismissModal(true)} />}
      />
      {isLoading && <LocationDetailSkeleton />}

      <Rates />
      {!isLoading && showLocationDetail && (
        <>
          <IonContent>
            <div className="location-detail-content">
              {locationInfo && !locationInfo.not_visible_private ? (
                <>
                  <IonRow className="location-detail-header">
                    <IonLabel className="location-detail-address">{locationInfo.address}</IonLabel>
                    {locationInfo.green_energy && (
                      <div className="green-energy">
                        <div className="text">
                          <div className="energy">{t("LOCATION.ENERGY")}</div>
                          <div>100%</div>
                          <div className={`renewable ${i18n.language}`}>{t("LOCATION.RENEWABLE")}</div>
                        </div>
                      </div>
                    )}
                  </IonRow>
                  <IonAccordionGroup onIonChange={handleOpenMoreInfo}>
                    <IonAccordion value="open" toggleIconSlot="start">
                      <IonItem slot="header">
                        <IonLabel>{t("BUTTONS.MORE_INFO")}</IonLabel>
                      </IonItem>
                      <div className="ion-padding" slot="content">
                        <LocationMoreInfo locationInfo={locationInfo} />
                      </div>
                    </IonAccordion>
                  </IonAccordionGroup>
                  {!!activeFilters.filterNumber && (
                    <IonChip onClick={clearFilters}>
                      <IonIcon icon={funnel} />
                      <IonLabel>{t("LOCATION.CLEAR_FILTERS")}</IonLabel>
                      <IonIcon icon={closeCircle} />
                    </IonChip>
                  )}

                  <IonRow className="location-detail-chargers">
                    {locationInfo.chargers.map((charger) => (
                      <ChargerInfo key={charger.id_charger} charger={charger} />
                    ))}
                  </IonRow>
                  <SocketBusy />
                </>
              ) : (
                <div className="no-location">
                  <IonRow>
                    {locationInfo?.not_visible_private ? (
                      <IonIcon className="icon" icon={lockClosed} />
                    ) : (
                      <NoLocation className="icon" />
                    )}
                  </IonRow>
                  <IonRow>
                    <IonLabel className="no-location-text">
                      {t(
                        `ERRORS.${locationInfo?.not_visible_private ? "PRIVATE" : errorStatus === 404 ? "NO_LOCATION" : "LOAD_LOCATION"}`,
                      )}
                    </IonLabel>
                  </IonRow>
                </div>
              )}
            </div>
          </IonContent>
        </>
      )}
    </IonModal>
  );
};
