import { LOCATION_MAP_STATUS } from "@/core/models/locationModel";
import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import "./CustomMarker.scss";

interface CustomMarkerProps {
  number?: number;
  heading?: number | null;
  selectedLocation?: boolean;
  locationStatus?: LOCATION_MAP_STATUS;
}

export const CustomMarker = ({ number, selectedLocation, locationStatus }: CustomMarkerProps) => {
  const LOCATION_STATUS_CLASS: Record<number, string> = {
    [LOCATION_MAP_STATUS.AVAILABLE]: "available",
    [LOCATION_MAP_STATUS.CHARGING]: "charging",
    [LOCATION_MAP_STATUS.RESERVED]: "reserved",
  };

  const locationStatusId = locationStatus ?? -1;

  const locationStatusClass = LOCATION_STATUS_CLASS[locationStatusId] || "not-available";

  return divIcon({
    className: `custom-marker`,
    html: renderToStaticMarkup(
      <div className={`location-container ${locationStatusClass}`}>
        <div className="location-border" />
        <div className="location-center">{number}</div>
        {selectedLocation && (
          <div className="location-close-icon">
            <IonIcon icon={close} />
          </div>
        )}
      </div>,
    ),
    iconSize: [30, 30],
    iconAnchor: [15, 15],
  });
};
