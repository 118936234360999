import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { AddCreditCardGateway } from "@/commons/components/Payments/AddCreditCardGateway/AddCreditCardGateway";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonContent, IonPage } from "@ionic/react";
import { t } from "i18next";
import { useHistory } from "react-router-dom";

export const WalletAddCreditCardPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleCallbackAddCard = () => {
    dispatch(uiActions.setToast({ show: true, color: "success", message: t("CREDIT_CARD.CREDIT_CARD_ADDED") }));
    history.goBack();
  };
  return (
    <IonPage>
      <AppHeader startIcon={<GoBackButton />} title={t("BUTTONS.ADD_CARD")} />
      <IonContent>
        <div className="ion-margin" style={{ height: "100%" }}>
          <AddCreditCardGateway callbackAddCard={handleCallbackAddCard} />
        </div>
      </IonContent>
    </IonPage>
  );
};
