import { RouteMapModal } from "@/commons/components/Map/RouteMapModal/RouteMapModal";
import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonCol, IonFooter, IonIcon, IonItem, IonLabel, IonRow, IonToolbar } from "@ionic/react";
import { helpCircleOutline, navigateCircleOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { ScanButton } from "../ScanButton/ScanButton";
import "./MapFooter.scss";

export const MapFooter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setLogEvent } = useFirebaseAnalytics();

  const { isUserLogged } = useAppSelector((state) => state.userReducer);
  const { isKeyboardOpen } = useAppSelector((state) => state.uiReducer);
  const { showRouteMenu } = useAppSelector((state) => state.mapReducer.routeInfo);

  const handleOpenRoute = () => {
    if (!isUserLogged) {
      dispatch(uiActions.setAlertType({ type: "alertRegister" }));
      return;
    }

    setLogEvent("open_route");
    dispatch(mapActions.setShowRouteMenu(true));
  };

  const handleShowHelp = () => {
    dispatch(uiActions.showHelp({ charger: null, step: 0 }));
  };

  return (
    <>
      {showRouteMenu && <RouteMapModal />}

      {!isKeyboardOpen && (
        <>
          <ScanButton />
          <IonFooter className="footer ion-no-border">
            <IonToolbar>
              <IonRow className="footer-row">
                <IonItem lines="none" onClick={handleOpenRoute} routerDirection="none" mode="md">
                  <IonCol style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <IonRow className="ion-justify-content-center">
                      <IonIcon
                        color="primary"
                        icon={navigateCircleOutline}
                        size="large"
                        className="icon-footer"
                      ></IonIcon>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonLabel className="body2 footerLabel">{t("MENU.ROUTE")}</IonLabel>
                    </IonRow>
                  </IonCol>
                </IonItem>
                <IonItem lines="none"></IonItem>
                <IonItem lines="none" onClick={handleShowHelp} mode="md">
                  <IonCol style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <IonRow className="ion-justify-content-center">
                      <IonIcon color="primary" src={helpCircleOutline} size="large" className="icon-footer"></IonIcon>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonLabel className="body2 footerLabel">{t("MENU.HELP")}</IonLabel>
                    </IonRow>
                  </IonCol>
                </IonItem>
              </IonRow>
            </IonToolbar>
          </IonFooter>
        </>
      )}
    </>
  );
};
