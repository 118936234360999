import { LOGINS_TYPES } from "@/commons/constants/login";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { IonButton, IonIcon } from "@ionic/react";
import { logoApple } from "ionicons/icons";
import { jwtDecode } from "jwt-decode";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginUserInfo } from "../LoginSocial";
const { VITE_APP_ID } = import.meta.env;

interface LoginAppleProps {
  checkRegistered: (userInfo: LoginUserInfo, type: LOGINS_TYPES) => void;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
}

export const LoginApple = ({ checkRegistered, setShowAlert }: LoginAppleProps) => {
  const { t } = useTranslation();

  const [disableButton, setDisableButton] = useState(false);

  const loginApple = async () => {
    try {
      setDisableButton(true);
      const { response } = await SignInWithApple.authorize({
        clientId: VITE_APP_ID || "",
        redirectURI: "",
        scopes: "email name",
        state: "12345",
        nonce: "nonce",
      });

      let { email } = response;
      const { givenName, familyName, identityToken: accessToken, user: id_apple_user } = response;

      if (!accessToken || !id_apple_user) {
        throw new Error(t("APP_ERRORS.API"));
      }

      if (!email) {
        const decoded: { email: string } = jwtDecode(accessToken);
        email = decoded.email;
      }

      let surname1 = "";
      let surname2 = "";

      if (familyName) {
        const surnames = familyName.split(" ");
        surname1 = surnames[0] || "";
        surname2 = surnames[1] || "";
      }

      const userInfo = {
        email,
        name: givenName || email,
        surname1,
        surname2,
        password: Math.random().toString(36).slice(-10),
        accessToken,
        id_apple_user,
      };

      checkRegistered(userInfo, LOGINS_TYPES.APPLE);
    } catch (e) {
      const { error } = e as { error: string };

      if (["popup_closed_by_user"].includes(error)) return;

      console.error("Error login apple:", e);
      setShowAlert(true);
    } finally {
      setDisableButton(false);
    }
  };

  return (
    <IonButton className="buttonSecondary" onClick={loginApple} disabled={disableButton}>
      <IonIcon icon={logoApple} slot="start" className="apple-icon" />
      {t("BUTTONS.LOGIN_APPLE")}
    </IonButton>
  );
};
