import { SelectInput } from "@/commons/components/Form/SelectInput/SelectInput";
import { SPAIN_CODE } from "@/commons/constants/personalData";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { CountryModel, DocumentTypeModel, UserInfoModel } from "@/core/models/userModel";
import { IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { t } from "i18next";
import { TargetFormType } from "../PersonalDataPage";

interface UserInfoInputsProps {
  showErrors: boolean;
  userInfoTemp: UserInfoModel;
  documentTypes: DocumentTypeModel[];
  countries: CountryModel[];
  handleChangeForm: (target: TargetFormType) => void;
}
export const UserInfoInputs = ({
  showErrors,
  userInfoTemp,
  documentTypes,
  countries,
  handleChangeForm,
}: UserInfoInputsProps) => {
  const { isEmpty, getLabel, checkRequiredFields, isValidSpanishDNI } = useIsValidUser();

  return (
    <div className="user-info">
      <IonLabel className="title-h3">{t("TITLES.USER_DATA")}</IonLabel>
      <IonItem lines="full" className="ion-margin-top">
        <IonInput
          name="email"
          label={getLabel("email")}
          labelPlacement="floating"
          disabled
          onIonInput={(event) => handleChangeForm(event.target)}
          type="text"
          value={userInfoTemp.email}
        />
      </IonItem>
      {isEmpty(userInfoTemp.email) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full" className="ion-margin-top">
        <IonInput
          name="name"
          label={getLabel("name")}
          labelPlacement="floating"
          onIonInput={(event) => handleChangeForm(event.target)}
          type="text"
          value={userInfoTemp.name}
        />
      </IonItem>
      {isEmpty(userInfoTemp.name) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full" className="ion-margin-top">
        <IonInput
          name="surname1"
          label={getLabel("surname1")}
          labelPlacement="floating"
          onIonInput={(event) => handleChangeForm(event.target)}
          type="text"
          value={userInfoTemp.surname1}
        />
      </IonItem>
      {isEmpty(userInfoTemp.surname1) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full" className="ion-margin-top">
        <IonInput
          name="surname2"
          label={getLabel("surname2")}
          labelPlacement="floating"
          onIonInput={(event) => handleChangeForm(event.target)}
          type="text"
          value={userInfoTemp.surname2}
        />
      </IonItem>
      {checkRequiredFields("surname2") && isEmpty(userInfoTemp.surname2) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full">
        <IonInput
          className="ion-margin-top"
          name="telf"
          label={getLabel("telf")}
          labelPlacement="floating"
          inputMode="tel"
          onIonInput={(event) => handleChangeForm(event.target)}
          type="number"
          value={userInfoTemp.telf}
        />
      </IonItem>
      {checkRequiredFields("telf") && isEmpty(userInfoTemp.telf) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <SelectInput
        name="document_country"
        className="ion-margin-top"
        label={getLabel("document_country")}
        placeholder={getLabel("document_country", "placeholder")}
        isClearable
        loadData={async () =>
          countries.map((country) => ({ value: country.id, label: `${country.flag} ${country.name}` }))
        }
        currentValue={userInfoTemp.document_country}
        onIonChange={(event) => handleChangeForm(event.target)}
      />
      {checkRequiredFields("document_country") && isEmpty(userInfoTemp.document_country) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full" className="ion-margin-top">
        <IonSelect
          name="document_type"
          label={getLabel("document_type")}
          labelPlacement="floating"
          value={userInfoTemp.document_type}
          onIonChange={(event) => handleChangeForm(event.target)}
        >
          <IonSelectOption />
          {documentTypes
            .filter((documentType) => !documentType.hidden)
            .map((documentType) => (
              <IonSelectOption key={documentType.id} value={documentType.id}>
                {documentType.name}
              </IonSelectOption>
            ))}
        </IonSelect>
      </IonItem>
      {checkRequiredFields("document_type") && isEmpty(userInfoTemp.document_type) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
      <IonItem lines="full">
        <IonInput
          className="ion-margin-top"
          name="dni_nif"
          label={getLabel("dni_nif")}
          labelPlacement="floating"
          onIonInput={(event) => handleChangeForm(event.target)}
          type="text"
          value={userInfoTemp.dni_nif}
        />
      </IonItem>
      {userInfoTemp.document_type === "dni" &&
        userInfoTemp.document_country === SPAIN_CODE &&
        !isEmpty(userInfoTemp.dni_nif) &&
        !isValidSpanishDNI(userInfoTemp.dni_nif) &&
        showErrors && (
          <div>
            <IonLabel className="form-error">{t("ERRORS.DNI")}</IonLabel>
          </div>
        )}

      {checkRequiredFields("dni_nif") && isEmpty(userInfoTemp.dni_nif) && showErrors && (
        <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
      )}
    </div>
  );
};
