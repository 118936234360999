import { mapActions } from "@/core/store/slices/mapSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonLabel, IonToggle, ToggleCustomEvent } from "@ionic/react";
import { t } from "i18next";

export const FiltersToggles = () => {
  const dispatch = useAppDispatch();

  const { selectedFilters } = useAppSelector((state) => state.mapReducer.filters);

  const handleToggleSwitch = (event: ToggleCustomEvent<string>) => {
    const { target, detail } = event;

    dispatch(
      mapActions.setSelectedFilters({
        ...selectedFilters,
        [target.name]: detail.checked,
        filterNumber: detail.checked ? selectedFilters.filterNumber + 1 : selectedFilters.filterNumber - 1,
      }),
    );
  };
  return (
    <div className="filter-list-toggle">
      <div className="filter-toggle">
        <div>
          <IonLabel>{t("FILTERS.TOGGLE_AVAILABLE")}</IonLabel>
        </div>
        <div className="filter-toggle-switch">
          <IonToggle mode="ios" name="available" checked={selectedFilters.available} onIonChange={handleToggleSwitch} />
        </div>
      </div>
      <div className="filter-toggle">
        <div>
          <IonLabel>{t("FILTERS.TOGGLE_FREE")}</IonLabel>
        </div>
        <div className="filter-toggle-switch">
          <IonToggle mode="ios" name="free" checked={selectedFilters.free} onIonChange={handleToggleSwitch} />
        </div>
      </div>
    </div>
  );
};
