import NoConnection from "@/assets/imgs/icons/noConnection.svg?react";
import NoLocation from "@/assets/imgs/icons/noLocation.svg?react";
import { useLocationContext } from "@/commons/hooks/useLocationContext";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { MapPage } from "@/pages/MapPage/MapPage";
import { APP_PATH } from "@/router/routes";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { IonButton, IonContent, IonIcon, IonLabel, IonPage, IonRow, IonSpinner, useIonRouter } from "@ionic/react";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { t } from "i18next";
import { cloudDownloadOutline, refreshOutline, settingsOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { gte } from "semver";
import "./HomePage.scss";

const { VITE_PACKAGE_VERSION, VITE_APP_ID } = import.meta.env;

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();

  const { userLocation, permissionStatus } = useLocationContext();
  const { parameters } = useParametersContext();
  const haveParameters = parameters.id !== -1;

  const { userInfo, seenOnboarding } = useAppSelector((state) => state.userReducer);
  const { isLoadingApp } = useAppSelector((state) => state.uiReducer);

  const [haveMinVersion, setHaveMinVersion] = useState(false);
  const [version, setVersion] = useState("");

  useEffect(() => {
    dispatch(uiActions.setLoadingApp(true));

    const timeoutId = setTimeout(() => {
      dispatch(uiActions.setLoadingApp(false));
      console.info("Timeout loading app");
    }, 15 * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (!userLocation.isLoading && userInfo && haveParameters) {
      dispatch(uiActions.setLoadingApp(false));
    }
  }, [userLocation.isLoading, userInfo, parameters, haveMinVersion]);

  useEffect(() => {
    if (haveParameters && !seenOnboarding) {
      router.push(APP_PATH.ONBOARDING, "root", "replace");
    }
  }, [haveParameters, seenOnboarding]);

  useEffect(() => {
    setVersionApp();
  }, [parameters.min_version]);

  useEffect(() => {
    // Agregar el listener del botón de retroceso
    const listenerPromises = App.addListener("backButton", () => {
      const platform = Capacitor.getPlatform();
      if (platform === "android") {
        if (window.location.pathname === APP_PATH.HOME && !window.location.search && !router.canGoBack()) {
          App.exitApp();
        } else {
          window.history.back();
        }
      }
    });

    return () => {
      listenerPromises.then((listener) => listener.remove());
    };
  }, []);

  const handleReloadPage = () => {
    window.location.reload();
  };

  const handleOpenSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  const setVersionApp = async () => {
    dispatch(uiActions.setLoadingApp(true));

    let newVersion = VITE_PACKAGE_VERSION || "";

    if (Capacitor.getPlatform() !== "web") {
      newVersion = (await App.getInfo()).version;
    }

    setVersion(newVersion);

    setHaveMinVersion(!!parameters.min_version && gte(newVersion, parameters.min_version));
  };

  const handleOpenStore = async () => {
    const androidUrl = `https://play.google.com/store/apps/details?id=${VITE_APP_ID}`;
    if (Capacitor.getPlatform() === "web") {
      return window.open(androidUrl, "_blank");
    }

    try {
      await AppUpdate.openAppStore();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
      return window.open(androidUrl, "_blank");
    }
  };
  return (
    <>
      {!isLoadingApp &&
      permissionStatus.location === "granted" &&
      seenOnboarding &&
      haveParameters &&
      haveMinVersion ? (
        <MapPage />
      ) : (
        <IonPage>
          <IonContent>
            <div className="home-page">
              {isLoadingApp ? (
                <div className="home-page-loading">
                  <IonSpinner />
                </div>
              ) : (
                <div className="home-page-fallback">
                  {!haveParameters ? (
                    <>
                      <NoConnection className="icon" />
                      <IonRow>
                        <IonLabel>
                          <strong>{t("ALERT.PARAMETERS_ERROR_TITLE")}</strong>
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonLabel>{t("ALERT.PARAMETERS_ERROR_TEXT")}</IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonButton onClick={handleReloadPage}>
                          <IonIcon icon={refreshOutline} slot="start" />
                          {t("BUTTONS.RETRY")}
                        </IonButton>
                      </IonRow>
                    </>
                  ) : !haveMinVersion ? (
                    <>
                      <IonIcon icon={cloudDownloadOutline} className="icon" />
                      <IonRow>
                        <IonLabel>
                          <strong>{t("ALERT.VERSION_ERROR_TITLE")}</strong>
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonLabel>
                          {t("ALERT.VERSION_ERROR_TEXT", { version, minVersion: parameters.min_version })}
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonButton onClick={handleOpenStore}>{t("BUTTONS.UPDATE")}</IonButton>
                      </IonRow>
                    </>
                  ) : (
                    <>
                      <NoLocation className="icon" />
                      <IonRow>
                        <IonLabel>{t("ALERT.NO_LOCATION_PERMISSION")}</IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonButton onClick={handleOpenSettings}>
                          <IonIcon icon={settingsOutline} slot="start" />
                          {t("BUTTONS.CONFIGURATION")}
                        </IonButton>
                      </IonRow>
                    </>
                  )}
                </div>
              )}
            </div>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};
