import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { Illustration13 } from "@/commons/constants/imgs";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { LocationContext } from "@/context/locationContext";
import { ChargerModel } from "@/core/models/chargerModel";
import { getFavoriteChargersService } from "@/core/services/location/locationServices";
import { setUserFavoriteChargerService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  useIonViewDidEnter,
} from "@ionic/react";
import { t } from "i18next";
import { createOutline } from "ionicons/icons";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { FavoriteCard } from "./FavoriteCard";
import { FavoriteCardSkeleton } from "./FavoriteCardSkeleton";
import "./FavoritesPage.scss";

export const FavoritesPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useAppSelector((state) => state.userReducer);
  const { userLocation } = useContext(LocationContext);
  const { parameters } = useParametersContext();

  const [isLoading, setIsLoading] = useState(true);
  const [favoriteChargers, setFavoriteChargers] = useState<ChargerModel[]>([]);
  const [showDelete, setShowDelete] = useState(false);

  useIonViewDidEnter(() => {
    setShowDelete(false);
    loadData();
  }, [userLocation.latitude, userLocation.longitude]);

  const loadData = async () => {
    try {
      setIsLoading(true);

      if (userLocation.latitude === null || userLocation.longitude === null) {
        return dispatch(uiActions.setToastError(t("APP_ERRORS.API")));
      }

      const { fav_chargers } = await getFavoriteChargersService(
        userLocation.latitude,
        userLocation.longitude,
        user.auth_token,
        parameters.token_api,
      );
      setFavoriteChargers(fav_chargers || []);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCharger = (chargerId: string) => {
    dispatch(
      uiActions.setAlertType({
        type: "deleteFavoriteCharger",
        options: {
          primaryAction: () => unMarkCharger(chargerId),
        },
      }),
    );
  };

  const unMarkCharger = async (chargerId: string) => {
    try {
      setIsLoading(true);
      await setUserFavoriteChargerService(chargerId, false);
      dispatch(uiActions.setToastType({ type: "successDeleteFavoriteCharger" }));

      await loadData();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoHome = () => {
    history.push(APP_PATH.HOME);
  };

  return (
    <IonPage>
      <AppHeader
        title={t("MENU.FAVOURITES")}
        endIcon={
          favoriteChargers.length > 0 && (
            <IonCol
              className="ion-justify-content-center toolbarCol editCol"
              onClick={() => setShowDelete(!showDelete)}
              style={{ width: "fit-content", paddingTop: "5px" }}
            >
              <IonIcon icon={createOutline} size="large"></IonIcon>
              <IonLabel className="filtrosLabel">{t("TITLES.EDIT")}</IonLabel>
            </IonCol>
          )
        }
      />
      <IonContent>
        {isLoading ? (
          <>
            <FavoriteCardSkeleton />
            <FavoriteCardSkeleton />
            <FavoriteCardSkeleton />
            <FavoriteCardSkeleton />
            <FavoriteCardSkeleton />
          </>
        ) : (
          <>
            {favoriteChargers.length === 0 ? (
              <IonList className="listReservationsContainer ion-padding">
                <Illustration13 className="imageReservations" />
                <IonList className="listReservationsContainer textReservation">
                  <IonLabel className="titleKeychain titleReservations">{t("FAVORITES.ANY_FAV")}</IonLabel>
                  <IonText>{t("FAVORITES.ANY_FAV_TEXT")}</IonText>
                </IonList>
                <IonButton style={{ height: "124px" }} onClick={handleGoHome}>
                  {t("BUTTONS.SEARCH_CHARGE_POINT")}
                </IonButton>
              </IonList>
            ) : (
              favoriteChargers.map((charger) => (
                <FavoriteCard
                  key={charger.id_charger}
                  charger={charger}
                  showDelete={showDelete}
                  onDeleteCharger={handleDeleteCharger}
                />
              ))
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};
