import { SPAIN_CODE } from "@/commons/constants/personalData";
import { UserInfoModel } from "@/core/models/userModel";
import { useAppSelector } from "@/core/store/store";
import { t } from "i18next";

export const useIsValidUser = () => {
  const { requiredFields, requiredFieldsForm } = useAppSelector((state) => state.userFormReduce);

  const checkRequiredFields = (field: string, isGlobalUser = false) =>
    isGlobalUser ? requiredFields.includes(field) : requiredFieldsForm.includes(field);

  const isEmpty = (value?: string | number | null) => value === undefined || value === null || `${value}`.trim() === "";

  const haveError = (key: string, value: string | number | null, isGlobalUser = false) =>
    checkRequiredFields(key, isGlobalUser) && isEmpty(value);

  const getLabel = (field: string, parent?: string) =>
    `${t(`PROFILE.${field.toUpperCase()}`)} ${checkRequiredFields(parent ? `${parent}.${field}` : field) ? "*" : ""}`;

  const checkValidVehicle = (userInfo: UserInfoModel) => {
    const haveBrandId = userInfo.vehicle_brand;
    const haveModelId = userInfo.vehicle_model;
    const haveBrand = userInfo.vehicle;
    const haveModel = userInfo.model;

    if (!userInfo.vehicle_id) return false;

    if (!haveBrandId && (!haveBrand || !haveModel)) return false;

    if (!haveModelId && !haveModel) return false;

    return true;
  };

  const isValidSpanishDNI = (dni: string) => {
    const dniRegex = /^\d{1,8}[a-zA-Z]$/;

    if (!dniRegex.test(dni)) return false;

    // Extract the numbers and the letter
    const numbers = dni.slice(0, -1);
    const letter = dni.slice(-1).toUpperCase();

    // Calculate the expected letter
    const validLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
    const expectedLetter = validLetters[parseInt(numbers, 10) % 23];

    // Check if the provided letter matches the expected letter
    return letter === expectedLetter;
  };

  const checkValidUser = (userInfo: UserInfoModel, isGlobalUser = false) => {
    let validUser = true;
    const isBillingUser =
      (userInfo.billing_user?.billing_type && userInfo.billing_user.billing_type !== "TICKET") ||
      userInfo.isBillingUser;

    let error = "";
    const userInfoProps = Object.entries(userInfo);
    userInfoProps.forEach(([key, value]) => {
      // Check billing_user required fields
      if (isBillingUser && key === "billing_user") {
        if (!userInfo.billing_user) {
          error = "empty billing_user";
          return (validUser = false);
        }

        const billingUserInfoProps = Object.entries(userInfo.billing_user);

        billingUserInfoProps.forEach(([billingKey, billingValue]) => {
          if (
            billingKey === "billing_type" &&
            checkRequiredFields("billing_user.billing_type") &&
            (isEmpty(billingValue) || billingValue === "TICKET")
          ) {
            error = "billing_type";
            validUser = false;
          }

          if (haveError("billing_user." + `${billingKey}`, billingValue, isGlobalUser)) {
            error = `${key}.${billingKey}`;
            validUser = false;
          }
        });
        return;
      }

      if (key === "default_charge_values") {
        const defaultChargeValuesProps = Object.entries(userInfo.default_charge_values);

        defaultChargeValuesProps.some(([defaultKey, defaultValue]) => {
          if (haveError("default_charge_values." + `${defaultKey}`, defaultValue, isGlobalUser)) {
            error = `${key}.${defaultKey}`;
            validUser = false;
          }
        });
        return;
      }

      // Check user required fields
      if (haveError(key, value, isGlobalUser)) {
        error = key;
        return (validUser = false);
      }

      if (
        key === "dni_nif" &&
        userInfo.dni_nif &&
        userInfo.document_type === "dni" &&
        userInfo.document_country === SPAIN_CODE
      ) {
        if (!isValidSpanishDNI(userInfo.dni_nif)) {
          error = "invalid DNI";
          return (validUser = false);
        }
      }
    });

    error && console.info("No valid User: ", error);

    return validUser && userInfo.privacy_policy && userInfo.terms_conditions;
  };

  return {
    checkValidUser,
    checkValidVehicle,
    isEmpty,
    checkRequiredFields,
    getLabel,
    isValidSpanishDNI,
  };
};
