import { CHARGER_INSTRUCTION_TYPES, CHARGER_INSTRUCTION_TYPES_IMAGE } from "@/commons/constants/charger";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { IonCol, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import "./InstructionCharge.scss";

interface Props {
  chargeInstructionType: CHARGER_INSTRUCTION_TYPES;
  disabled?: boolean;
}

export const InstructionCharge = ({ chargeInstructionType, disabled }: Props) => {
  const Illustration = CHARGER_INSTRUCTION_TYPES_IMAGE[chargeInstructionType];

  return (
    <IonRow className={`instruction-card ${classIf(!!disabled, "disable-card")}`}>
      <IonCol size="3.5">
        <Illustration />
      </IonCol>
      <IonCol className="instruction-card-text">
        <IonLabel>{t(`CHARGE_INSTRUCTIONS.${chargeInstructionType}`)}</IonLabel>
      </IonCol>
    </IonRow>
  );
};
