import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const useBlockNavigation = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [unsavedChange, setUnsavedChange] = useState(false);

  useEffect(() => {
    if (!unsavedChange) return;

    const unblock = history.block((location) => {
      if (unsavedChange) {
        dispatch(
          uiActions.setAlertType({
            type: "unsavedChanges",
            options: {
              primaryAction: () => {
                setUnsavedChange(false);
                unblock();
                history.push(`${location.pathname}${location.search}`);
              },
            },
          }),
        );
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [history, unsavedChange]);

  return {
    unsavedChange,
    setUnsavedChange,
  };
};
