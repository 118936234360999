import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { UserInfoModel } from "@/core/models/userModel";
import { IonInput, IonItem, IonLabel, IonRow, IonToggle } from "@ionic/react";
import { t } from "i18next";
import { TargetFormType } from "../PersonalDataPage";

interface PublicAdministrationProps {
  showErrors: boolean;
  userInfoTemp: UserInfoModel;
  handleChangeForm: (target: TargetFormType) => void;
}
export const PublicAdministration = ({ showErrors, userInfoTemp, handleChangeForm }: PublicAdministrationProps) => {
  const { parameters } = useParametersContext();
  const { isEmpty, getLabel, checkRequiredFields } = useIsValidUser();

  return (
    <>
      {parameters.show_type_client && (
        <div className="public-administration">
          <IonRow className="billing-row">
            <IonLabel className="title-h3">{t("PROFILE.PUBLIC_ADMINISTRATION")}</IonLabel>
            <IonToggle
              name="is_public_administration"
              checked={!!userInfoTemp.is_public_administration}
              onIonChange={(event) => handleChangeForm(event.target)}
            />
          </IonRow>
          {userInfoTemp.is_public_administration && (
            <>
              <IonItem lines="full">
                <IonInput
                  className="ion-margin-top"
                  name="dir3"
                  label={getLabel("dir3")}
                  labelPlacement="floating"
                  onIonInput={(event) => handleChangeForm(event.target)}
                  value={userInfoTemp.dir3}
                />
              </IonItem>
              {checkRequiredFields("dir3") && isEmpty(userInfoTemp.dir3) && showErrors && (
                <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
