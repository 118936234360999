import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { keyboardIcon } from "@/commons/constants/imgs";
import { useOpenLocation } from "@/commons/hooks/useOpenLocation";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { getLocationIdFromScanner } from "@/commons/utils/scannerUtils";
import { activePromotionalCodeService } from "@/core/services/promotionalCode/promotionalCodeServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { BarcodeFormat, BarcodeScanner, LensFacing } from "@capacitor-mlkit/barcode-scanning";
import { App } from "@capacitor/app";
import {
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { closeOutline, flashlightOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import "./QrScanner.scss";

interface QrScannerProps {
  showCamera: boolean;
  setShowCamera: (show: boolean) => void;
  setShowAddModal?: (show: boolean) => void;
  callbackSuccess?: () => void;
  type: "location" | "promotionalCode";
}

export const QrScanner = ({ showCamera, setShowCamera, setShowAddModal, callbackSuccess, type }: QrScannerProps) => {
  const dispatch = useAppDispatch();
  const { openLocation } = useOpenLocation();
  const { parameters } = useParametersContext();

  const { user } = useAppSelector((state) => state.userReducer);

  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (showCamera) {
      handleCheckPermissions();
    }
  }, [showCamera]);

  const handleCheckPermissions = async () => {
    const permsion = await BarcodeScanner.checkPermissions()
      .then((result) => {
        if (result.camera == "granted") {
          return result;
        } else {
          return BarcodeScanner.requestPermissions();
        }
      })
      .then((result) => result);

    if (permsion.camera !== "granted") {
      setShowCamera(false);

      if (type === "promotionalCode") {
        return setShowAddModal?.(true);
      }

      BarcodeScanner.openSettings();
      return;
    }

    setPermissionsGranted(true);
    scanSingleBarcode();
  };

  const scanSingleBarcode = async () => {
    try {
      document.querySelector("body")?.classList.add("qr-scanner-active");

      await BarcodeScanner.addListener("barcodeScanned", async ({ barcode }) => {
        await stopBarcodeScanner();
        try {
          setIsLoading(true);

          if (type === "promotionalCode") {
            return await handlePromotionalCode(barcode.rawValue);
          }

          if (type === "location") {
            return await handleLocation(barcode.rawValue);
          }
        } catch (error) {
          console.error(error);
          if (type === "promotionalCode") {
            return dispatch(uiActions.setToastError(error));
          }

          if (type === "location") {
            return openLocation(-1, -1);
          }
        } finally {
          setIsLoading(false);
        }
      });

      App.addListener("backButton", async () => {
        await stopBarcodeScanner();
      });

      await BarcodeScanner.startScan({
        formats: [BarcodeFormat.QrCode],
        lensFacing: LensFacing.Back,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handlePromotionalCode = async (barcode: string) => {
    await activePromotionalCodeService(barcode);
    dispatch(uiActions.setAlertType({ type: "successActivePromotion" }));
    callbackSuccess && callbackSuccess();
  };

  const handleLocation = async (barcode: string) => {
    const locatioData = await getLocationIdFromScanner(barcode, parameters.token_api, user.auth_token);

    if (locatioData) {
      openLocation(locatioData.locationId, locatioData.chargeId, locatioData.socketNumber);
    }
  };

  const stopBarcodeScanner = async () => {
    await BarcodeScanner.removeAllListeners();
    setShowCamera(false);
    document.querySelector("body")?.classList.remove("qr-scanner-active");
    await BarcodeScanner.stopScan();
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <IonModal className="qr-scanner-modal" isOpen={showCamera && permissionsGranted}>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="end">
              <IconButton icon={closeOutline} color="light" onClick={() => stopBarcodeScanner()} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonFab className="qr-scanner-modal-buttons" slot="fixed" horizontal="end" vertical="bottom">
            {type === "promotionalCode" && (
              <IonFabButton
                onClick={() => {
                  setShowAddModal?.(true);
                  stopBarcodeScanner();
                }}
              >
                <IonIcon icon={keyboardIcon} />
              </IonFabButton>
            )}
            <IonFabButton onClick={() => BarcodeScanner.toggleTorch()}>
              <IonIcon icon={flashlightOutline} />
            </IonFabButton>
          </IonFab>
        </IonContent>
      </IonModal>
    </>
  );
};
