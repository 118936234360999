import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { reservationStepsActions } from "@/core/store/slices/reservationStepsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { useEffect } from "react";
import { PaymentStepGuest } from "./PaymentStepGuest/PaymentStepGuest";
import { PaymentStepLogged } from "./PaymentStepLogged/PaymentStepLogged";

export type StepType = "charge" | "reservation";
export type StepReducer = "chargeStepsReducer" | "reservationStepsReducer";
export type StepsActions = typeof chargeStepsActions | typeof reservationStepsActions;

const stepsReducers: Record<StepType, StepReducer> = {
  charge: "chargeStepsReducer",
  reservation: "reservationStepsReducer",
};

const stepsActionsFunc: Record<StepType, StepsActions> = {
  charge: chargeStepsActions,
  reservation: reservationStepsActions,
};

interface PaymentStateProps {
  stepsType: StepType;
}

export const PaymentStep = ({ stepsType }: PaymentStateProps) => {
  const dispatch = useAppDispatch();

  const stepsReducer = stepsReducers[stepsType];
  const stepsActions = stepsActionsFunc[stepsType];

  const { isUserLogged } = useAppSelector((state) => state.userReducer);
  const { chooseStep, paymentStep } = useAppSelector((state) => state[stepsReducer]);
  const { isLoadingPrice } = chooseStep;
  const { paymentMethod, selectPaymentId } = paymentStep;

  useEffect(() => {
    if (!isLoadingPrice && (!paymentMethod || paymentMethod === "CREDITCARD")) {
      dispatch(stepsActions.setNextStepDisabled(!selectPaymentId));
    }
  }, [paymentMethod, selectPaymentId]);

  if (!chooseStep.price) return;

  return (
    <>
      {isUserLogged ? (
        <PaymentStepLogged stepsReducer={stepsReducer} stepsActions={stepsActions} />
      ) : (
        <PaymentStepGuest stepsReducer={stepsReducer} stepsActions={stepsActions} />
      )}
    </>
  );
};
