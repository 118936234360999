import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { Color } from "@ionic/core";
import { chevronBackOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";

interface GoBackButtonProps {
  className?: string;
  color?: Color;
  onClick?: () => void;
}

export const GoBackButton = ({ className, color = "dark", onClick }: GoBackButtonProps) => {
  const history = useHistory();

  const defaultOnClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    history.goBack();
  };
  return <IconButton className={className} color={color} icon={chevronBackOutline} onClick={defaultOnClick} />;
};
