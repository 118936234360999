export const classIf = (condition: boolean, className: string) => (condition ? className : "");

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `${r}, ${g}, ${b}`;
};

export const getDarkerColor = (hexColor: string, factor: number) => {
  let r = parseInt(hexColor.slice(1, 3), 16),
    g = parseInt(hexColor.slice(3, 5), 16),
    b = parseInt(hexColor.slice(5, 7), 16);

  r = Math.max(r - factor, 0);
  g = Math.max(g - factor, 0);
  b = Math.max(b - factor, 0);

  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const getLighterColor = (hexColor: string, factor = 30) => {
  // Convertimos el color hexadecimal a RGB
  let r = parseInt(hexColor.slice(1, 3), 16),
    g = parseInt(hexColor.slice(3, 5), 16),
    b = parseInt(hexColor.slice(5, 7), 16);

  // Aumentamos cada uno de los componentes RGB
  r = Math.min(r + factor, 255);
  g = Math.min(g + factor, 255);
  b = Math.min(b + factor, 255);

  // Convertimos de nuevo a color hexadecimal
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const interpolateColor = (color1: string, color2: string, factor: number) => {
  let result = "#";
  for (let i = 1; i <= 6; i += 2) {
    const v1 = parseInt(color1.slice(i, i + 2), 16);
    const v2 = parseInt(color2.slice(i, i + 2), 16);
    const val = Math.round(v1 + factor * (v2 - v1)).toString(16);
    result += ("00" + val).slice(-2);
  }
  return result;
};

export const reloadColors = (primaryColor: string | null, theme: string) => {
  if (!primaryColor) return;

  document.documentElement.style.setProperty("--ion-color-primary", primaryColor);
  document.documentElement.style.setProperty("--ion-color-primary-rgb", hexToRgb(primaryColor));
  document.documentElement.style.setProperty("--ion-color-primary-shade", getDarkerColor(primaryColor, 50));
  document.documentElement.style.setProperty("--ion-color-primary-tint", getLighterColor(primaryColor, 90));
  document.documentElement.style.setProperty("--ion-color-tertiary", `rgba(${hexToRgb(primaryColor)}, 0.2)`);
  document.documentElement.style.setProperty("--ion-color-disable", `rgba(${hexToRgb(primaryColor)}, 0.15)`);
  document.documentElement.style.setProperty("--ion-color-disable-label", `rgba(${hexToRgb(primaryColor)}, 0.3)`);

  document.documentElement.style.setProperty("--ion-color-primary-darker", getDarkerColor(primaryColor, 100));
  document.documentElement.style.setProperty("--ion-color-primary-lighter", getLighterColor(primaryColor, 100));

  const body = document.querySelector("body");
  if (body) {
    if (theme === "light") {
      body.style.removeProperty("--ion-color-primary");
      body.style.removeProperty("--ion-color-primary-rgb");
      body.style.removeProperty("--ion-color-primary-shade");
      body.style.removeProperty("--ion-color-primary-tint");
      body.style.removeProperty("--ion-color-tertiary");
      body.style.removeProperty("--ion-color-disable");
    } else {
      primaryColor = getLighterColor(primaryColor, 20);
      body.style.setProperty("--ion-color-primary", primaryColor);
      body.style.setProperty("--ion-color-primary-rgb", hexToRgb(primaryColor));
      body.style.setProperty("--ion-color-primary-shade", getDarkerColor(primaryColor, 60));
      body.style.setProperty("--ion-color-primary-tint", getLighterColor(primaryColor));
      body.style.setProperty("--ion-color-tertiary", `rgba(${hexToRgb(primaryColor)}, 0.2)`);
      body.style.setProperty("--ion-color-disable", `rgba(${hexToRgb(primaryColor)}, 0.15)`);
      body.style.setProperty("--ion-color-disable-label", getDarkerColor(primaryColor, 60));

      document.documentElement.style.setProperty("--ion-color-primary-darker", getDarkerColor(primaryColor, 100));
      document.documentElement.style.setProperty("--ion-color-primary-lighter", getLighterColor(primaryColor, 100));
    }
  }
};
