import { buildURLFilters } from "@/commons/utils/locationUtils/locationUtil";
import { LocationMapModel } from "@/core/models/locationModel";
import { getLocationsService } from "@/core/services/location/locationServices";
import { useAppSelector } from "@/core/store/store";
import { LatLng } from "leaflet";
import React, { createContext, useContext, useState } from "react";
import { ParametersContext } from "./appParametersContext";

interface LocationsContextValue {
  isLoadingLocations: boolean;
  locationsCx: LocationMapModel[];
  setLocationsCx: (locations: LocationMapModel[]) => void;
  getLocations: (showLoading?: boolean, params?: string) => Promise<void>;
}

const LocationsContext = createContext<LocationsContextValue>({
  isLoadingLocations: false,
  locationsCx: [],
  setLocationsCx: () => {},
  getLocations: () => new Promise(() => {}),
});

interface LocationsProviderProps {
  children: React.ReactNode;
}

const LocationsProvider = ({ children }: LocationsProviderProps) => {
  const { parameters } = useContext(ParametersContext);

  const { user } = useAppSelector((state) => state.userReducer);
  const { activeFilters } = useAppSelector((state) => state.mapReducer.filters);

  const [locationsCx, setLocationsCx] = useState<LocationMapModel[]>([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);

  const getLocations = async (showLoading = true, params?: string) => {
    try {
      showLoading && setIsLoadingLocations(true);

      params = buildURLFilters(activeFilters);

      if (!parameters.token_api || !user.auth_token) return;

      const res = await getLocationsService(parameters.token_api, user.auth_token, params);

      if (res.locations) {
        const locationsWithChargers = res.locations.filter((location) => location.chargers.length > 0);

        const newLocations = locationsWithChargers.map((location) => {
          const latitud = location.location_latitude.replace(",", ".");
          const longitud = location.location_longitude.replace(",", ".");

          return {
            ...location,
            location_latitude: latitud,
            location_longitude: longitud,
            latLng: new LatLng(Number(latitud), Number(longitud)).wrap(),
          };
        });

        setLocationsCx(newLocations);
      }
    } catch (error) {
      console.error("Error getting locations", error);
    } finally {
      showLoading && setIsLoadingLocations(false);
    }
  };

  return (
    <LocationsContext.Provider value={{ isLoadingLocations, locationsCx, setLocationsCx, getLocations }}>
      {children}
    </LocationsContext.Provider>
  );
};

export { LocationsContext, LocationsProvider };
