import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { InfoButton } from "@/commons/components/Buttons/InfoButton/InfoButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { CreditCard } from "@/commons/components/Payments/CreditCard/CreditCard";
import {
  PaymentFrame,
  PaymentFrameRespose,
  PaymentFrameType,
} from "@/commons/components/Payments/PaymentFrame/PaymentFrame";
import { usePayment } from "@/commons/hooks/usePayment";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { formatNumberDecimals } from "@/commons/utils/numberUtils";
import { getUserAppCard } from "@/commons/utils/userUtils/userUtils";
import { ParametersContext } from "@/context/appParametersContext";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonIcon,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  SegmentCustomEvent,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import { t } from "i18next";
import { addCircle, checkmarkCircle, closeOutline } from "ionicons/icons";
import { uniq } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AddCustomBalance } from "./AddCustomBalance/AddCustomBalance";
import "./WalletAddBalancePage.scss";

export const WalletAddBalancePage = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const history = useHistory();
  const { addBalanceWallet } = usePayment();

  const { parameters } = useContext(ParametersContext);
  const { loadUserInfo } = useUserContext();
  const { min_amount_wallet: minAmountWallet, max_amount_wallet: maxAmountWallet } = parameters;

  const { user, userInfo } = useAppSelector((state) => state.userReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [fee, setFee] = useState(0);

  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showCreditCardsModal, setShowCreditCardsModal] = useState(false);
  const [disableButtonAccept, setDisableButtonAccept] = useState(false);
  const [disableButtonContinue, setDisableButtonContinue] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState(
    userInfo?.stripe_payment_methods.find((paymentMethod) => paymentMethod.predetermined) ||
      userInfo?.stripe_payment_methods[0] ||
      null,
  );

  const [paymentFrame, setPaymentFrame] = useState<{
    isOpen: boolean;
    type: PaymentFrameType;
  }>({
    isOpen: false,
    type: "redsysPayment",
  });
  const [response, setResponse] = useState<PaymentFrameRespose>();

  useIonViewWillEnter(() => {
    loadData();
  });

  useEffect(() => {
    if (selectedCreditCard) setDisableButtonAccept(false);
    else setDisableButtonAccept(true);
  }, [selectedCreditCard]);

  useEffect(() => {
    if (selectedAmount) setDisableButtonContinue(false);
    else setDisableButtonContinue(true);

    if (!selectedAmount) {
      setFee(0);
      return;
    }

    const { wallet_fee_fix, wallet_fee_percent } = parameters;

    const newFee = (selectedAmount * wallet_fee_percent) / 100 + wallet_fee_fix;
    setFee(newFee);
  }, [selectedAmount]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      await loadUserInfo();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const getDetailtAmounts = () => {
    const maxAmountCalculate = minAmountWallet * 10;
    if (maxAmountCalculate > maxAmountWallet) {
      return uniq([maxAmountWallet, (maxAmountWallet + minAmountWallet) / 2, minAmountWallet]);
    }

    return [maxAmountCalculate, minAmountWallet * 5, minAmountWallet];
  };

  const handleClickClose = () => {
    router.goBack();
  };

  const handleStepClick = (event: SegmentCustomEvent) => {
    const index = parseInt(event.detail.value!.toString());
    setCurrentStep(index);
  };

  const handleContinueClick = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleClickCustomAmount = () => {
    setShowCustomModal(true);
  };

  const handleDissmissModalCreditCard = () => {
    setShowCreditCardsModal(false);
  };

  const handleSelectCreditCard = (card: any) => {
    setSelectedCreditCard(selectedCreditCard === card ? null : card);
  };

  const handleAddCreditCard = () => {
    setShowCreditCardsModal(false);
    router.push(APP_PATH.WALLET_ADD_CREDITCARD);
  };

  const createPayment = async () => {
    setShowProcessing(true);
    try {
      if (!selectedCreditCard) {
        throw new Error(t("APP_ERRORS.SELECTED_PAYMENT"));
      }

      const card = await getUserAppCard(user.user_id, userInfo);

      await addBalanceWallet(
        card.code_card,
        selectedCreditCard.payment_method,
        Number(selectedAmount),
        setResponse,
        parameters.currency,
      );

      if (parameters.payment_type.includes("redsys")) {
        return setPaymentFrame({
          isOpen: true,
          type: "redsysPayment",
        });
      }

      handleSuccessPayment();
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setAlertType({ type: "errorAddBalance" }));
    } finally {
      setShowProcessing(false);
    }
  };

  const handleSuccessPayment = () => {
    history.replace(APP_PATH.WALLET);

    dispatch(
      uiActions.setAlertType({
        type: "successAddBalance",
        options: {
          message: `${selectedAmount} ${parameters.currency} `,
        },
      }),
    );
  };

  const handleChangeCreditCardSeleted = () => {
    setShowCreditCardsModal(true);
  };

  const handleAddCustomAmount = (amount: number) => {
    setSelectedAmount(amount);

    handleContinueClick();
  };

  const setIsOpenPaymentFrame = (isOpen: boolean) => setPaymentFrame({ ...paymentFrame, isOpen });

  const steps = [t("ADD_BALANCE_STEPS.QUANTITY"), t("ADD_BALANCE_STEPS.END")];

  return (
    <IonPage>
      <AppHeader
        hiddenMenu
        title={t("TITLES.ADD_BALANCE")}
        endIcon={<IconButton icon={closeOutline} onClick={handleClickClose} />}
      />
      <IonContent class="ion-padding">
        <IonLoading isOpen={isLoading} />
        <IonLoading isOpen={showProcessing} message={t("LOADING.PROCESSING_PAYMENT")} />
        <PaymentFrame
          isOpen={paymentFrame.isOpen}
          setIsOpenPaymentFrame={setIsOpenPaymentFrame}
          response={response}
          callback={(values) => {
            if (!values?.success) {
              return dispatch(uiActions.setToastError(t("TOAST.ERROR_ACCOUNT_VALIDATION")));
            }

            handleSuccessPayment();
          }}
          type={paymentFrame.type}
        />
        <IonSegment className="segment" value={currentStep} mode="md" onIonChange={handleStepClick}>
          {steps.map((step, index) => (
            <IonSegmentButton key={index} value={index} disabled={index > currentStep}>
              {index < currentStep ? (
                <IonIcon className="ion-no-margin" icon={checkmarkCircle} color="primary" />
              ) : (
                <IonLabel class="ion-no-margin">{index + 1}</IonLabel>
              )}
              <IonLabel className="ion-no-margin">{step}</IonLabel>
            </IonSegmentButton>
          ))}
        </IonSegment>
        {currentStep === 0 && (
          <IonList className="contentStep currenStepOne">
            <IonLabel className="titleQuestionAdd">{t("TITLES.HOW_MUCH_BALANCE")}</IonLabel>
            <IonRow className="rowQuantityContainer">
              {getDetailtAmounts().map((amount) => (
                <div
                  key={amount}
                  className="containerQuantity"
                  onClick={() => setSelectedAmount(selectedAmount === amount ? 0 : amount)}
                  style={{ backgroundColor: selectedAmount === amount ? "var(--ion-color-primary)" : "" }}
                >
                  <IonLabel style={{ color: selectedAmount === amount ? "white" : "" }}>
                    {amount} {parameters.currency}
                  </IonLabel>
                </div>
              ))}
            </IonRow>
            <IonButton className="ion-margin" color="primary" fill="outline" onClick={handleClickCustomAmount}>
              {t("BUTTONS.CUSTOM")}
            </IonButton>
          </IonList>
        )}

        {currentStep === 1 && (
          <>
            <IonList className="contentStep ion-margin-top" style={{ height: "70%" }}>
              <IonLabel className="labelTitleWallet">{t("TITLES.CREDIT_CARD")}</IonLabel>
              <IonRow className="select-credit-card">
                {selectedCreditCard && <CreditCard creditCard={selectedCreditCard} />}
                <div>
                  <IonButton color="primary" fill="outline" onClick={handleChangeCreditCardSeleted}>
                    {t("BUTTONS.SELECT_CARD")}
                  </IonButton>
                </div>
              </IonRow>
            </IonList>
          </>
        )}

        {showCustomModal && (
          <AddCustomBalance
            showCustomModal={showCustomModal}
            selectedAmount={selectedAmount}
            closeModal={() => setShowCustomModal(false)}
            handleAddCustomAmount={handleAddCustomAmount}
          />
        )}

        {showCreditCardsModal && (
          <IonModal id="choose-creditcard" isOpen={showCreditCardsModal} onDidDismiss={handleDissmissModalCreditCard}>
            <IonList>
              <IonLabel className="labelModalTitle ion-margin-bottom">{t("TITLES.CHOOSE_CARD")}</IonLabel>
              <div className="scrollableDiv">
                {userInfo?.stripe_payment_methods.map((card) => (
                  <CreditCard
                    key={card.id}
                    creditCard={card}
                    isSelected={selectedCreditCard?.id === card.id}
                    onClick={() => handleSelectCreditCard(card)}
                  />
                ))}
              </div>
              <IonRow className="addCreditCardRow" onClick={handleAddCreditCard}>
                <IonIcon icon={addCircle}></IonIcon>
                <IonLabel>{t("BUTTONS.ADD_CARD")}</IonLabel>
              </IonRow>
              <IonRow className="buttonContainer">
                <IonButton className="buttonTerciary" onClick={handleDissmissModalCreditCard}>
                  {t("ALERT_BUTTONS.CANCEL")}
                </IonButton>
                <IonButton disabled={disableButtonAccept} onClick={handleDissmissModalCreditCard}>
                  {t("BUTTONS.ACCEPT")}
                </IonButton>
              </IonRow>
            </IonList>
          </IonModal>
        )}
      </IonContent>
      {currentStep === 1 && (
        <div className="cart-total-price-info">
          {!!fee && (
            <>
              <IonRow>
                <IonCol>
                  <span className="subtotal">{t("TITLES.SUBTOTAL")}</span>
                </IonCol>
                <IonCol size="5">
                  <div className="price">
                    <span className="price-subtotal">
                      {formatNumberDecimals(selectedAmount)} {parameters.currency}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonRow className="ion-align-items-center">
                    <span className="fee">{t("BUTTONS.SERVICE_COST")}: </span>
                    <InfoButton message="WALLET_FEE" />
                  </IonRow>
                </IonCol>
                <IonCol size="5">
                  <div className="price">
                    <span className="price-subtotal">
                      {formatNumberDecimals(fee)} {parameters.currency}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
            </>
          )}
          <IonRow className="total">
            <IonCol>
              <span>{t("TITLES.TOTAL")}</span>
            </IonCol>
            <IonCol size="5">
              <span className="price">
                {formatNumberDecimals(selectedAmount + fee)}
                {parameters.currency}
              </span>
            </IonCol>
          </IonRow>
        </div>
      )}
      <IonFooter className="ion-no-border ion-padding">
        {currentStep === 0 && (
          <IonButton mode="ios" onClick={handleContinueClick} disabled={disableButtonContinue}>
            {t("BUTTONS.NEXT")}
          </IonButton>
        )}
        {currentStep === 1 && (
          <IonButton disabled={!selectedCreditCard} onClick={createPayment}>
            {t("BUTTONS.CONFIRM")}
          </IonButton>
        )}
      </IonFooter>
    </IonPage>
  );
};
