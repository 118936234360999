import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { IonButton, IonInput, IonLabel, IonModal, IonRow } from "@ionic/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import "./AddCustomBalance.scss";

interface AddCustomBalanceProps {
  showCustomModal: boolean;
  selectedAmount: number;
  closeModal: () => void;
  handleAddCustomAmount: (amount: number) => void;
}

export const AddCustomBalance = ({
  showCustomModal,
  selectedAmount,
  closeModal,
  handleAddCustomAmount,
}: AddCustomBalanceProps) => {
  const { parameters } = useParametersContext();

  const isDisableButton = (amount: number | null) =>
    amount && (amount < parameters.min_amount_wallet || amount > parameters.max_amount_wallet);

  const [customAmount, setCustomAmount] = useState<number | null>(selectedAmount);
  const [disableButton, setDisableButton] = useState(true);

  const [errorMessageInput, setErrorMessageInput] = useState("");

  useEffect(() => {
    handleInputChange(selectedAmount);
  }, [selectedAmount]);

  const handleInputChange = (value: HTMLIonInputElement["value"]) => {
    const newValue = Number(value) || null;

    setCustomAmount(newValue);

    if (newValue && isDisableButton(newValue)) {
      setDisableButton(true);

      setErrorMessageInput(
        newValue < parameters.min_amount_wallet
          ? t("TITLES.MIN_BALANCE_WALLET", { value: `${parameters.min_amount_wallet}${parameters.currency}` })
          : t("TITLES.MAX_BALANCE_WALLET", { value: `${parameters.max_amount_wallet}${parameters.currency}` }),
      );

      return;
    }

    setDisableButton(!newValue);
    setErrorMessageInput("");
  };

  const handleClickAccept = () => {
    if (!errorMessageInput && customAmount) {
      handleDismiss();
      handleAddCustomAmount(customAmount);
    }
  };

  const handleDismiss = () => {
    closeModal();
  };

  return (
    <IonModal className="add-custom-balance" isOpen={showCustomModal} onDidDismiss={closeModal}>
      <div className="add-custom-balance-container">
        <IonLabel className="strong-text">{t("TITLES.CHOOSE_QUANTITY")}</IonLabel>
        <div className="containerAmountCustom">
          <IonInput
            className={`add-custom-balance-input ion-touched ${classIf(!!errorMessageInput, "ion-invalid")}`}
            type="number"
            inputMode="numeric"
            value={customAmount}
            onIonInput={(e) => handleInputChange(e.target.value)}
            errorText={errorMessageInput}
          >
            <IonLabel className="add-custom-balance-currency" slot="end">
              {parameters.currency}
            </IonLabel>
          </IonInput>
        </div>
        <IonRow className="buttonContainer">
          <IonButton className="buttonTerciary" onClick={closeModal}>
            {t("ALERT_BUTTONS.CANCEL")}
          </IonButton>
          <IonButton disabled={disableButton} onClick={handleClickAccept}>
            {t("BUTTONS.ACCEPT")}
          </IonButton>
        </IonRow>
      </div>
    </IonModal>
  );
};
