import { SelectInput, SelectInputElement } from "@/commons/components/Form/SelectInput/SelectInput";
import { HIDDEN_FIELDS_DOCUMENT_TYPE, SPAIN_CODE } from "@/commons/constants/personalData";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { CountryModel, DocumentTypeModel } from "@/core/models/userModel";
import { userFormActions } from "@/core/store/slices/userFormSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonToggle } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SecondStepProps {
  values: {
    name: string;
    surname1: string;
    surname2: string;
    telf: string;
    documentType: string | undefined;
    dni: string;
    documentCountry: string;
    isPublicAdministration: boolean;
    dir3: string;
    businessData: any;
  };
  onStringsReceived: (
    userName: string,
    userSurname1: string,
    userSurname2: string,
    userTel: string,
    userDocumentType: string | undefined,
    userDni: string,
    userDocumentCountry: string,
    userisPublicAdministration: boolean,
    userDir3: string,
    businessData: any,
  ) => void;
  countries: CountryModel[];
  documentTypes: DocumentTypeModel[];
}

export const SecondStep = ({ values, onStringsReceived, countries, documentTypes }: SecondStepProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getLabel, isValidSpanishDNI } = useIsValidUser();

  const { parameters } = useParametersContext();

  const [name, setName] = useState(values.name);
  const [surname, setSurname] = useState(values.surname1);
  const [surname2, setSurname2] = useState(values.surname2);
  const [tel, setTel] = useState(values.telf);
  const [documentType, setDocumentType] = useState<string | undefined>(values.documentType);
  const [dni, setDni] = useState(values.dni);
  const [documentCountry, setDocumentCountry] = useState(values.documentCountry);
  const [isPublicAdministration, setIsPublicAdministration] = useState(values.isPublicAdministration);
  const [dir3, setDir3] = useState(values.dir3);
  const [cif, setCif] = useState(values.businessData?.nif);
  const [registryNumber, setRegistryNumber] = useState(values.businessData?.reg_company);
  const [fiscalName, setFiscalName] = useState(values.businessData?.fiscal_name);
  const [fiscalAddress, setFiscalAddress] = useState(values.businessData?.fiscal_address);
  const [billingCity, setBillingCity] = useState(values.businessData?.city);
  const [billingCp, setBillingCp] = useState(values.businessData?.cp);
  const [billingType, setBillingType] = useState(values.businessData?.billing_type);
  const [businessData, setBusinessData] = useState<any>(values.businessData);

  const [documentTypesFilter, setDocumentTypesFilter] = useState(documentTypes);

  useEffect(() => {
    onStringsReceived(
      name,
      surname,
      surname2,
      tel,
      documentType,
      dni,
      documentCountry,
      isPublicAdministration,
      dir3,
      businessData,
    );
  }, [
    name,
    surname,
    surname2,
    tel,
    documentType,
    dni,
    documentCountry,
    isPublicAdministration,
    dir3,
    cif,
    registryNumber,
    fiscalName,
    fiscalAddress,
    billingCity,
    billingCp,
    businessData,
  ]);

  const handleNameChange = (e: CustomEvent) => {
    setName(e.detail.value);
  };

  const handleSurnameChange = (e: CustomEvent) => {
    setSurname(e.detail.value);
  };

  const handleSurname2Change = (e: CustomEvent) => {
    setSurname2(e.detail.value);
  };

  const handleTelChange = (e: CustomEvent) => {
    setTel(e.detail.value);
  };

  const handleDocumentCountry = (target: SelectInputElement<string>) => {
    const newValue = target.value || "";
    setDocumentCountry(newValue);

    const hiddenFields = HIDDEN_FIELDS_DOCUMENT_TYPE[newValue];
    if (hiddenFields) {
      documentType && hiddenFields.includes(documentType) && setDocumentType(undefined);
      return setDocumentTypesFilter(documentTypes.filter((documentType) => !hiddenFields.includes(documentType.id)));
    }

    setDocumentTypesFilter(documentTypes);
  };

  const handleDocumentType = (e: CustomEvent) => {
    setDocumentType(e.detail.value);
  };

  const handleDniChange = (e: CustomEvent) => {
    setDni(e.detail.value!);
  };

  const togglePublicAdministration = () => {
    const newValue = !isPublicAdministration;

    dispatch(userFormActions.setRequiredFieldsForm({ name: "is_public_administration", value: newValue }));

    setIsPublicAdministration(newValue);
  };

  const handleDir3Change = (e: CustomEvent) => {
    setDir3(e.detail.value!);
  };

  const toggleBussinessContent = () => {
    const newValue = !businessData?.active;

    dispatch(userFormActions.setRequiredFieldsForm({ name: "billing_user", value: newValue }));

    setBusinessData({
      ...businessData,
      active: newValue,
    });
  };

  const handleCifChange = (e: CustomEvent) => {
    setCif(e.detail.value);
    const updateCif = { ...businessData };
    updateCif.nif = e.detail.value;
    setBusinessData(updateCif);
  };

  const handleRegistryNumberChange = (e: CustomEvent) => {
    setRegistryNumber(e.detail.value);
    const updateRegistryNumber = { ...businessData };
    updateRegistryNumber.reg_company = e.detail.value;
    setBusinessData(updateRegistryNumber);
  };

  const handleFiscalNameChange = (e: CustomEvent) => {
    setFiscalName(e.detail.value);
    const updateFiscalName = { ...businessData };
    updateFiscalName.fiscal_name = e.detail.value;
    setBusinessData(updateFiscalName);
  };

  const handleFiscalAddressChange = (e: CustomEvent) => {
    setFiscalAddress(e.detail.value);
    const updateFiscalAddress = { ...businessData };
    updateFiscalAddress.fiscal_address = e.detail.value;
    setBusinessData(updateFiscalAddress);
  };

  const handleBillingCityChange = (e: CustomEvent) => {
    setBillingCity(e.detail.value);
    const updateBillingCity = { ...businessData };
    updateBillingCity.city = e.detail.value;
    setBusinessData(updateBillingCity);
  };

  const handleBillingCpChange = (e: CustomEvent) => {
    setBillingCp(e.detail.value);
    const updateBillingCp = { ...businessData };
    updateBillingCp.cp = e.detail.value;
    setBusinessData(updateBillingCp);
  };

  const handleSelectInvoiceType = (e: CustomEvent) => {
    setBillingType(e.detail.value);
    const updateBillingType = { ...businessData };
    updateBillingType.billing_type = e.detail.value;
    setBusinessData(updateBillingType);
  };

  return (
    <>
      <IonRow className="ion-margin-bottom">
        <IonLabel className="neutralBlack70">
          {t("STEPS.STEP")} <IonLabel className="number number-bold">2</IonLabel> {t("STEPS.OUT_OF")}{" "}
          <IonLabel className="number number-bold">6</IonLabel>
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel className="titleH3">{t("TITLES.PERSONAL_DATA")}</IonLabel>
      </IonRow>

      <IonItem>
        <IonInput
          label={getLabel("name")}
          labelPlacement="floating"
          type="text"
          value={name}
          onIonInput={handleNameChange}
        />
      </IonItem>

      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("surname1")}
          labelPlacement="floating"
          type="text"
          value={surname}
          onIonInput={handleSurnameChange}
        />
      </IonItem>

      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("surname2")}
          labelPlacement="floating"
          type="text"
          value={surname2}
          onIonInput={handleSurname2Change}
        />
      </IonItem>

      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("telf")}
          labelPlacement="floating"
          type="number"
          value={tel}
          onIonInput={handleTelChange}
          className="number"
          inputMode="tel"
        />
      </IonItem>

      <SelectInput
        name="country"
        className="ion-margin-top"
        label={getLabel("document_country")}
        placeholder={getLabel("document_country", "placeholder")}
        isClearable
        loadData={async () =>
          countries.map((country) => ({ value: country.id, label: `${country.flag} ${country.name}` }))
        }
        currentValue={documentCountry}
        onIonChange={(event) => handleDocumentCountry(event.target as SelectInputElement<string>)}
        outSideMarginLeft
      />

      <IonItem className="ion-margin-top">
        <IonSelect
          name="document_type"
          label={getLabel("document_type")}
          labelPlacement="floating"
          value={documentType}
          onIonChange={handleDocumentType}
        >
          <IonSelectOption />
          {documentTypesFilter.map((documentType) => (
            <IonSelectOption key={documentType.id} value={documentType.id}>
              {documentType.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>

      <IonItem className="ion-margin-top">
        <IonInput
          label={getLabel("dni_nif")}
          labelPlacement="floating"
          type="text"
          value={dni}
          onIonInput={handleDniChange}
          className="number"
        />
      </IonItem>

      {dni && documentCountry === SPAIN_CODE && !isValidSpanishDNI(dni) && (
        <IonLabel className="formError">{t("ERRORS.DNI")}</IonLabel>
      )}

      <IonRow className="bussinessRowToggle">
        <IonLabel class="strongLabel">{t("PROFILE.BUSSINESS")}</IonLabel>
        <IonToggle checked={!!businessData?.active} onIonChange={toggleBussinessContent} />
      </IonRow>

      {!!businessData?.active && (
        <>
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              label={getLabel("nif", "billing_user")}
              labelPlacement="floating"
              type="text"
              value={cif}
              onIonInput={handleCifChange}
            />
          </IonItem>
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              label={getLabel("reg_company", "billing_user")}
              labelPlacement="floating"
              type="text"
              value={registryNumber}
              onIonInput={handleRegistryNumberChange}
            />
          </IonItem>
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              label={getLabel("fiscal_name", "billing_user")}
              labelPlacement="floating"
              type="text"
              value={fiscalName}
              onIonInput={handleFiscalNameChange}
            />
          </IonItem>
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              label={getLabel("fiscal_address", "billing_user")}
              labelPlacement="floating"
              type="text"
              value={fiscalAddress}
              onIonInput={handleFiscalAddressChange}
            />
          </IonItem>
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              label={getLabel("city", "billing_user")}
              labelPlacement="floating"
              type="text"
              value={billingCity}
              onIonInput={handleBillingCityChange}
            />
          </IonItem>
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              label={getLabel("cp", "billing_user")}
              labelPlacement="floating"
              type="text"
              value={billingCp}
              onIonInput={handleBillingCpChange}
            />
          </IonItem>
          <IonItem lines="full" className="ion-margin-top">
            <IonSelect
              label={getLabel("billing_type", "billing_user")}
              labelPlacement="floating"
              value={billingType}
              placeholder={t("PROFILE.SELECT_BILLING_TYPE")}
              onIonChange={handleSelectInvoiceType}
            >
              <IonSelectOption value="INVOICE">{t("PROFILE.INVOICE_CHARGE")}</IonSelectOption>
              <IonSelectOption value="INVOICE_MONTHLY">{t("PROFILE.INVOICE_MONTHLY")}</IonSelectOption>
            </IonSelect>
          </IonItem>
        </>
      )}

      {parameters.show_type_client && (
        <>
          <IonRow className="bussinessRowToggle">
            <IonLabel class="strongLabel">{t("PROFILE.PUBLIC_ADMINISTRATION")}</IonLabel>
            <IonToggle checked={isPublicAdministration} onIonChange={togglePublicAdministration} />
          </IonRow>

          {isPublicAdministration && (
            <IonItem className="ion-margin-top">
              <IonInput
                label={getLabel("dir3")}
                labelPlacement="floating"
                type="text"
                value={dir3}
                onIonInput={handleDir3Change}
                className="number"
              />
            </IonItem>
          )}
        </>
      )}
    </>
  );
};
