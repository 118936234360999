import {
  PaymentFrame,
  PaymentFrameRespose,
  PaymentFrameType,
} from "@/commons/components/Payments/PaymentFrame/PaymentFrame";
import { useChargeStart } from "@/commons/hooks/useChargeStart";
import { useCreateChargeAccount } from "@/commons/hooks/useCreateChargeAccount";
import { formatNumber } from "@/commons/utils/numberUtils";
import { AccountModel } from "@/core/models/accountModel";
import { ChargeStepLabel, chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonLabel,
  IonLoading,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { t } from "i18next";
import { useState } from "react";
import { ChargeInitTimeout } from "./ChargeInitTimeout/ChargeInitTimeout";
import "./ChargeStepsFooter.scss";

export const ChargeStepsFooter = () => {
  const dispatch = useAppDispatch();
  const { handleStartCharge, handleStopCharge } = useChargeStart();

  const { isUserLogged } = useAppSelector((state) => state.userReducer);
  const { isKeyboardOpen } = useAppSelector((state) => state.uiReducer);
  const { selectedCharger, selectedSocket } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { currentStep, currentStepLabel, nextStepDisabled, chooseStep, chargeStep } = useAppSelector(
    (state) => state.chargeStepsReducer,
  );

  const goNextStep = (_account: AccountModel | null, error: any) => {
    if (error) return;

    dispatch(chargeStepsActions.setCurrentStep(currentStep + 1));
  };

  const [paymentFrame, setPaymentFrame] = useState<{
    isOpen: boolean;
    type: PaymentFrameType;
  }>({
    isOpen: false,
    type: "redsysPayment",
  });

  const [response, setResponse] = useState<PaymentFrameRespose>();
  const { isLoading, handleNextStep } = useCreateChargeAccount({ callback: goNextStep, setPaymentFrame, setResponse });

  const handleOpenTicket = () => {
    if (isUserLogged) {
      dispatch(uiActions.showHelp({ charger: selectedCharger, step: 1 }));
    } else {
      dispatch(uiActions.setAlertType({ type: "alertRegister" }));
    }
  };
  const setIsOpenPaymentFrame = (isOpen: boolean) => setPaymentFrame({ ...paymentFrame, isOpen });

  return (
    <>
      {currentStepLabel === ChargeStepLabel.PAYMENT && !isKeyboardOpen && (
        <IonRow className="charge-steps-total-price-info">
          <span>{t("TITLES.TOTAL")}</span>

          {chooseStep.isLoadingPrice ? (
            <IonSpinner name="dots" />
          ) : (
            <span>
              {formatNumber(chooseStep.price, { minimumFractionDigits: 2 })}
              {chooseStep.currency?.symbol}
            </span>
          )}
        </IonRow>
      )}
      <PaymentFrame
        isOpen={paymentFrame.isOpen}
        setIsOpenPaymentFrame={setIsOpenPaymentFrame}
        response={response}
        callback={(values) => {
          if (!values?.success || !values?.account) {
            return dispatch(uiActions.setToastError(t("TOAST.ERROR_ACCOUNT_VALIDATION")));
          }

          dispatch(chargeStepsActions.setChargeStepAccount(values.account));

          goNextStep(values.account, null);
        }}
        type={paymentFrame.type}
      />
      <IonFooter className="charge-steps-footer ion-no-border">
        <IonLoading isOpen={isLoading} />
        <IonLoading
          isOpen={!!chargeStep.loadingMessage || chargeStep.isStoppingCharge}
          message={chargeStep.loadingMessage}
        />

        <IonToolbar>
          <IonGrid>
            <ChargeInitTimeout />

            <IonRow>
              <IonCol size="6">
                <IonLabel className="ion-text-wrap ion-justify-content-center">{t("INFO_CHARGE.HELP_LABEL")}</IonLabel>
              </IonCol>
              <IonCol size="6">
                <IonButton className="open-ticket-button" color="primary" fill="outline" onClick={handleOpenTicket}>
                  {t("BUTTONS.OPEN_TICKET")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>

          <div className="charge-steps-footer-buttons">
            {currentStepLabel !== ChargeStepLabel.CHARGE ? (
              <IonButton disabled={nextStepDisabled} onClick={() => handleNextStep()}>
                {t("BUTTONS.NEXT")}
              </IonButton>
            ) : (
              <>
                {!chargeStep.isChargeInit ? (
                  <IonButton onClick={() => handleStartCharge(null)}>
                    {t(`BUTTONS.${selectedSocket?.instructions_type === "3" ? "UNLOCK_SOCKET" : "START_CHARGE"}`)}
                  </IonButton>
                ) : (
                  <IonButton
                    onClick={() =>
                      dispatch(
                        uiActions.setAlertType({ type: "cancelCharge", options: { primaryAction: handleStopCharge } }),
                      )
                    }
                  >
                    {t("ALERT_BUTTONS.CANCEL")}
                  </IonButton>
                )}
              </>
            )}
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
