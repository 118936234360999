import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useLocationContext } from "@/commons/hooks/useLocationContext";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { getURLDomain } from "@/commons/utils/apiUtils/apiUtils";
import { useAppSelector } from "@/core/store/store";
import { useApi } from "@/services/apiService";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonTextarea,
  IonToolbar,
  IonicSafeString,
  useIonViewDidLeave,
} from "@ionic/react";
import {
  chatbox,
  closeOutline,
  downloadOutline,
  person,
  personCircleOutline,
  sendOutline,
  ticketOutline,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const { VITE_TOKEN_PARAMETERS } = import.meta.env;

interface ChatProps {
  response: any;
  setShowChat: any;
  language: string;
}

export const Chat = ({ response, setShowChat, language }: ChatProps) => {
  const { t } = useTranslation();
  const {
    sendSupportTicketChatbot,
    showInvoice,
    getUserInvoicesByChargerGraph,
    ticketToInvoice,
    sendMessageChatbotUser,
    deleteChatSession,
    startConversation,
  } = useApi();

  const { user } = useAppSelector((state) => state.userReducer);
  const user_id = user.user_id;
  const auth_token = user.auth_token;

  //APP
  const { parameters } = useParametersContext();
  const { userLocation } = useLocationContext();

  // Traducciones de casos concretos
  const translatedHeader = t("CHAT_BUTTONS.TICKET_QUESTION_CONFIRMATION");
  const leave_string = t("CHATBOT_SPECIFIC_RESPONSES.LEAVE");
  const create_ticket_string = t("CHATBOT_SPECIFIC_RESPONSES.CREATE_TICKET");
  const solved_string = t("CHATBOT_SPECIFIC_RESPONSES.SOLVED");

  //VARIABLES
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const [buttons, setButtons] = useState<any[]>([]);

  const [newMessage, setNewMessage] = useState("");
  const [menuOption, setMenuOption] = useState("");
  const [ticketReason, setTicketReason] = useState("");
  const [type_support_ticket_id, setType_support_ticket_id] = useState("");
  const [account, setAccount] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [inputTextDisabled, setInputTextDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertSelectOption, setShowAlertSelectOption] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(false);
  const [noMoreQuestions, setNoMoreQuestions] = useState(false);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [showConfirmSupport, setShowConfirmSupport] = useState(false);
  const [showSuccessTicketCreated, setShowSuccessTicketCreated] = useState(false);
  const [showFailedTicketCreated, setShowFailedTicketCreated] = useState(false);
  const [isInactive, setIsInactive] = useState(false);

  const app_domain_id = parameters.domain_id;
  const contentRef = useRef<any>(null);
  let charger_graph_id: any;

  const sessionAttributes: any = {
    latitude: userLocation.latitude,
    longitude: userLocation.longitude,
    app_domain_id: app_domain_id,
    menuOption: menuOption,
    page: 0,
    IDCharger: null,
    IDAccount: null,
    businessAccount: false,
    inputHowToCharge: null,
    inputStopCharge: null,
    inputPaymentProblems: null,
    inputPowerProblems: null,
    invoiceOrTicket: null,
    inputAux: null,
    chargerPowerProblems: null,
    API_URL_LD: getURLDomain("charger/list_distance"),
    API_URL_SHOW: getURLDomain("charger/show/"),
    API_URL_RESET_HARD: getURLDomain("charger/reset-hard"),
    TOKEN: `Token token=${VITE_TOKEN_PARAMETERS}`,
    APPETECNIC: "EvCharge_Etecnic",
    CHATBOT: "Chatbot_Etecnic",
    language: language,
  };

  useEffect(() => {
    setTimeout(() => {
      contentRef.current?.scrollToBottom();
    }, 100);

    contentRef.current?.scrollToBottom();
  }, [chatMessages, ticketReason, buttons]);

  useEffect(() => {
    if (!welcomeMessage) {
      switch (language) {
        case "es_ES":
          addMessage("Hola, gracias por contactar con Etecnic.");
          break;
        case "ca_ES":
          addMessage("Hola, gràcies por contactar amb Etecnic.");
          break;
        case "en_GB":
          addMessage("Hello, thank you for contacting Etecnic.");
          break;
      }
      processMessage(response);
      setWelcomeMessage(true);
    }
    let timer: any;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => setIsInactive(true), 120000); // 2 min de inactividad cierran la session
    };

    const handleInteraction = () => {
      setIsInactive(false);
      resetTimer();
    };

    const handleChatChange = () => {
      setChatMessages((prevMessages) => {
        resetTimer();
        return prevMessages;
      });
    };

    resetTimer();
    window.addEventListener("click", handleInteraction);
    window.addEventListener("touchstart", handleInteraction);
    window.addEventListener("message", handleChatChange);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("click", handleInteraction);
      window.removeEventListener("touchstart", handleInteraction);
      window.removeEventListener("message", handleChatChange);
    };
  }, [chatMessages]);

  const addMessage = (newResponse: any) => {
    if (newResponse != null) {
      setChatMessages((prevMessages) => [
        ...prevMessages,
        <div key={prevMessages.length + 1}>
          <IonIcon icon={person} />
          <IonItem
            className="number"
            style={{
              background: "var(--ion-color-botmessage)",
              borderRadius: "10px",
              padding: "2px",
              marginBottom: "10px",
              width: "fit-content",
            }}
          >
            {newResponse}
          </IonItem>
        </div>,
      ]);
    }
  };

  const getPaymentTicket = async () => {
    const invoices: any = await getUserInvoicesByChargerGraph(charger_graph_id, auth_token);
    if (invoices?.ticket != undefined && invoices?.ticket != null) {
      await showInvoice(invoices.ticket, "0");
    } else {
      const newButton = (
        <div>
          <IonButton onClick={getPaymentTicket} className={"dangerButton"}>
            <IonIcon icon={downloadOutline} />
            {t("CHAT_BUTTONS.DOWNLOAD_TICKET_BUTTON")}
            <IonIcon icon={ticketOutline} />
          </IonButton>
          <IonButton onClick={handleNoMoreQuestions} className={"buttonPrimaryChatbot"}>
            {t("CHAT_BUTTONS.LEAVE_BUTTON")}
          </IonButton>
        </div>
      );
      addMessage(t("CHAT_BUTTONS.TICKET_DOWNLOAD_FAILED"));
      setButtons([newButton]);
    }
  };

  useIonViewDidLeave(() => {
    handleDeleteChatSession();
  });

  const getPaymentInvoice = async () => {
    let invoices: any;
    invoices = await getUserInvoicesByChargerGraph(charger_graph_id, auth_token);
    if (invoices?.invoice != undefined && invoices?.invoice != null) {
      await showInvoice(invoices.invoice, "1");
    } else if (invoices?.invoice_exchange != undefined && invoices?.invoice_exchange != null) {
      await showInvoice(invoices.invoice_exchange, "2");
    } else {
      const data: any = await ticketToInvoice(charger_graph_id);
      if (data.success) {
        invoices = await getUserInvoicesByChargerGraph(charger_graph_id, auth_token);
        await showInvoice(invoices.invoice_exchange, "2");
      } else {
        const newButton = (
          <div>
            <IonButton onClick={getPaymentTicket} className={"dangerButton"}>
              <IonIcon icon={downloadOutline} />
              {t("CHAT_BUTTONS.DOWNLOAD_TICKET_BUTTON")}
              <IonIcon icon={ticketOutline} />
            </IonButton>
            <IonButton onClick={handleNoMoreQuestions} className={"buttonPrimaryChatbot"}>
              {t("CHAT_BUTTONS.LEAVE_BUTTON")}
            </IonButton>
          </div>
        );
        addMessage(t("CHAT_BUTTONS.INVOICE_DOWNLOAD_FAILED"));
        setButtons([newButton]);
      }
    }
  };

  //HANDLES

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessageToBot(newMessage);
      setNewMessage("");
    }
  };

  const handleKeyPressTicket = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendReason();
    }
  };

  const handleNoMoreQuestions = () => {
    setNoMoreQuestions(true);
  };

  const handleInputChange = (event: any) => {
    setNewMessage(event.target.value);
  };

  const handleInputButton = (event: any) => {
    const buttonValue = event.target.id;
    const allowedValues = ["1", "2", "3", "3.1", "3.2", "4", "5"];
    if (allowedValues.includes(buttonValue)) {
      setMenuOption(buttonValue);
    }
    sendMessageToBot(event.target);
    setInputTextDisabled(false);
    setButtons([]);
  };

  const handleClose = () => {
    setShowAlert(true);
  };

  const handleSelectAButton = () => {
    setShowAlertSelectOption(true);
  };

  const handleAlertDismiss = () => {
    setShowAlert(false);
    setShowAlertSelectOption(false);
  };

  const handleDeleteChatSession = async () => {
    await deleteChatSession(user_id, language);
    setShowChat(false);
  };

  const handleRestartChatSession = async () => {
    await deleteChatSession(user_id, language);
    response = await startConversation(user_id, userLocation.latitude, userLocation.longitude, language);
    setChatMessages([]);
    setNoMoreQuestions(false);
    setShowReasonInput(false);
    setShowConfirmSupport(false);
    setTicketReason("");
    setWelcomeMessage(false);
  };

  const handleInputReasonWindowTextArea = (event: any) => {
    setTicketReason(event.target.value);
  };

  const handleInputReasonWindow = () => {
    setTicketReason("");

    setChatMessages((prevMessages) => [
      ...prevMessages,
      <div key={prevMessages.length + 1}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IonItem className="chatUserMessage">{create_ticket_string}</IonItem>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div>
            <IonIcon icon={personCircleOutline} />
          </div>
        </div>
      </div>,
    ]);
    addMessage(t("CHAT_BUTTONS.INPUT_TICKET_REASON"));
    setInputTextDisabled(false);
    setButtons([]);
    setShowReasonInput(true);
  };

  const handleInputReasonWindowNo = (event: any) => {
    setTicketReason(event.target.id);
    sendMessageToBot(event.target.id);
    setInputTextDisabled(false);
    setButtons([]);
  };

  const handleSendReason = () => {
    setShowConfirmSupport(true);
  };

  const handleSendReasonConfirmed = async () => {
    setShowConfirmSupport(false);
    setShowReasonInput(false);
    sendSupportTicketToBot(ticketReason, account, type_support_ticket_id, false);
    setInputTextDisabled(true);
    setIsLoading(true);
    setButtons([]);
  };

  const handleCancelReasonWindow = () => {
    setInputTextDisabled(true);
    chatMessages.length = chatMessages.length - 1;
    addMessage(t("CHAT_BUTTONS.TICKET_QUESTION"));
    setButtons([
      <div key="cancel-reason-buttons">
        <IonButton className={"buttonPrimaryChatbot"} id={create_ticket_string} onClick={handleInputReasonWindow}>
          {t("CHAT_BUTTONS.YES")}
        </IonButton>

        <IonButton className={"buttonPrimaryChatbot"} id={solved_string} onClick={(event) => handleInputButton(event)}>
          {t("CHAT_BUTTONS.NO")}
        </IonButton>
      </div>,
    ]);
  };

  const generateResponseButtons = (jsonData: any) => {
    if (jsonData !== "") {
      const botonesGenerados = jsonData.map((item: any, index: any) => {
        return (
          <IonButton
            className={"buttonPrimaryChatbot"}
            key={index}
            id={item.value}
            onClick={(event) => handleInputButton(event)}
            mode="ios"
          >
            {item.text}
          </IonButton>
        );
      });
      setInputTextDisabled(true);
      setButtons(botonesGenerados);
    }
  };

  const processMessage = (data: any) => {
    if (sessionAttributes.chargerPowerProblems == "NO_POWER") {
      sendSupportTicketToBot("", sessionAttributes.inputAux, "11", true);
    }
    if (data != null && data.messages?.length != null) {
      if (data.session_state?.dialog_action?.type === "Close") {
        for (let i = 0; i < data.messages?.length; i++) {
          addMessage(data.messages?.[i].content ?? null);
        }
      }
      if (data.session_state?.dialog_action?.type === "ElicitSlot") {
        for (let i = 0; i < data.messages?.length; i++) {
          if (data.messages?.[i].content_type === "ImageResponseCard") {
            addMessage(data.messages?.[i].image_response_card?.title ?? null);
            addMessage(data.messages?.[i].image_response_card?.subtitle ?? null);
            addMessage(data.messages?.[i].content ?? null);
            if (
              data.messages?.[i].image_response_card?.buttons[0]?.value === create_ticket_string &&
              data.messages?.[i].image_response_card?.buttons[1]?.value === solved_string
            ) {
              switch (data?.session_state?.intent?.name) {
                case "Finanzas_CobroErroneo":
                  setType_support_ticket_id("15");
                  break;
                default:
                  setType_support_ticket_id("10");
              }
              const botonesGenerados: any = (
                <div>
                  <IonButton
                    className={"buttonPrimaryChatbot"}
                    id={data.messages?.[i].image_response_card?.buttons[0].value}
                    onClick={handleInputReasonWindow}
                    mode="ios"
                  >
                    {data.messages?.[i].image_response_card?.buttons[0].text}
                  </IonButton>
                  <IonButton
                    className={"buttonPrimaryChatbot"}
                    id={data.messages?.[i].image_response_card?.buttons[1].value}
                    onClick={(event) => handleInputReasonWindowNo(event)}
                    mode="ios"
                  >
                    {data.messages?.[i].image_response_card?.buttons[1].text}
                  </IonButton>
                </div>
              );
              setInputTextDisabled(true);
              setButtons(botonesGenerados);
            } else {
              generateResponseButtons(data.messages?.[i].image_response_card?.buttons ?? null);
            }
          } else {
            addMessage(data.messages?.[i].content ?? null);
          }
        }
      }
      //Tractar otros casos de tipo de solicitud y dentro de cada uno su tipo de mensaje
      if (data.session_state?.dialog_action?.type === "ConfirmIntent") {
        for (let i = 0; i < data.messages?.length; i++) {
          addMessage(data.messages?.[i].content ?? null);
        }
      }
      if (data.session_state?.dialog_action?.type === "ConfirmSlot") {
        for (let i = 0; i < data.messages?.length; i++) {
          addMessage(data.messages?.[i].content ?? null);
        }
      }
      if (data.session_state?.dialog_action?.type === "Delegate") {
        for (let i = 0; i < data.messages?.length; i++) {
          addMessage(data.messages?.[i].content ?? null);
        }
      }
      if (data.session_state?.dialog_action?.type === "ElicitIntent") {
        for (let i = 0; i < data.messages?.length; i++) {
          addMessage(data.messages?.[i].content ?? null);
        }
      }

      //Tratar casos en concreto

      //Caso de terminar la conversacion
      if (
        data?.session_state?.intent?.name == "OtraDuda" &&
        data?.session_state?.intent?.slots?.otherQuestion?.value?.interpreted_value == leave_string
      ) {
        setNoMoreQuestions(true);
      }

      //Caso de tickets y/o facturas
      if (
        data?.session_state?.intent?.name == "Finanzas_FacturaTicket" &&
        !isNaN(data?.session_state?.intent?.slots?.invoiceOrTicket?.value?.original_value)
      ) {
        charger_graph_id = data?.session_state?.intent?.slots?.invoiceOrTicket?.value?.original_value;
        let downloadButton;
        if (
          data?.session_state?.session_attributes?.inputAux == "generate" &&
          data?.session_state?.session_attributes?.businessAccount == "true"
        ) {
          downloadButton = (
            <div>
              <IonButton onClick={getPaymentTicket} className={"dangerButton"}>
                <IonIcon icon={downloadOutline} />
                {t("CHAT_BUTTONS.DOWNLOAD_TICKET_BUTTON")}
                <IonIcon icon={ticketOutline} />
              </IonButton>
              <IonButton onClick={getPaymentInvoice} className={"dangerButton"}>
                <IonIcon icon={downloadOutline} />
                {t("CHAT_BUTTONS.DOWNLOAD_INVOICE_BUTTON")}
                <IonIcon icon={ticketOutline} />
              </IonButton>
              <IonButton onClick={handleNoMoreQuestions} className={"buttonPrimaryChatbot"}>
                {t("CHAT_BUTTONS.LEAVE_BUTTON")}
              </IonButton>
            </div>
          );
        } else {
          downloadButton = (
            <div>
              <IonButton onClick={getPaymentTicket} className={"dangerButton"}>
                <IonIcon icon={downloadOutline} />
                {t("CHAT_BUTTONS.DOWNLOAD_TICKET_BUTTON")}
                <IonIcon icon={ticketOutline} />
              </IonButton>
              <IonButton onClick={handleNoMoreQuestions} className={"buttonPrimaryChatbot"}>
                {t("CHAT_BUTTONS.LEAVE_BUTTON")}
              </IonButton>
            </div>
          );
        }
        setInputTextDisabled(true);
        setButtons([buttons, downloadButton]);
      }

      //Caso crear ticket por mal cobro
      if (data?.session_state?.intent?.name == "Finanzas_CobroErroneo") {
        if (data?.session_state?.intent?.slots?.inputAux?.value?.original_value != "null") {
          setAccount(data?.session_state?.session_attributes?.inputAux);
        }
      }

      refreshSessionAttributes(data);
    }
  };

  const sendMessageToBot = async (newMessage: any) => {
    let message: any;
    let message_id: any;
    if (typeof newMessage === "string") {
      message = newMessage;
      message_id = newMessage;
    } else {
      message = newMessage.textContent;
      message_id = newMessage.id;
    }
    setNewMessage("");
    if (typeof message === "string") {
      if (message.trim() != "") {
        setIsLoading(true);
        setChatMessages((prevMessages) => [
          ...prevMessages,
          <div key={prevMessages.length + 1}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IonItem className="chatUserMessage">{message}</IonItem>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div>
                <IonIcon icon={personCircleOutline} />
              </div>
            </div>
          </div>,
        ]);

        const data = await sendMessageChatbotUser(user_id, language, sessionAttributes, message_id);
        refreshSessionAttributes(data);
        processMessage(data);
      }
    } else {
      setIsLoading(true);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        <div key={prevMessages.length + 1}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IonItem className="chatUserMessage">{message.textContent}</IonItem>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div>
              <IonIcon icon={personCircleOutline} />
            </div>
          </div>
        </div>,
      ]);

      const data = await sendMessageChatbotUser(user_id, language, sessionAttributes, message.id);
      refreshSessionAttributes(data);
      processMessage(data);
    }
    setIsLoading(false);
  };

  const sendSupportTicketToBot = async (
    ticketReason: any,
    account: any,
    type_support_ticket_id: any,
    powerErrorTicket: any,
  ) => {
    setIsLoading(true);
    sessionAttributes.chargerPowerProblems = null;
    setTicketReason("");
    const data: any = await sendSupportTicketChatbot(
      user_id,
      language,
      sessionAttributes,
      ticketReason,
      account,
      type_support_ticket_id,
    );
    if (powerErrorTicket) {
      //Crea el ticket y no muestra mensaje de salida.
    } else {
      if (data?.response == "ticket_created") {
        setShowSuccessTicketCreated(true);
      } else {
        setShowFailedTicketCreated(true);
      }
    }
  };

  const refreshSessionAttributes = (data: any) => {
    if (data != null && data.session_attributes) {
      if (
        data.session_state.session_attributes.IDCharger != undefined &&
        data.session_state.session_attributes.IDCharger != null
      )
        sessionAttributes.IDCharger = data.session_state.session_attributes.IDCharger;
      if (
        data.session_state.session_attributes.inputHowToCharge != undefined &&
        data.session_state.session_attributes.inputHowToCharge != null
      )
        sessionAttributes.inputHowToCharge = data.session_state.session_attributes.inputHowToCharge;
      if (
        data.session_state.session_attributes.inputStopCharge != undefined &&
        data.session_state.session_attributes.inputStopCharge != null
      )
        sessionAttributes.inputStopCharge = data.session_state.session_attributes.inputStopCharge;
      if (
        data.session_state.session_attributes.menuOption != undefined &&
        data.session_state.session_attributes.menuOption != null
      )
        sessionAttributes.menuOption = data.session_state.session_attributes.menuOption;
      if (
        data.session_state.session_attributes.inputPaymentProblems != undefined &&
        data.session_state.session_attributes.inputPaymentProblems != null
      )
        sessionAttributes.inputPaymentProblems = data.session_state.session_attributes.inputPaymentProblems;
      if (
        data.session_state.session_attributes.inputPowerProblems != undefined &&
        data.session_state.session_attributes.inputPowerProblems != null
      )
        sessionAttributes.inputPowerProblems = data.session_state.session_attributes.inputPowerProblems;
      if (
        data.session_state.session_attributes.inputAux != undefined &&
        data.session_state.session_attributes.inputAux != null
      )
        sessionAttributes.inputAux = data.session_state.session_attributes.inputAux;
      if (
        data.session_state.session_attributes.invoiceOrTicket != undefined &&
        data.session_state.session_attributes.invoiceOrTicket != null
      )
        sessionAttributes.invoiceOrTicket = data.session_state.session_attributes.invoiceOrTicket;
      if (
        data.session_state.session_attributes.chargerPowerProblems != undefined &&
        data.session_state.session_attributes.chargerPowerProblems != null
      )
        sessionAttributes.chargerPowerProblems = data.session_state.session_attributes.chargerPowerProblems;
      if (data.session_state.session_attributes.page != undefined && data.session_state.session_attributes.page != null)
        sessionAttributes.page = data.session_state.session_attributes.page;
      if (
        data.session_state.session_attributes.IDAccount != undefined &&
        data.session_state.session_attributes.IDAccount != null
      )
        sessionAttributes.IDAccount = data.session_state.session_attributes.IDAccount;
      sessionAttributes.businessAccount = data.session_state.session_attributes.businessAccount;
    }
  };
  return (
    <IonPage>
      <AppHeader title={t("MENU.HELP")} hiddenMenu endIcon={<IconButton icon={closeOutline} onClick={handleClose} />} />
      <IonHeader className="ion-no-border" mode="ios">
        <IonToolbar style={{ "--background": "var(--ion-color-chattitle)" }}>
          <IonLabel style={{ fontWeight: "bold" }}>
            <div style={{ display: "flex", gap: "8px", justifyContent: "center", alignItems: "center" }}>
              <div>{t("CHAT_BUTTONS.CHAT_TITLE")}</div>
              {parameters.beta_features && <StatusLabel active>BETA</StatusLabel>}
            </div>
          </IonLabel>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={true} style={{ "--background": "var(--ion-color-chatbackground)" }} ref={contentRef}>
        <IonList
          ref={contentRef}
          lines="none"
          style={{
            padding: "2px",
            marginTop: "15px",
            marginLeft: "20px",
            marginRight: "20px",
            bottom: "0px",
            position: "bottom",
          }}
        >
          {chatMessages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
          <div>{buttons}</div>
          {isLoading && (
            <div>
              <IonIcon icon={person} />
              <IonItem
                className="number"
                style={{
                  background: "var(--ion-color-botmessage)",
                  borderRadius: "10px",
                  padding: "2px",
                  marginBottom: "10px",
                  width: "fit-content",
                }}
              >
                <IonSpinner className="sendButtonIcon" name="dots" />
              </IonItem>
            </div>
          )}
        </IonList>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={handleAlertDismiss}
          message={
            new IonicSafeString(
              `<div class="alert">
                        <img style="border-radius: 2px">
                        <ion-label class="titleH3">${t("ALERT.CLOSE_CHAT_SESSION")}</ion-label>
                        <ion-label class="alertText">${t("ALERT.CLOSE_CHAT_SESSION_MESSAGE")}</ion-label>
                        </div>`,
            )
          }
          buttons={[
            `${t("ALERT_BUTTONS.CANCEL")}`,
            {
              text: `${t("ALERT_BUTTONS.EXIT")}`,
              handler: handleDeleteChatSession,
            },
          ]}
          cssClass="alertContainer"
        />
        <IonAlert
          isOpen={showAlertSelectOption}
          onDidDismiss={handleAlertDismiss}
          message={
            new IonicSafeString(
              `<div class="alert">
                        <img style="border-radius: 2px">
                        <ion-label class="titleH3">${t("ALERT.ERROR_SELECT_OPTION")}</ion-label>
                        </div>`,
            )
          }
          buttons={[`${t("ALERT_BUTTONS.EXIT")}`]}
          cssClass="alertContainer"
        />
        <IonAlert
          isOpen={showSuccessTicketCreated}
          onDidDismiss={handleDeleteChatSession}
          message={
            new IonicSafeString(
              `<div class="alert">
                        <img style="border-radius: 2px">
                        <ion-label class="titleH3">${t("ALERT.INFO_TICKET_CREATED")}</ion-label>
                        </div>`,
            )
          }
          buttons={[
            {
              text: t("BUTTONS.CLOSE"),
              handler: handleDeleteChatSession,
            },
          ]}
          cssClass="alertContainer"
        />
        <IonAlert
          isOpen={showFailedTicketCreated}
          onDidDismiss={handleDeleteChatSession}
          message={
            new IonicSafeString(
              `<div class="alert">
                        <img style="border-radius: 2px">
                        <ion-label class="titleH3">${t("ALERT.INFO_TICKET_FAILED")}</ion-label>
                        </div>`,
            )
          }
          buttons={[
            {
              text: t("BUTTONS.CLOSE"),
              handler: handleDeleteChatSession,
            },
          ]}
          cssClass="alertContainer"
        />
        <IonAlert
          isOpen={showConfirmSupport}
          onDidDismiss={() => setShowConfirmSupport(false)}
          header={translatedHeader}
          buttons={[
            {
              text: t("CHAT_BUTTONS.YES"),
              handler: handleSendReasonConfirmed,
            },
            {
              text: t("CHAT_BUTTONS.NO"),
              handler: handleCancelReasonWindow,
            },
            {
              text: t("CHAT_BUTTONS.NO_OTHER"),
              handler: handleRestartChatSession,
            },
          ]}
        />
        <IonAlert
          isOpen={isInactive}
          onDidDismiss={() => handleDeleteChatSession()}
          message={
            new IonicSafeString(
              `<div class="alert">
                        <img style="border-radius: 2px">
                        <ion-label class="inactiveAlert">${t("ALERT.INFO_SESSION_CLOSED")}</ion-label>
                        </div>`,
            )
          }
          cssClass="centeredAlertButton"
          buttons={[
            {
              text: "Aceptar",
              handler: handleDeleteChatSession,
            },
          ]}
        />
      </IonContent>
      {noMoreQuestions ? (
        <>
          <IonFooter style={{ background: "var(--ion-color-toolbarcolor)", paddingTop: "17px", paddingBottom: "17px" }}>
            <div className="rowFooter">
              <IonButton
                mode="ios"
                onClick={handleRestartChatSession}
                style={{ marginLeft: "15px", marginRight: "15px" }}
              >
                <IonIcon icon={chatbox} slot="start" />
                <>{t("CHAT_BUTTONS.NEW_CHAT")}</>
              </IonButton>
            </div>
          </IonFooter>
        </>
      ) : (
        <>
          <IonFooter className="chatbotFooter">
            {isLoading ? (
              <IonRow className="rowFooter">
                <IonTextarea
                  disabled={true}
                  className="chatbotTextArea"
                  value={newMessage}
                  placeholder={t("PLACEHOLDERS.TYPE_MESSAGE")}
                  onIonChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                />
                <IonButton className="chatbotSendButton">
                  <div>
                    <IonSpinner className="sendButtonIcon" />
                  </div>
                </IonButton>
              </IonRow>
            ) : (
              <>
                {inputTextDisabled ? (
                  <IonRow className="rowFooter">
                    <IonTextarea
                      className="chatbotTextArea"
                      disabled={true}
                      placeholder={t("PLACEHOLDERS.TYPE_MESSAGE")}
                    />
                    <IonButton onClick={handleSelectAButton} className="chatbotSendButton">
                      <div>
                        <IonIcon className="sendButtonIcon" icon={sendOutline} slot="start" />
                      </div>
                    </IonButton>
                  </IonRow>
                ) : (
                  <>
                    {showReasonInput ? (
                      <IonRow className="rowFooter">
                        <IonTextarea
                          className="chatbotTextArea"
                          value={ticketReason}
                          placeholder={t("PLACEHOLDERS.TYPE_MESSAGE")}
                          onIonChange={handleInputReasonWindowTextArea}
                          onKeyDown={handleKeyPressTicket}
                        />
                        <IonButton onClick={handleSendReason} className="chatbotSendButton">
                          <div>
                            <IonIcon className="sendButtonIcon" icon={sendOutline} slot="start" />
                          </div>
                        </IonButton>
                      </IonRow>
                    ) : (
                      <IonRow>
                        <IonTextarea
                          className="chatbotTextArea"
                          value={newMessage}
                          placeholder={t("PLACEHOLDERS.TYPE_MESSAGE")}
                          onIonInput={handleInputChange}
                          onKeyDown={handleKeyPress}
                        />
                        <IonButton onClick={() => sendMessageToBot(newMessage)} className="chatbotSendButton">
                          <div>
                            <IonIcon className="sendButtonIcon" icon={sendOutline} slot="start" />
                          </div>
                        </IonButton>
                      </IonRow>
                    )}
                  </>
                )}
              </>
            )}
          </IonFooter>
        </>
      )}
    </IonPage>
  );
};
