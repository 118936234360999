import { CartProductModel, MerchantProductModel } from "@/core/models/accountMerchantModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ShopSlice {
  products: MerchantProductModel[];
  shop: Record<number, CartProductModel[] | []>;
}

export const shopSliceInitialState: ShopSlice = {
  products: [],
  shop: {},
};

export const shopSlice = createSlice({
  name: "shopReducer",
  initialState: shopSliceInitialState,
  reducers: {
    setProducts: (state, { payload: newProduct }: PayloadAction<MerchantProductModel[]>) => {
      state.products = newProduct;
    },
    addProductToShop: (state, action: PayloadAction<{ newProductId: number; user_id: number }>) => {
      const { newProductId: newProduct, user_id } = action.payload;
      const product = state.products.find((product) => product.id === newProduct);
      const userProducts = state.shop[user_id] || [];

      if (!product) return;

      const shopProductUser = userProducts.find((product) => product.id === newProduct);
      if (shopProductUser) {
        shopProductUser.uds++;
      } else {
        state.shop[user_id] = [...userProducts, { id: newProduct, uds: 1 }];
      }
    },
    removeProductFromShop: (state, action: PayloadAction<{ productToRemove: number; user_id: number }>) => {
      const { productToRemove, user_id } = action.payload;
      const product = state.products.find((product) => product.id === productToRemove);
      const userProducts = state.shop[user_id] || [];

      if (!product) return;

      const shopProductUser = userProducts.find((product) => product.id === productToRemove);

      if (!shopProductUser) return;

      shopProductUser.uds > 1
        ? shopProductUser.uds--
        : (state.shop[user_id] = state.shop[user_id].filter((product) => product.id !== productToRemove));
    },
    removeAllProductFromShop: (state, action: PayloadAction<{ productIdToRemove: number; user_id: number }>) => {
      const { productIdToRemove: productToRemove, user_id } = action.payload;
      const userProducts = state.shop[user_id];

      if (!userProducts) return;

      const shopProductUser = userProducts.find((product) => product.id === productToRemove);
      if (shopProductUser) {
        state.shop[user_id] = state.shop[user_id].filter((product) => product.id !== productToRemove);
      }
    },
    cleanCart: (state, action: PayloadAction<{ user_id: number }>) => {
      delete state.shop[action.payload.user_id];
    },
  },
});

export const shopActions = shopSlice.actions;

export default shopSlice.reducer;
