import { CHARGER_SOCKET_CONNECTOR_ICON, ChargeSocketConnectorFilterItem } from "@/commons/constants/charger";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { CHARGER_SOCKET_CONNECTOR_TYPE } from "@/core/models/chargerModel";
import { mapActions } from "@/core/store/slices/mapSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonIcon, IonLabel } from "@ionic/react";
interface FilterConnectorProps {
  connector: [string, ChargeSocketConnectorFilterItem];
}

export const FilterConnector = ({ connector }: FilterConnectorProps) => {
  const dispatch = useAppDispatch();

  const key = connector[0] as CHARGER_SOCKET_CONNECTOR_TYPE;
  const { name } = connector[1];

  const { selectedFilters } = useAppSelector((state) => state.mapReducer.filters);

  const handleClick = (key: CHARGER_SOCKET_CONNECTOR_TYPE) => {
    let newConnectorTypes = [...selectedFilters.connectorTypes];
    let newFilterNumber = selectedFilters.filterNumber;

    if (newConnectorTypes.includes(key)) {
      newConnectorTypes = newConnectorTypes.filter((item) => item !== key);
      newFilterNumber--;
    } else {
      newConnectorTypes.push(key);
      newFilterNumber++;
    }

    dispatch(
      mapActions.setSelectedFilters({
        ...selectedFilters,
        connectorTypes: newConnectorTypes,
        filterNumber: newFilterNumber,
      }),
    );
  };
  return (
    <div
      className={`filter-item ${classIf(selectedFilters.connectorTypes.includes(key), "selected")}`}
      onClick={() => handleClick(key)}
    >
      <IonIcon icon={CHARGER_SOCKET_CONNECTOR_ICON[key]} className="icon-filter connector-icon" />
      <IonLabel>{name}</IonLabel>
    </div>
  );
};
