import { ChargerImages } from "@/commons/components/Map/ChargerImages/ChargerImages";
import { DAYS_OF_WEEK } from "@/commons/constants/date";
import { LocationModel } from "@/core/models/locationModel";
import { IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import { timeOutline } from "ionicons/icons";
import "./LocationMoreInfo.scss";
import { LocationMoreInfoExceptionals } from "./LocationMoreInfoExceptionals/LocationMoreInfoExceptionals";
import { LocationMoreInfoServices } from "./LocationMoreInfoServices/LocationMoreInfoServices";

interface LocationMoreInfoProps {
  locationInfo: LocationModel;
}

export const LocationMoreInfo = ({ locationInfo }: LocationMoreInfoProps) => {
  return (
    <div className="location-more-info">
      <ChargerImages chargers={locationInfo.chargers} />
      <IonRow>
        <IonCol>
          <IonLabel className="location-more-info-title">{t("BOOKING_INFO.SCHEDULE")}</IonLabel>
          <IonRow className="location-more-info-schedule">
            <IonIcon icon={timeOutline} className="location-more-info-icon" />
            <IonCol className="regular-hours">
              {locationInfo.regular_hours.length ? (
                <>
                  {locationInfo.regular_hours.map((regularHours, index) => (
                    <IonRow key={index}>
                      <IonLabel key={index} className="regular-hours-days">
                        {regularHours.days
                          .map((day) => {
                            const dayTranslation = DAYS_OF_WEEK.find(
                              (dayOfWeek) => dayOfWeek.key === day.toLowerCase(),
                            );
                            return dayTranslation ? dayTranslation.name : day;
                          })
                          .join(", ")}
                      </IonLabel>
                      <IonLabel className="regular-hours-hours">{`${regularHours.period_begin} - ${regularHours.period_end}`}</IonLabel>
                    </IonRow>
                  ))}
                </>
              ) : (
                <IonLabel>{t("BOOKING_INFO.ATTENDANCE_DAYS")}</IonLabel>
              )}
            </IonCol>
          </IonRow>
        </IonCol>
        {locationInfo.facilities?.length > 0 && <LocationMoreInfoServices facilities={locationInfo.facilities} />}
      </IonRow>
      {(locationInfo.exceptional_periods_close.length > 0 || locationInfo.exceptional_periods_open.length > 0) && (
        <LocationMoreInfoExceptionals locationInfo={locationInfo}></LocationMoreInfoExceptionals>
      )}
      {locationInfo.additional_info && (
        <IonRow>
          <IonCol>
            <div className="location-more-info-title">{t("LOCATION.ADDITIONAL_INFO")}</div>
            <div
              className="location-more-info-additional-info"
              dangerouslySetInnerHTML={{ __html: locationInfo.additional_info }}
            />
          </IonCol>
        </IonRow>
      )}
    </div>
  );
};
