import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { CreditCard } from "@/commons/components/Payments/CreditCard/CreditCard";
import { WalletView } from "@/commons/components/Payments/WalletView/WalletView";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { usePayment } from "@/commons/hooks/usePayment";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { uiActions } from "@/core/store/slices/uiSlice";
import { walletActions } from "@/core/store/slices/walletSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import { t } from "i18next";
import { addCircleOutline, cardOutline, createOutline, timeOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CreditCardListSkeleton } from "./CreditCardListSkeleton/CreditCardListSkeleton";
import { WalletHistoryModal } from "./WalletHistoryModal/WalletHistoryModal";
import "./WalletPage.scss";

export const WalletPage = () => {
  const dispatch = useAppDispatch();
  const router = useHistory();
  const { loadUserInfo } = useUserContext();
  const { isWallet } = usePayment();
  const { parameters } = useParametersContext();

  const { userInfo, isUserLogged } = useAppSelector((state) => state.userReducer);
  const haveCreditCard = userInfo?.stripe_payment_methods.length !== 0;

  const [isLoading, setIsLoading] = useState(true);
  const [showActions, setShowActions] = useState(false);

  useIonViewWillEnter(() => {
    if (!isUserLogged) {
      router.replace(APP_PATH.HOME);
    }

    loadData();
    setShowActions(false);
  }, [parameters]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      await loadUserInfo();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCreditCard = () => {
    router.push(APP_PATH.WALLET_ADD_CREDITCARD);
  };

  return (
    <IonPage>
      <AppHeader
        title={t("MENU.WALLET")}
        endIcon={
          isWallet() && <IconButton icon={timeOutline} onClick={() => dispatch(walletActions.setShowHistory(true))} />
        }
      />
      <WalletHistoryModal />
      <IonContent className="wallet-page">
        <div className="wallet-page-content">
          {isLoading ? (
            <>
              <CreditCardListSkeleton /> {/* TODO: Change this skeleton for wallet */}
              <CreditCardListSkeleton />
            </>
          ) : (
            <>
              {isWallet() && <WalletView />}

              <div className="credit-cards-container">
                <IonRow className="credit-cards-header">
                  <IonLabel className="strong-text">{t("TITLES.CREDIT_CARD")}</IonLabel>
                  {haveCreditCard && (
                    <IonButtons className="credit-cards-header-edit-button">
                      <IconButton icon={createOutline} onClick={() => setShowActions(!showActions)} />
                    </IonButtons>
                  )}
                </IonRow>
                {haveCreditCard ? (
                  <div className="credit-cards-list">
                    {userInfo?.stripe_payment_methods.map((card) => (
                      <CreditCard key={card.id} creditCard={card} isSelected={false} showActions={showActions} />
                    ))}
                  </div>
                ) : (
                  <div className="credit-cards-list-empty">
                    <IonRow>
                      <IonIcon className="card-icon" icon={cardOutline} />
                    </IonRow>
                    <IonText>{t("CREDIT_CARD.ANY_DETECTED")}</IonText>
                  </div>
                )}
                <IonButton color="primary" fill={haveCreditCard ? "outline" : "solid"} onClick={handleAddCreditCard}>
                  <IonIcon slot="start" icon={addCircleOutline}></IonIcon>
                  {t("BUTTONS.ADD_CARD")}
                </IonButton>
              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
