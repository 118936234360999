import { AlertType, AlertTypeOptions } from "@/commons/components/Alert/alertTypes";
import { ToastType, ToastTypeOptions } from "@/commons/components/Toast/toastTypes";
import { ErrorModel } from "@/core/models/apiModel";
import { ChargerModel } from "@/core/models/chargerModel";
import { ConnectionStatus } from "@capacitor/network";
import { Color } from "@ionic/core";
import { ToastButton } from "@ionic/react";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";

export interface UiSlice {
  isLoadingApp: boolean;
  alert: {
    show: boolean;
    type?: AlertType;
    options?: AlertTypeOptions;
  };
  toast: {
    show: boolean;
    type?: ToastType;
    message?: string;
    duration?: number;
    buttons?: (string | ToastButton)[];
    position?: "top" | "bottom" | "middle";
    color?: Color;
    options?: ToastTypeOptions;
  };
  help: {
    show: boolean;
    charger: ChargerModel | null;
    step: number;
  };
  isRateAppModalOpen: boolean;
  isMarketingModalOpen: boolean;
  marketingNotificationId?: number | undefined;
  isKeyboardOpen: boolean;
  isCartModalOpen: boolean;
  isShopHistoryOpen: boolean;
  nfc: {
    isNfcOpen: boolean;
    showManualKeychain: boolean;
    isAddManualKeychainOpen: boolean;
  };
  network?: ConnectionStatus;
}

export const uiInitialState: UiSlice = {
  isLoadingApp: true,
  alert: {
    show: false,
  },
  toast: {
    show: false,

    message: "",
    duration: undefined,
    buttons: [],
    position: undefined,
    color: undefined,
  },
  help: {
    show: false,
    charger: null,
    step: 0,
  },
  isRateAppModalOpen: false,
  isMarketingModalOpen: false,
  marketingNotificationId: undefined,
  isKeyboardOpen: false,
  isCartModalOpen: false,
  isShopHistoryOpen: false,
  nfc: {
    isNfcOpen: false,
    showManualKeychain: false,
    isAddManualKeychainOpen: false,
  },
};

export const uiSlice = createSlice({
  name: "uiReducer",
  initialState: uiInitialState,
  reducers: {
    // Loading
    setLoadingApp: (state, action: PayloadAction<boolean>) => {
      state.isLoadingApp = action.payload;
    },
    // Alert
    setAlertType: (state, action: PayloadAction<{ type: AlertType; options?: AlertTypeOptions }>) => {
      state.alert = {
        ...state.alert,
        show: true,
        type: action.payload.type,
        options: action.payload.options,
      };
    },
    cleanAlert: (state) => {
      state.alert = uiInitialState.alert;
    },
    // Toast
    setToast: (state, action: PayloadAction<UiSlice["toast"]>) => {
      state.toast = action.payload;
    },
    setToastType: (state, action: PayloadAction<{ type: ToastType; options?: ToastTypeOptions }>) => {
      state.toast = {
        ...state.toast,
        show: true,
        type: action.payload.type,
        options: action.payload.options,
      };
    },
    setToastError: (state, { payload }: PayloadAction<any>) => {
      const errorPayload = payload as ErrorModel;

      const errorMessage = errorPayload.jsonError?.error_code
        ? t(`API_ERRORS.${errorPayload.jsonError?.error_code}`)
        : errorPayload?.message;

      let message = `${errorMessage || payload || ""}`.trim();

      if (message === "Failed to fetch" || errorPayload.status === 500) message = t("ALERT.SERVER_ERROR");

      console.error(payload);

      state.toast = {
        ...state.toast,
        show: true,
        type: "error",
        message,
      };
    },
    cleanToast: (state) => {
      state.toast = uiInitialState.toast;
    },
    // Help
    showHelp: (state, action: PayloadAction<any>) => {
      state.help = {
        ...state.help,
        show: true,
        charger: action.payload.charger,
        step: action.payload.step,
      };
    },
    cleanHelp: (state) => {
      state.help = uiInitialState.help;
    },
    // Rate App
    setIsRateAppModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isRateAppModalOpen = action.payload;
    },
    setIsMarketingModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isMarketingModalOpen = action.payload;
    },
    setMarketingNotificationId: (state, action: PayloadAction<number | undefined>) => {
      state.marketingNotificationId = action.payload;
    },
    // Keyboard
    setIsKeyboardOpen: (state, action: PayloadAction<boolean>) => {
      state.isKeyboardOpen = action.payload;
    },
    setIsCartModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCartModalOpen = action.payload;
    },
    setIsShopHistoryOpen: (state, action: PayloadAction<boolean>) => {
      state.isShopHistoryOpen = action.payload;
    },
    setNfc: (state, action: PayloadAction<{ isNfcOpen: boolean; showManualKeychain?: boolean }>) => {
      state.nfc.isNfcOpen = action.payload.isNfcOpen;
      state.nfc.showManualKeychain = !!action.payload.showManualKeychain;
    },
    setIsAddManualKeychainOpen: (state, action: PayloadAction<boolean>) => {
      state.nfc.isAddManualKeychainOpen = action.payload;
    },
    // Network
    setNetwork: (state, action: PayloadAction<ConnectionStatus>) => {
      state.network = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
