import i18n from "@/assets/i18n/i18n";
import keychainBanner from "@/assets/imgs/keychain-banner.png";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { AccountMerchantModel } from "@/core/models/accountMerchantModel";
import { SetState } from "@/core/models/globalModel";
import { getDocumentDownloadApiService } from "@/core/services/document/documentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonAccordion, IonCol, IonItem, IonLabel, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { downloadOutline } from "ionicons/icons";
import "./ShopHistoryAccountMerchant.scss";

interface ShopHistoryAccountMerchant {
  accountMerchant: AccountMerchantModel;
  setIsLoadingDownload: SetState<boolean>;
}

export const ShopHistoryAccountMerchant = ({ accountMerchant, setIsLoadingDownload }: ShopHistoryAccountMerchant) => {
  const { parameters } = useParametersContext();
  const dispatch = useAppDispatch();

  const formattedDate = format(new Date(accountMerchant.created_at), "dd/MM/yyyy");

  const getMerchantTicket = async () => {
    try {
      setIsLoadingDownload(true);
      await getDocumentDownloadApiService(
        `ticket_${accountMerchant.id}.pdf`,
        "account_merchants/show_merchant_ticket",
        {
          account_id: accountMerchant.id,
          language: i18n.language,
        },
      );
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoadingDownload(false);
    }
  };

  return (
    <IonAccordion value={`${accountMerchant.id}`}>
      <IonItem slot="header" color="light">
        <IconButton icon={downloadOutline} onClick={getMerchantTicket} color="dark" />
        <IonLabel>
          {t("KEYCHAIN.ACCOUNT_MERCHANT")}: #{accountMerchant.id}
        </IonLabel>
        <IonLabel>{formattedDate}</IonLabel>
      </IonItem>
      <div className="account-merchant-content" slot="content">
        {accountMerchant.account_merchant_details.map((detail) => (
          <div key={detail.id}>
            <IonItem lines="none" className="account-merchant-product">
              <IonCol size="4">
                <img src={detail.product.image || keychainBanner} alt="product" />
              </IonCol>
              <IonCol>
                <IonRow>
                  <IonLabel style={{ marginRight: "15px" }}>{detail.uds}</IonLabel>
                  <IonLabel>{detail.product.name}</IonLabel>
                </IonRow>
              </IonCol>
              <IonCol size="3">
                {(detail.amount || 0) / 100} {parameters.currency}
              </IonCol>
            </IonItem>
          </div>
        ))}
        <IonRow>
          <IonLabel style={{ marginLeft: "20px", fontStyle: "italic" }}>+ {t("BUTTONS.SHIPPING_COST")}</IonLabel>
        </IonRow>
        <IonRow>
          <IonLabel style={{ marginLeft: "20px", fontWeight: "600", marginTop: "16px" }}>
            {t("TITLES.TOTAL").toUpperCase()} {(accountMerchant.amount || 0) / 100} {parameters.currency}
          </IonLabel>
        </IonRow>
      </div>
    </IonAccordion>
  );
};
