import keychainBanner from "@/assets/imgs/keychain-banner.png";
import { formatNumber } from "@/commons/utils/numberUtils";
import { CartProductModel, MerchantProductModel } from "@/core/models/accountMerchantModel";
import { shopActions } from "@/core/store/slices/shopSlice";
import { useAppSelector } from "@/core/store/store";
import { IonCol, IonItem, IonLabel, IonText, IonThumbnail } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ShopProductButtons } from "../ShopProductButtons/ShopProductButtons";
import "./ShopCart.scss";

interface ShopCartProductProps {
  productCart: CartProductModel;
}

export const ShopCartProduct = ({ productCart }: ShopCartProductProps) => {
  const dispatch = useDispatch();

  const { products } = useAppSelector((state) => state.shopReducer);
  const { user_id } = useAppSelector((state) => state.userReducer.user);

  const [product, setProduct] = useState<MerchantProductModel>();
  const [priceInfo, setPriceInfo] = useState({
    price: 0,
    discount: 0,
    discountPrice: 0,
  });

  useEffect(() => {
    const newProductInfo = products.find((pd) => pd.id === productCart.id);

    if (!newProductInfo) {
      dispatch(shopActions.removeAllProductFromShop({ productIdToRemove: productCart.id, user_id: user_id }));
      return;
    }

    setPriceInfo({
      price: newProductInfo.price_ud / 100,
      discount: newProductInfo.discount / 100,
      discountPrice: newProductInfo.price_ud / 100 - newProductInfo.discount / 100,
    });

    setProduct(newProductInfo);
  }, [productCart.id]);

  return (
    <>
      {product && (
        <IonItem lines="none" className="cart-product">
          <IonCol size="4">
            <img src={product.image_url || keychainBanner} alt="product" />
          </IonCol>
          <IonCol size="4">
            <IonThumbnail slot="start" />
            <IonLabel>{product?.name}</IonLabel>
            {!priceInfo.discount ? (
              <IonText>
                {formatNumber(priceInfo.price, { minimumFractionDigits: 2 })} {product.currency}
              </IonText>
            ) : (
              <div className="shop-cart-product-discount">
                <IonText color="primary">
                  {formatNumber(priceInfo.discountPrice, { minimumFractionDigits: 2 })} {product.currency}
                </IonText>
                <IonText className="shop-cart-product-price-out">
                  {formatNumber(priceInfo.price, { minimumFractionDigits: 2 })} {product.currency}
                </IonText>
              </div>
            )}
          </IonCol>
          <IonCol size="4">
            <ShopProductButtons product={product} productCart={productCart} size="small" />
          </IonCol>
        </IonItem>
      )}
    </>
  );
};
