import { combineReducers, configureStore, ThunkAction, UnknownAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import chargeStepsReducer from "./slices/chargeStepsSlice";
import chargesReducer from "./slices/chargesSlice";
import incidenceReducer from "./slices/incidenceSlice";
import mapReducer from "./slices/mapSlice";
import parametersReducer from "./slices/parametersSlice";
import reservationStepsReducer from "./slices/reservationStepsSlice";
import shopReducer, { ShopSlice } from "./slices/shopSlice";
import uiReducer from "./slices/uiSlice";
import userFormReduce from "./slices/userFormSlice";
import userReducer, { UserSlice } from "./slices/userSlice";
import walletReducer from "./slices/walletSlice";

const { VITE_CODE_APP, VITE_HOST } = import.meta.env;

const persistConfig = {
  key: `user-${VITE_CODE_APP}-${VITE_HOST}`,
  storage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  parametersReducer,
  uiReducer,
  userReducer: persistReducer<UserSlice>(persistConfig, userReducer),
  userFormReduce,
  mapReducer,
  chargeStepsReducer,
  reservationStepsReducer,
  chargesReducer,
  walletReducer,
  shopReducer: persistReducer<ShopSlice>({ ...persistConfig, key: `shop-${VITE_CODE_APP}-${VITE_HOST}` }, shopReducer),
  incidenceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const mockStore = configureStore({
  devTools: false,
  reducer: rootReducer,
});

export const persistor = persistStore(store);

export type StoreReducer = keyof ReturnType<typeof rootReducer>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkAppAction = ThunkAction<void, RootState, unknown, UnknownAction>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
