import { AlertModal } from "@/commons/components/UI/AlertModal/AlertModal";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonLabel } from "@ionic/react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./Alert.scss";
import { alertTypesComponent } from "./alertTypes";

export const Alert = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { show, type, options } = useAppSelector((state) => state.uiReducer.alert);

  const handleDismiss = () => {
    dispatch(uiActions.cleanAlert());
  };

  const customType = (type && alertTypesComponent(t, history, options)[type]) || null;

  if (!customType) return null;

  const {
    i18n_key,
    title,
    message,
    primaryText,
    primaryAction,
    secondaryText,
    secondaryAction,
    hiddenSecondary,
    closingTime,
  } = customType;

  return (
    <AlertModal
      isOpen={show}
      handleDismiss={handleDismiss}
      primaryText={primaryText || t("BUTTONS.ACCEPT")}
      primaryAction={primaryAction || handleDismiss}
      secondaryText={secondaryText}
      secondaryAction={secondaryAction}
      hideSecondary={hiddenSecondary === false ? false : true}
      closingTime={closingTime}
    >
      <div className="alert-global">
        {customType.illustration && (
          <div className="alert-illustration">
            <customType.illustration />
          </div>
        )}

        <IonLabel class="alert-global-title">{i18n_key ? <Trans i18nKey={`${i18n_key}_TITLE`} /> : title}</IonLabel>
        <IonLabel class="alert-global-text">{i18n_key ? <Trans i18nKey={`${i18n_key}_TEXT`} /> : message}</IonLabel>
      </div>
    </AlertModal>
  );
};
