import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { Illustration9 } from "@/commons/constants/imgs";
import { useQuery } from "@/commons/hooks/useQuery";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { VehicleBrand, VehicleBrandModel, VehicleFormModel, VehicleModel } from "@/core/models/userModel";
import { getVehicleBrandsService } from "@/core/services/user/vehicleUserServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonIcon,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
} from "@ionic/react";
import { t } from "i18next";
import { addCircleOutline, createOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { FormVehicleModal } from "./FormVehicleModal/FormVehicleModal";
import { VehicleCard } from "./VehicleCard/VehicleCard";
import { VehicleCardSkeleton } from "./VehicleCardSkeleton";
import "./VehiclesPage.scss";

const DEFAULT_FORM_VEHICLE: VehicleFormModel = {
  carId: "",
};

export const VehiclesPage = () => {
  const dispatch = useAppDispatch();
  const { query } = useQuery();

  const { loadUserInfo } = useUserContext();

  const { userInfo } = useAppSelector((state) => state.userReducer);
  const userVehicles = userInfo?.vehicles || [];

  const [isLoading, setIsLoading] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [modalType, setModalType] = useState<"ADD" | "EDIT">();
  const [vehicleEdit, setVehicleEdit] = useState(DEFAULT_FORM_VEHICLE);

  const [vehicleBrands, setVehicleBrands] = useState<VehicleBrand[]>([]);
  const [vehicleModels, setVehicleModels] = useState<Record<string, VehicleBrandModel[]>>({});

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (query.createVehicle) {
      handleAddVehicleClick();
    }
  }, [query]);

  const loadData = async () => {
    try {
      setFirstRender(false);
      setIsLoading(true);
      const promises: any = [loadUserInfo()];

      firstRender && promises.push(getBrandsVehicles());

      await Promise.all(promises);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const getBrandsVehicles = async () => {
    try {
      const newVehiclesBrands = await getVehicleBrandsService();
      const newVehicleModels: Record<string, VehicleBrandModel[]> = {};
      newVehiclesBrands.forEach((brand) => {
        newVehicleModels[brand.id] = brand.models;
      });

      setVehicleBrands(newVehiclesBrands);
      setVehicleModels(newVehicleModels);
    } catch (error) {
      console.error(error);
      dispatch(uiActions.setToastType({ type: "error" }));
    }
  };

  const handleAddVehicleClick = () => {
    setModalType("ADD");
    setVehicleEdit(DEFAULT_FORM_VEHICLE);
  };

  const handleEditVehicleClick = (vehicle: VehicleModel) => {
    setModalType("EDIT");
    setVehicleEdit({
      vehicle_id: vehicle.vehicle_id,
      carId: vehicle.car_id,
      vehicleBrandId: Number(vehicle.vehicle_brand_id),
      vehicleModelId: Number(vehicle.vehicle_model_id),
      vehicle: vehicle.vehicle,
      model: vehicle.model,
      predetermined: vehicle.predetermined,
    });
  };

  const handleSuccess = () => {
    setVehicleEdit(DEFAULT_FORM_VEHICLE);
    loadData();
  };

  return (
    <IonPage className="vehicles-page">
      <FormVehicleModal
        vehicleBrands={vehicleBrands}
        vehicleModels={vehicleModels}
        modalType={modalType}
        setModalType={setModalType}
        vehicleEdit={vehicleEdit}
        handleSuccess={handleSuccess}
      />

      <AppHeader
        title={t("TITLES.MY_VEHICLES")}
        endIcon={
          <IonCol
            onClick={() => setShowEdit(!showEdit)}
            className="ion-justify-content-center toolbarCol editCol"
            style={{ width: "fit-content", paddingTop: "5px" }}
          >
            <IonIcon icon={createOutline} size="large"></IonIcon>
            <IonLabel className="filtrosLabel">{t("TITLES.EDIT")}</IonLabel>
          </IonCol>
        }
      />
      <IonContent>
        <IonLoading isOpen={isLoading}></IonLoading>

        {isLoading ? (
          <>
            <VehicleCardSkeleton />
            <VehicleCardSkeleton />
            <VehicleCardSkeleton />
          </>
        ) : (
          <>
            {userVehicles.length === 0 ? (
              <IonList className="empty" mode="md">
                <Illustration9 className="noVehicles" />
                <IonRow>
                  <IonLabel className="titleH3">{t("VEHICLES.NO_VEHICLE_LINKED")}</IonLabel>
                </IonRow>
                <IonRow>
                  <IonLabel className="ion-margin-top ion-margin-bottom">
                    {t("VEHICLES.NO_VEHICLE_LINKED_TEXT")}
                  </IonLabel>
                </IonRow>
              </IonList>
            ) : (
              <>
                <IonContent className="vehiclesPage">
                  {userVehicles.map((vehicle) => (
                    <IonRow key={vehicle.vehicle_id} className="rowContainerEdit">
                      <VehicleCard
                        allowDelete={userVehicles.length > 1}
                        edit={showEdit}
                        vehicle={vehicle}
                        handleEditVehicleClick={handleEditVehicleClick}
                        handleSuccess={handleSuccess}
                      />
                    </IonRow>
                  ))}
                </IonContent>
              </>
            )}
          </>
        )}
      </IonContent>
      <IonFooter className="ion-no-border">
        <div className="vehicles-page-footer-buttons">
          <IonButton className="" onClick={handleAddVehicleClick}>
            <IonIcon icon={addCircleOutline} slot="start"></IonIcon>
            {t("BUTTONS.ADD_VEHICLE")}
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
};
