import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { RecentSearchesModel } from "@/core/models/recentSearchesModel";
import { IonIcon, IonLabel } from "@ionic/react";
import { closeOutline, refreshOutline } from "ionicons/icons";
import "./SearchTerm.scss";

interface SearchTermProps {
  recentSearch: RecentSearchesModel;
  onDelete: (recentSearch: RecentSearchesModel) => void;
  onClick: (recentSearch: RecentSearchesModel) => void;
}

export const SearchTerm = ({ recentSearch, onDelete, onClick }: SearchTermProps) => {
  return (
    <div className="search-term" onClick={() => onClick(recentSearch)}>
      <div className="search-term-title">
        <IonIcon icon={refreshOutline} className="iconRecent" />
        <IonLabel>{recentSearch.name}</IonLabel>
      </div>
      <div>
        <IconButton
          icon={closeOutline}
          className="search-term-delete"
          onClick={(e) => {
            // Avoids the event from propagating to the IonRow
            e.stopPropagation();
            onDelete(recentSearch);
          }}
        />
      </div>
    </div>
  );
};
