import { ChargerSocketModel } from "@/core/models/chargerModel";
import { RateLimitPeriodicity, RateLimitScope, RateTypeLimits } from "@/core/models/rateModel";
import { ReservationSocketModel } from "@/core/models/reservationModel";
import { t } from "i18next";

export const getSocketInfoPrice = (socket: ChargerSocketModel | ReservationSocketModel) => {
  const haveTimePrice = socket.price_time_min && socket.price_time_min !== "0.0";
  const haveEnergyPrice = socket.price_energy_kwh && socket.price_energy_kwh !== "0.0";

  const isFreeCharge =
    socket.rates.length === 0 && (!socket.socket_consumption_code || (!haveTimePrice && !haveEnergyPrice));

  return {
    haveTimePrice,
    haveEnergyPrice,
    isFreeCharge,
  };
};

/**
 * Get the time in minutes to charge
 * @param selectedSocket
 * @param energy kWh
 * @returns
 */
export const getSocketTimeByEnergy = (power: string, energy: number) => {
  if (!Number(power)) return -1;

  const totalTimeMinutes = Math.ceil((energy / Number(power)) * 60);

  return totalTimeMinutes;
};

/**
 * Get the max energy that can be charged in the selected socket in KWh
 * @param selectedSocket
 * @param rateLimits
 * @returns kwh
 */
export const getSocketMaxEnergy = (selectedSocket: ChargerSocketModel | null, rateLimits?: RateTypeLimits) => {
  if (!selectedSocket) return 0;

  let maxEnergySocket = (Number(selectedSocket.max_energy_wh) || 100000) / 1000;
  if (rateLimits?.Energy) {
    const { limit, consumed } = rateLimits.Energy;
    const energyRemaining = limit - consumed;
    if (energyRemaining < maxEnergySocket) maxEnergySocket = Number(energyRemaining.toFixed(2));
  }
  return maxEnergySocket;
};

export const getRateScopeTranslation = (scope: RateLimitScope) => {
  const scopeTranslation: Record<RateLimitScope, string> = {
    Socket: "SOCKET",
    Charger: "CHARGER",
    Rate: "RATE",
  };

  return t(`RATES.${scopeTranslation[scope]}`);
};

export const getRatePeriodicityTranslation = (periodicity: RateLimitPeriodicity) => {
  const periodicityTranslation: Record<RateLimitPeriodicity, string> = {
    Daily: "DAILY",
    Weekly: "WEEKLY",
    Monthly: "MONTHLY",
    Yearly: "YEARLY",
    Charge: "CHARGE",
  };

  return t(`RATES.${periodicityTranslation[periodicity]}`);
};
