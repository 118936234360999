import { IonCol, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";

export const IncidenceCardSkeleton = () => {
  return (
    <IonRow
      className={"chargeInfoContainer"}
      style={{ boxShadow: "0px 1px 1px var(--ion-color-focus)", width: "100%" }}
    >
      <IonRow className="rowChargeHistory">
        <IonCol className="colCharge">
          <IonLabel className="chargerNameTitle">
            <IonSkeletonText animated={true} style={{ width: "220px" }} />
          </IonLabel>
        </IonCol>
      </IonRow>

      <IonRow className="rowChargeHistory">
        <IonCol className="colCharge">
          <IonLabel className="subtitleInfoCharge">
            <IonSkeletonText animated={true} style={{ width: "100px" }} />
          </IonLabel>
          <IonLabel className="labelInfoCharge number">
            <IonSkeletonText animated={true} style={{ width: "100px" }} />
          </IonLabel>
        </IonCol>
      </IonRow>
    </IonRow>
  );
};
