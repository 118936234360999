import { addHours, addMinutes, addSeconds, format } from "date-fns";
import { t } from "i18next";

export const formatToHours = (time: number, type: "seconds" | "minutes" | "hours" = "seconds") => {
  const date = new Date(0, 0, 0);

  const addFunction = {
    seconds: addSeconds,
    minutes: addMinutes,
    hours: addHours,
  };

  const newDate = addFunction[type](date, time);

  return format(newDate, "HH:mm:ss");
};

export const formatToMinutes = (time: number, type: "seconds" | "minutes" | "hours" = "seconds") => {
  const date = new Date(0, 0, 0);

  const addFunction = {
    seconds: addSeconds,
    minutes: addMinutes,
    hours: addHours,
  };

  const newDate = addFunction[type](date, time);

  if (newDate.getHours() >= 1) {
    return ` > ${newDate.getHours()}h`;
  }

  return format(newDate, "mm:ss");
};

export const minutesToHours = (time: number) => {
  let hoursText = t("SEND_NOTIFICATION.HOUR");
  const minutesText = t("SEND_NOTIFICATION.MINUTES");
  if (time >= 60) {
    const hours = Math.floor(time / 60);
    const remainingMinutes = time % 60;

    if (hours > 1) hoursText = t("SEND_NOTIFICATION.HOURS");
    return `${hours} ${hoursText} ${remainingMinutes} ${minutesText}`;
  } else {
    return `${time} ${minutesText}`;
  }
};

export const formatRemainingTime = (time: number) => {
  if (time <= 0) return t("INFO_CHARGE.TIME_REMAINING_CHARGE_MIN");
  if (time > 1440) return t("INFO_CHARGE.TIME_REMAINING_CHARGE_MAX");
  return minutesToHours(time);
};

export const dateDiff = (createdAt: number, language: string) => {
  const now: any = new Date();
  let timeValue;

  const diffInMs: any = now - createdAt;
  const diffInMins = Math.floor(diffInMs / (1000 * 60));
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));
  const formatter = new Intl.RelativeTimeFormat(language, { numeric: "auto" });

  if (diffInMins < 60) {
    return formatter.format(-diffInMins, "minute");
  } else if (diffInHours < 24) {
    timeValue = formatter.format(-diffInHours, "hour");
  } else if (diffInDays < 30) {
    timeValue = formatter.format(-diffInDays, "day");
  } else if (diffInMonths < 12) {
    timeValue = formatter.format(-diffInMonths, "month");
  } else {
    timeValue = formatter.format(-Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365)), "year");
  }
  return timeValue;
};
