import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { formatNumberDecimals } from "@/commons/utils/numberUtils";
import { IonIcon, IonLabel, IonProgressBar, IonRow, IonText } from "@ionic/react";
import { t } from "i18next";
import { batteryChargingOutline, batteryHalfOutline, cashOutline, flashOutline, timeOutline } from "ionicons/icons";
import { Trans } from "react-i18next";
import { ChargeInfo } from "../ActiveCharge";
import "./ActiveChargeMonitoring.scss";

interface ActiveChargeMonitoringProps {
  chargeInfo: ChargeInfo;
  showExtraData?: boolean;
}

export const ActiveChargeMonitoring = ({ chargeInfo, showExtraData }: ActiveChargeMonitoringProps) => {
  const { parameters } = useParametersContext();

  return (
    <div className="active-charge-monitoring">
      <IonRow>
        <IonLabel className="active-charge-monitoring-label-time">
          {!chargeInfo.unlockedCharger && chargeInfo.progressEnergy < 1 ? (
            <>
              <IonIcon className="active-charge-icon" icon={batteryChargingOutline}></IonIcon>
              <span className="remaining-time">{chargeInfo.energy} kWh</span>
              <span> / </span>
              <span>{chargeInfo.totalEnergy} kWh</span>
            </>
          ) : chargeInfo.unlockedCharger ? (
            <div className="disconnect-cable">
              <div>{t("INFO_CHARGE.DISCONNECT_CABLE_TITLE")}</div>
              {showExtraData && (
                <div className="disconnect-cable-extra-info">{t("INFO_CHARGE.DISCONNECT_CABLE_TEXT")}</div>
              )}
            </div>
          ) : (
            <span>{t("INFO_CHARGE.PROCESSING_STOP")}</span>
          )}
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonProgressBar
          class="active-charge-monitoring-progress-bar"
          type={!chargeInfo.unlockedCharger && chargeInfo.progressEnergy < 1 ? "determinate" : "indeterminate"}
          value={chargeInfo.progressEnergy}
        ></IonProgressBar>
      </IonRow>
      <IonRow>
        <IonLabel className="active-charge-monitoring-label-time">
          <IonIcon className="active-charge-icon" icon={timeOutline}></IonIcon>
          <span>{chargeInfo.timeConsumed}</span>
        </IonLabel>
        {!showExtraData && chargeInfo.percentBattery && (
          <IonLabel className="active-charge-monitoring-label-time">
            <IonIcon className="active-charge-icon" icon={batteryHalfOutline} />
            <span>{chargeInfo.percentBattery}%</span>
          </IonLabel>
        )}
      </IonRow>

      {showExtraData && (
        <>
          {chargeInfo.percentBattery && (
            <IonRow>
              <IonLabel className="active-charge-monitoring-label-time">
                <IonIcon className="active-charge-icon" icon={batteryHalfOutline} />
                <span>{chargeInfo.percentBattery}%</span>
              </IonLabel>
            </IonRow>
          )}
          <IonRow>
            <IonIcon className="active-charge-icon" icon={flashOutline}></IonIcon>
            <IonLabel>{formatNumberDecimals(chargeInfo.runtimePower)} kW</IonLabel>
          </IonRow>
          <IonRow>
            {!!chargeInfo.amount && <IonIcon className="active-charge-icon" icon={cashOutline}></IonIcon>}
            <IonLabel>
              {chargeInfo.amount ? (
                `${(chargeInfo.amount_consumed || 0).toFixed(2)} ${parameters.currency}`
              ) : (
                <StatusLabel className="free-label">{t("CHARGER.FREE")}</StatusLabel>
              )}
            </IonLabel>
          </IonRow>
          <IonRow className="active-charge-monitoring-remaining-time">
            <IonText>
              {chargeInfo.remainingTimeFormatted === t("INFO_CHARGE.TIME_REMAINING_CHARGE_MIN") ? (
                chargeInfo.periods.length === 1 ? (
                  <Trans i18nKey="INFO_CHARGE.FIRST_PERIOD" />
                ) : (
                  <Trans i18nKey="INFO_CHARGE.TIME_REMAINING_CHARGE_MIN" />
                )
              ) : (
                <Trans
                  i18nKey="INFO_CHARGE.TIME_REMAINING_CHARGE"
                  values={{ remainingTime: chargeInfo.remainingTimeFormatted }}
                />
              )}
            </IonText>
          </IonRow>
        </>
      )}
    </div>
  );
};
