import { getLocationByChargerService } from "@/core/services/location/locationServices";
const { VITE_APP_ID, VITE_HOST } = import.meta.env;

export const checkUrlScanner = (url: string) => {
  url = url.replace("\x04", "").replace("\x00", "");

  const regexIsURI = /^(?!https?:\/\/)[a-zA-Z0-9+.-]+:\/\//;
  const isURI = regexIsURI.test(url);

  if (isURI) {
    const [prefix] = url.split("://");
    return VITE_APP_ID === `com.etecnic.${prefix}`;
  }

  const match = url.match(/^(?:[a-zA-Z]+:\/\/)?([^/]+)/);
  const host = match ? match[1] : null;

  return host === VITE_HOST;
};

export const getLocationIdFromScanner = async (url: string, tokenApi: string, tokenUser: string) => {
  const info = url.replace(/^[a-zA-Z]+:\/\/[^/]+\//, "");

  const [key, chargerId, socketNumber] = info.split("/");

  if (["charge_qr", "home"].includes(key)) {
    const { location_id } = await getLocationByChargerService(chargerId, tokenApi, tokenUser);

    return { locationId: location_id, chargeId: Number(chargerId), socketNumber: Number(socketNumber) };
  }
};
