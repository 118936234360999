import { ParametersModel } from "@/core/models/globalModel";
import { getAppParametersService } from "@/core/services/appParameters/appParametersServices";
import { parametersActions, parametersIntialState } from "@/core/store/slices/parametersSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { userFormActions } from "@/core/store/slices/userFormSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { App } from "@capacitor/app";
import React, { createContext, useEffect, useState } from "react";

interface ParametersContextValue {
  parameters: ParametersModel;
}

const ParametersContext = createContext<ParametersContextValue>({
  parameters: parametersIntialState,
});

interface ParametersProviderProps {
  children: React.ReactNode;
}

const ParametersProvider = ({ children }: ParametersProviderProps) => {
  const dispatch = useAppDispatch();

  const [parameters, setParameters] = useState<ParametersModel>(parametersIntialState);
  const { user, userInfo } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    loadData();

    const listenerPromise = App.addListener("resume", loadData);

    return () => {
      listenerPromise.then((listener) => listener.remove());
    };
  }, [user.user_id]);

  useEffect(() => {
    const { payment_type } = parameters;

    if ((payment_type.includes("wallet") || payment_type.includes("both")) && userInfo && !userInfo.can_use_wallet) {
      if (payment_type === "both" || payment_type === "wallet") {
        return setParameters({ ...parameters, payment_type: "stripe" });
      }

      setParameters({ ...parameters, payment_type: payment_type.replace("wallet_", "").replace("both_", "") });
    }
  }, [parameters.payment_type, userInfo?.can_use_wallet]);

  useEffect(() => {
    dispatch(parametersActions.setParameters(parameters));
  }, [parameters]);

  const loadData = async () => {
    try {
      localStorage.removeItem("domainId");
      let newParams = await getAppParametersService();

      newParams = {
        ...parameters,
        ...newParams,
      };

      // Calculate payment_gateway
      let payment_gateway = newParams.payment_type.replace("wallet_", "").replace("both_", "");
      payment_gateway = newParams.payment_type !== "both" ? payment_gateway : "stripe";
      payment_gateway = payment_gateway === "wallet" ? "stripe" : payment_gateway;

      // Format Socket status available list
      const newSocketStatusAvailable = newParams.socket_status_available.split(",") || [];

      newParams = {
        ...newParams,
        payment_gateway,
        socket_status_available_list: newSocketStatusAvailable.map((item) => Number(item)),
      };

      setParameters(newParams);

      // Save domainId to use with api headers
      localStorage.setItem("domainId", newParams.domain_id.toString());

      // Required fields
      dispatch(userFormActions.setParametersRequiredFields(newParams));
    } catch (error) {
      console.error("Error getting parameters", error);
      dispatch(uiActions.setLoadingApp(false));
    }
  };

  return <ParametersContext.Provider value={{ parameters }}>{parameters && children}</ParametersContext.Provider>;
};

export { ParametersContext, ParametersProvider };
