import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { AddCreditCardGateway } from "../../AddCreditCardGateway/AddCreditCardGateway";
import { StepReducer, StepsActions } from "../PaymentStep";

interface PaymentStepGuestProps {
  stepsReducer: StepReducer;
  stepsActions: StepsActions;
}

export const PaymentStepGuest = ({ stepsReducer, stepsActions }: PaymentStepGuestProps) => {
  const dispatch = useAppDispatch();

  const { selectPaymentId } = useAppSelector((state) => state[stepsReducer].paymentStep);

  const callbackAddCard = (paymentMethod: string, extraField: string) => {
    dispatch(stepsActions.setPaymentStepSelectPaymentId(paymentMethod));
    dispatch(stepsActions.setPaymentStepExtraField(extraField));
  };

  return (
    <div className="ion-margin" style={{ height: "100%" }}>
      <AddCreditCardGateway selectPaymentId={selectPaymentId} callbackAddCard={callbackAddCard} />
    </div>
  );
};
