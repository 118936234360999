import { useLogoApp } from "@/commons/hooks/useLogoApp";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { useAppSelector } from "@/core/store/store";
import { IonButtons, IonHeader, IonIcon, IonImg, IonLabel, IonMenuButton, IonRow, IonToolbar } from "@ionic/react";
import { cloudOfflineOutline, menuOutline } from "ionicons/icons";
import { ReactNode } from "react";
import "./AppHeader.scss";

interface AppHeaderProps {
  className?: string;
  title?: ReactNode;
  hiddenMenu?: boolean;
  hiddenTitle?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  size?: "small" | "default";
}

export const AppHeader = ({
  className,
  hiddenMenu,
  hiddenTitle,
  title,
  startIcon,
  endIcon,
  size = "default",
}: AppHeaderProps) => {
  const { logoToolbar } = useLogoApp();

  const { network } = useAppSelector((state) => state.uiReducer);
  const { theme } = useAppSelector((state) => state.userReducer);

  const defaultStartIcon = hiddenMenu ? (
    <div className="empty-button" />
  ) : (
    <IonMenuButton color="primary">
      <IonIcon icon={menuOutline} />
    </IonMenuButton>
  );

  return (
    <IonHeader className={`app-header ion-no-border ${className} ${classIf(size === "small", "header-small")}`}>
      <IonToolbar className="ion-align-items-center ion-justify-content-center">
        <IonButtons slot="start">{startIcon || defaultStartIcon}</IonButtons>
        <IonRow className="ion-justify-content-center">
          {!hiddenTitle && (
            <>
              {title ? (
                <IonLabel className="app-header-title">{title}</IonLabel>
              ) : (
                <IonImg className="app-header-img" src={logoToolbar} />
              )}
            </>
          )}
        </IonRow>
        <IonButtons slot="end">{endIcon || <div className="empty-button" />}</IonButtons>
      </IonToolbar>
      {network && !network.connected && (
        <IonRow className={`app-header-network ${theme}`}>
          <IonIcon color="light" icon={cloudOfflineOutline} />
          <IonLabel color="light">Sin conexión</IonLabel>
        </IonRow>
      )}
    </IonHeader>
  );
};
