import { Divider } from "@/commons/components/Divider/Divider";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { ChargeChart } from "@/commons/components/charts/ChargeChart/ChargeChart";
import { useLogoApp } from "@/commons/hooks/useLogoApp";
import { CHARGER_STATUS_IDS, ChargerModel, ChargerSocketModel } from "@/core/models/chargerModel";
import { RateTypeLimits } from "@/core/models/rateModel";
import { IonButton, IonContent, IonIcon, IonLabel, IonModal, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { calendarOutline, stopCircleOutline } from "ionicons/icons";
import { useRef } from "react";
import { RateLimits } from "../../../RateLimits/RateLimits";
import { ChargeInfo } from "../ActiveCharge";
import { ActiveChargeMonitoring } from "../ActiveChargeMonitoring/ActiveChargeMonitoring";
import "./ActiveChargeDetail.scss";
import { ActiveChargeDetailSkeleton } from "./ActiveChargeDetailSkeleton";

interface ActiveChargeDetailProps {
  chargeInfo: ChargeInfo;
  charger: ChargerModel;
  showDetail: boolean;
  setShowDetail: (showDetail: boolean) => void;
  remainingMeterValues: number;
  handleStopCharge: () => void;
  selectedSocket: ChargerSocketModel;
  rateLimits?: RateTypeLimits;
  loadingData: boolean;
}

export const ActiveChargeDetail = ({
  charger,
  chargeInfo,
  showDetail,
  setShowDetail,
  remainingMeterValues,
  handleStopCharge,
  selectedSocket,
  rateLimits,
  loadingData,
}: ActiveChargeDetailProps) => {
  const { appLogo } = useLogoApp();

  const contentRef = useRef<HTMLIonContentElement>(null);
  const handleCloseModal = () => setShowDetail(false);

  const domainLogoUrl =
    charger.domain_logo_url && charger.domain_logo_url !== "assets/imgs/app_logo.png"
      ? charger.domain_logo_url
      : appLogo;

  return (
    <IonModal
      className="active-charge-detail"
      isOpen={showDetail}
      canDismiss={true}
      onDidDismiss={handleCloseModal}
      initialBreakpoint={0.6}
      breakpoints={[0, 0.6, 0.75, 1]}
      onIonBreakpointDidChange={(e) => {
        e.detail.breakpoint !== 1 && contentRef.current?.scrollToTop(200);
      }}
    >
      <IonContent ref={contentRef} className="ion-padding">
        {loadingData ? (
          <ActiveChargeDetailSkeleton />
        ) : (
          <>
            <IonLabel className="active-charge-detail-title">{t("INFO_CHARGE.STARTED_CHARGE")}</IonLabel>
            <div className="ion-margin-vertical">
              <div>
                <IonLabel>{charger.name}</IonLabel>
              </div>
              {charger.id_status === CHARGER_STATUS_IDS.OUT_OF_SERVICE && (
                <IonRow style={{ margin: "8px 0" }}>
                  <StatusLabel> {t("CHARGER.NO_COMMUNICATION")} </StatusLabel>
                </IonRow>
              )}
              <div>
                <IonLabel className="active-charge-detail-address">{charger.address}</IonLabel>
              </div>
            </div>
            <img className="active-charge-detail-domain-logo" src={domainLogoUrl} alt="domain-logo" />
            <IonRow className="ion-margin-vertical">
              <IonIcon className="active-charge-icon" icon={calendarOutline}></IonIcon>
              <IonLabel>{format(chargeInfo.createAt, "P")}</IonLabel>
            </IonRow>
            <Divider />
            <div className="ion-padding-vertical">
              <ActiveChargeMonitoring chargeInfo={chargeInfo} showExtraData />
            </div>
            <Divider />
            <RateLimits rateLimits={rateLimits} selectedSocket={selectedSocket} />
            <Divider />
            <div className="active-charge-detail-chart" style={{ height: 300 }}>
              <ChargeChart data={chargeInfo.periods} />
            </div>
            <IonLabel>
              {t("INFO_CHARGE.NEXT_UPDATE")} {remainingMeterValues} {t("INFO_CHARGE.SECONDS")}
            </IonLabel>
            <div className="active-charge-detail-buttons">
              <IonButton color="primary" fill="outline" onClick={handleCloseModal}>
                {t("BUTTONS.SHOW_LESS")}
              </IonButton>
              {!chargeInfo.unlockedCharger && (
                <IonButton onClick={handleStopCharge}>
                  <IonIcon slot="start" icon={stopCircleOutline}></IonIcon>
                  {t("BUTTONS.STOP_CHARGE")}
                </IonButton>
              )}
            </div>
          </>
        )}
      </IonContent>
    </IonModal>
  );
};
