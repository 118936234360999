import {
  Illustration10,
  Illustration11,
  Illustration14,
  Illustration15,
  Illustration16,
  Illustration5,
} from "@/commons/constants/imgs";
import { ParametersContext } from "@/context/appParametersContext";
import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { IonButton, IonLabel, IonPage, IonRow, IonText, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./OnboardingPage.scss";

export const OnboardingPage = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { t } = useTranslation();
  const { parameters } = useContext(ParametersContext);

  const { seenOnboarding } = useAppSelector((state) => state.userReducer);

  const [finishReached, setFinisgReached] = useState(false);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  useEffect(() => {
    seenOnboarding && handleRedirectHome();
  }, []);

  const handleNextClick = () => {
    swiper && swiper.slideNext();
  };

  const handleRedirectHome = () => {
    dispatch(userActions.setSeenOnboarding(true));
    router.push(APP_PATH.HOME, "root", "replace");
  };

  const handleChangeSlide = () => {
    setFinisgReached(swiper?.isEnd ?? false);
  };

  return (
    <IonPage className="onboarding-container">
      <Swiper
        slidesPerView={1}
        pagination={true}
        modules={[Pagination]}
        onSwiper={setSwiper}
        onSlideChange={handleChangeSlide}
      >
        <SwiperSlide className="slide">
          <Illustration16 className="ion-margin-bottom" />
          <IonLabel className="ion-text-start title">{t("ONBOARDING.NEW_VERSION_TITLE")}</IonLabel>
          <IonText className="ion-text-start text">{t("ONBOARDING.NEW_VERSION_TEXT")}</IonText>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <Illustration14 />
          {/* <img src={Illustration14} className="ion-margin-bottom"></img> */}
          <IonLabel className="ion-text-start title">{t("ONBOARDING.SERVICES_TITLE")}</IonLabel>
          <IonText className="ion-text-start text">{t("ONBOARDING.SERVICES_TEXT")}</IonText>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <Illustration11 className="ion-margin-bottom" />
          <IonLabel className="ion-text-start title">{t("ONBOARDING.HELP_TITLE")}</IonLabel>
          <IonText className="ion-text-start text">{t("ONBOARDING.HELP_TEXT")}</IonText>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <Illustration15 className="ion-margin-bottom" />
          <IonLabel className="ion-text-start title">{t("ONBOARDING.OPTIMIZE_TITLE")}</IonLabel>
          <IonText className="ion-text-start text">{t("ONBOARDING.OPTIMIZE_TEXT")}</IonText>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <Illustration10 className="ion-margin-bottom" />
          <IonLabel className="ion-text-start title">{t("ONBOARDING.BOOKING_TITLE")}</IonLabel>
          <IonText className="ion-text-start text">{t("ONBOARDING.BOOKING_TEXT")}</IonText>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <Illustration5 className="ion-margin-bottom" />
          <IonLabel className="ion-text-start title">{t("ONBOARDING.LOCATION_TITLE")}</IonLabel>
          <IonText className="ion-text-start text">{t("ONBOARDING.LOCATION_TEXT", { name: parameters.name })}</IonText>
          <IonText className="ion-text-start text locationText topLine"> {t("ONBOARDING.LOCATION_POINT_1")}</IonText>
          <IonText className="ion-text-start text locationText">{t("ONBOARDING.LOCATION_POINT_2")}</IonText>
        </SwiperSlide>
      </Swiper>

      {!finishReached && (
        <IonRow className="ion-justify-content-between">
          <IonButton className="onboardingButtons buttonTerciary skipTutorial" onClick={handleRedirectHome}>
            {t("BUTTONS.SKIP_STEPS")}
          </IonButton>
          <IonButton className="onboardingButtons" onClick={handleNextClick}>
            {t("BUTTONS.CONTINUE")}
          </IonButton>
        </IonRow>
      )}
      {finishReached && (
        <IonButton className="onboardingButtons" onClick={handleRedirectHome}>
          {t("BUTTONS.CONTINUE")}
        </IonButton>
      )}
    </IonPage>
  );
};
