import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { RotateImageIcon } from "@/commons/constants/imgs";
import { useLogoApp } from "@/commons/hooks/useLogoApp";
import { ChargerModel } from "@/core/models/chargerModel";
import { getDocumentDownloadUrlService } from "@/core/services/document/documentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { Capacitor } from "@capacitor/core";
import { IonButtons, IonImg, IonLoading } from "@ionic/react";
import { downloadOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import "swiper/css";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ChargerImages.scss";

interface ChargerImagesProps {
  chargers: ChargerModel[];
}

export const ChargerImages = ({ chargers }: ChargerImagesProps) => {
  const dispatch = useAppDispatch();
  const { appLogo } = useLogoApp();

  const platform = Capacitor.getPlatform();

  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const newChargerUrls: string[] = [];
    let domainLogoUrl = "";

    chargers.forEach((charger) => {
      if (!domainLogoUrl && charger.domain_logo_url !== "assets/imgs/app_logo.png") {
        domainLogoUrl = charger.domain_logo_url;
      }

      newChargerUrls.push(...charger.images_charger);
    });

    // Add app logo to the beginning of the array
    newChargerUrls.unshift(domainLogoUrl || appLogo);

    setImages(newChargerUrls);
  }, [chargers]);

  const handleDownloadImage = async (index: number) => {
    try {
      setLoading(true);
      const image = images[index];
      // Get the file name from the image url last part and remove the query string
      const fileName = image.split("/").pop()?.split("?")[0] || "image.jpg";

      await getDocumentDownloadUrlService(fileName, image);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="charger-images">
      <IonLoading isOpen={loading} style={{ zIndex: "100000000" }} />
      <PhotoProvider
        className={`${platform}`}
        photoClosable={false}
        maskOpacity={0.8}
        loop={false}
        toolbarRender={({ index, rotate, onRotate }) => {
          const image = images[index];
          const isUrl = image.startsWith("http");

          return (
            <IonButtons>
              {isUrl && <IconButton icon={downloadOutline} color="clear" onClick={() => handleDownloadImage(index)} />}
              <IconButton
                icon=""
                color="clear"
                onClick={() => {
                  onRotate(rotate + 90);
                }}
              >
                <RotateImageIcon style={{ width: "18px", height: "18px" }} />
              </IconButton>
            </IonButtons>
          );
        }}
      >
        <Swiper spaceBetween={16} slidesPerView={1} modules={[Pagination]} pagination={{ clickable: true }}>
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <PhotoView src={image}>
                <IonImg className="charger-image" src={image} alt="charger" />
              </PhotoView>
            </SwiperSlide>
          ))}
        </Swiper>
      </PhotoProvider>
    </div>
  );
};
