import { downloadBlob, rejectError, resolveBlobResponse, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { MethodOptions } from "@/core/models/apiModel";
import { ImageModel } from "@/core/models/documentModel";

export const getDocumentDownloadApiService = async (
  fileName: string,
  endpoint: string,
  params?: any,
  options?: MethodOptions,
) => {
  return api
    .get(endpoint, params, options)
    .then(resolveBlobResponse)
    .then((blob) => downloadBlob(blob, fileName))
    .catch(rejectError);
};

export const getDocumentDownloadUrlService = async (fileName: string, endpoint: string) => {
  return api
    .get("image_contents/download", { url: endpoint }, { isRootPath: true })
    .then(resolveBlobResponse)
    .then((blob) => downloadBlob(blob, fileName))
    .catch(rejectError);
};

export const uploadImageService = async (file: File): Promise<ImageModel> => {
  const body = new FormData();
  body.append("file", file);

  return api
    .post("image_contents", undefined, body, { isRootPath: true, haveFormData: true })
    .then(resolveResponse)
    .catch(rejectError);
};
