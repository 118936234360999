import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { DEFAULT_MAX_ELECTRIC_POWER } from "@/commons/constants/charger";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { minutesToHours } from "@/commons/utils/dateUtils/dateUtils";
import { formatNumber } from "@/commons/utils/numberUtils";
import { getSocketInfoPrice, getSocketMaxEnergy, getSocketTimeByEnergy } from "@/commons/utils/socketUtils/socketUtils";
import { ParametersContext } from "@/context/appParametersContext";
import { getPaymentPriceService } from "@/core/services/payment/paymentServices";
import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import {
  IonAlert,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRange,
  IonRow,
  IonSpinner,
  IonText,
  IonicSafeString,
} from "@ionic/react";
import { handLeftOutline, informationCircleOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RateLimits } from "../../RateLimits/RateLimits";
import "./ChargeStepChoose.scss";

interface ChargeStepChooseProps {
  isAutomaticCharge?: boolean;
}
export const ChargeStepChoose = ({ isAutomaticCharge = false }: ChargeStepChooseProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { parameters } = useContext(ParametersContext);
  const { selectedSocket, selectedCharger } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { currentStep, chooseStep, nextStepDisabled, rateLimits } = useAppSelector((state) => state.chargeStepsReducer);
  const { userInfo } = useAppSelector((state) => state.userReducer);

  const defaultWh = userInfo?.default_charge_values.default_wh;
  const activeAutomaticCharge = !!userInfo?.default_charge_values.active_automatic_charge;

  const { time, energy, price, currency, isLoadingPrice } = chooseStep;

  const maxEnergy = getSocketMaxEnergy(selectedSocket, rateLimits);

  const [showAlertPrice, setShowAlertPrice] = useState(false);
  const [errorMessageInput, setErrorMessageInput] = useState("");
  const [inputValue, setInputValue] = useState<number | null>(energy);
  const [isFreeCharge, setIsFreeCharge] = useState(false);

  useEffect(() => {
    if (currentStep === 0) {
      const isActive = !errorMessageInput && !!energy && (isFreeCharge || (!isFreeCharge && !isLoadingPrice));
      nextStepDisabled !== !isActive && dispatch(chargeStepsActions.setNextStepDisabled(!isActive));
    }
  }, [currentStep, chooseStep, isFreeCharge]);

  useEffect(() => {
    if (selectedSocket && selectedCharger) {
      const { isFreeCharge } = getSocketInfoPrice(selectedSocket);
      setIsFreeCharge(isFreeCharge);
    }
  }, [selectedSocket, selectedCharger]);

  useEffect(() => {
    if (activeAutomaticCharge && defaultWh && maxEnergy) {
      const defaultKWh = defaultWh / 1000;
      const newEnergy = defaultKWh > maxEnergy ? maxEnergy : defaultKWh;
      setInputValue(newEnergy);
    }
  }, [maxEnergy, defaultWh, activeAutomaticCharge]);

  const handleEnergyChange = (e: CustomEvent) => {
    const newEnergy = e.detail.value as number;
    if (selectedSocket) {
      const maxEnergy = getSocketMaxEnergy(selectedSocket, rateLimits);

      if (newEnergy < 0 || newEnergy > maxEnergy) {
        if (newEnergy < 0) {
          setErrorMessageInput(t("INFO_CHARGE.NEGATIVE_ENERGY"));
          setInputValue(0);
        }

        if (newEnergy > maxEnergy) {
          setInputValue(maxEnergy);
        }

        return;
      }

      setInputValue(newEnergy);
      setErrorMessageInput("");

      const newTime = getSocketTimeByEnergy(selectedSocket.max_electric_power || DEFAULT_MAX_ELECTRIC_POWER, newEnergy);
      dispatch(chargeStepsActions.setChooseStep({ time: newTime, energy, price }));

      handleChangeData(newEnergy, newTime);
    }
  };

  const handleInputEnergyRange = (e: CustomEvent) => {
    const newEnergy = e.detail.value as number;
    setInputValue(newEnergy);
    setErrorMessageInput("");

    if (selectedSocket) {
      const newTime = getSocketTimeByEnergy(selectedSocket.max_electric_power || DEFAULT_MAX_ELECTRIC_POWER, newEnergy);
      dispatch(chargeStepsActions.setChooseStep({ time: newTime, energy, price }));
    }
  };

  const handleChangeData = async (newInputValue?: number, newTime?: number) => {
    const parametersCurrency = { symbol: parameters.currency, code: parameters.currency_code };
    const newEnergy = newInputValue || inputValue || 0;

    if (!newEnergy) {
      return dispatch(
        chargeStepsActions.setChooseStep({
          time: 0,
          energy: 0,
          price: 0,
          currency: parametersCurrency,
        }),
      );
    }

    if (isFreeCharge) {
      return dispatch(
        chargeStepsActions.setChooseStep({
          time: newTime || time,
          energy: newEnergy,
          price: 0,
          currency: parametersCurrency,
        }),
      );
    }

    if (selectedSocket) {
      try {
        dispatch(chargeStepsActions.setChooseStepIsLoadingPrice(true));

        const priceObject = await getPaymentPriceService(selectedSocket.id_socket, newEnergy * 1000);
        const newPrice = priceObject.price;
        const currency = priceObject.currency || parametersCurrency;

        dispatch(
          chargeStepsActions.setChooseStep({
            time: newTime || time,
            energy: newEnergy,
            price: newPrice / 100,
            currency: currency,
          }),
        );
      } catch (error) {
        dispatch(uiActions.setToastError(error));
      } finally {
        dispatch(chargeStepsActions.setChooseStepIsLoadingPrice(false));
      }
    }
  };

  return (
    <>
      {selectedSocket && (
        <>
          <IonAlert
            className="price-info-alert"
            isOpen={showAlertPrice}
            onDidDismiss={() => setShowAlertPrice(false)}
            message={
              new IonicSafeString(`
            <div class="price-info-container">
                <ion-text>${t("INFO_CHARGE.PRICE_TEXT_1")}</ion-text>
                <ion-text>${t("INFO_CHARGE.PRICE_TEXT_2")}</ion-text>
                <ion-text>${t("INFO_CHARGE.PRICE_TEXT_3")}</ion-text>
                <ion-text>${t("INFO_CHARGE.PRICE_TEXT_4")}</ion-text>
            </div>
          `)
            }
            buttons={[`${t("BUTTONS.ACCEPT")}`]}
            mode="md"
          />
          <IonGrid className="charge-step-choose ion-margin">
            <IonRow className="charge-step-choose-time">
              <IonLabel className="title-h3">{t("TITLES.TIME_CHARGE")}</IonLabel>
            </IonRow>
            <IonRow className="charge-step-choose-time">
              <div className={`time-label-container ${classIf(isAutomaticCharge, "automatic-charge")}`}>
                <IonCol>
                  <IonInput
                    className={`ion-text-center ion-touched ${classIf(!!errorMessageInput, "ion-invalid")}`}
                    inputMode="numeric"
                    value={inputValue}
                    maxlength={3}
                    onIonInput={(e) => handleEnergyChange(e)}
                    errorText={errorMessageInput}
                    clearOnEdit
                  />
                </IonCol>
                <IonCol className="kWh">kWh</IonCol>
              </div>
            </IonRow>
            <IonRow className="ion-margin-horizontal">
              <IonRange
                onIonInput={handleInputEnergyRange}
                onIonChange={(event) =>
                  handleChangeData(
                    event.detail.value as number,
                    getSocketTimeByEnergy(
                      selectedSocket.max_electric_power || DEFAULT_MAX_ELECTRIC_POWER,
                      event.detail.value as number,
                    ),
                  )
                }
                value={inputValue || 0}
                min={0}
                max={maxEnergy}
                ticks={true}
                step={1}
                mode="ios"
                disabled={isLoadingPrice}
              />
            </IonRow>
            <div className={`charge-step-choose-info ${classIf(isAutomaticCharge, "automatic-charge")}`}>
              <IonRow>
                <div>
                  <IonIcon icon={informationCircleOutline}></IonIcon>
                </div>
                <IonText className="time-text">
                  <Trans i18nKey="INFO_CHARGE.INFO_APROX_TIME" values={{ time: minutesToHours(time) }} />
                </IonText>
              </IonRow>
              <IonRow>
                <div>
                  <IonIcon icon={handLeftOutline}></IonIcon>
                </div>
                <IonText>{t("INFO_CHARGE.STOP_CHARGE")}</IonText>
              </IonRow>
              <RateLimits rateLimits={rateLimits} selectedSocket={selectedSocket} />
              {!isAutomaticCharge && (
                <IonRow className="price-row">
                  <IonRow>
                    <IonIcon icon={informationCircleOutline} onClick={() => setShowAlertPrice(true)}></IonIcon>
                    <IonLabel>
                      <strong>{t("TITLES.TOTAL")}</strong>
                    </IonLabel>
                  </IonRow>
                  <IonRow class="price-info">
                    {isFreeCharge || (!isLoadingPrice && energy && price === 0) ? (
                      <StatusLabel>{t("CHARGER.FREE")}</StatusLabel>
                    ) : isLoadingPrice ? (
                      <IonSpinner className="price-loading" name="dots" />
                    ) : (
                      <IonLabel>
                        {formatNumber(price, { minimumFractionDigits: 2 })} {currency?.symbol || parameters.currency}
                      </IonLabel>
                    )}
                  </IonRow>
                </IonRow>
              )}
            </div>
          </IonGrid>
        </>
      )}
    </>
  );
};
