import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { ParametersModel } from "@/core/models/globalModel";

export const getAppParametersService = async (): Promise<ParametersModel> => {
  const { VITE_TOKEN_PARAMETERS, VITE_CODE_APP } = import.meta.env;

  return api
    .get("get_app_parameters", { app_code: VITE_CODE_APP }, { customToken: VITE_TOKEN_PARAMETERS })
    .then(resolveResponse)
    .catch(rejectError);
};
