import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonLabel, IonRow, IonToggle } from "@ionic/react";
import { t } from "i18next";

export const NotificationsInputs = () => {
  const dispatch = useAppDispatch();
  // same but with usememo
  const { settings } = useAppSelector((state) => state.userReducer);

  const { nearLocation } = settings.alerts;

  const handleChangeShowNearLocation = () => {
    dispatch(userActions.setSettings({ ...settings, alerts: { ...settings.alerts, nearLocation: !nearLocation } }));
  };

  return (
    <div className="notifications-values">
      <IonRow className="billing-row">
        <div className="title-label">
          <IonLabel className="title-h3">{t("SETTINGS.ACTIVE_NEAR_LOCATION_TITLE")}</IonLabel>
          <IonLabel className="title-h5">{t("SETTINGS.ACTIVE_NEAR_LOCATION_TEXT")}</IonLabel>
        </div>
        <IonToggle
          name="default_charge_values.active_automatic_charge"
          checked={nearLocation}
          onIonChange={handleChangeShowNearLocation}
        />
      </IonRow>
    </div>
  );
};
