import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { LocationContext } from "@/context/locationContext";
import { ChargerModel } from "@/core/models/chargerModel";
import { getNearChargersService } from "@/core/services/location/locationServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "./useQuery";

export const useNearLocation = () => {
  const dispatch = useAppDispatch();
  const { query } = useQuery();

  const { parameters } = useParametersContext();
  const { userLocation } = useContext(LocationContext);

  const { user, settings } = useAppSelector((state) => state.userReducer);
  const { isRateAppModalOpen, help, alert, isMarketingModalOpen } = useAppSelector((state) => state.uiReducer);
  const { selectedLocation, activeReservations, routeInfo, filters } = useAppSelector((state) => state.mapReducer);
  const { activeCharges } = useAppSelector((state) => state.chargesReducer);
  const { showChargeSteps, showLocationDetail, showRates } = selectedLocation.options;

  const [nearCharger, setNearCharger] = useState<ChargerModel>();
  const [nearLocationId, setNearLocationId] = useState<number>();
  const [nearLocation, setNearLocation] = useState<{ locationId: number; name: string; img: string }>();
  const [firstCharger, setFirstCharger] = useState<ChargerModel>();

  useEffect(() => {
    if (!firstCharger || !canOpenNearLocation()) return;

    if (firstCharger?.near) {
      setNearLocationId(undefined);
      setNearCharger(firstCharger);
      setNearLocationId(Number(firstCharger.location_id));

      return;
    }

    if ((firstCharger?.distance || 9999) > 500) {
      dispatch(uiActions.setAlertType({ type: "farChargers" }));
    }
  }, [firstCharger]);

  const canOpenNearLocation = () =>
    settings.alerts.nearLocation &&
    window.location.pathname === APP_PATH.HOME &&
    !isRateAppModalOpen &&
    activeCharges.length === 0 &&
    activeReservations.length === 0 &&
    !showChargeSteps &&
    !showLocationDetail &&
    !showRates &&
    !help.show &&
    !routeInfo.showRoute &&
    !routeInfo.showRouteMenu &&
    !alert.show &&
    !isMarketingModalOpen &&
    !selectedLocation.locationId &&
    userLocation.latitude !== null &&
    userLocation.longitude !== null &&
    !filters.showFilters;

  const checkNearLocation = () => {
    if (canOpenNearLocation() && nearCharger && nearLocationId) {
      let img = "";
      const { images_charger, domain_logo_url, location_name } = nearCharger;
      if (images_charger && images_charger.length > 0) {
        img = images_charger[0];
      } else if (domain_logo_url && domain_logo_url !== "assets/imgs/app_logo.png") {
        img = domain_logo_url;
      }

      setNearLocation({ locationId: nearLocationId, name: `${location_name || nearLocationId}`, img });

      setNearCharger(undefined);
      setNearLocationId(undefined);
    }
  };

  const getNearLocation = async () => {
    // if modal is open, do not check for near charger
    if (canOpenNearLocation() && !query.locationId) {
      try {
        const { chargers } = await getNearChargersService(
          userLocation.latitude!,
          userLocation.longitude!,
          user.auth_token,
          parameters.token_api,
        );
        if (chargers) {
          setFirstCharger(chargers[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return { nearLocationId, nearLocation, setNearLocation, checkNearLocation, getNearLocation };
};
