import i18n from "@/assets/i18n/i18n";
import idIcon from "@/assets/imgs/icons/idSocketIcon.svg";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { Divider } from "@/commons/components/Divider/Divider";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { ParametersContext } from "@/context/appParametersContext";
import { ChargerModel } from "@/core/models/chargerModel";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonIcon, IonLabel, IonRow } from "@ionic/react";
import { hammerOutline, lockClosedOutline, warningOutline } from "ionicons/icons";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./ChargerInfo.scss";
import { FavoriteButton } from "./FavoriteButton/FavoriteButton";
import { SocketsInfo } from "./SocketsInfo/SocketsInfo";

interface ChargerInfoProps {
  charger: ChargerModel;
}

export const ChargerInfo = ({ charger }: ChargerInfoProps) => {
  const dispatch = useAppDispatch();
  const { isUserLogged } = useAppSelector((state) => state.userReducer);
  const { t } = useTranslation();
  const {
    parameters: { sat_available: sat_available_domain },
  } = useContext(ParametersContext);

  const handleGoIncidence = () => {
    if (isUserLogged) {
      dispatch(uiActions.showHelp({ charger: charger, step: 1 }));
    } else {
      dispatch(uiActions.setAlertType({ type: "alertRegister" }));
    }
  };

  return (
    <div className="charger-info">
      <IonRow class="charger-info-detail">
        <div>
          <IonLabel class="charger-title">
            <span className="charger-name">
              {charger.name} {charger.physical_reference && `- ${charger.physical_reference}`}
            </span>
            {charger.mobile_app_private && (
              <StatusLabel active className="charger-label">
                <IonIcon icon={lockClosedOutline} />
                {t("CHARGER.PRIVATE")}
              </StatusLabel>
            )}
            {charger.sat_available && (
              <StatusLabel active className="charger-label assistance-label">
                <IonIcon icon={hammerOutline} />
                {t("CHARGER.ASSISTANCE")}
              </StatusLabel>
            )}
          </IonLabel>
        </div>
        <div>
          <IonRow class="charger-info-detail-buttons">
            <IonIcon icon={idIcon}></IonIcon>
            <IonLabel className="number-id">{charger.id_charger}</IonLabel>
            <IonRow style={{ flexWrap: "nowrap" }}>
              {sat_available_domain && <IconButton icon={warningOutline} onClick={handleGoIncidence} />}
              <FavoriteButton chargerId={charger.id_charger} />
            </IonRow>
          </IonRow>
        </div>
      </IonRow>
      {charger.app_disable_temporal && (
        <IonRow className="disabled-charger">
          <IonIcon color="danger" icon={warningOutline} />
          <IonLabel color="danger">{charger.app_disable_temporal_text?.[i18n.language]}</IonLabel>
        </IonRow>
      )}
      <IonRow className={`charger-info-sockets ${classIf(!!charger.app_disable_temporal, "disabled-charger-sockets")}`}>
        {!charger.charger_sockets.length && (
          <IonRow className="sockets-empty">
            <IonLabel>{t("CHARGER.NO_SOCKETS")}</IonLabel>
          </IonRow>
        )}

        {charger.charger_sockets.map((socket, index) => (
          <Fragment key={socket.id_socket}>
            <SocketsInfo charger={charger} socket={socket} />
            {index !== charger.charger_sockets.length - 1 && <Divider />}
          </Fragment>
        ))}
      </IonRow>
    </div>
  );
};
