import { DAYS_OF_WEEK } from "@/commons/constants/date";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { RateModel, RatePeriodModel } from "@/core/models/rateModel";
import { useAppSelector } from "@/core/store/store";
import { IonCol, IonLabel, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { RatesConditionals } from "../RatesConditionals/RatesConditionals";
import "./RatesPeriods.scss";

interface RatesPeriodsProps {
  rate: RateModel;
}

export const RatesPeriods = ({ rate }: RatesPeriodsProps) => {
  const getDays = (period: RatePeriodModel) =>
    DAYS_OF_WEEK.map((day) => ({ ...day, active: period[day.key] === "true" }));

  const { parameters } = useParametersContext();
  const { theme } = useAppSelector((state) => state.userReducer);

  return (
    <>
      {rate.periods.map((period) => {
        const periodPenalty = period.rate_period_join_consumptions.find(
          (consumption) => consumption.code === "OCCUPANCY_PENALTY",
        );
        return (
          <IonRow key={`rate-${rate.id_rate}-period-${period.id_period}`} className="container-rates-period">
            <IonCol>
              <IonLabel className="bold">{period.title}:</IonLabel>
              {period.rate_period_join_consumptions && (
                <IonCol className="join">
                  {period.rate_period_join_consumptions
                    .filter((consumption) => consumption.code !== "OCCUPANCY_PENALTY")
                    .map((consumption, index) => (
                      <>
                        <IonRow key={index}>
                          <IonLabel className="price">
                            {consumption.price_base} {consumption.period_consumption} {periodPenalty ? "*" : ""}
                          </IonLabel>
                        </IonRow>
                      </>
                    ))}
                </IonCol>
              )}
              {periodPenalty && (
                <div className={`penalty ${theme}`}>
                  <Trans
                    i18nKey="RATES.PENALTY"
                    values={{
                      price_base: periodPenalty.price_base,
                      period_consumption: periodPenalty.period_consumption,
                      time_limit: periodPenalty.occupancy_penalty_time_limit,
                      max_penalty_amount: periodPenalty.max_penalty_amount,
                      currency: parameters.currency,
                    }}
                  />
                </div>
              )}
              <IonRow className="info-period">
                <IonCol className="info-period-schedule-col">
                  <IonRow>
                    <IonLabel className="bold">{t("BOOKING_INFO.SCHEDULE") + ":"}</IonLabel>
                  </IonRow>
                  <IonRow>
                    <div className="period-days-list">
                      {getDays(period).map((day) => (
                        <div key={day.key} className={`period-day ${classIf(day.active, "active")}`}>
                          {day.name}
                        </div>
                      ))}
                    </div>
                  </IonRow>
                  <div className="period-hours">
                    <IonLabel>{format(period.time_start, "HH:mm")}</IonLabel>
                    <span>-</span>
                    <IonLabel>{format(period.time_end, "HH:mm")}</IonLabel>
                  </div>
                </IonCol>
              </IonRow>
            </IonCol>
            {period.conditionals.length > 0 && <RatesConditionals rateId={rate.id_rate} period={period} />}
          </IonRow>
        );
      })}
    </>
  );
};
