import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { UserInfoModel } from "@/core/models/userModel";
import { IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonToggle } from "@ionic/react";
import { t } from "i18next";
import { TargetFormType } from "../PersonalDataPage";

interface BillingUserInputsProps {
  showErrors: boolean;
  userInfoTemp: UserInfoModel;
  handleChangeForm: (target: TargetFormType) => void;
}
export const BillingUserInputs = ({ showErrors, userInfoTemp, handleChangeForm }: BillingUserInputsProps) => {
  const { isEmpty, getLabel, checkRequiredFields } = useIsValidUser();

  return (
    <div className="billing-user">
      <IonRow className="billing-row">
        <IonLabel className="title-h3">{t("PROFILE.BUSSINESS")}</IonLabel>
        <IonToggle
          name="isBillingUser"
          checked={userInfoTemp.isBillingUser}
          onIonChange={(event) => handleChangeForm(event.target)}
        />
      </IonRow>
      {userInfoTemp.isBillingUser && (
        <div>
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              name="billing_user.nif"
              label={getLabel("nif", "billing_user")}
              labelPlacement="floating"
              onIonInput={(event) => handleChangeForm(event.target)}
              type="text"
              value={userInfoTemp.billing_user?.nif}
            />
          </IonItem>
          {isEmpty(userInfoTemp.billing_user?.nif) && showErrors && (
            <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
          )}
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              name="billing_user.reg_company"
              label={getLabel("reg_company", "billing_user")}
              labelPlacement="floating"
              onIonInput={(event) => handleChangeForm(event.target)}
              type="text"
              value={userInfoTemp.billing_user?.reg_company}
            />
          </IonItem>
          {checkRequiredFields("billing_user.reg_company") &&
            isEmpty(userInfoTemp.billing_user?.reg_company) &&
            showErrors && <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>}
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              name="billing_user.fiscal_name"
              label={getLabel("fiscal_name", "billing_user")}
              labelPlacement="floating"
              onIonInput={(event) => handleChangeForm(event.target)}
              type="text"
              value={userInfoTemp.billing_user?.fiscal_name}
            />
          </IonItem>
          {isEmpty(userInfoTemp.billing_user?.fiscal_name) && showErrors && (
            <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
          )}
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              name="billing_user.fiscal_address"
              label={getLabel("fiscal_address", "billing_user")}
              labelPlacement="floating"
              onIonInput={(event) => handleChangeForm(event.target)}
              type="text"
              value={userInfoTemp.billing_user?.fiscal_address}
            />
          </IonItem>
          {isEmpty(userInfoTemp.billing_user?.fiscal_address) && showErrors && (
            <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
          )}
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              name="billing_user.city"
              label={getLabel("city", "billing_user")}
              labelPlacement="floating"
              onIonInput={(event) => handleChangeForm(event.target)}
              type="text"
              value={userInfoTemp.billing_user?.city}
            />
          </IonItem>
          {isEmpty(userInfoTemp.billing_user?.city) && showErrors && (
            <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
          )}
          <IonItem lines="full" className="ion-margin-top">
            <IonInput
              name="billing_user.cp"
              label={getLabel("cp", "billing_user")}
              labelPlacement="floating"
              onIonInput={(event) => handleChangeForm(event.target)}
              type="text"
              value={userInfoTemp.billing_user?.cp}
            />
          </IonItem>
          {isEmpty(userInfoTemp.billing_user?.cp) && showErrors && (
            <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
          )}
          <IonItem lines="full" className="ion-margin-top">
            <IonSelect
              name="billing_user.billing_type"
              label={getLabel("billing_type", "billing_user")}
              labelPlacement="floating"
              value={userInfoTemp.billing_user?.billing_type}
              onIonChange={(event) => handleChangeForm(event.target)}
              disabled={userInfoTemp.billing_user?.billing_type === "INVOICE_MONTHLY_B2B"}
            >
              <IonSelectOption value="INVOICE">{t("PROFILE.INVOICE_CHARGE")}</IonSelectOption>
              <IonSelectOption value="INVOICE_MONTHLY">{t("PROFILE.INVOICE_MONTHLY")}</IonSelectOption>
            </IonSelect>
          </IonItem>
          {(isEmpty(userInfoTemp.billing_user?.billing_type) ||
            (userInfoTemp.billing_user && userInfoTemp.billing_user.billing_type === "TICKET")) &&
            showErrors && <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>}
        </div>
      )}
    </div>
  );
};
