import { Divider } from "@/commons/components/Divider/Divider";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { SupportTicketModel } from "@/core/models/incidenceModel";
import { buildPath } from "@/router/routerUtils";
import { APP_PATH } from "@/router/routes";
import { IonIcon, IonLabel, IonRow, useIonRouter } from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { timeOutline } from "ionicons/icons";
import "./IncidenceCard.scss";

interface IncidenceCardProps {
  incidence: SupportTicketModel;
}

export const IncidenceCard = ({ incidence }: IncidenceCardProps) => {
  const router = useIonRouter();
  const isActive = incidence.status !== "CLOSED";

  const handleIncidenceClick = () => {
    router.push(buildPath(APP_PATH.INCIDENCE, { id: incidence.id }));
  };

  return (
    <>
      <div className="incidence-card" onClick={handleIncidenceClick}>
        <IonLabel className="strong-text">{incidence.title}</IonLabel>
        <IonLabel>{format(incidence.date, "dd/MM/yyyy")}</IonLabel>
        <IonRow>
          <StatusLabel active={isActive}>
            {isActive && <IonIcon icon={timeOutline} />}
            {isActive ? t("INCIDENCES.ACTIVE") : t("INCIDENCES.CLOSED")}
          </StatusLabel>
        </IonRow>
      </div>
      <Divider />
    </>
  );
};
