import { ParametersModel } from "@/core/models/globalModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ParametersSlice {
  parameters: ParametersModel;
}

export const parametersIntialState: ParametersModel = {
  id: -1,
  code: "",
  name: "",
  min_version: "",
  payment_type: "",
  payment_gateway: "",
  promotional_codes: false,
  token_api: "",
  token_api_guest: "",
  token_google: "",
  id_playstore: "",
  id_appstore: "",
  menu_background_color: "",
  menu_text_color: "",
  footer_background_color: "",
  background_color: "",
  text_color: "",
  power_text_color: "",
  background2_color: "",
  primary_color: "",
  text2_color: "",
  protocol: "",
  host: "",
  domain_id: -1,
  currency: "",
  currency_code: "",
  min_amount_wallet: -1,
  min_amount_stripe: 10,
  max_amount_wallet: 100,
  keychain_active: false,
  keychain_landing: "",
  keychain_price_shipment: null,
  url_privacy: "",
  url_terms: "",
  url_promotional: "",
  about_us: "",
  sat_available: false,
  code_deeplink: "",
  url_deeplink: "",
  sat_number: "",
  user_guest_id: -1,
  refresh_location: 15000,
  refresh_map: 30000,
  max_anonymous_charges: 0,
  required_fields: "",
  allow_register_users: false,
  only_b2b: false,
  allowed_logins: "",
  show_type_client: false,
  wallet_fee_fix: 0,
  wallet_fee_percent: 0,
  beta_features: false,
  socket_status_available: "",
  socket_status_available_list: [],
};

export const parametersSlice = createSlice({
  name: "parametersReducer",
  initialState: {
    parameters: parametersIntialState,
  },
  reducers: {
    setParameters: (state, action: PayloadAction<ParametersModel>) => {
      state.parameters = action.payload;
    },
  },
});

export const parametersActions = parametersSlice.actions;

export default parametersSlice.reducer;
