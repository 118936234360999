import chargeTypeFastIcon from "@/assets/imgs/charge-type/fast.svg";
import chargeTypeSlowIcon from "@/assets/imgs/charge-type/normal.svg";
import chargeTypeSemiFastIcon from "@/assets/imgs/charge-type/semiFast.svg";
import chargeTypeSuperFastIcon from "@/assets/imgs/charge-type/superFast.svg";
import chargerConnector3PinIcon from "@/assets/imgs/charger-connector/3-pin.svg";
import chargerConnectorAcIcon from "@/assets/imgs/charger-connector/ac.svg";
import chargerConnectorCcsDcIcon from "@/assets/imgs/charger-connector/ccs-dc.svg";
import chargerConnectorCcsIcon from "@/assets/imgs/charger-connector/ccs.svg";
import chargerConnectorChademoIcon from "@/assets/imgs/charger-connector/chademo.svg";
import chargerConnectorDcIcon from "@/assets/imgs/charger-connector/dc.svg";
import chargerConnectorGbAcIcon from "@/assets/imgs/charger-connector/gb-ac.svg";
import chargerConnectorGbDcIcon from "@/assets/imgs/charger-connector/gb-dc.svg";
import chargerConnectorSchukoIcon from "@/assets/imgs/charger-connector/schuko.svg";
import chargerConnectorTeslaIcon from "@/assets/imgs/charger-connector/tesla.svg";
import chargerConnectorType2Icon from "@/assets/imgs/charger-connector/type-2.svg";
import chargerConnectorYazakiIcon from "@/assets/imgs/charger-connector/yazaki.svg";
import { CHARGER_SOCKET_CONNECTOR_TYPE } from "@/core/models/chargerModel";
import { Illustration6, Illustration7 } from "./imgs";

export const CHARGER_STATUS_TRANSLATE = {
  Available: "AVAILABLE",
  Charging: "CHARGING",
  Reserved: "RESERVED",
  "Out of service": "OUT_OF_SERVICE",
  Unknown: "OUT_OF_SERVICE",
};

export const CHARGER_SOCKET_CONNECTOR_ICON: Record<CHARGER_SOCKET_CONNECTOR_TYPE, string> = {
  "TYPE-2-F": chargerConnectorType2Icon,
  "TYPE-2-M": chargerConnectorType2Icon,
  "3-PIN": chargerConnector3PinIcon,
  AC: chargerConnectorAcIcon,
  CCS: chargerConnectorCcsIcon,
  CCS_DC: chargerConnectorCcsDcIcon,
  CHADEMO: chargerConnectorChademoIcon,
  DC: chargerConnectorDcIcon,
  "GB-T_AC": chargerConnectorGbAcIcon,
  GB: chargerConnectorGbDcIcon,
  SCHUKO: chargerConnectorSchukoIcon,
  TESLA_R: chargerConnectorTeslaIcon,
  TESLA_S: chargerConnectorTeslaIcon,
  YAZAKI: chargerConnectorYazakiIcon,
};

export enum CHARGER_SOCKET_CHARGE_TYPE {
  SLOW = "SLOW",
  SEMI_FAST = "SEMI_FAST",
  FAST = "FAST",
  SUPER_FAST = "SUPER_FAST",
}

interface ChargerSocketChargeTypePower {
  type: string;
  min: number;
  max: number;
}
export const CHARGER_SOCKET_CHARGE_TYPE_POWER: Record<CHARGER_SOCKET_CHARGE_TYPE, ChargerSocketChargeTypePower> = {
  [CHARGER_SOCKET_CHARGE_TYPE.SUPER_FAST]: { type: "superfast", min: 55, max: 200 },
  [CHARGER_SOCKET_CHARGE_TYPE.FAST]: { type: "fast", min: 22, max: 55 },
  [CHARGER_SOCKET_CHARGE_TYPE.SEMI_FAST]: { type: "semifast", min: 7, max: 22 },
  [CHARGER_SOCKET_CHARGE_TYPE.SLOW]: { type: "slow", min: 1, max: 7 },
};

export const CHARGER_SOCKET_CHARGE_TYPE_ICON: Record<CHARGER_SOCKET_CHARGE_TYPE, string> = {
  [CHARGER_SOCKET_CHARGE_TYPE.SLOW]: chargeTypeSlowIcon,
  [CHARGER_SOCKET_CHARGE_TYPE.SEMI_FAST]: chargeTypeSemiFastIcon,
  [CHARGER_SOCKET_CHARGE_TYPE.FAST]: chargeTypeFastIcon,
  [CHARGER_SOCKET_CHARGE_TYPE.SUPER_FAST]: chargeTypeSuperFastIcon,
};

export enum CHARGER_INSTRUCTION_TYPES {
  CONNECT_WAIT = "CONNECT_WAIT",
  CONNECT_VEHICLE = "CONNECT_VEHICLE",
  PRESS_START = "PRESS_START",
  PRESS_UNLOCK = "PRESS_UNLOCK",
  WAIT_START = "WAIT_START",
}

export const CHARGER_INSTRUCTION_TYPES_IMAGE: Record<CHARGER_INSTRUCTION_TYPES, typeof Illustration6> = {
  [CHARGER_INSTRUCTION_TYPES.CONNECT_WAIT]: Illustration6,
  [CHARGER_INSTRUCTION_TYPES.CONNECT_VEHICLE]: Illustration7,
  [CHARGER_INSTRUCTION_TYPES.PRESS_START]: Illustration7,
  [CHARGER_INSTRUCTION_TYPES.PRESS_UNLOCK]: Illustration7,
  [CHARGER_INSTRUCTION_TYPES.WAIT_START]: Illustration6,
};

type ChargeSocketConnectorType =
  | "type2"
  | "type2f"
  | "type3pin"
  | "typeac"
  | "typechademo"
  | "typeccs"
  | "typeccsdc"
  | "typedc"
  | "typegbac"
  | "typegbdc"
  | "typeteslar"
  | "typeteslas"
  | "typeschuko"
  | "typeyazaki";

export interface ChargeSocketConnectorFilterItem {
  type: ChargeSocketConnectorType;
  name: string;
  show: boolean;
}

export const CHARGE_SOCKET_CONECTOR_FILTER: Record<CHARGER_SOCKET_CONNECTOR_TYPE, ChargeSocketConnectorFilterItem> = {
  [CHARGER_SOCKET_CONNECTOR_TYPE.TYPE2M]: { type: "type2", name: "Type 2", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.TYPE2F]: { type: "type2f", name: "Type 2", show: false },
  [CHARGER_SOCKET_CONNECTOR_TYPE.YAZAKI]: { type: "typeyazaki", name: "Type 1", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.CCS]: { type: "typeccs", name: "CCS", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.CHADEMO]: { type: "typechademo", name: "CHAdeMO", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.CCS_DC]: { type: "typeccsdc", name: "CCS1", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.SCHUKO]: { type: "typeschuko", name: "Schuko", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.AC]: { type: "typeac", name: "AC", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.DC]: { type: "typedc", name: "DC", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.GB]: { type: "typegbdc", name: "GB/T (DC)", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE["GB-T_AC"]]: { type: "typegbac", name: "GB/T (AC)", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE["3PIN"]]: { type: "type3pin", name: "3 Pin", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.TESLA_R]: { type: "typeteslar", name: "TESLA", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.TESLA_S]: { type: "typeteslas", name: "TESLA", show: false },
};

export const CHARGE_SOCKET_CONECTOR_PERSONAL_DATA: Record<
  CHARGER_SOCKET_CONNECTOR_TYPE,
  ChargeSocketConnectorFilterItem
> = {
  [CHARGER_SOCKET_CONNECTOR_TYPE.TYPE2M]: { type: "type2", name: "Type 2", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.TYPE2F]: { type: "type2f", name: "Type 2", show: false },
  [CHARGER_SOCKET_CONNECTOR_TYPE.YAZAKI]: { type: "typeyazaki", name: "Type 1", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.CCS]: { type: "typeccs", name: "CCS", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.CHADEMO]: { type: "typechademo", name: "CHAdeMO", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.CCS_DC]: { type: "typeccsdc", name: "CCS1", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.SCHUKO]: { type: "typeschuko", name: "SCHUKO", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.AC]: { type: "typeac", name: "AC", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.DC]: { type: "typedc", name: "DC", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.GB]: { type: "typegbdc", name: "GB/T (DC)", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE["GB-T_AC"]]: { type: "typegbac", name: "GB/T (AC)", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE["3PIN"]]: { type: "type3pin", name: "3 Pin", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.TESLA_R]: { type: "typeteslar", name: "TESLA_R", show: true },
  [CHARGER_SOCKET_CONNECTOR_TYPE.TESLA_S]: { type: "typeteslas", name: "TESLA_S", show: false },
};

export const DEFAULT_MAX_ELECTRIC_POWER = "100";
