import { RouteInfo } from "@ionic/react";
import { Children, ReactElement, ReactNode } from "react";
import { matchPath } from "react-router";
import { APP_PATH } from "./routes";

export const matchRoute = (node: ReactNode, routeInfo: RouteInfo): (ReactNode & { props?: any }) | null | undefined => {
  let matchedNode: ReactNode;
  Children.forEach(node as ReactElement, (child: ReactElement) => {
    const matchProps = {
      exact: child.props.exact,
      path: child.props.path || child.props.from,
      component: child.props.component,
    };
    const match = matchPath(routeInfo.pathname, matchProps);
    if (match) {
      matchedNode = child;
    }
  });

  if (matchedNode) {
    return matchedNode;
  }
  // If we haven't found a node
  // try to find one that doesn't have a path or from prop, that will be our not found route
  Children.forEach(node as ReactElement, (child: ReactElement) => {
    if (!(child.props.path || child.props.from)) {
      matchedNode = child;
    }
  });

  return matchedNode;
};

export const buildPath = (path: APP_PATH, params: Record<string, string | number>): string => {
  let builtPath: string = path;

  for (const [key, value] of Object.entries(params)) {
    builtPath = builtPath.replace(`:${key}`, value.toString());
  }

  return builtPath;
};
