import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { RouteWaypointDTO, RouteWaypointModel } from "@/core/models/routeModel";

export const getRouteService = async (
  params: {
    autonomy: number;
    efficiency: number;
    lat_o: any;
    lon_o: any;
    lat_d: any;
    lon_d: any;
    token_user: string;
  },
  token_admin: string,
): Promise<RouteWaypointModel[]> => {
  return api
    .get("routes/get_optimal_route", params, { customToken: token_admin })
    .then((response) => resolveResponse<RouteWaypointDTO[]>(response))
    .then((response) => response.map((waypoint) => ({ ...waypoint, lng: waypoint.lon })))
    .catch(rejectError);
};
