import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { useAppSelector } from "@/core/store/store";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { useHistory } from "react-router-dom";
import IonRouterOutletPatchedWithForwardedRef from "./IonRouterOutletPatched";
import { APP_PATH, routes } from "./routes";

export const Router = () => {
  const history = useHistory();
  const { setScreenAnalytics } = useFirebaseAnalytics();
  const { userInfo, isUserLogged } = useAppSelector((state) => state.userReducer);
  const { requiredFields } = useAppSelector((state) => state.userFormReduce);
  const { checkValidUser } = useIsValidUser();
  const [location, setLocation] = useState();

  useEffect(() => {
    setScreenAnalytics(window.location.pathname);
  }, [location]);

  useEffect(() => {
    const path = window.location.pathname;

    if (isUserLogged && userInfo && path !== APP_PATH.PERSONAL_DATA && path !== APP_PATH.VEHICLES) {
      if (!userInfo.vehicle_id) {
        history.replace(`${APP_PATH.VEHICLES}?createVehicle=true`);
        return;
      }

      const isValidUser = checkValidUser(userInfo, true);
      if (!isValidUser) {
        checkValidUser(userInfo);
        history.replace(`${APP_PATH.PERSONAL_DATA}?showError=true`);
      }
    }
  }, [userInfo, isUserLogged, requiredFields, location]);

  return (
    <IonRouterOutletPatchedWithForwardedRef id="scheduleAppMenu1" setLocation={setLocation}>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} component={route.component} exact />
      ))}
      <Redirect to={APP_PATH.HOME} />
    </IonRouterOutletPatchedWithForwardedRef>
  );
};
