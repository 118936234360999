import { ProgressBarCircular } from "@/commons/components/ProgressBarCircular/ProgressBarCircular";
import { useLocationsContext } from "@/commons/hooks/useLocationsContext";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { getUserAppCard } from "@/commons/utils/userUtils/userUtils";
import { SetState } from "@/core/models/globalModel";
import { ReservationModel } from "@/core/models/reservationModel";
import { cancelReservationService } from "@/core/services/reservation/reservationServices";
import { mapActions } from "@/core/store/slices/mapSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButton, IonCol, IonLabel, IonLoading, IonRow } from "@ionic/react";
import { t } from "i18next";
import { useState } from "react";
import { ReservationInfo } from "../ActiveReservation";
import "./ActiveReservationData.scss";

interface ActiveReservationDataProps {
  reservation: ReservationModel;
  reservationInfo: ReservationInfo;
  isDetail?: boolean;
  setShowDetail?: SetState<boolean>;
}

export const ActiveReservationData = ({
  reservation,
  reservationInfo,
  isDetail = false,
  setShowDetail,
}: ActiveReservationDataProps) => {
  const dispatch = useAppDispatch();
  const { getLocations } = useLocationsContext();

  const { user, userInfo } = useAppSelector((state) => state.userReducer);
  const { activeReservations } = useAppSelector((state) => state.mapReducer);

  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleCancelReservation = async () => {
    try {
      setLoadingDelete(true);

      const { code_card } = await getUserAppCard(user.user_id, userInfo);
      await cancelReservationService(reservation.id, code_card);

      const newActiveReservations = activeReservations.filter(
        (activeReservations) => activeReservations.id !== reservation.id,
      );

      dispatch(mapActions.setActiveReservations(newActiveReservations));
      dispatch(uiActions.setToastType({ type: "reservationFinished" }));
      getLocations(false);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <>
      <IonLoading isOpen={loadingDelete}></IonLoading>

      <div className={`active-reservation-data ${classIf(isDetail, "is-detail")}`}>
        <div className="active-reservation-data-progress">
          <ProgressBarCircular
            text={reservationInfo.remainingTimeFormat}
            value={reservationInfo.completeTimePercentage}
          />
        </div>
        <div className="active-reservation-data-info">
          <IonRow>
            <IonCol>
              <IonLabel className="ellipsis-2">{reservation.charger}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel className="hours-title">{t("INFO_CHARGE.INIT")}</IonLabel>
              <IonLabel className="hours-number">{reservationInfo.startHour}</IonLabel>
            </IonCol>
            <IonCol>
              <IonLabel className="hours-title">{t("INFO_CHARGE.ENDED")}</IonLabel>
              <IonLabel className="hours-number">{reservationInfo.endHour}</IonLabel>
            </IonCol>
          </IonRow>
        </div>
        <div className="active-reservation-data-buttons">
          <IonButton
            color="primary"
            fill="outline"
            onClick={() =>
              dispatch(
                uiActions.setAlertType({
                  type: "cancelReservation",
                  options: { primaryAction: handleCancelReservation },
                }),
              )
            }
          >
            {t("ALERT_BUTTONS.CANCEL")}
          </IonButton>
          {!isDetail && setShowDetail && (
            <IonButton onClick={() => setShowDetail(true)}>{t("BUTTONS.VIEW_BOOK")}</IonButton>
          )}
        </div>
      </div>
    </>
  );
};
