import { PaymentMethod } from "@/core/models/PaymentModel";
import { useAppSelector } from "@/core/store/store";
import { IonIcon, IonItem, IonLabel, IonList, IonRow } from "@ionic/react";
import { t } from "i18next";
import { cardOutline, walletOutline } from "ionicons/icons";

interface PaymentSelectorProps {
  handleMethodClick: (method: PaymentMethod) => void;
}

export const PaymentSelector = ({ handleMethodClick }: PaymentSelectorProps) => {
  const { selectedCharger } = useAppSelector((state) => state.mapReducer.selectedLocation);

  const paymentMethods = selectedCharger?.payment_methods || [];

  return (
    <IonList lines="full" style={{ height: "100%" }}>
      <IonLabel className="title-h3">{t("TITLES.CHOOSE_PAYMENT_METHOD")}</IonLabel>

      {paymentMethods.includes("CREDITCARD") && (
        <IonItem lines="full" onClick={() => handleMethodClick("CREDITCARD")} className="ion-margin-top">
          <IonRow className="infoRow">
            <IonIcon icon={cardOutline}></IonIcon>
            <IonLabel className="ion-padding-start">{t("CREDIT_CARD.CREDIT_CARD")}</IonLabel>
          </IonRow>
        </IonItem>
      )}

      {paymentMethods.includes("WALLET") && (
        <IonItem lines="full" onClick={() => handleMethodClick("WALLET")} className="ion-margin-top">
          <IonRow className="infoRow">
            <IonIcon icon={walletOutline}></IonIcon>
            <IonLabel className="ion-padding-start">{t("MENU.WALLET")}</IonLabel>
          </IonRow>
        </IonItem>
      )}
    </IonList>
  );
};
