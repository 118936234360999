import { IonButton, IonCol, IonRow } from "@ionic/react";
import { t } from "i18next";

interface FavoriteButtonProps {
  searchTypeCharger: any;
  setSearchTypeCharger: any;
}

export const SearchButtons = ({ searchTypeCharger, setSearchTypeCharger }: FavoriteButtonProps) => {
  return (
    <IonRow>
      <IonCol style={{ width: "50%" }}>
        <IonButton
          color="primary"
          fill={!searchTypeCharger ? "solid" : "outline"}
          mode="ios"
          onClick={() => setSearchTypeCharger(!searchTypeCharger)}
        >
          {t("BUTTONS.SEARCHBAR_LOCATIONS")}
        </IonButton>
      </IonCol>
      <IonCol style={{ width: "50%" }}>
        <IonButton
          color="primary"
          fill={searchTypeCharger ? "solid" : "outline"}
          mode="ios"
          onClick={() => setSearchTypeCharger(!searchTypeCharger)}
        >
          {t("BUTTONS.SEARCHBAR_CHARGERS")}
        </IonButton>
      </IonCol>
    </IonRow>
  );
};
