import i18n from "@/assets/i18n/i18n";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { NewIncidence } from "@/commons/components/Help/NewIncidence/NewIncidence";
import { useFirebaseAnalytics } from "@/commons/hooks/useFirebaseAnalytics";
import { ParametersContext } from "@/context/appParametersContext";
import { LocationContext } from "@/context/locationContext";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { useApi } from "@/services/apiService";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonLabel,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  SegmentCustomEvent,
  useIonRouter,
} from "@ionic/react";
import { callOutline, chatbubbleOutline, chatbubblesOutline, closeOutline, documentTextOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Chat } from "./Chat/Chat";
import "./HelpModal.scss";
import { HelpQuestions } from "./HelpQuestions/HelpQuestions";

const { VITE_PACKAGE_VERSION, VITE_APP_NAME_DISPLAY, VITE_HOST } = import.meta.env;

export const HelpModal = () => {
  const { t } = useTranslation();
  const { startConversation, deleteChatSession } = useApi();
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { setLogEvent } = useFirebaseAnalytics();

  const {
    parameters: { sat_number, sat_available: sat_available_domain },
  } = useContext(ParametersContext);
  const { userLocation } = useContext(LocationContext);
  const { parameters } = useContext(ParametersContext);

  const { show, charger } = useAppSelector((state) => state.uiReducer.help);
  const { isUserLogged, user } = useAppSelector((state) => state.userReducer);

  const steps = [`${t("HELP.STEP_1")}`, `${t("HELP.STEP_2")}`];

  const [currentStep, setCurrentStep] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [version, setVersion] = useState("1.0");

  const [showHostCounter, setShowHostCounter] = useState(0);

  const currentLanguage = i18n.language;
  const languageMap: Record<string, string> = {
    ca: "ca_ES",
    de: "en_GB",
    en: "en_GB",
    es: "es_ES",
    fr: "en_GB",
    pt: "en_GB",
    pl: "en_GB",
    ro: "en_GB",
  };
  const language = languageMap[currentLanguage] || "en_GB";

  useEffect(() => {
    setVersionApp();
    if (charger) setCurrentStep(1);
  }, []);

  useEffect(() => {
    handleDismiss();
  }, [router.routeInfo.pathname]);

  const handleDismiss = () => {
    dispatch(uiActions.cleanHelp());
    setCurrentStep(0);
    if (showChat) handleDeleteChatSession();
  };

  const setVersionApp = async () => {
    let newVersion = VITE_PACKAGE_VERSION || "";

    if (Capacitor.getPlatform() !== "web") {
      newVersion = (await App.getInfo()).version;
    }

    setVersion(newVersion);
  };

  const handleStepClick: any = (event: SegmentCustomEvent) => {
    const index = parseInt(event.detail.value!.toString());
    setCurrentStep(index);
  };

  const handleDeleteChatSession = async () => {
    await deleteChatSession(user.user_id, language);
    setShowChat(false);
  };

  const handleSatCall = () => {
    if (sat_available_domain && charger?.sat_available && sat_number) {
      window.open(`tel:${sat_number}`);
    } else {
      dispatch(
        uiActions.setAlertType({
          type: "satDisabled",
          options: {
            primaryAction: () => {
              setCurrentStep(1);
            },
          },
        }),
      );
    }
  };

  const handleOpenPolicy = () => {
    parameters.url_privacy && window.open(parameters.url_privacy);
  };

  const handleOpenTerms = () => {
    parameters.url_terms && window.open(parameters.url_terms);
  };

  const handleOpenChat = async () => {
    setLogEvent("open_chat");

    if (!isUserLogged) {
      return dispatch(uiActions.setAlertType({ type: "alertRegister" }));
    }
    setIsLoading(true);
    const response: any = await startConversation(
      user.user_id,
      userLocation.latitude,
      userLocation.longitude,
      language,
    );
    if (response && response.length !== 0) {
      setResponse(response);
      setShowChat(true);

      if (language === "en_GB" && currentLanguage !== "en") {
        dispatch(uiActions.setAlertType({ type: "chatbotDefaultLanguage" }));
      }
    }

    setIsLoading(false);
  };

  const handleClickVersion = () => {
    showHostCounter < 10 && setShowHostCounter((prev) => prev + 1);
  };

  return (
    <IonModal isOpen={show} onDidDismiss={handleDismiss} initialBreakpoint={1} breakpoints={[0, 0.5, 0.75, 1]}>
      {showChat ? (
        <Chat response={response} setShowChat={setShowChat} language={language}></Chat>
      ) : (
        <>
          <div style={{ marginTop: "15px" }}>
            <AppHeader
              hiddenMenu
              title={t("MENU.HELP")}
              endIcon={<IconButton icon={closeOutline} onClick={handleDismiss} />}
            />
          </div>
          {charger && (
            <IonSegment className="segment" value={currentStep.toString()} onIonChange={handleStepClick} mode="md">
              {steps.map((step, index) => (
                <IonSegmentButton key={index} value={index.toString()}>
                  <IonIcon src={index == 0 ? documentTextOutline : chatbubbleOutline}></IonIcon>
                  <IonLabel>{step}</IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
          )}
          <IonContent>
            {currentStep === 0 && <HelpQuestions />}
            {currentStep === 1 && <NewIncidence />}
            <IonCardHeader>
              <IonCardTitle>{t("HELP.TECHNICAL_SERVICE")}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent style={{ gap: "8px" }}>
              <IonButton onClick={() => !isLoading && handleOpenChat()}>
                {isLoading ? (
                  <IonSpinner />
                ) : (
                  <IonRow style={{ gap: 5 }}>
                    <IonIcon src={chatbubblesOutline}></IonIcon>
                    {t("HELP.START_CHAT")}
                  </IonRow>
                )}
              </IonButton>
              {charger && (
                <IonButton color="primary" fill="outline" onClick={handleSatCall}>
                  <IonRow style={{ gap: 5 }}>
                    <IonIcon src={callOutline}></IonIcon>
                    {t("HELP.START_CALL")}
                  </IonRow>
                </IonButton>
              )}
            </IonCardContent>
            <IonRow>
              <IonCol className="policy">
                <IonLabel onClick={handleClickVersion}>
                  {VITE_APP_NAME_DISPLAY} - Version {version}
                </IonLabel>
                {showHostCounter >= 10 && <IonLabel onClick={handleClickVersion}>{VITE_HOST}</IonLabel>}
                <IonLabel onClick={handleOpenPolicy}>
                  <a className="policy-link">{t("HELP.PRIVACY_POLICY")}</a>
                </IonLabel>
                <IonLabel onClick={handleOpenTerms}>
                  <a className="policy-link">{t("HELP.TERMS_CONDITIONS")}</a>
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonContent>
        </>
      )}
    </IonModal>
  );
};
