import google from "@/assets/imgs/icons/google.svg";
import { LOGINS_TYPES } from "@/commons/constants/login";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { IonButton, IonIcon } from "@ionic/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginUserInfo } from "../LoginSocial";

interface LoginGoogleProps {
  checkRegistered: (userInfo: LoginUserInfo, type: LOGINS_TYPES) => void;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
}

export const LoginGoogle = ({ checkRegistered, setShowAlert }: LoginGoogleProps) => {
  const { t } = useTranslation();

  const [disableButton, setDisableButton] = useState(false);

  const loginGoogle = async () => {
    try {
      setDisableButton(true);
      const response = await GoogleAuth.signIn();
      const { email, authentication, familyName, givenName, name: fullName } = response;
      if (!email) {
        throw new Error(t("APP_ERRORS.API"));
      }

      let name = "";
      let surname1 = "";
      let surname2 = "";

      if (givenName && familyName) {
        name = givenName;
        const surnames = familyName.split(" ");
        surname1 = surnames[0] || "";
        surname2 = surnames[1] || "";
      } else {
        const names = fullName.split(" ");
        name = names[0];
        surname1 = names[1] || "";
        surname2 = names[2] || "";
      }

      const userInfo = {
        email,
        name,
        surname1,
        surname2,
        accessToken: authentication.accessToken,
        password: Math.random().toString(36).slice(-10),
      };

      checkRegistered(userInfo, LOGINS_TYPES.GOOGLE);
    } catch (e) {
      const { error } = e as { error: string };

      if (["popup_closed_by_user"].includes(error)) return;

      console.error("Error login google", e);
      setShowAlert(true);
    } finally {
      setDisableButton(false);
    }
  };

  return (
    <IonButton className="buttonSecondary" onClick={loginGoogle} disabled={disableButton}>
      <IonIcon icon={google} slot="start" />
      {t("BUTTONS.LOGIN_GOOGLE")}
    </IonButton>
  );
};
