import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { SetState } from "@/core/models/globalModel";
import { IonIcon, IonLabel } from "@ionic/react";
import { t } from "i18next";
import { caretDownOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import Select, {
  components,
  DropdownIndicatorProps,
  MultiValueGenericProps,
  OptionProps,
  Props,
  StylesConfig,
} from "react-select";
import makeAnimated from "react-select/animated";
import "./SelectInput.scss";

const animatedComponents = makeAnimated();

export interface SelectOption {
  value: string;
  label: string;
  icon?: string;
}
export interface SelectEventChange<T = unknown> {
  target: SelectInputElement<T>;
}

export interface SelectInputElement<T = unknown> {
  name: string;
  value: T | undefined;
  isSelect: boolean;
}

const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <IonIcon icon={caretDownOutline} />
    </components.DropdownIndicator>
  );
};

const Option = (props: OptionProps<SelectOption, true>) => {
  return (
    <components.Option {...props} className={`${classIf(props.isSelected, "select-input-option-selected")}`}>
      <div className="select-input-option">
        {props.data.icon && <IonIcon icon={props.data.icon} className="icon-select" />}
        <IonLabel>{props.data.label}</IonLabel>
      </div>
    </components.Option>
  );
};

const MultiValueLabel = (props: MultiValueGenericProps<SelectOption, true>) => {
  return (
    <components.MultiValueLabel {...props}>
      <div className="select-input-option">
        {props.data.icon && <IonIcon icon={props.data.icon} />}
        <IonLabel>{props.data.label}</IonLabel>
      </div>
    </components.MultiValueLabel>
  );
};

interface SelectInputProps<SelectOption> extends Props<SelectOption> {
  label?: string;
  loadData: (setLoading: SetState<boolean>) => Promise<SelectOption[]>;
  hiddenDivider?: boolean;
  onIonChange?: (event: SelectEventChange) => void;
  currentValue?: string | null;
  currentValues?: string[];
  loadDependencies?: unknown[];
  outSideMarginLeft?: boolean;
}

export const SelectInput = ({
  className,
  label,
  loadData,
  hiddenDivider,
  closeMenuOnSelect,
  placeholder,
  currentValue,
  currentValues,
  onIonChange,
  loadDependencies = [],
  outSideMarginLeft = false,
  ...props
}: SelectInputProps<SelectOption>) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    loadData(setLoading)
      .then((newOptions) => setOptions(newOptions))
      .finally(() => setLoading(false));

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 30 * 1000);

    return () => clearTimeout(timeout);
  }, loadDependencies);

  const styles: StylesConfig<SelectOption, true> = {
    control: (css) => ({
      ...css,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
    }),
    multiValue: (css) => ({
      ...css,
      background: "var(--ion-color-primary)",
      borderRadius: "20px",
      padding: "4px",
    }),

    multiValueLabel: (css) => ({ ...css, color: "white", fontSize: "14px", fontWeight: "bold" }),
    multiValueRemove: (css) => ({ ...css, color: "white" }),
    dropdownIndicator: (css) => ({ ...css, paddingRight: "14px", color: "gray" }),
    clearIndicator: (css) => ({ ...css, color: "gray" }),
    menu: (css) => ({
      ...css,
      zIndex: 9,
      width: "95%",
      marginLeft: outSideMarginLeft ? "0" : "16px",
      background: "var(--ion-color-secondary)",
    }),
    singleValue: (css) => ({ ...css, color: "var(--ion-text-color)", marginLeft: outSideMarginLeft ? "0" : "8px" }),
    option: (css) => {
      return {
        ...css,
        ":active": {
          backgroundColor: "var(--ion-color-primary)",
          color: "white",
        },
        background: "var(--ion-color-secondary)",
        color: "var(--ion-text-color)",
      };
    },
    input: (css) => ({ ...css, color: "var(--ion-text-color)", marginLeft: outSideMarginLeft ? "0" : "8px" }),
    placeholder: (css) => ({ ...css, marginLeft: outSideMarginLeft ? "0" : "8px" }),
  };

  const getValue = () => {
    if (props.isMulti && currentValues) {
      return options?.filter((option) => currentValues.includes(option.value));
    }

    if (!props.isMulti && currentValue) {
      return options?.find((option) => option.value === `${currentValue || ""}`);
    }

    return props.value || null;
  };

  return (
    <div className={`${className} select-input-container ${classIf(outSideMarginLeft, "outside-margin-left")}`}>
      {label && <IonLabel className="select-input-label">{label}</IonLabel>}{" "}
      <Select
        {...props}
        onChange={(value, actionMeta) => {
          if (onIonChange) {
            let newValue;

            if (Array.isArray(value)) {
              newValue = value?.map((v) => ({ value: v.value, label: v.label }));
            } else {
              value = value as SelectOption | null;
              newValue = value?.value;
            }

            return onIonChange({ target: { name: props.name || "", value: newValue, isSelect: true } });
          }

          return props.onChange?.(value, actionMeta);
        }}
        closeMenuOnSelect={closeMenuOnSelect ?? true}
        blurInputOnSelect={closeMenuOnSelect ?? true}
        options={options}
        components={{ ...animatedComponents, MultiValueLabel, DropdownIndicator, Option }}
        className="select-input-container"
        styles={styles}
        isLoading={loading}
        loadingMessage={() => `${t("LOADING.LOADING")}...`}
        noOptionsMessage={() => `${t("ALERT.SEARCH_NOT_FOUND")}`}
        placeholder={placeholder || ""}
        value={getValue()}
      />
      {!hiddenDivider && <div className={`select-input-divider`} />}
    </div>
  );
};
