import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { ChargeModel } from "@/core/models/chargeModel";
import { ParametersModel } from "@/core/models/globalModel";
import { RatesUserLimits } from "@/core/models/rateModel";
import {
  CountryModel,
  CountryStateModel,
  DocumentTypeModel,
  UserCardModel,
  UserInfoModel,
  UserModel,
} from "@/core/models/userModel";

/**
 * Get user info
 * @param userId
 */
export const getUserInfoService = (userId: number, deviceId: string): Promise<{ user: UserInfoModel }> => {
  return api.get(`users/show/${userId}`, { device_id: deviceId }).then(resolveResponse).catch(rejectError);
};

/**
 * Add or remove a charger from the user's favorite chargers list
 * @param charger_id
 * @param isMarked
 */
export const setUserFavoriteChargerService = (charger_id: string, isMarked: boolean): Promise<{ result: boolean }> => {
  const option = isMarked ? "mark" : "unmark";

  return api.post(`users/${option}_charger_as_fav`, undefined, { charger_id }).then(resolveResponse).catch(rejectError);
};

/**
 * Service to create a user card
 * @param user_id
 */
export const createUserCardService = (user_id: string): Promise<{ card: UserCardModel }> => {
  return api
    .post(`cards/create`, {
      title: "App Mobile Card",
      code: "",
      active: "true",
      app_card: "true",
      user_id,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

/**
 * Service to get user charges
 * @param cardId
 * @param page // page number
 * @param deviceId
 */
export const getUserChargesService = async (
  userId: number,
  page = 0,
  deviceId?: string,
): Promise<{ user_Id: string; user_name: string; charges: ChargeModel[] }> => {
  return api.get(`users/charges`, { id: userId, page, device_id: deviceId }).then(resolveResponse).catch(rejectError);
};

export const getUserAppNotificationsService = (userId: number, language: string, page: number): Promise<any> => {
  return api
    .get(`app_notifications/${userId}`, {
      language: language,
      page: page,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const putSeenNotification = (notificationId: number): Promise<any> => {
  return api.put(`app_notifications/${notificationId}/seen`).then(resolveResponse).catch(rejectError);
};

export const putSeenAllNotification = (): Promise<any> => {
  return api.put(`app_notifications/seen_all`).then(resolveResponse).catch(rejectError);
};

export const activateKeychain = (keychainId: number): Promise<any> => {
  return api.put(`keychains/${keychainId}/activate`).then(resolveResponse).catch(rejectError);
};

export const disableKeychain = (keychainId: number): Promise<any> => {
  return api.put(`keychains/${keychainId}/disable`).then(resolveResponse).catch(rejectError);
};

export const updateUserFirebaseTokenService = async (
  user: UserModel,
  tokenFirebase: string,
  appOs: string,
  language: string,
): Promise<any> => {
  return api
    .get(`payments/update-firebase-token`, {
      userId: user.user_id,
      userToken: user.auth_token,
      tokenFirebase,
      os_app: appOs,
      language,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const getUserActiveChargesService = async (deviceId: string): Promise<{ charges: ChargeModel[] }> => {
  return api.get(`users/active_charges`, { device_id: deviceId }).then(resolveResponse).catch(rejectError);
};

export const createAppFeedbackService = async (feedback: string, appParameterId: number): Promise<any> => {
  return api.post(`app_feedbacks`, undefined, { feedback, appParameterId }).then(resolveResponse).catch(rejectError);
};

/**
 * List of rate with limits and their consumptions according to the type of limit.
 */
export const getUserChargeLimitsService = async (socketId: string, userId: number): Promise<RatesUserLimits> => {
  return api.get("users/get_user_charge_limits", { socketId, userId }).then(resolveResponse).catch(rejectError);
};

/**
 * Service to create a token for user that not have it
 */
export const createTokenUserService = async (
  userId: number,
  tokenApi: string,
): Promise<{ user_id: number; new_token: string }> => {
  return api
    .put(`users/set-token/${userId}`, undefined, undefined, {
      customToken: tokenApi,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

/**
 * Service to loged user with email and password
 */
export const loginUserService = async (body: {
  email: string;
  password: string;
}): Promise<{ user_id: number; auth_token: string; authenticated: string }> => {
  return api.post(`users/check-authenticated`, undefined, body).then(resolveResponse).catch(rejectError);
};

export const getCountriesUserService = async (): Promise<CountryModel[]> => {
  return api.get(`countries`).then(resolveResponse).catch(rejectError);
};
export const getCountryStatesUserService = async (countryId: string): Promise<CountryStateModel[]> => {
  return api.get(`countries/${countryId}/states`).then(resolveResponse).catch(rejectError);
};

export const getDocuementTypesUserService = async (): Promise<DocumentTypeModel[]> => {
  return api.get(`get_document_types`).then(resolveResponse).catch(rejectError);
};

export const checkExistEmailUserService = async (email: string): Promise<{ exist: boolean }> => {
  return api.get(`users/check_email`, { email }).then(resolveResponse).catch(rejectError);
};

export const createUserService = async (body: any, parameters: ParametersModel): Promise<any> => {
  return api
    .post(`users/create`, undefined, body, { customToken: parameters.token_api })
    .then(resolveResponse)
    .catch(rejectError);
};

export const updateUserService = async (
  userInfo: UserInfoModel,
  domain_id: number,
  payment_gateway: string,
): Promise<{ user: UserInfoModel }> => {
  let body: any = { ...userInfo, domain_id, active: "true" };

  delete body.billing_user;
  if (userInfo.billing_user) {
    body = {
      ...body,
      billing_fiscal_name: userInfo.billing_user.fiscal_name,
      billing_nif: userInfo.billing_user.nif,
      billing_reg_company: userInfo.billing_user.reg_company,
      billing_fiscal_address: userInfo.billing_user.fiscal_address,
      billing_city: userInfo.billing_user.city,
      billing_cp: userInfo.billing_user.cp,
      billing_type: userInfo.billing_user.billing_type,
      billing_user: "true",
    };
  } else {
    body = {
      ...body,
      billing_user: "false",
    };
  }

  if (userInfo.default_charge_values.active_automatic_charge) {
    const { default_credit_card, default_payment_method, default_wh } = userInfo.default_charge_values;
    body = {
      ...body,
      active_automatic_charge: "true",
      default_credit_card,
      default_payment_method,
      default_wh: default_wh ? default_wh * 1000 : default_wh,
    };
  } else {
    body = {
      ...body,
      active_automatic_charge: "false",
    };
  }

  return api.put(`users/update`, { payment_gateway }, body).then(resolveResponse).catch(rejectError);
};

export const deleteUserService = async (
  userId: number,
  parameters: ParametersModel,
): Promise<{
  status_code: string;
  status_message: string;
}> => {
  return api
    .del(`users/delete`, { id: userId, domain_id: parameters.domain_id }, { customToken: parameters.token_api })
    .then(resolveResponse)
    .catch(rejectError);
};
