import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { getSocketInfoPrice } from "@/commons/utils/socketUtils/socketUtils";
import { ChargerModel, ChargerSocketModel } from "@/core/models/chargerModel";
import { ReservationSocketModel } from "@/core/models/reservationModel";
import { mapActions } from "@/core/store/slices/mapSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import { MouseEvent } from "react";

interface SocketRatesProps {
  socket: ChargerSocketModel | ReservationSocketModel;
  charger?: ChargerModel;
}

export const SocketRates = ({ socket, charger }: SocketRatesProps) => {
  const dispatch = useAppDispatch();
  const { parameters } = useParametersContext();

  const { locationInfo, options } = useAppSelector((state) => state.mapReducer.selectedLocation);

  const handleGoRates = (event: MouseEvent<HTMLIonLabelElement>) => {
    event.stopPropagation();

    if (charger) {
      socket = socket as ChargerSocketModel;
      dispatch(mapActions.setSelectedSocket({ selectedCharger: charger, selectedSocket: socket }));
      if (locationInfo) {
        dispatch(
          mapActions.setLocationOptions({
            ...options,
            showRates: true,
          }),
        );
      }
    }
  };

  const { haveEnergyPrice, haveTimePrice, isFreeCharge } = getSocketInfoPrice(socket);

  return (
    <>
      {socket.rates.length > 0 ? (
        <StatusLabel onClick={(event) => handleGoRates(event)}>{t("BUTTONS.RATES")}</StatusLabel>
      ) : (
        <>
          {isFreeCharge ? (
            <StatusLabel>{t("CHARGER.FREE")}</StatusLabel>
          ) : (
            <div>
              {socket.socket_consumption_code === "TIME" && (
                <IonRow>
                  {haveTimePrice && (
                    <IonLabel>
                      {socket.price_time_min} {parameters.currency}/min
                    </IonLabel>
                  )}
                </IonRow>
              )}
              {socket.socket_consumption_code === "ENERGY" && (
                <IonRow>
                  {haveEnergyPrice && (
                    <IonLabel>
                      {socket.price_energy_kwh} {parameters.currency}/kWh
                    </IonLabel>
                  )}
                </IonRow>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
