import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { ConnectorInput } from "@/commons/components/Form/ConnectorInput/ConnectorInput";
import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { SetState } from "@/core/models/globalModel";
import { DefaultPaymentMethod, UserInfoModel } from "@/core/models/userModel";
import { userFormActions } from "@/core/store/slices/userFormSlice";
import { useAppDispatch } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonToggle } from "@ionic/react";
import { t } from "i18next";
import { addCircle } from "ionicons/icons";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { TargetFormType } from "../PersonalDataPage";

interface DefaultValuesInputsProps {
  showErrors: boolean;
  userInfoTemp: UserInfoModel;
  handleChangeForm: (target: TargetFormType) => void;
  setUserInfoTemp: SetState<UserInfoModel | undefined>;
  setUnsavedChange: SetState<boolean>;
}
export const DefaultValuesInputs = ({
  showErrors,
  userInfoTemp,
  handleChangeForm,
  setUserInfoTemp,
  setUnsavedChange,
}: DefaultValuesInputsProps) => {
  const dispatch = useAppDispatch();
  const { parameters } = useParametersContext();
  const history = useHistory();
  const { isEmpty, getLabel, checkRequiredFields } = useIsValidUser();

  const { active_automatic_charge, default_credit_card, default_payment_method, default_wh } =
    userInfoTemp.default_charge_values;

  const paymentsMethonds = useMemo(() => {
    const defaultPaymentMethod: DefaultPaymentMethod[] = ["credit_card", "wallet"];
    if (parameters.payment_type.includes("both")) {
      return defaultPaymentMethod;
    }

    if (parameters.payment_type.includes("wallet")) {
      return [defaultPaymentMethod[1]];
    }

    return [defaultPaymentMethod[0]];
  }, [parameters.payment_type]);

  useEffect(() => {
    const newValue = paymentsMethonds.length === 1 ? paymentsMethonds[0] : default_payment_method;

    dispatch(
      userFormActions.setRequiredFieldsForm({
        name: "default_charge_values.default_payment_method",
        value: active_automatic_charge && newValue === "credit_card",
      }),
    );

    setUserInfoTemp({
      ...userInfoTemp,
      default_charge_values: {
        ...userInfoTemp.default_charge_values,
        default_payment_method: newValue,
      },
    });
  }, [paymentsMethonds, active_automatic_charge]);

  return (
    <div className="default-values">
      <IonRow className="billing-row">
        <div className="title-label">
          <IonLabel className="title-h3">{t("PROFILE.ACTIVE_AUTOMATIC_CHARGE")}</IonLabel>
          <IonLabel className="title-h5">{t("PROFILE.ACTIVE_AUTOMATIC_CHARGE_SUBTITLE")}</IonLabel>
        </div>
        <IonToggle
          name="default_charge_values.active_automatic_charge"
          checked={!!active_automatic_charge}
          onIonChange={(event) => handleChangeForm(event.target)}
        />
      </IonRow>
      {!!active_automatic_charge && (
        <>
          <IonItem lines="full">
            <IonSelect
              name="default_charge_values.default_payment_method"
              value={default_payment_method}
              disabled={paymentsMethonds.length === 1}
              label={getLabel("default_payment_method", "default_charge_values")}
              labelPlacement="floating"
              onIonChange={(event) => handleChangeForm(event.target)}
            >
              {paymentsMethonds.map((value) => (
                <IonSelectOption key={value} value={value}>
                  {t(`PROFILE.${value.toUpperCase()}`)}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          {checkRequiredFields("default_charge_values.default_payment_method") &&
            isEmpty(default_payment_method) &&
            showErrors && <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>}
          {default_payment_method === "credit_card" && (
            <>
              <IonItem lines="full">
                <IonSelect
                  name="default_charge_values.default_credit_card"
                  value={default_credit_card}
                  label={getLabel("default_credit_card", "default_charge_values")}
                  labelPlacement="floating"
                  onIonChange={(event) => handleChangeForm(event.target)}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  {userInfoTemp.stripe_payment_methods.map((creditCard) => (
                    <IonSelectOption key={creditCard.id} value={Number(creditCard.id)}>
                      {creditCard.brand.toUpperCase()} / **** **** **** {creditCard.last4}
                    </IonSelectOption>
                  ))}

                  {!userInfoTemp.stripe_payment_methods.length && (
                    <>
                      <IonSelectOption disabled>{t("PROFILE.NO_CREDIT_CARD")}</IonSelectOption>
                      <IconButton
                        icon={addCircle}
                        slot="start"
                        onClick={() => {
                          history.push(APP_PATH.WALLET_ADD_CREDITCARD);
                        }}
                        fragment
                      />
                    </>
                  )}
                </IonSelect>
              </IonItem>
              {checkRequiredFields("default_charge_values.default_credit_card") &&
                isEmpty(default_credit_card) &&
                showErrors && <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>}
            </>
          )}
          <IonItem lines="full">
            <IonSelect
              name="vehicle_id"
              value={userInfoTemp.vehicle_id}
              label={getLabel("vehicle_id")}
              labelPlacement="floating"
              onIonChange={(event) => handleChangeForm(event.target)}
            >
              {userInfoTemp.vehicles.map((vehicle) => (
                <IonSelectOption key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                  {vehicle.vehicle_brand_name || vehicle.vehicle} / {vehicle.vehicle_model_name || vehicle.model} /{" "}
                  {vehicle.car_id.toLocaleUpperCase()}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          {checkRequiredFields("vehicle_id") && isEmpty(userInfoTemp.vehicle_id) && showErrors && (
            <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
          )}
          <IonItem lines="full">
            <IonInput
              name="default_charge_values.default_wh"
              label={getLabel("default_wh", "default_charge_values")}
              labelPlacement="floating"
              onIonInput={(event) => handleChangeForm(event.target)}
              type="number"
              inputMode="numeric"
              value={default_wh}
            >
              <IonLabel slot="end">kWh</IonLabel>
            </IonInput>
          </IonItem>
          {checkRequiredFields("default_charge_values.default_wh") && isEmpty(default_wh) && showErrors && (
            <IonLabel className="form-error">{t("ERRORS.MISSING_ATTRIBUTE")}</IonLabel>
          )}
          <ConnectorInput
            showErrors={showErrors}
            value={userInfoTemp.app_connector_default}
            handleChange={(value) => {
              setUnsavedChange(true);
              setUserInfoTemp({ ...userInfoTemp, app_connector_default: value });
            }}
          />
        </>
      )}
    </div>
  );
};
