import {
  CHARGER_SOCKET_CHARGE_TYPE,
  CHARGER_SOCKET_CHARGE_TYPE_ICON,
  CHARGER_SOCKET_CHARGE_TYPE_POWER,
} from "@/commons/constants/charger";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { mapActions } from "@/core/store/slices/mapSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonIcon, IonLabel, IonList } from "@ionic/react";
import { t } from "i18next";
import "./FiltersChargeType.scss";

export const FiltersChargeType = () => {
  const dispatch = useAppDispatch();

  const powerTypes = Object.entries(CHARGER_SOCKET_CHARGE_TYPE_POWER);

  const { selectedFilters } = useAppSelector((state) => state.mapReducer.filters);

  const handleClick = (type: string) => {
    let newChargeTypes = [...selectedFilters.chargeTypes];
    let newFilterNumber = selectedFilters.filterNumber;

    if (newChargeTypes.includes(type)) {
      newChargeTypes = newChargeTypes.filter((item) => item !== type);
      newFilterNumber--;
    } else {
      newChargeTypes.push(type);
      newFilterNumber++;
    }

    dispatch(
      mapActions.setSelectedFilters({ ...selectedFilters, chargeTypes: newChargeTypes, filterNumber: newFilterNumber }),
    );
  };

  return (
    <div className="filters-charge-type">
      <IonLabel className="filter-title">{t("TITLES.CHARGE_TYPE")}</IonLabel>

      <IonList className="filter-list" lines="none">
        {powerTypes.map((powerType) => {
          const key = powerType[0] as CHARGER_SOCKET_CHARGE_TYPE;
          const { type, max, min } = powerType[1];

          return (
            <div
              key={key}
              className={`filter-item ${classIf(selectedFilters.chargeTypes.includes(type), "selected")}`}
              onClick={() => handleClick(type)}
            >
              <div>
                <IonIcon icon={CHARGER_SOCKET_CHARGE_TYPE_ICON[key]} className="icon-filter" />
              </div>
              <div className="description">
                <IonLabel className="charge-title">{t(`CHARGES_TYPES.${key}`)}</IonLabel>
                <IonLabel className="charge-type-power">
                  {`> ${min}`} - {max} kW
                </IonLabel>
              </div>
            </div>
          );
        })}
      </IonList>
    </div>
  );
};
