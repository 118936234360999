import { IonCol, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";

export const CreditCardListSkeleton = () => {
  return (
    <div
      style={{
        padding: "15px",
      }}
      className="container"
    >
      <IonCol>
        <IonRow className="ion-margin-bottom">
          <IonLabel style={{ width: "fit-content", paddingTop: "5px" }}>
            <IonSkeletonText animated={true} style={{ width: "80px", height: "20px" }} />
          </IonLabel>
          <IonCol className="ion-justify-content-center editCol" style={{ width: "fit-content" }}>
            <IonSkeletonText animated={true} style={{ width: "30px", height: "30px" }}></IonSkeletonText>
          </IonCol>
        </IonRow>
        <IonLabel style={{ paddingTop: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <IonSkeletonText animated={true} style={{ height: "70px", borderRadius: "15px" }} />
        </IonLabel>
        <IonLabel style={{ paddingTop: "5px" }}>
          <IonSkeletonText
            animated={true}
            style={{ marginTop: "10px", alignItems: "center", width: "100%", height: "50px", borderRadius: "30px" }}
          />
        </IonLabel>
      </IonCol>
    </div>
  );
};
