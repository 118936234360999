import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { getSocketInfoPrice } from "@/commons/utils/socketUtils/socketUtils";
import { getUserAppCard } from "@/commons/utils/userUtils/userUtils";
import { AccountModel } from "@/core/models/accountModel";
import { SetState } from "@/core/models/globalModel";
import { createPaymentAccountB2BService, createPaymentAccountService } from "@/core/services/payment/paymentServices";
import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { t } from "i18next";
import { useState } from "react";
import { PaymentFrameRespose, PaymentFrameType } from "../components/Payments/PaymentFrame/PaymentFrame";
import { usePayment } from "./usePayment";
import { useSlack } from "./useSlack";

interface CreateChargeAccountProps {
  callback: (account: AccountModel | null, error?: any) => void;
  setPaymentFrame?: (paymentFrame: { isOpen: boolean; type: PaymentFrameType }) => void;
  setResponse?: SetState<PaymentFrameRespose | undefined>;
}

export const useCreateChargeAccount = ({ callback, setPaymentFrame, setResponse }: CreateChargeAccountProps) => {
  const dispatch = useAppDispatch();
  const { preCapturePaymentCreditCard, payChargeWithWalletBalance } = usePayment();
  const slack = useSlack();

  const { parameters } = useParametersContext();
  const { user, userInfo, deviceId } = useAppSelector((state) => state.userReducer);
  const { selectedCharger, selectedSocket } = useAppSelector((state) => state.mapReducer.selectedLocation);
  const { currentStep, stepsLabels, nextStepDisabled, chooseStep, activeStep, paymentStep } = useAppSelector(
    (state) => state.chargeStepsReducer,
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleNextStep = async (forceCreateAccount = false) => {
    try {
      if (!selectedSocket) {
        throw new Error(t("APP_ERRORS.SELECTED_SOCKET"));
      }
      if (!selectedCharger) {
        throw new Error(t("APP_ERRORS.SELECTED_CHARGER"));
      }

      // If is rate Charge free and next step is shouldbe charge
      const { isFreeCharge } = getSocketInfoPrice(selectedSocket);

      const stepsLength =
        !isFreeCharge && !chooseStep.price && stepsLabels.length === 3 && currentStep === 0
          ? stepsLabels.length - 1
          : stepsLabels.length;

      const nextStepIsLast = currentStep + 1 === stepsLength - 1;

      if (nextStepIsLast || forceCreateAccount) {
        // Only go next step if precapture is completed
        setIsLoading(true);

        const card = await getUserAppCard(user.user_id, userInfo);
        dispatch(chargeStepsActions.setPaymentStepCardCode(card.code_card));

        const { paymentMethod, selectPaymentId, extraField } = paymentStep;

        const userEmail = userInfo ? userInfo.email : activeStep.email;
        const cardCode = card.code_card;
        const energyWh = chooseStep.energy * 1000;
        const price = chooseStep.price;
        const currencyCode = chooseStep.currency?.code;

        if (isFreeCharge || price === 0) {
          const accountUser = await createPaymentAccountService(
            parameters,
            user.user_id,
            userEmail,
            cardCode,
            selectedSocket,
            energyWh,
            price,
            activeStep,
            deviceId,
          );

          dispatch(chargeStepsActions.setChargeStepAccount(accountUser));

          return callback(accountUser);
        }

        if (selectedSocket.is_b2b_user) {
          const { account } = await createPaymentAccountB2BService(
            parameters,
            user.user_id,
            userEmail,
            cardCode,
            selectedSocket,
            energyWh,
            price,
            activeStep,
            deviceId,
          );

          const acountJson = JSON.parse(account) as AccountModel;

          dispatch(chargeStepsActions.setChargeStepAccount(acountJson));

          return callback(acountJson);
        }

        if (paymentMethod === "CREDITCARD") {
          if (!selectPaymentId || !activeStep.vehicleId) {
            throw new Error(t("APP_ERRORS.PAYMENT_DATA"));
          }

          const account = await preCapturePaymentCreditCard(
            cardCode,
            selectedCharger,
            selectedSocket.id_socket,
            energyWh,
            selectPaymentId,
            deviceId,
            activeStep.vehicleId,
            price,
            setResponse || (() => {}),
            extraField,
          );

          if (!account) {
            return (
              setPaymentFrame &&
              setPaymentFrame({
                isOpen: true,
                type: "redsysPayment",
              })
            );
          }

          dispatch(chargeStepsActions.setChargeStepAccount(account));
          return callback(account);
        }

        if (paymentMethod === "WALLET" && userInfo) {
          if (price * 100 > userInfo.wallet_amount) {
            throw new Error(t("APP_ERRORS.MIN_BALANCE"));
          }
          if (!activeStep.vehicleId) {
            throw new Error(t("APP_ERRORS.PAYMENT_DATA"));
          }
          const account = await payChargeWithWalletBalance(
            selectedSocket.id_socket,
            energyWh,
            cardCode,
            activeStep.vehicleId,
            currencyCode,
            deviceId,
          );
          dispatch(chargeStepsActions.setChargeStepAccount(account));
          return callback(account);
        }
      }
    } catch (error) {
      slack.error("handleNextStep", error);
      callback(null, error);
      return dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }

    !nextStepDisabled && callback(null, undefined);
  };

  return { isLoading, setIsLoading, handleNextStep };
};
