import { RemoveButton } from "@/commons/components/Buttons/RemoveButton/RemoveButton";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { CREDIT_CARD_LOGOS } from "@/commons/constants/payments";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { usePayment } from "@/commons/hooks/usePayment";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { StripePaymentMethod } from "@/core/models/userModel";
import { setDefaultPaymentMethodService } from "@/core/services/payment/paymentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonCol, IonIcon, IonLabel, IonLoading, IonRow } from "@ionic/react";
import { t } from "i18next";
import { checkmarkCircleOutline } from "ionicons/icons";
import { useState } from "react";
import "./CreditCard.scss";

interface CreditCardProps {
  creditCard: StripePaymentMethod;
  isSelected?: boolean;
  showActions?: boolean;
  onClick?: (creditCard: StripePaymentMethod) => void;
}

export const CreditCard = ({ creditCard, isSelected, showActions = false, onClick }: CreditCardProps) => {
  const dispatch = useAppDispatch();
  const { parameters } = useParametersContext();
  const { deleteCreditCard } = usePayment();
  const { loadUserInfo } = useUserContext();

  const { id, last4, exp, brand, predetermined } = creditCard;
  const logo = CREDIT_CARD_LOGOS[brand.toLocaleLowerCase()];

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectDeleteCreditCard = () => {
    dispatch(
      uiActions.setAlertType({
        type: "deleteCreditCard",
        options: { primaryAction: handleDeleteCreditCardUser, message: last4 },
      }),
    );
  };

  const handleDeleteCreditCardUser = async () => {
    try {
      setIsLoading(true);
      await deleteCreditCard(creditCard.payment_method);
      await loadUserInfo();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePredetermined = async () => {
    try {
      setIsLoading(true);
      await setDefaultPaymentMethodService({ id, payment_gateway: parameters.payment_gateway });
      await loadUserInfo();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="credit-card">
      <IonLoading isOpen={isLoading} />
      <div
        className={`credit-card-container ${classIf(!!isSelected, "selected")} ${classIf(!!showActions, "show-actions")}`}
        onClick={() => onClick && onClick(creditCard)}
      >
        <div className="credit-card-container-first-row">
          {logo ? (
            <img src={logo} alt={brand} className="credit-card-container-logo" />
          ) : (
            <IonLabel>{brand.toUpperCase()}</IonLabel>
          )}
          {!!predetermined && (
            <div>
              <StatusLabel active>{t("CREDIT_CARD.PREDETERMINED")}</StatusLabel>
            </div>
          )}
        </div>

        <IonRow className={`credit-card-container-number ${classIf(!!exp, "have-exp")}`}>
          <IonLabel>{exp}</IonLabel>
          <IonLabel className="">**** **** **** {last4}</IonLabel>
        </IonRow>
        {showActions && (
          <IonRow className="credit-card-container-actions">
            <RemoveButton onClick={handleSelectDeleteCreditCard} />
            {!predetermined && (
              <IonCol className="vehicle-card-button" onClick={handleChangePredetermined}>
                <IonIcon icon={checkmarkCircleOutline} color="primary" />
                <IonLabel className="filtrosLabel" color="primary">
                  {t("BUTTONS.MARK_DEFAULT")}
                </IonLabel>
              </IonCol>
            )}
          </IonRow>
        )}
      </div>
    </div>
  );
};
