import i18n from "@/assets/i18n/i18n";
import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { PaymentCreateIntentModel, WalletTransactionModel } from "@/core/models/PaymentModel";
import { AccountMerchantModel, CartProductModel, MerchantProductModel } from "@/core/models/accountMerchantModel";

export const keychainLinkService = async (id: number | string): Promise<any> => {
  return api.post(`keychains/${id}/link`, {}).then(resolveResponse).catch(rejectError);
};

export const getMerchatProductsService = async (): Promise<{ products: MerchantProductModel[] }> => {
  return api.get(`app_merchant_products`, { language: i18n.language }).then(resolveResponse).catch(rejectError);
};

export const getAccountMerchantsService = async (): Promise<{ account_merchants: AccountMerchantModel[] }> => {
  return api.get(`account_merchants`, { language: i18n.language }).then(resolveResponse).catch(rejectError);
};

export const createMerchantPaymentIntentService = async (
  userCart: CartProductModel[],
  payment_method: any,
): Promise<{
  intent: PaymentCreateIntentModel;
  account: AccountMerchantModel;
  transaction: WalletTransactionModel;
}> => {
  return api
    .post(`account_merchants/create_merchant_payment_intent`, undefined, {
      cart_products: userCart,
      payment_method: payment_method,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const confirmStripeMerchantPaymentIntentService = async (
  accountId: number,
  transactionId?: number,
): Promise<any> => {
  return api
    .post(`account_merchants/confirm_stripe_merchant_payment_intent`, undefined, {
      account: accountId,
      transaction: transactionId,
    })
    .then(resolveResponse)
    .catch(rejectError);
};
