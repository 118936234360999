import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { Color } from "@ionic/core";
import { IonBadge, IonButton, IonIcon, IonLabel, IonSpinner } from "@ionic/react";
import { MouseEventHandler, ReactNode } from "react";
import "./IconButton.scss";

interface IconButtonProps {
  id?: string;
  className?: string;
  color?: Color | "clear";
  icon: string;
  badge?: number;
  fill?: "clear" | "outline" | "solid" | "default";
  disabled?: boolean;
  slot?: "start" | "end";
  size?: "large" | "medium" | "small";
  fragment?: boolean;
  onClick: MouseEventHandler<HTMLIonButtonElement>;
  children?: ReactNode;
  loading?: boolean;
}

const ConditionalWrapper = ({ condition, children }: { condition: boolean; children: ReactNode }) =>
  condition ? <div>{children}</div> : <>{children}</>;

export const IconButton = ({
  id = "",
  className = "icon-button",
  color = "primary",
  icon,
  badge = 0,
  fill = "clear",
  disabled = false,
  slot,
  size = "large",
  fragment = false,
  children,
  loading = false,
  onClick,
}: IconButtonProps) => {
  return (
    <ConditionalWrapper condition={!fragment}>
      <IonButton
        id={id}
        className={`${className} ${size}`}
        color={color}
        fill={fill}
        disabled={disabled || loading}
        slot={slot}
        onClick={onClick}
      >
        {loading ? (
          <IonSpinner />
        ) : (
          <>
            {!children ? (
              <IonIcon slot="icon-only" icon={icon} />
            ) : (
              <>
                <IonIcon slot="icon-only" icon={""} />
                <IonLabel>{children}</IonLabel>
              </>
            )}

            {!!badge && (
              <IonBadge className={`badge ${classIf(badge > 0 && badge < 10, "oneDigit")}`} color="primary">
                {badge}
              </IonBadge>
            )}
          </>
        )}
      </IonButton>
    </ConditionalWrapper>
  );
};
