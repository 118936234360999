import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { getURLDomain } from "@/commons/utils/apiUtils/apiUtils";
import { PaymentDSModel } from "@/core/models/PaymentModel";
import { AccountModel } from "@/core/models/accountModel";
import { ReservationModel } from "@/core/models/reservationModel";
import { IonContent, IonModal } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";

export type PaymentResponseTypeRedsys = {
  success: boolean;
  data?: PaymentDSModel;
  account?: AccountModel;
  account_id?: number;
  reservation?: ReservationModel;
  reservation_id?: number;
  transaction_id?: any;
};

export type PaymentResponseTypeWalletSISP = {
  amount: number;
  creditCard?: string;
};

export type PaymentFrameType = "redsysPayment" | "walletSISP";

export type PaymentFrameRespose = PaymentResponseTypeRedsys | PaymentResponseTypeWalletSISP;

interface PaymentFrameProps {
  isOpen: boolean;
  setIsOpenPaymentFrame: (isOpen: boolean) => void;
  type: PaymentFrameType;
  callback: (values: any) => void;
  response?: PaymentFrameRespose;
}

const TARGET_FORM = "card-form-iframe";
const { VITE_HOST } = import.meta.env;

export const PaymentFrame = ({ isOpen, setIsOpenPaymentFrame, response, type, callback }: PaymentFrameProps) => {
  const [promiseResolver, setPromiseResolver] = useState<(props: any) => (value: unknown) => void>();

  const [iFrameSrc, setIFrameSrc] = useState("");

  useEffect(() => {
    isOpen && handleOpenModal();

    !isOpen && document.removeEventListener("message", captureEvent);
  }, [isOpen]);

  const handleOpenModal = async () => {
    // Abre el modal y espera a que se resuelva la promesa
    const data = await openModal();
    // Aquí se ejecutará después de que se resuelva la promesa
    // callback(values);
    callback(data);
  };

  const openModal = () => {
    setIsOpenPaymentFrame(true);

    const newPromise = new Promise((resolve) => {
      setPromiseResolver(() => resolve);
    });

    return newPromise;
  };

  const handleLoadFrame = () => {
    if (!response) return;

    if (type === "walletSISP") {
      const { amount, creditCard } = response as PaymentResponseTypeWalletSISP;
      const params = new URLSearchParams({ amount: `${amount}` });
      creditCard && params.append("paymentMethod", creditCard);

      const url = getURLDomain(`api/v1/sisp/load_form_create_sisp_payment${params}`);
      setIFrameSrc(url);
    }

    if (type === "redsysPayment") {
      const { success, data, account_id, reservation_id, transaction_id } = response as PaymentResponseTypeRedsys;

      window.addEventListener("message", captureEvent);

      if (data?.Ds_EMV3DS) {
        const { threeDSInfo, creq, acsURL, MD, PaReq, TermUrl } = data.Ds_EMV3DS;

        if (threeDSInfo === "ChallengeRequest" && acsURL && creq) {
          const formElement = document.getElementById("threeDSFormV2") as HTMLFormElement | undefined;
          formElement?.setAttribute("action", acsURL);

          setValueElement("creqInput", creq);

          return formElement?.submit();
        }

        if (threeDSInfo === "AuthenticationRequest" && acsURL && MD && PaReq && TermUrl) {
          const formElement = document.getElementById("threeDSFormV1") as HTMLFormElement | undefined;
          formElement?.setAttribute("action", acsURL);

          setValueElement("MDInput", MD);
          setValueElement("PaReqInput", PaReq);
          setValueElement("TermUrlInput", TermUrl);

          return formElement?.submit();
        }
      }

      const formElement = document.getElementById("thirdForm") as HTMLFormElement | undefined;
      const action = getURLDomain("api/v1/redsys/notification_3ds", undefined, { isRootPath: true });
      formElement?.setAttribute("action", action);

      if (success && data?.Ds_Response === "0000" && (account_id || reservation_id || transaction_id)) {
        setValueElement("responseSuccess", "true");
        setValueElement("walletThird", "true");
        setValueElement("account_id", `${account_id || ""}`);
        setValueElement("reservation_id", `${reservation_id || ""}`);
        setValueElement("transaction_id", `${transaction_id || ""}`);

        return formElement?.submit();
      }

      // Error
      setValueElement("responseSuccess", "false");

      formElement?.submit();
    }
  };

  const dismissModal = (data: any) => {
    setIsOpenPaymentFrame(false);

    const result = data?.success ? data : null;
    promiseResolver?.(result);
  };

  const captureEvent = ({ origin, data }: any) => {
    if (origin.includes(VITE_HOST)) {
      setTimeout(() => {
        dismissModal(data);
      }, 2000);
    }
  };

  const setValueElement = (id: string, value: string) => document.getElementById(id)?.setAttribute("value", value);
  return (
    <div style={{ color: "black" }}>
      <div style={{ display: "none" }}>
        <form id="threeDSFormV2" method="post" target={TARGET_FORM}>
          <input id="creqInput" readOnly name="creq" />
        </form>

        <form id="threeDSFormV1" method="post" target={TARGET_FORM}>
          <input id="MDInput" readOnly name="MD" />
          <input id="PaReqInput" readOnly name="PaReq" />
          <input id="TermUrlInput" readOnly name="TermUrl" />
        </form>

        <form id="thirdForm" method="post" target={TARGET_FORM}>
          <input id="walletThird" readOnly name="walletThird" />
          <input id="responseSuccess" readOnly name="response[success]" />
          <input id="account_id" readOnly name="account_id" />
          <input id="reservation_id" readOnly name="reservation_id" />
          <input id="transaction_id" readOnly name="transaction_id" />
        </form>
      </div>
      <IonModal isOpen={isOpen} onDidPresent={handleLoadFrame}>
        <AppHeader hiddenMenu hiddenTitle endIcon={<IconButton icon={closeOutline} onClick={dismissModal} />} />
        <IonContent>
          <iframe
            id={TARGET_FORM}
            name={TARGET_FORM}
            src={iFrameSrc}
            style={{ border: "none", width: "100%", height: "calc(100vh - 54px)" }}
            title="paymentFrame"
          />
        </IonContent>
      </IonModal>
    </div>
  );
};
