import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { useHistory } from "react-router-dom";

import keychainBanner from "@/assets/imgs/keychain-banner.png";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { Capacitor } from "@capacitor/core";
import { IonButton, IonContent, IonImg, IonLabel, IonLoading, IonPage, useIonViewWillEnter } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { sortBy } from "lodash";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { KeychainCard } from "./KeychainCard/KeychainCard";
import "./KeychainsPage.scss";

const { VITE_APP_NAME_DISPLAY } = import.meta.env;

export const KeychainsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { loadUserInfo } = useUserContext();

  const { parameters } = useParametersContext();

  const { userInfo, isUserLogged } = useAppSelector((state) => state.userReducer);

  const [showLoadingData, setShowLoadingData] = useState(false);

  const keychains = sortBy(
    userInfo?.user_cards?.filter((card) => card.etecnic_card === "true"),
    "id_card",
  );

  const haveKeychain = userInfo?.user_cards?.some((card) => card.etecnic_card === "true");

  useIonViewWillEnter(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setShowLoadingData(true);
      await loadUserInfo();
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setShowLoadingData(false);
    }
  };

  const handleAddKeychain = () => {
    if (Capacitor.getPlatform() === "android") {
      dispatch(uiActions.setNfc({ isNfcOpen: true, showManualKeychain: true }));
      return;
    }

    dispatch(uiActions.setIsAddManualKeychainOpen(true));
  };

  const handleMoreInfo = () => {
    if (parameters.keychain_landing) {
      window.open(parameters.keychain_landing, "_system");
    }
  };

  const handleKeychain = () => {
    if (!isUserLogged) {
      dispatch(uiActions.setAlertType({ type: "alertRegister" }));
      return;
    }
    history.push(APP_PATH.SHOP);
  };

  return (
    <IonPage className="keychain-page">
      <AppHeader
        title={`${t("MENU.KEYCHAIN")}`}
        endIcon={<IconButton color="dark" icon={addOutline} onClick={handleAddKeychain} />}
      />
      <IonLoading isOpen={showLoadingData} />
      <IonContent>
        {!haveKeychain ? (
          <div>
            <IonImg src={keychainBanner} />
            <div className="keychain-page-buttons">
              <IonButton onClick={handleMoreInfo} fill="outline" color="primary">
                {t("BUTTONS.MORE_INFO")}
              </IonButton>
              <IonButton onClick={handleKeychain}>{t("BUTTONS.KEYCHAIN")}</IonButton>
            </div>
            <div className="text-container">
              <IonLabel className="title">{t("KEYCHAIN.TEXT1")}</IonLabel>
              <div className="text-list">
                {[2, 3, 4].map((number) => (
                  <IonLabel key={number} className="text-item">
                    <Trans i18nKey={`KEYCHAIN.TEXT${number}`} values={{ app_name: VITE_APP_NAME_DISPLAY }} />
                  </IonLabel>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <>
            {keychains.map((card) => (
              <KeychainCard key={card.id_card} card={card} />
            ))}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};
