import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { uploadImageService } from "@/core/services/document/documentServices";
import { addIncidenceCommentService, getIncidenceService } from "@/core/services/user/ticketServices";
import { incidenceActions } from "@/core/store/slices/incidenceSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppSelector } from "@/core/store/store";
import { App } from "@capacitor/app";
import {
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonTextarea,
} from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { attachOutline, closeOutline, send, timeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { IncidenceComment } from "./IncidenceComment/IncidenceComment";
import "./IncidencePage.scss";
import { IncidenceSkeleton } from "./IncidenceSkeleton/IncidenceSkeleton";

export const IncidencePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { user } = useAppSelector((state) => state.userReducer);
  const { isLoadingIncidence, incidence, newIncidenceComment, lastUpdateIncidence } = useAppSelector(
    (state) => state.incidenceReducer,
  );
  const isActive = incidence && incidence.status !== "CLOSED";

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const contentRef = useRef<HTMLIonContentElement>(null);

  const [isSendingMessage, setIsSendingMessage] = useState(false);

  useEffect(() => {
    id && loadData();
  }, [id]);

  useEffect(() => {
    lastUpdateIncidence && lastUpdateIncidence.id == id && loadData(false);
  }, [lastUpdateIncidence?.date]);

  useEffect(() => {
    const listenerPromises = App.addListener("resume", () => {
      id && loadData();
    });

    return () => {
      listenerPromises.then((listener) => listener.remove());
    };
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      contentRef.current?.scrollToBottom(0);
    }, 100);
  }, [incidence, contentRef.current]);

  const loadData = async (showLoading = true) => {
    try {
      if (!id) return;

      showLoading && dispatch(incidenceActions.setIsLoadingIncidence(true));
      const { support_ticket } = await getIncidenceService(Number(id));
      dispatch(incidenceActions.setIncidence(support_ticket));
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      showLoading && dispatch(incidenceActions.setIsLoadingIncidence(false));
    }
  };

  const handleChangeComment = (event: any) => {
    dispatch(incidenceActions.setNewIncidenceComment({ ...newIncidenceComment, comment: event.target.value }));
  };

  const handleAttachFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    try {
      setIsSendingMessage(true);
      const selectedFile = e.target.files[0];
      const image = await uploadImageService(selectedFile);

      dispatch(
        incidenceActions.setNewIncidenceComment({
          ...newIncidenceComment,
          image: { id: image.id, data: selectedFile, file_name: selectedFile.name },
        }),
      );
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsSendingMessage(false);
    }
  };

  const handlerDeleteImage = () => {
    dispatch(incidenceActions.setNewIncidenceComment({ ...newIncidenceComment, image: null }));
  };

  const sendComment = async () => {
    if (!incidence) return;

    try {
      setIsSendingMessage(true);
      if (user) {
        // comment_support_ticket is new incidence (not new comment)
        const { comment_support_ticket } = await addIncidenceCommentService({
          user_id: user.user_id,
          support_ticket_id: incidence.id,
          comment: newIncidenceComment.comment,
          image_id: newIncidenceComment.image?.id || null,
        });

        comment_support_ticket && dispatch(incidenceActions.setIncidence(comment_support_ticket));
        dispatch(incidenceActions.setNewIncidenceComment({ comment: "", image: null }));
      }
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsSendingMessage(false);
    }
  };

  return (
    <IonPage className="incidence">
      <AppHeader
        startIcon={
          <GoBackButton
            onClick={() => {
              history.goBack();
              dispatch(incidenceActions.clearIncidence());
            }}
          />
        }
        title={
          !isLoadingIncidence ? (
            t("TITLES.INCIDENCE", { id })
          ) : (
            <IonSkeletonText animated={true} style={{ width: "140px", height: "24px" }} />
          )
        }
      />
      <IonContent ref={contentRef} scrollY={!isLoadingIncidence}>
        <IonLoading isOpen={isSendingMessage} message={t("LOADING.SENDING_MESSAGE")} />

        {isLoadingIncidence && <IncidenceSkeleton />}

        {!isLoadingIncidence && incidence && (
          <>
            <div className="incidence-info">
              <div className="title-container">
                <IonLabel>{incidence.title}</IonLabel>
                <IonRow>
                  <StatusLabel active={isActive}>
                    {isActive && <IonIcon icon={timeOutline} />}
                    {isActive ? t("INCIDENCES.ACTIVE") : t("INCIDENCES.CLOSED")}
                  </StatusLabel>
                </IonRow>
              </div>
              <IonLabel>{format(incidence.date, "dd/MM/yyyy")}</IonLabel>
            </div>
            <IonList className="incidence-comments">
              {incidence.comments.map((comment) => (
                <IncidenceComment key={comment.id} comment={comment} />
              ))}
            </IonList>
          </>
        )}
      </IonContent>
      {incidence?.status !== "CLOSED" && (
        <div>
          {newIncidenceComment.image && (
            <div className="attachment-image">
              <IonItem lines="none">
                <IonIcon icon={closeOutline} slot="start" onClick={handlerDeleteImage} />
                <p>
                  {t("BUTTONS.ATTACH_FILE_NAME")} {newIncidenceComment.image.file_name}
                </p>
              </IonItem>
              <img
                src={URL.createObjectURL(newIncidenceComment.image.data)}
                style={{ width: "25%", alignItems: "center" }}
                alt="attachment"
              />
            </div>
          )}
          <IonRow className="send-input-container">
            <IonCol>
              <IonItem lines="none" className="send-input">
                <IonTextarea
                  placeholder={t("PLACEHOLDERS.TYPE_MESSAGE")}
                  autoGrow={true}
                  value={newIncidenceComment.comment}
                  onIonInput={handleChangeComment}
                  disabled={isLoadingIncidence}
                />
                <IconButton
                  className="attachment-button"
                  icon={attachOutline}
                  slot="end"
                  onClick={handleAttachFile}
                  disabled={isLoadingIncidence}
                />
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                </div>
              </IonItem>
            </IonCol>
            <IonCol size="20%">
              <IonRow>
                <IconButton
                  className="send-button"
                  fill="solid"
                  icon={send}
                  slot="end"
                  disabled={!newIncidenceComment.image && !newIncidenceComment.comment}
                  onClick={sendComment}
                />
              </IonRow>
            </IonCol>
          </IonRow>
        </div>
      )}
    </IonPage>
  );
};
