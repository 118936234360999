import { CHARGER_SOCKET_CONNECTOR_ICON } from "@/commons/constants/charger";
import { CHARGER_SOCKET_CONNECTOR_TYPE } from "@/core/models/chargerModel";
import { IonIcon, IonLabel, IonRow } from "@ionic/react";
import "./SocketConnector.scss";

interface SocketConnectorProps {
  connectorTypeCode: CHARGER_SOCKET_CONNECTOR_TYPE;
}

export const SocketConnector = ({ connectorTypeCode }: SocketConnectorProps) => {
  let connectorTypeLabel = connectorTypeCode === CHARGER_SOCKET_CONNECTOR_TYPE.TYPE2F ? "TYPE2F" : connectorTypeCode;

  connectorTypeLabel = connectorTypeCode === CHARGER_SOCKET_CONNECTOR_TYPE.TYPE2M ? "TYPE2M" : connectorTypeLabel;

  return (
    <IonRow className="socket-connector">
      <IonIcon className="socket-icon" src={CHARGER_SOCKET_CONNECTOR_ICON[connectorTypeCode]} />
      <IonLabel className="socket-connector-label">{connectorTypeLabel}</IonLabel>
    </IonRow>
  );
};
