import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonToast } from "@ionic/react";
import { t } from "i18next";
import "./Toast.scss";
import { toastTypesComponents } from "./toastTypes";

export const Toast = () => {
  const dispatch = useAppDispatch();

  const { show, type, message, duration, color, position, buttons } = useAppSelector((state) => state.uiReducer.toast);

  const handleDismiss = () => {
    dispatch(uiActions.cleanToast());
  };

  const customType = (type && toastTypesComponents(t)[type]) || null;

  const toastPosition = customType?.position || position || "top";
  return (
    <IonToast
      className={`custom-toast ${classIf(toastPosition === "top", "top")}`}
      isOpen={show}
      onDidDismiss={handleDismiss}
      message={message || customType?.message}
      duration={customType?.duration || duration || 2000}
      position={toastPosition}
      color={customType?.color || color || "primary"}
      buttons={customType?.buttons || buttons}
      swipeGesture="vertical"
    />
  );
};
