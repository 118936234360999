import i18n from "@/assets/i18n/i18n";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { WalletHistoryModel } from "@/core/models/PaymentModel";
import { getDocumentDownloadApiService } from "@/core/services/document/documentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonIcon, IonItem, IonLabel, IonLoading, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { arrowBackCircle, arrowForwardCircle, downloadOutline } from "ionicons/icons";
import { useState } from "react";
import { Trans } from "react-i18next";
import "./HistoryTransaction.scss";

interface HistoryTransactionProps {
  transaction: WalletHistoryModel;
}

export const HistoryTransaction = ({ transaction }: HistoryTransactionProps) => {
  const { parameters } = useParametersContext();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const amount = (transaction.amount || 0) / 100;
  const type = amount > 0 ? "ADDED" : "WITHDRAWN";
  const isAdded = type === "ADDED";
  const invoice_id = transaction.invoice_id;

  const getWalletPaymentTicket = async () => {
    try {
      setIsLoading(true);
      await getDocumentDownloadApiService(`invoice-${invoice_id}.pdf`, "payments/show_invoice.pdf", {
        invoice_id,
        locale: i18n.language,
      });
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <IonItem lines="full" className="history-transaction">
        <IonRow className="history-transaction-row">
          <div>
            <IonIcon
              className={`icon ${classIf(isAdded, "added")}`}
              size="1"
              color={isAdded ? "primary" : "default"}
              icon={isAdded ? arrowForwardCircle : arrowBackCircle}
            />
          </div>
          <div>
            <IonLabel className="description">
              <Trans
                i18nKey={`BALANCE.${type}`}
                components={{
                  span: <span className="amount strong-text" />,
                }}
                values={{ amount: `${amount.toFixed(2)} ${parameters.currency_code}` }}
              />
            </IonLabel>

            <IonLabel className="date">{format(transaction.created_at, "dd/MM/yyyy HH:mm")}</IonLabel>
          </div>
        </IonRow>
        {invoice_id && (
          <IconButton
            className="download-button"
            icon={downloadOutline}
            onClick={getWalletPaymentTicket}
            color="dark"
          />
        )}
      </IonItem>
    </>
  );
};
