import { t as translate } from "i18next";
import { z } from "zod";

export const CHARGE_ACTIVATE_FORM_SCHEMA = (t: typeof translate) =>
  z
    .object({
      email: z.string().email(t("ERRORS.EMAIL_ERROR")),
      vehicleBrand: z
        .object({
          id: z.number(),
          name: z.string(),
          models: z.any().array(),
        })
        .nullable(),
      otherBrand: z.string(),
      vehicleModel: z
        .object({
          id: z.number(),
          name: z.string(),
        })
        .nullable(),
      otherModel: z.string(),
      carId: z.string().min(1),
    })
    .refine((data) => {
      if (!data.vehicleBrand) return false;

      if (data.vehicleBrand?.id === 0 && (!data.otherBrand || !data.otherModel)) return false;

      if (data.vehicleBrand?.id !== 0 && !data.vehicleModel) return false;

      return true;
    }, "brand required");
