import { chargeStepsActions } from "@/core/store/slices/chargeStepsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { useEffect } from "react";
import { ChargeDataGuestUser } from "./ChargeDataGuestUser/ChargeDataGuestUser";
import { ChargeDataLoggedUser } from "./ChargeDataLoggedUser/ChargeDataLoggedUser";

export const ChargeStepActivate = () => {
  const dispatch = useAppDispatch();
  const { isUserLogged, userInfo } = useAppSelector((state) => state.userReducer);
  const { activeStep, chooseStep } = useAppSelector((state) => state.chargeStepsReducer);

  useEffect(() => {
    if (!chooseStep.isLoadingPrice) {
      dispatch(chargeStepsActions.setNextStepDisabled(!activeStep.vehicleId));
    }
  }, [activeStep.vehicleId]);

  return <>{isUserLogged && userInfo?.vehicles.length !== 0 ? <ChargeDataLoggedUser /> : <ChargeDataGuestUser />}</>;
};
