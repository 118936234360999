import i18n from "i18next";
import LanguageDetector, { DetectorOptions } from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ca from "./ca.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import pl from "./pl.json";
import pt from "./pt.json";
import ro from "./ro.json";

import {
  Locale,
  ca as caDate,
  de as deDate,
  enGB as enGBDate,
  es as esDate,
  fr as frDate,
  pl as plDate,
  pt as ptDate,
} from "date-fns/locale";

export const dateFnslocalesDate: Record<string, Pick<Locale, keyof Locale>> = {
  es: esDate,
  en: enGBDate,
  ca: caDate,
  de: deDate,
  fr: frDate,
  pl: plDate,
  pt: ptDate,
};

const options: DetectorOptions = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
  convertDetectedLanguage: (lng) => lng.split("-")[0],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    detection: options,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      ca: {
        translation: ca,
      },
      de: {
        translation: de,
      },
      pt: {
        translation: pt,
      },
      fr: {
        translation: fr,
      },
      pl: {
        translation: pl,
      },
      ro: {
        translation: ro,
      },
    },
  });

export default i18n;
