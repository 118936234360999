import { LatLng } from "leaflet";
import { ChargerModel } from "./chargerModel";

export enum LOCATION_MAP_STATUS {
  AVAILABLE = 0,
  CHARGING = 1,
  RESERVED = 2,
  OUT_SERVICE = 3,
}
export interface LocationMapModel {
  location_id: number;
  external_code?: string;
  address: string;
  location_latitude: string;
  location_longitude: string;
  location_sockets_count: number;
  chargers: LocationMapChargerModel[];
  location_status: LOCATION_MAP_STATUS;
  latLng: LatLng;
}

export interface LocationMapChargerModel {
  id: number;
  name: string;
  power_max_kW: number;
  status: number;
  location_id?: number;
}

export interface LocationModel {
  id: number;
  external_code?: any;
  address: string;
  facilities: FacilityModel[];
  latitude: string;
  longitude: string;
  additional_info?: string;
  regular_hours: RegularHourModel[];
  exceptional_periods_open: any[];
  exceptional_periods_close: any[];
  green_energy: boolean;
  distance: number;
  near: boolean;
  not_visible_private: boolean; // Not visible private chargers in the location
  chargers: ChargerModel[];
}

interface RegularHourModel {
  days: string[];
  period_begin: string;
  period_end: string;
}

export interface FacilityModel {
  id: number;
  name: string;
  icon: string;
}
