import { APP_PATH } from "@/router/routes";
import { useHistory } from "react-router-dom";
import { useApi } from "./apiService";

export const useChargeService = () => {
  const { postSocketStopCharge } = useApi();
  //const history = useHistory();
  const history = useHistory();

  interface Configuration {
    goHome: boolean;
    refund?: {
      accountId: string;
      amount: number;
      socketId: string;
    };
    deleteLocalStorage: boolean;
    chargerGraph?: any;
  }

  interface Setter {
    showSpinnerWhileDeleting?: boolean;
    setShowSpinnerWhileDeleting?: (value: boolean) => void;
    setShowToastSuccessDeleting?: (value: boolean) => void;
    setShowToastDeleting?: (value: boolean) => void;
    setSuccessDeleted?: (value: boolean) => void;
  }

  const sendStopCommand = async (
    chargerId: string,
    socketNumber: string,
    countErrors: number,
    cancelled: boolean,
    setters: Setter,
    config: Configuration,
  ) => {
    try {
      const socketStop: any = await postSocketStopCharge(chargerId, socketNumber);

      if (socketStop) {
        if (!socketStop || socketStop["error_code"] !== undefined) {
          if (countErrors < 6) {
            if (setters["showSpinnerWhileDeleting"] === false && setters["setShowSpinnerWhileDeleting"])
              setters["setShowSpinnerWhileDeleting"](true);
            const aux = countErrors + 1;
            await sendStopCommand(chargerId, socketNumber, aux, cancelled, setters, config);
          } else {
            if (setters["setShowToastDeleting"]) setters["setShowToastDeleting"](true);
            if (config["goHome"]) goToHome();
          }
        } else if (socketStop === null || socketStop === "" || socketStop === undefined || socketStop.length === 0) {
          if (setters["setShowToastDeleting"]) setters["setShowToastDeleting"](true);
        } else if (socketStop["error_code"]) {
          if (setters["setShowToastDeleting"]) setters["setShowToastDeleting"](true);
          if (config["goHome"]) goToHome();
        } else {
          if (config["deleteLocalStorage"]) {
            if (config["chargerGraph"]) {
              const chargerGraphSt = JSON.stringify(config["chargerGraph"]);
              localStorage.setItem("endCharge", chargerGraphSt);
            }
            localStorage.removeItem(`dateBeforeExit${chargerId}&${socketNumber}`);
            localStorage.removeItem(`counter_time_remaining${chargerId}&${socketNumber}`);
            localStorage.removeItem(`chargingTime${chargerId}&${socketNumber}`);
          }

          if (setters["setShowSpinnerWhileDeleting"]) setters["setShowSpinnerWhileDeleting"](false);
          if (setters["setShowToastDeleting"]) setters["setShowToastDeleting"](false);
          if (setters["setShowToastSuccessDeleting"]) setters["setShowToastSuccessDeleting"](true);
          if (setters["setSuccessDeleted"]) setters["setSuccessDeleted"](true);

          if (config["goHome"]) goToHome();
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("sendStopCommand Error: " + error);
      if (countErrors < 6) {
        if (setters["showSpinnerWhileDeleting"] === false && setters["setShowSpinnerWhileDeleting"])
          setters["setShowSpinnerWhileDeleting"](true);
        const aux = countErrors + 1;
        await sendStopCommand(chargerId, socketNumber, aux, cancelled, setters, config);
      } else {
        if (setters["setShowToastDeleting"]) setters["setShowToastDeleting"](true);
        if (config["goHome"]) goToHome();
      }
    }
  };

  const goToHome = () => {
    history.push(APP_PATH.HOME);
  };

  return {
    sendStopCommand,
  };
};
