import { t } from "i18next";

interface DayOfWeek {
  key: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
  name: string;
}

export const DAYS_OF_WEEK: DayOfWeek[] = [
  { key: "monday", name: t("RATES.MONDAY") },
  { key: "tuesday", name: t("RATES.TUESDAY") },
  { key: "wednesday", name: t("RATES.WEDNESDAY") },
  { key: "thursday", name: t("RATES.THURSDAY") },
  { key: "friday", name: t("RATES.FRIDAY") },
  { key: "saturday", name: t("RATES.SATURDAY") },
  { key: "sunday", name: t("RATES.SUNDAY") },
];
