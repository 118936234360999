import { getExtraHeaders, rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { AccountModel } from "@/core/models/accountModel";
import { PaymentDSModel } from "@/core/models/PaymentModel";
import { ReservationModel } from "@/core/models/reservationModel";

export const deleteCardRedsysPaymentService = async (paymentMethod: string): Promise<any> => {
  return api
    .post("api/v1/redsys/delete_card", { merchant_identifier: paymentMethod }, undefined, { isRootPath: true })
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentIntentRedsysService = async (
  currency: string,
  cardCode: string,
  socketId: string,
  chargingEnergy: number,
  paymentMethod: string,
  deviceId: string,
  vehicleId: string,
  price: number,
  extraField?: string,
): Promise<{
  success: boolean;
  data: PaymentDSModel;
  account_id: number;
  account: AccountModel;
}> => {
  return api
    .post(
      "api/v1/redsys/create_charge_preauth_payment_customer",
      undefined,
      {
        currency,
        cardCode,
        socketId,
        chargingEnergy,
        merchant_identifier: paymentMethod,
        vehicleId,
        merchantOrder: extraField,
        deviceId,
        amount: price,
        ...getExtraHeaders(),
      },
      { isRootPath: true },
    )
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentWalletRedsysService = async (
  paymentMethod: string,
  amount: number,
  currency: string,
  cardCode: string,
  userId: number,
): Promise<{
  success: boolean;
  data: PaymentDSModel;
  transaction_id: string;
  transaction: any;
}> => {
  return api
    .post(
      "api/v1/redsys/payment_wallet_add_funds",
      {
        currency,
        cardCode,
        amount,
        merchant_identifier: paymentMethod,
        user_id: userId,
        ...getExtraHeaders(),
      },
      undefined,
      { isRootPath: true },
    )
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentReservationRedsysService = async (
  currency: string,
  cardCode: string,
  socketId: string,
  reserveTime: number,
  paymentMethod: string,
  userId: number,
): Promise<{ success: boolean; data: PaymentDSModel; reservation_id: number; reservation: ReservationModel }> => {
  return api
    .post(
      "api/v1/redsys/create_reservation_preauth_payment_customer",
      {
        currency,
        cardCode,
        socketId,
        reserveTime,
        merchant_identifier: paymentMethod,
        user_id: userId,
        ...getExtraHeaders(),
      },
      undefined,
      { isRootPath: true },
    )
    .then(resolveResponse)
    .catch(rejectError);
};
