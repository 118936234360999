import { useSlack } from "@/commons/hooks/useSlack";
import { PaymentOSFields } from "@/commons/types/ionicTypes";
import { ResponseCardPayuModel, SetupIntentPayu } from "@/core/models/PaymentModel";
import {
  createCardPayuPaymentService,
  getSetupIntentPayuPaymentService,
} from "@/core/services/payment/payuPaymentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButton, IonIcon, IonLabel, IonLoading, IonSpinner } from "@ionic/react";
import { t } from "i18next";
import { addCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { AddCreditCardFeedback } from "../AddCreditCardFeedback/AddCreditCardFeedback";
import "./AddCreditCardPayU.scss";

interface AddCreditCardPayUProps {
  selectPaymentId?: string;
  callbackAddCard: (paymentMethod: string, extraField: string) => void;
}

export const AddCreditCardPayU = ({ selectPaymentId, callbackAddCard }: AddCreditCardPayUProps) => {
  const slack = useSlack();
  const dispatch = useAppDispatch();

  const { userInfo, theme } = useAppSelector((state) => state.userReducer);

  const [loading, setLoading] = useState(true);
  const [isLoadingAddCard, setIsLoadingAddCard] = useState(false);
  const [isCreditCardAdded, setIsCreditCardAdded] = useState(!!selectPaymentId);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, settextColor] = useState("second");

  const [setupIntent, setSetupIntent] = useState<SetupIntentPayu>();

  useEffect(() => {
    loadData();
  }, []);

  // Set background and text color
  useEffect(() => {
    const backgroundElement = document.createElement("div");
    const colorElement = document.createElement("div");
    backgroundElement.className = "background-color";
    colorElement.className = "text-color";
    document.body.appendChild(backgroundElement);
    document.body.appendChild(colorElement);

    const { color: backgroundColor } = getComputedStyle(backgroundElement);
    const { color: textColor } = getComputedStyle(colorElement);

    setBackgroundColor(backgroundColor);
    settextColor(textColor);
  }, []);

  useEffect(() => {
    if (!loading && !selectPaymentId && setupIntent) {
      const { public_key, enviroment } = setupIntent;
      const POS = window.POS;

      const formElements: PaymentOSFields = new POS.Fields(public_key, {
        fonts: [
          {
            src: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
          },
        ],
      });

      const placeholders = {
        cardNumber: "1234 1111 1234 1234",
        expDate: "MM / YY",
      };

      const cardNumber = formElements.create("cardNumber", {
        style: {
          base: {
            backgroundColor,
            color: textColor,
          },
        },
        placeholders,
        includeCardImage: theme === "light",
      });

      cardNumber.mount("#card-number");

      const expiry = formElements.create("creditCardExpiry", {
        style: {
          base: {
            backgroundColor,
            color: textColor,
          },
        },
        placeholders,
      });

      expiry.mount("#exp-date");

      // Cvv not used
      // const cvv = formElements.create("cvv", {
      //   style: {
      //     base: {
      //       backgroundColor,
      //       color: textColor,
      //     },
      //   },
      //   placeholders: {
      //     cvv: "123",
      //   },
      // });

      // cvv.mount("#cvv");

      document.getElementById("payment-form")?.addEventListener("submit", async (event) => {
        setIsLoadingAddCard(true);

        try {
          event.preventDefault();
          const additionalData = {
            holder_name: userInfo?.name || "user",
          };

          const reponse = await POS.createToken(cardNumber, {
            additionalData,
            environment: enviroment,
          });

          if (reponse.error) {
            throw new Error(t("APP_ERRORS.INVALID_CARD"));
          }

          const responseJson: ResponseCardPayuModel = JSON.parse(reponse);

          if (!responseJson.token) {
            throw new Error(t("APP_ERRORS.ADD_PAYMENT"));
          }

          const { customer } = await createCardPayuPaymentService(reponse);

          callbackAddCard(responseJson.token, customer?.encrypted_cvv || "");

          setIsCreditCardAdded(true);
        } catch (error) {
          dispatch(uiActions.setToastError(error));
          slack.error("handleAddCard", error);
        } finally {
          setIsLoadingAddCard(false);
        }
      });
    }

    return () => {
      document.getElementById("pay_button")?.removeEventListener("click", () => {});
    };
  }, [loading, setupIntent, selectPaymentId]);

  const loadData = async () => {
    try {
      setLoading(true);
      const newSetupIntent = await getSetupIntentPayuPaymentService();
      setSetupIntent(newSetupIntent);
    } catch (error) {
      slack.error("loadPayuInfo", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="add-credit-card-payu-loading">
          <IonSpinner color="primary" />
        </div>
      ) : (
        <>
          {setupIntent ? (
            <>
              <div className="add-credit-card">
                <IonLoading isOpen={isLoadingAddCard} />
                {!isCreditCardAdded ? (
                  <form id="payment-form">
                    <IonLabel className="title-h3">{t("TITLES.INTRODUCE_CREDITCARD_DATA")}</IonLabel>
                    <div className="add-credit-card-form">
                      <div className="input-credit-card">
                        <IonLabel>{t("CREDIT_CARD.CARD_NUMBER")}</IonLabel>
                        <div id="card-number" className="input empty"></div>
                      </div>
                      <div className="input-row">
                        <div className="input-credit-card">
                          <IonLabel>{t("CREDIT_CARD.EXPIRATION_DATE")}</IonLabel>
                          <div id="exp-date" className="input empty"></div>
                        </div>
                      </div>
                      {/* <div className="input-row">
                        <div className="input-credit-card">
                          <IonLabel>CVV</IonLabel>
                          <div id="cvv" className="input empty"></div>
                        </div>
                      </div> */}
                    </div>
                    <IonButton className="add-credit-card-button" type="submit">
                      <IonIcon slot="start" icon={addCircleOutline} />
                      {t("BUTTONS.ADD_CARD")}
                    </IonButton>
                  </form>
                ) : (
                  <AddCreditCardFeedback />
                )}
              </div>
            </>
          ) : (
            <IonLabel>{t("ERRORS.SETUP_INTENT")}</IonLabel>
          )}
        </>
      )}
    </>
  );
};
