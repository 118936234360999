import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { IonButton, IonModal, IonSpinner } from "@ionic/react";
import { t } from "i18next";
import { closeOutline } from "ionicons/icons";
import { ReactNode, useEffect, useState } from "react";
import { ProgressBarCircular } from "../../ProgressBarCircular/ProgressBarCircular";
import "./AlertModal.scss";

interface AlertModalProps {
  className?: string;
  isOpen?: boolean;
  handleDismiss?: () => void;
  children: ReactNode;
  primaryText?: ReactNode;
  primaryAction?: () => void;
  primaryDisabled?: boolean;
  primaryCloseOnClicked?: boolean;
  secondaryText?: string;
  secondaryAction?: () => void;
  secondaryDisabled?: boolean;
  hideSecondary?: boolean;
  isLoadingProcess?: boolean;
  closingTime?: number; // seconds
}

export const AlertModal = ({
  children,
  className,
  isOpen = false,
  handleDismiss,
  primaryText,
  primaryAction,
  primaryDisabled,
  primaryCloseOnClicked = true,
  secondaryText,
  secondaryAction,
  secondaryDisabled,
  hideSecondary = false,
  isLoadingProcess = false,
  closingTime,
}: AlertModalProps) => {
  const [showModal, setShowModal] = useState(isOpen);

  const closingTimeMs = (closingTime || 0) * 1000;

  const [remaining, setRemaining] = useState({
    time: closingTimeMs,
    percentage: 100,
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (closingTime) {
      interval = setInterval(() => {
        setRemaining((prevRemaining) => {
          const newTime = prevRemaining.time - 100;

          if (newTime <= -400) {
            clearInterval(interval);
          }

          return {
            time: newTime,
            percentage: (newTime / closingTimeMs) * 100,
          };
        });
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    showModal && remaining.time <= -400 && handleCloseModal();
  }, [showModal, remaining.time]);

  useEffect(() => {
    !isLoadingProcess && setShowModal(isOpen);
  }, [isOpen, isLoadingProcess]);

  const handleCloseModal = () => {
    setShowModal(false);
    handleDismiss && handleDismiss();
  };

  primaryText = primaryText || t("BUTTONS.ACCEPT");

  secondaryText = secondaryText || "ALERT_BUTTONS.CANCEL";

  return (
    <IonModal
      className={`alert-modal ${className}`}
      isOpen={showModal}
      onDidDismiss={handleCloseModal}
      canDismiss={!isLoadingProcess}
    >
      <AppHeader
        hiddenMenu
        hiddenTitle
        size="small"
        endIcon={
          <>
            {closingTime ? (
              <ProgressBarCircular
                className="close-progress-bar"
                value={remaining.percentage}
                text={
                  <IconButton
                    className="close-button"
                    icon={closeOutline}
                    color="dark"
                    disabled={isLoadingProcess}
                    onClick={handleCloseModal}
                  />
                }
              />
            ) : (
              <IconButton icon={closeOutline} color="dark" disabled={isLoadingProcess} onClick={handleCloseModal} />
            )}
          </>
        }
      />
      <div className="alert-modal-container">
        <div className="alert-modal-content">{children}</div>
        <div className={`alert-modal-footer ${classIf(hideSecondary, "hidden-secondary")}`}>
          {!hideSecondary && (
            <IonButton
              color="primary"
              fill="outline"
              disabled={isLoadingProcess || secondaryDisabled}
              onClick={() => {
                secondaryAction && secondaryAction();
                handleCloseModal();
              }}
            >
              {t(secondaryText)}
            </IonButton>
          )}
          {primaryAction && (
            <IonButton
              disabled={isLoadingProcess || primaryDisabled}
              onClick={() => {
                primaryAction();
                primaryCloseOnClicked && handleCloseModal();
              }}
            >
              {isLoadingProcess ? <IonSpinner /> : <>{primaryText}</>}
            </IonButton>
          )}
        </div>
      </div>
    </IonModal>
  );
};
