import { ParametersContext } from "@/context/appParametersContext";
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from "@ionic/react";
import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";

export const HelpQuestions = () => {
  const { t } = useTranslation();
  const { parameters } = useContext(ParametersContext);
  const { VITE_APP_NAME_DISPLAY } = import.meta.env;

  return (
    <>
      <IonAccordionGroup>
        <IonAccordion value="first">
          <IonItem slot="header" color="light">
            <Trans i18nKey="HELP.APP_INFO_LABEL" values={{ app_name: VITE_APP_NAME_DISPLAY }} />
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.APP_INFO_TEXT")}</p>
          </div>
        </IonAccordion>
        <IonAccordion value="second">
          <IonItem slot="header" color="light">
            <IonLabel>{t("HELP.START_CHARGE_LABEL")}</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.START_CHARGE_TEXT1")}</p>
            <p>
              <b>1. </b>
              {t("HELP.START_CHARGE_TEXT2")}
            </p>
            <p>
              <b>2. </b>
              {t("HELP.START_CHARGE_TEXT3")}
            </p>
            <p>
              <b>3. </b>
              {t("HELP.START_CHARGE_TEXT4")}
            </p>
            <p>
              <b>4. </b>
              {t("HELP.START_CHARGE_TEXT5")}
            </p>
          </div>
        </IonAccordion>
        <IonAccordion value="third">
          <IonItem slot="header" color="light">
            <IonLabel>{t("HELP.STOP_CHARGE_LABEL")}</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.STOP_CHARGE_TEXT")}</p>
          </div>
        </IonAccordion>
        <IonAccordion value="fifth">
          <IonItem slot="header" color="light">
            <IonLabel>{t("HELP.INVOICES_LABEL")}</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.INVOICES_TEXT1") + " " + parameters.name + " " + t("HELP.INVOICES_TEXT1_2")}</p>
            <p>{t("HELP.INVOICES_TEXT2")}</p>
            <p>{t("HELP.INVOICES_TEXT3")}</p>
          </div>
        </IonAccordion>
        <IonAccordion value="sixth">
          <IonItem slot="header" color="light">
            <IonLabel>{t("HELP.FAVORITES_CHARGERS_LABEL")}</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.FAVORITES_CHARGERS_TEXT1")}</p>
            <p>{t("HELP.FAVORITES_CHARGERS_TEXT2")}</p>
          </div>
        </IonAccordion>
        <IonAccordion value="seventh">
          <IonItem slot="header" color="light">
            <IonLabel>{t("HELP.CHARGES_HISTORY_LABEL")}</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.CHARGES_HISTORY_TEXT")}</p>
          </div>
        </IonAccordion>
        <IonAccordion value="ninth">
          <IonItem slot="header" color="light">
            <IonLabel>{t("HELP.ADD_VEHICLE_LABEL")}</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.ADD_VEHICLE_TEXT")}</p>
          </div>
        </IonAccordion>
        <IonAccordion value="eighth">
          <IonItem slot="header" color="light">
            <IonLabel>{t("HELP.SUPPORT_TICKETS_LABEL")}</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.SUPPORT_TICKETS_TEXT")}</p>
          </div>
        </IonAccordion>
        <IonAccordion value="fourth">
          <IonItem slot="header" color="light">
            <IonLabel>{t("HELP.PAYMENTS_LABEL")}</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>{t("HELP.PAYMENTS_TEXT1")}</p>
            <p>{t("HELP.PAYMENTS_TEXT2")}</p>
            <p>{t("HELP.PAYMENTS_TEXT3")}</p>
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};
