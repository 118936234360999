import { IonIcon, IonLabel } from "@ionic/react";
import { t } from "i18next";
import { calendarClearOutline, timeOutline } from "ionicons/icons";
import "swiper/css";

interface LocationMoreInfoExceptionalsProps {
  locationInfoClosePeriods: any[];
}

export const LocationMoreInfoCloseExceptionals = ({ locationInfoClosePeriods }: LocationMoreInfoExceptionalsProps) => {
  return (
    <>
      <IonLabel className="regular-hours-days">{t("RATES.CLOSED_EXCEP")}</IonLabel>
      {locationInfoClosePeriods.map((exceptionalPeriodsClose, index) => (
        <div key={index} className="scheduleCol">
          <IonLabel>
            <IonLabel className="exceptional">
              <IonIcon className="iconMargin" icon={calendarClearOutline}></IonIcon>
              {exceptionalPeriodsClose.period_begin.split(" ")[0]}
              <IonIcon className="iconMargin" icon={timeOutline}></IonIcon>
              {exceptionalPeriodsClose.period_begin.split(" ")[1]}
            </IonLabel>
            <IonLabel className="exceptional">
              <IonIcon className="iconMargin" icon={calendarClearOutline}></IonIcon>
              {exceptionalPeriodsClose.period_end.split(" ")[0]}
              <IonIcon className="iconMargin" icon={timeOutline}></IonIcon>
              {exceptionalPeriodsClose.period_end.split(" ")[1]}
            </IonLabel>
          </IonLabel>
          <br />
        </div>
      ))}
    </>
  );
};
