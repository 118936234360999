import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import { PromotionalCodeModel } from "@/core/models/promotionalCodeModel";

export const getPromotionalCodesService = async (): Promise<PromotionalCodeModel[]> => {
  return api.get("payments/get_promotional_codes").then(resolveResponse).catch(rejectError);
};

export const activePromotionalCodeService = async (promotional_code: string) => {
  return api.post("payments/activate_promotional_code", { promotional_code }).then(resolveResponse).catch(rejectError);
};
