import i18n from "@/assets/i18n/i18n";
import { Illustration2 } from "@/commons/constants/imgs";
import { ErrorModel } from "@/core/models/apiModel";
import { SupportTicketTypeModel } from "@/core/models/incidenceModel";
import { uploadImageService } from "@/core/services/document/documentServices";
import { createTicketService, getIncidenceTypesService } from "@/core/services/user/ticketServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import {
  IonButton,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { closeOutline, documentAttachOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./NewIncidence.scss";

export const NewIncidence = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { user, isUserLogged } = useAppSelector((state) => state.userReducer);
  const { charger } = useAppSelector((state) => state.uiReducer.help);

  const [incidenceTypeId, setIncidenceTypeId] = useState<number>();
  const [commentIncidence, setCommentIncidence] = useState<string>("");
  const [imageIncidence, setImageIncidence] = useState<{ id: number; filename: string; data: File }>();

  const [enableButton, setEnableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [typesTickets, setTypesTickets] = useState<SupportTicketTypeModel[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isUserLogged) {
      return history.push(APP_PATH.LOGIN);
    }

    getTypesSupportTickets();
  }, [isUserLogged]);

  useEffect(() => {
    checkAllComplete();
  }, [incidenceTypeId, commentIncidence]);

  const getTypesSupportTickets = async () => {
    try {
      const res = await getIncidenceTypesService(i18n.language);
      if (res) setTypesTickets(res);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    }
  };

  const handleSelectedIncidence = (e: CustomEvent) => {
    setIncidenceTypeId(e.detail.value);
  };

  const handleTextIncidenceChange = (e: CustomEvent) => {
    setCommentIncidence(e.detail.value);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    try {
      setIsLoading(true);
      const selectedFile = e.target.files[0];
      const image = await uploadImageService(selectedFile);

      setImageIncidence({
        id: image.id,
        filename: selectedFile.name,
        data: selectedFile,
      });
    } catch (error) {
      fileInputRef.current?.value && (fileInputRef.current.value = "");
      setImageIncidence(undefined);
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handlerDeleteImage = () => {
    setImageIncidence(undefined);
  };

  const handleAttachFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const checkAllComplete = () => {
    setEnableButton(!!incidenceTypeId && !!commentIncidence);
  };

  const sendTicket = async () => {
    if (user && charger && incidenceTypeId) {
      try {
        setIsLoading(true);
        const { support_ticket } = await createTicketService({
          user_id: user.user_id,
          type_support_ticket_id: incidenceTypeId,
          charger_id: charger.id_charger,
          comment: commentIncidence,
          image_id: imageIncidence?.id || null,
        });

        if (!support_ticket) {
          throw new Error(t("APP_ERRORS.CREATE_INCIDENCE"));
        }

        dispatch(uiActions.cleanHelp());
        dispatch(uiActions.setToastType({ type: "successTicket" }));
      } catch (error) {
        const errorFormat = error as ErrorModel;
        if (errorFormat.jsonError?.error_code === "-26") {
          return dispatch(
            uiActions.setToast({ show: true, message: t("ALERT.ALREADY_OPENED_TICKET"), color: "warning" }),
          );
        }

        dispatch(uiActions.setToastError(error));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      {charger && (
        <>
          {charger.support_tickets_active ? (
            <IonList className="list">
              <IonLabel className="list-title">{t("BUTTONS.NOTIFY_INCIDENCE")}</IonLabel>
              <IonItem className="list-item">
                <IonSelect
                  label={t("PLACEHOLDERS.INCIDENCE_TYPE")}
                  labelPlacement="floating"
                  value={incidenceTypeId}
                  style={{ width: "100%" }}
                  onIonChange={handleSelectedIncidence}
                >
                  {typesTickets.map((incidenceType) => (
                    <IonSelectOption key={incidenceType.id} value={incidenceType.id}>
                      {incidenceType.desc}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonTextarea
                onIonInput={handleTextIncidenceChange}
                className="customInput"
                placeholder={t("PLACEHOLDERS.INCIDENCE_TEXT")}
              ></IonTextarea>
              <IonRow className="list-row-buttons">
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <IonButton fill="outline" color="primary" onClick={handleAttachFile}>
                    {t("BUTTONS.ATTACH_FILE")}
                    <IonIcon
                      icon={documentAttachOutline}
                      className="list-send"
                      slot="start"
                      onClick={handleAttachFile}
                    ></IonIcon>
                  </IonButton>
                </div>
              </IonRow>
              {imageIncidence && (
                <div>
                  <IonItem lines="none">
                    <IonIcon icon={closeOutline} slot="start" onClick={handlerDeleteImage}></IonIcon>
                    <p>
                      {t("BUTTONS.ATTACH_FILE_NAME")} {imageIncidence.filename}
                    </p>
                  </IonItem>
                </div>
              )}
              <IonButton disabled={!enableButton} onClick={sendTicket}>
                {t("BUTTONS.NOTIFY_INCIDENCE")}
              </IonButton>
            </IonList>
          ) : (
            <IonCol className="tickets-disabled">
              <Illustration2 />
              <IonText className="tickets-disabled-text">{t("HELP.SUPPORT_TICKETS_DISABLED")}</IonText>
            </IonCol>
          )}
        </>
      )}
    </>
  );
};
