import { ActiveChargeModel } from "@/core/models/chargeModel";
import { ReservationModel } from "@/core/models/reservationModel";
import { Map } from "leaflet";
import { RefObject } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ActiveCharge } from "./ActiveCharge/ActiveCharge";
import "./ActiveEntities.scss";
import { ActiveReservation } from "./ActiveReservation/ActiveReservation";

interface ActiveEntitiesProps {
  activeCharges: ActiveChargeModel[];
  activeReservations: ReservationModel[];
  mapRef: RefObject<Map>;
}
export const ActiveEntities = ({ activeCharges, activeReservations, mapRef }: ActiveEntitiesProps) => {
  const activeChargesFiltered = activeCharges.filter((activeCharge) => !activeCharge.hidden);

  return (
    <div className="active-entities">
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        spaceBetween={8}
        slidesPerView={activeChargesFiltered.length + activeReservations.length > 1 ? 1.1 : 1}
        onTouchMove={() => {
          // Disable map dragging when swiping
          mapRef.current?.dragging.disable();
        }}
        onTouchEnd={() => {
          // Enable map dragging when swiping ends
          mapRef.current?.dragging.enable();
        }}
      >
        {activeReservations.map((reservation) => (
          <SwiperSlide key={reservation.id}>
            <ActiveReservation reservation={reservation} />
          </SwiperSlide>
        ))}
        {activeChargesFiltered.map((activeCharge) => (
          <SwiperSlide key={activeCharge.charge_id}>
            <ActiveCharge activeCharge={activeCharge} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
