import { IonLabel, IonSegment, IonSegmentButton, IonSkeletonText } from "@ionic/react";

export const IncidencesStepsSkeleton = () => {
  return (
    <IonSegment className="segment" mode="md">
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <IonSegmentButton key={index}>
            <IonLabel>
              <IonSkeletonText animated={true} style={{ width: "80px" }} />
            </IonLabel>
          </IonSegmentButton>
        ))}
    </IonSegment>
  );
};
