import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { ChargeChart, ChargeChartData } from "@/commons/components/charts/ChargeChart/ChargeChart";
import { Illustration16 } from "@/commons/constants/imgs";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { ChargeModel } from "@/core/models/chargeModel";
import { ChargerGraphModel, ChargerModel } from "@/core/models/chargerModel";
import { UserModel } from "@/core/models/userModel";
import { getChargerGraphService, getChargerService } from "@/core/services/charge/chargeServices";
import { getUserChargesService } from "@/core/services/user/userServices";
import { chargesActions } from "@/core/store/slices/chargesSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { useApi } from "@/services/apiService";
import { useChargeService } from "@/services/chargeService";
import { Browser } from "@capacitor/browser";
import { IonInfiniteScrollCustomEvent } from "@ionic/core";
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonToast,
  IonicSafeString,
  SegmentCustomEvent,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { alertCircleOutline, calendarOutline, downloadOutline, stopCircleOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ChargeCard } from "./ChargeCard";
import { ChargeCardSkeleton } from "./ChargeCardSkeleton";
import "./ChargesPage.scss";
import { ChargesStepsSkeleton } from "./ChargesStepsSkeleton";

export const ChargesPage = () => {
  const { parameters } = useParametersContext();
  const { generateRedemptionInvoice, getUserInvoicesTypes } = useApi();
  const { sendStopCommand } = useChargeService();
  const history = useHistory();
  const location: any = useLocation();

  const dispatch = useAppDispatch();

  const { loadUserInfo } = useUserContext();
  const { user, userInfo, deviceId } = useAppSelector((state) => state.userReducer);
  const isBillingUser = userInfo?.billing_user && userInfo.billing_user.billing_type !== "TICKET";
  const [currentStep, setCurrentStep] = useState(0);
  const [charges, setCharges] = useState<ChargeModel[]>([]);
  const [activeCharges, setActiveCharges] = useState<ChargeModel[]>([]);
  const [completedCharges, setCompletedCharges] = useState<ChargeModel[]>([]);
  const [showLoading, setShowLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [clickedCharge, setClickedCharge] = useState<ChargeModel | null>(null);
  const [clickedChargeImage, setClickedChargeImage] = useState("");
  const [chargeClickedUbi, setChargeClickedUbi] = useState("");
  const [formattedInitDate, setFormattedInitDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [clickedChargeEnergy, setClickedChargeEnergy] = useState("");
  const [clickedChargeDuration, setClickedChargeDuration] = useState("");
  const [, setClickedChargePower] = useState("");
  const [clickedChargePrice, setClickedChargePrice] = useState("");
  const [clickedChargePriceConsumed, setClickedChargePriceConsumed] = useState("");

  const [clickedChargeChargerGraph, setClickedChargeChargerGraph] = useState<ChargerGraphModel | null>(null);
  const [periodsArray, setPeriodsArray] = useState<ChargeChartData[]>([]);
  const [successDeleted, setSuccessDeleted] = useState(false);
  //const [showLoadingData, setShowLoadingData] = useState(false);
  const [showGenerateInvoiceButton, setShowGenerateInvoiceButton] = useState(false);
  const [canDownload, setCanDownload] = useState(false);

  const [showAlertServerError, setShowAlertServerError] = useState(false);
  const [showModalInfoCharge, setShowModalChargeInfo] = useState(false);
  const [showModalInfoChargeActive, setShowModalInfoChargeActive] = useState(false);
  const [formattedDate, setFormattedDate] = useState("");
  const [showSpinnerWhileDeleting, setShowSpinnerWhileDeleting] = useState(false);
  const [showToastErrorDeleting, setShowToastDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [, setInvoiceId] = useState("");
  const [page, setPage] = useState<number>(1);
  const [showMoreData, setShowMoreData] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [chargersInfo, setChargersInfo] = useState<Record<string, ChargerModel>>({});

  const steps = [`${t("CHARGES_STEPS.ALL")}`, `${t("CHARGES_STEPS.ACTIVE")}`, `${t("CHARGES_STEPS.FINISHED")}`];

  useIonViewWillEnter(() => {
    if (user) {
      setUserCharges(user);
      loadUserInfo();
    } else {
      setShowLoading(false);
      setShowError(true);
    }
  });

  useIonViewWillLeave(() => {
    setIsFirstTime(true);
  });

  useEffect(() => {
    if (charges.length > 0) {
      const activeCharges = charges.filter((charge: any) => charge["session_end_at"] === "");
      if (activeCharges) setActiveCharges(activeCharges);
      if (currentStep === 0 && activeCharges.length > 0 && isFirstTime) setCurrentStep(1);
      const completedCharges = charges.filter((charge: any) => charge["session_end_at"] !== "");
      if (completedCharges) setCompletedCharges(completedCharges);
      setShowLoading(false);
      setIsFirstTime(false);

      // Get chargers info
      activeCharges.forEach(async (charge) => {
        const newChargers = await getChargerService(Number(charge.charger_id));

        setChargersInfo((prev) => ({
          ...prev,
          [charge.charger_id]: newChargers,
        }));
      });
    }
  }, [charges]);

  useEffect(() => {
    if (clickedCharge) {
      if (clickedCharge["session_end_at"]) setShowModalChargeInfo(true);
      else setShowModalInfoChargeActive(true);

      const currentDate = clickedCharge["session_start_at"];
      const dateFormat = new Date(currentDate);
      const monthName = t(`MONTH.${dateFormat.getMonth()}`);
      const stringDate = `${dateFormat.getDate()} de ${monthName} de ${dateFormat.getFullYear()}`;
      setFormattedDate(stringDate);

      const stringInitDate = clickedCharge["session_start_at"];
      const dateInit = new Date(stringInitDate);
      const hourInit = dateInit.getHours().toString().padStart(2, "0");
      const minutesInit = dateInit.getMinutes().toString().padStart(2, "0");
      const formattedTimeStart = `${hourInit}:${minutesInit}`;
      setFormattedInitDate(formattedTimeStart);

      const stringEndDate = clickedCharge["session_end_at"];
      const dateEnd = new Date(stringEndDate);
      const hourEnd = dateEnd.getHours().toString().padStart(2, "0");
      const minutesEnd = dateEnd.getMinutes().toString().padStart(2, "0");
      const formattedEndDate = `${hourEnd}:${minutesEnd}`;
      setFormattedEndDate(formattedEndDate);

      setClickedChargeEnergy(clickedCharge["energy_Wh"]);

      const res = getChargeDuration(Number(clickedCharge["time_total_minutes"]));
      setClickedChargeDuration(res);
    } else setShowModalChargeInfo(false);
  }, [clickedCharge]);

  useEffect(() => {
    if (clickedChargeChargerGraph) {
      const power = clickedChargeChargerGraph["runtime_power"];
      const powerkW: any = parseInt(power) / 1000;
      setClickedChargePower(powerkW);

      const amount = clickedChargeChargerGraph["amount"];
      const price = (Number(amount) / 100).toFixed(2);
      setClickedChargePrice(price);
      const amountConsumed = clickedChargeChargerGraph["amount_consumed"];
      const priceConsumed = (Number(amountConsumed) / 100).toFixed(2);
      setClickedChargePriceConsumed(priceConsumed);
      generateArrayChart();
      setLoadingCard(false);
    }
  }, [clickedChargeChargerGraph]);

  const setUserCharges = async (user: UserModel, event?: IonInfiniteScrollCustomEvent<void>) => {
    if (!showMoreData) {
      event && event.target.complete();
      return;
    }
    try {
      const userCharges = await getUserChargesService(user.user_id, page, deviceId);
      setCharges(userCharges.charges);
      if (userCharges) {
        setCharges([...charges, ...userCharges.charges]);
        if (userCharges.charges.length === 0) setShowMoreData(false);
        setPage(page + 1);
      }
    } catch (error) {
      console.error(error);
      setShowError(true);
    } finally {
      event && event.target?.complete();
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (charges && charges?.length > 0 && location && location.state && location.state.state) {
      const charge: any = charges.find((charge: any) => charge["charge_id"] === String(location.state.state));
      if (charge) {
        handleClickCard(charge);
      }
    }
  }, [charges]);

  const getChargeDuration = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remianMinutes = minutes % 60;

    const hourFormatted = hours.toString().padStart(2, "0");
    const minutesFormatted = remianMinutes.toString().padStart(2, "0");

    return `${hourFormatted}:${minutesFormatted}`;
  };

  const getPaymentTicket = async () => {
    if (!clickedChargeChargerGraph) {
      return;
    }

    setIsLoading(true);
    const ticketLink = clickedChargeChargerGraph.ticket_url;
    const url = ticketLink + "&auth_token=" + user.auth_token;
    await Browser.open({ url: url });
    setIsLoading(false);
  };

  const generateInvoice = async () => {
    if (!clickedChargeChargerGraph) {
      return;
    }

    try {
      setIsLoading(true);
      const invoice: any = await generateRedemptionInvoice(clickedChargeChargerGraph.charger_graph_id);

      if (invoice.object) {
        setInvoiceId(invoice.object.id);
        setIsLoading(false);
        setShowGenerateInvoiceButton(false);
      }
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleStepClick: any = (event: SegmentCustomEvent) => {
    const index = parseInt(event.detail.value!.toString());
    setCurrentStep(index);
  };
  const handleDismissServerError = () => {
    setShowAlertServerError(false);
  };

  const handleDismissDownloadInfo = () => {
    setIsDownloaded(false);
  };

  const handleClickCard = async (charge: ChargeModel) => {
    setLoadingCard(true);
    const chargerGraph = await getChargerGraphService(Number(charge.charge_id));
    const invoice_check: any = await getUserInvoicesTypes(charge.charge_id);
    if (
      chargerGraph &&
      invoice_check &&
      invoice_check.invoice_type &&
      chargerGraph.ticket_url
      // && !chargerGraph.isFree
    ) {
      setCanDownload(true);
      if (invoice_check.invoice_type === "TICKET" && isBillingUser) {
        setShowGenerateInvoiceButton(true);
      }
    } else {
      setCanDownload(false);
    }
    setClickedCharge(charge);
    setChargeClickedUbi(charge.charger_address);
    setClickedChargeImage(chargerGraph.charger_logo);
    setClickedChargeChargerGraph(chargerGraph);
  };

  const dismissModal = () => {
    // Clean route state
    history.replace(APP_PATH.CHARGES);

    setShowModalChargeInfo(false);
    setShowModalInfoChargeActive(false);
    setClickedCharge(null);
    setChargeClickedUbi("");
    setClickedChargeImage("");
    setClickedChargeChargerGraph(null);
  };

  const generateArrayChart = () => {
    if (!clickedChargeChargerGraph) return;

    setPeriodsArray([]);

    const chargerPeriods = clickedChargeChargerGraph.charger_periods || [];
    const periods: ChargeChartData[] = chargerPeriods.map((period) => {
      const date = new Date(period.session_start_at);
      return {
        date,
        time: format(date, "HH:mm"),
        power: parseInt(period.energy) / 1000,
        percentBattery: period.percent_battery,
      };
    });

    setPeriodsArray(periods);
  };

  const handleStopCharge = async (chargeId: string, cancelled: boolean) => {
    await initStop(0, cancelled);
    dispatch(chargesActions.removeActivePayload(Number(chargeId)));
  };

  const handleGoHome = () => {
    history.push(APP_PATH.HOME);
  };

  useEffect(() => {
    if (successDeleted) {
      if (!showSpinnerWhileDeleting) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    }
  }, [successDeleted]);

  const initStop = async (countErrors: number, cancelled: boolean) => {
    setShowSpinnerWhileDeleting(true);

    if (!clickedCharge) return;

    const chargerId = clickedCharge.charger_id;
    const socketNumber = clickedCharge.charger_socket;

    const setters = {
      showSpinnerWhileDeleting,
      setShowSpinnerWhileDeleting,
      setShowToastDeleting,
      setSuccessDeleted,
    };
    const config = {
      goHome: false,
      deleteLocalStorage: true,
      chargerGraph: clickedChargeChargerGraph,
    };
    await sendStopCommand(chargerId, socketNumber, countErrors, cancelled, setters, config);
  };

  return (
    <IonPage>
      <AppHeader title={t("MENU.CHARGES")} />
      <IonContent>
        <IonLoading isOpen={showSpinnerWhileDeleting} message={t("LOADING.STOPING_CHARGE") ?? ""} />
        <IonToast
          isOpen={showToastErrorDeleting}
          duration={3000}
          icon={alertCircleOutline}
          message={t("ERRORS.DELETING_CHARGE") ?? ""}
          cssClass="addedVehicleFailure"
          position="top"
        />
        <IonLoading isOpen={loadingCard} message={t("LOADING.LOADING") ?? ""} />
        <IonAlert
          isOpen={showError}
          onDidDismiss={() => setShowError(false)}
          message={
            new IonicSafeString(
              `<div class="alert">
                        <ion-label class="titleH3">${t("ALERT.SOMETHING_WRONG_TITLE")}</ion-label>
                        <ion-label class="alertText">${t("ALERT.NOT_UPDATE")}</ion-label>
                      </div>`,
            )
          }
          buttons={[`${t("BUTTONS.ACCEPT")}`]}
          cssClass="alertContainer"
        />
        {showLoading ? (
          <>
            <ChargesStepsSkeleton />
            <ChargeCardSkeleton />
            <ChargeCardSkeleton />
            <ChargeCardSkeleton />
            <ChargeCardSkeleton />
            <ChargeCardSkeleton />
          </>
        ) : (
          <>
            {charges && (
              <>
                {charges.length === 0 ? (
                  <IonList className="listReservationsContainer ion-padding">
                    <Illustration16 className="imageReservations" />
                    <IonList className="listReservationsContainer textReservation">
                      <IonLabel className="titleKeychain titleReservations">{t("BUTTONS.ANY_CHARGE")}</IonLabel>
                    </IonList>
                    <IonButton style={{ height: "124px" }} onClick={handleGoHome}>
                      {t("BUTTONS.SEARCH_CHARGE_POINT")}
                    </IonButton>
                  </IonList>
                ) : (
                  <IonSegment
                    className="segment"
                    value={currentStep.toString()}
                    onIonChange={handleStepClick}
                    mode="md"
                  >
                    {steps.map((step, index) => (
                      <IonSegmentButton key={index} value={index.toString()}>
                        <IonLabel>{step}</IonLabel>
                      </IonSegmentButton>
                    ))}
                  </IonSegment>
                )}

                {currentStep === 0 && charges && (
                  <>
                    <IonList>
                      {charges.map((charge) => (
                        <div key={charge.charge_id} onClick={() => handleClickCard(charge)}>
                          <ChargeCard charge={charge} chargerInfo={chargersInfo[charge.charger_id]} />
                        </div>
                      ))}
                    </IonList>
                    <IonInfiniteScroll
                      onIonInfinite={(ev) => {
                        setUserCharges(user, ev);
                      }}
                    >
                      {showMoreData && <IonInfiniteScrollContent />}
                    </IonInfiniteScroll>
                  </>
                )}

                {currentStep === 1 &&
                  (activeCharges.length > 0 ? (
                    activeCharges.map((charge) => (
                      <div key={charge.charge_id} onClick={() => handleClickCard(charge)}>
                        <ChargeCard charge={charge} chargerInfo={chargersInfo[charge.charger_id]} />
                      </div>
                    ))
                  ) : (
                    <IonList className="listReservationsContainerActiveTab ion-padding">
                      <Illustration16 className="imageReservations" />
                      <IonList className="listReservationsContainer textReservation">
                        <IonLabel className="titleKeychain titleReservations">
                          {t("BOOKING_INFO.ANY_ACTIVE_CHARGES")}
                        </IonLabel>
                      </IonList>
                    </IonList>
                  ))}

                {currentStep === 2 && completedCharges && (
                  <>
                    <IonList>
                      {completedCharges.map((charge: any) => (
                        <div key={charge["charge_id"]} onClick={() => handleClickCard(charge)}>
                          <ChargeCard charge={charge} />
                        </div>
                      ))}
                    </IonList>
                    <IonInfiniteScroll
                      onIonInfinite={(ev) => {
                        setUserCharges(user, ev);
                      }}
                    >
                      {showMoreData && <IonInfiniteScrollContent />}
                    </IonInfiniteScroll>
                  </>
                )}

                {currentStep === 2 && completedCharges.length === 0 && (
                  <IonList className="listReservationsContainerActiveTab ion-padding">
                    <Illustration16 className="imageReservations" />
                    <IonList className="listReservationsContainer textReservation">
                      <IonLabel className="titleKeychain titleReservations">
                        {t("BOOKING_INFO.ANY_COMPLETED_CHARGES")}
                      </IonLabel>
                    </IonList>
                  </IonList>
                )}

                {clickedCharge && chargeClickedUbi && clickedCharge["session_end_at"] && (
                  <IonModal isOpen={showModalInfoCharge} className="modalActiveCharge" onDidDismiss={dismissModal}>
                    <IonLoading isOpen={isLoading} message={t("LOADING.LOADING") ?? ""} />

                    <AppHeader
                      startIcon={<GoBackButton onClick={dismissModal} />}
                      title={t("TITLES.CHARGE")}
                      endIcon={
                        <>
                          {canDownload ? (
                            <IconButton icon={downloadOutline} onClick={getPaymentTicket} color="dark" />
                          ) : (
                            <div className="empty-button" />
                          )}
                        </>
                      }
                    />
                    <IonContent>
                      <IonList className="contentModalShowMore" style={{ padding: "16px" }}>
                        <IonCol className="colModal ion-margin-bottom">
                          <IonLabel>{clickedCharge.charger_name}</IonLabel>
                        </IonCol>
                        <img
                          style={{ marginTop: "35px" }}
                          className="chargerImage ion-margin-top"
                          src={clickedChargeImage}
                          alt=""
                        />
                        <IonRow
                          className="ion-margin-bottom ion-margin-top labelRow"
                          style={{ alignItems: "center", alignText: "center", gap: 5 }}
                        >
                          <IonIcon icon={calendarOutline} />
                          <IonLabel>{formattedDate}</IonLabel>
                        </IonRow>

                        <div className="separatorLineModal">
                          <span className="lineModal clear" />
                        </div>

                        <IonRow>
                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.INIT")}</IonLabel>
                            <IonLabel className="textCharge number">{formattedInitDate}</IonLabel>
                          </IonCol>
                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.ENDED")}</IonLabel>
                            <IonLabel className="textCharge number">{formattedEndDate}</IonLabel>
                          </IonCol>
                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.DURATION")}</IonLabel>
                            <IonLabel className="textCharge number">{clickedChargeDuration} h</IonLabel>
                          </IonCol>
                        </IonRow>

                        <div className="separatorLineModal">
                          <span className="lineModal clear" />
                        </div>

                        <IonRow className="ion-margin-bottom">
                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.ENERGY")}</IonLabel>
                            <IonLabel className="textCharge number">{clickedChargeEnergy} Wh</IonLabel>
                          </IonCol>

                          {clickedChargeChargerGraph && (
                            <IonCol className="colCharges">
                              <IonLabel className="titleCharge">{t("INFO_CHARGE.PRICE")}</IonLabel>
                              {clickedChargeChargerGraph.payment_captured ? (
                                <>
                                  {clickedChargeChargerGraph.amount !== "0" ? (
                                    <IonLabel className="labelInfoCharge number">
                                      {clickedChargePrice} {parameters["currency"]}
                                    </IonLabel>
                                  ) : (
                                    <IonLabel className="labelInfoCharge number">
                                      {t("INFO_CHARGE.FREE_CHARGE")}
                                    </IonLabel>
                                  )}
                                </>
                              ) : (
                                <>
                                  {clickedChargeChargerGraph.amount !== "0" ? (
                                    <IonLabel className="labelInfoCharge number">
                                      {t("LOADING.PROCESSING_PAYMENT")}...
                                    </IonLabel>
                                  ) : (
                                    <IonLabel className="labelInfoCharge number">
                                      {t("INFO_CHARGE.FREE_CHARGE")}
                                    </IonLabel>
                                  )}
                                </>
                              )}
                            </IonCol>
                          )}
                        </IonRow>

                        <IonLabel className="ion-margin-top labelTitle">{t("TITLES.CONSUMPTION")}</IonLabel>
                        {periodsArray.length > 0 && (
                          <div className="graphContainer">
                            <ChargeChart data={periodsArray} />
                          </div>
                        )}
                      </IonList>
                      {showGenerateInvoiceButton && canDownload && (
                        <IonButton onClick={generateInvoice} style={{ padding: "16px" }}>
                          {t("TITLES.GENERATE_INVOICE")}
                        </IonButton>
                      )}
                    </IonContent>
                  </IonModal>
                )}

                {clickedCharge && chargeClickedUbi && !clickedCharge["session_end_at"] && (
                  <IonModal isOpen={showModalInfoChargeActive} onDidDismiss={dismissModal}>
                    <AppHeader startIcon={<GoBackButton onClick={dismissModal} />} title={t("TITLES.CHARGE")} />
                    <IonContent>
                      <IonList className="contentModalShowMore" style={{ padding: "16px" }}>
                        <IonCol className="colModal ion-margin-bottom">
                          <IonLabel>{clickedCharge.charger_name}</IonLabel>
                        </IonCol>
                        <img
                          style={{ marginTop: "35px" }}
                          className="chargerImage ion-margin-top"
                          src={clickedChargeImage}
                          alt=""
                        />
                        <IonRow
                          className="ion-margin-bottom ion-margin-top labelRow"
                          style={{ alignItems: "center", alignText: "center", gap: 5 }}
                        >
                          <IonIcon icon={calendarOutline} />
                          <IonLabel>{formattedDate}</IonLabel>
                        </IonRow>
                        <div className="separatorLineModal">
                          <span className="lineModal clear" />
                        </div>

                        <IonRow>
                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.INIT")}</IonLabel>
                            <IonLabel className="textCharge number">{formattedInitDate}</IonLabel>
                          </IonCol>
                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.ENDED")}</IonLabel>
                            <IonLabel className="textCharge number">-</IonLabel>
                          </IonCol>
                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.DURATION")}</IonLabel>
                            <IonLabel className="textCharge number">{clickedChargeDuration} h</IonLabel>
                          </IonCol>
                        </IonRow>
                        <div className="separatorLineModal">
                          <span className="lineModal clear" />
                        </div>

                        <IonRow className="ion-margin-bottom">
                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.ENERGY")}</IonLabel>
                            <IonLabel className="textCharge number">{clickedChargeEnergy} Wh</IonLabel>
                          </IonCol>

                          <IonCol className="colCharges">
                            <IonLabel className="titleCharge">{t("INFO_CHARGE.PRICE")}</IonLabel>
                            {clickedChargeChargerGraph?.amount && clickedChargeChargerGraph.amount_consumed ? (
                              <>
                                <IonLabel className="textCharge number">
                                  {clickedChargePriceConsumed} {parameters["currency"]}
                                </IonLabel>
                              </>
                            ) : (
                              <>
                                <IonLabel className="textCharge number">{t("INFO_CHARGE.FREE_CHARGE")}</IonLabel>
                              </>
                            )}
                            {/*                             {clickedChargePrice === "0" ? (
                              <IonLabel className="textCharge number">{t("INFO_CHARGE.FREE_CHARGE")}</IonLabel>
                            ) : (
                              <IonLabel className="textCharge number">
                                {clickedChargePrice} {parameters["currency"]}
                              </IonLabel>
                            )} */}
                          </IonCol>
                        </IonRow>

                        <IonLabel className="ion-margin-top labelTitle">{t("TITLES.CONSUMPTION")}</IonLabel>
                        {periodsArray.length > 0 && (
                          <div className="graphContainer">
                            <ChargeChart data={periodsArray} />
                          </div>
                        )}
                        {!clickedChargeChargerGraph?.unlocked_at && (
                          <IonButton
                            className="stopChargeButton"
                            onClick={() =>
                              dispatch(
                                uiActions.setAlertType({
                                  type: "stoppingCharge",
                                  options: { primaryAction: () => handleStopCharge(clickedCharge.charge_id, false) },
                                }),
                              )
                            }
                          >
                            <IonIcon slot="start" icon={stopCircleOutline} />
                            {t("BUTTONS.STOP_CHARGE")}
                          </IonButton>
                        )}
                      </IonList>
                      <IonAlert
                        isOpen={showAlertServerError}
                        onDidDismiss={handleDismissServerError}
                        message={
                          new IonicSafeString(
                            `<div class="alert">
                                            <ion-label class="titleH3">${t("ALERT.SOMETHING_WRONG_TITLE")}</ion-label>
                                            <ion-label class="alertText">${t("ALERT.SOMETHING_WRONG_TEXT")}</ion-label>
                                        </div>`,
                          )
                        }
                        buttons={[`${t("BUTTONS.ACCEPT")}`]}
                        cssClass="alertContainer"
                      />
                      <IonAlert
                        isOpen={isDownloaded}
                        onDidDismiss={handleDismissDownloadInfo}
                        message={
                          new IonicSafeString(
                            `<div class="alert">
                                            <ion-label class="titleH3">${t(
                                              "ALERT.EXCHANGE_INVOICE_GENERATED",
                                            )}</ion-label>
                                            <ion-label class="alertText">${t("ALERT.SOMETHING_WRONG_TEXT")}</ion-label>
                                        </div>`,
                          )
                        }
                        buttons={[`${t("BUTTONS.ACCEPT")}`]}
                        cssClass="alertContainer"
                      />
                    </IonContent>
                  </IonModal>
                )}
              </>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};
