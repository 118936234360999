import { EndpointFetchingModel, ErrorModel, MethodOptions, ParamsApi } from "@/core/models/apiModel";
import { FileOpener } from "@capacitor-community/file-opener";
import { Capacitor } from "@capacitor/core";
import { Directory } from "@capacitor/filesystem";
import write_blob from "capacitor-blob-writer";

const { VITE_PROTOCOL, VITE_HOST, VITE_PORT } = import.meta.env;

export const getURLDomain = (endpoint: string, params?: any, options?: MethodOptions) => {
  if (options?.isOtherDomain) {
    return endpoint;
  }
  const HOST_APP = VITE_HOST && VITE_PORT ? `${VITE_HOST}:${VITE_PORT}` : VITE_HOST;
  const apiUrl = `${VITE_PROTOCOL}://${HOST_APP}${!options?.isRootPath ? "/api/v1/etecnic/" : "/"}`;

  return `${apiUrl}${endpoint}${params ? "?" : ""}${new URLSearchParams(params).toString()}`;
};

export const resolveResponse = async <T = any>(response: Response): Promise<T> => {
  const contentType = response.headers.get("content-type");
  if (!contentType?.includes("application/json")) {
    if (!response.ok) {
      throw {
        status: response.status,
        message: response.statusText,
      };
    }

    return response as T;
  }

  if (!response.ok) {
    const jsonError = await response.json();
    throw {
      status: response.status,
      message: response.statusText,
      jsonError,
    };
  }

  return await response.json();
};

export const rejectError = async (error: any) => {
  if (error.status || error.name === "AbortError") {
    throw error;
  }

  throw { status: 500, message: error.message };
};

export const getExtraHeaders = () => {
  return {
    browser_accept_header: window.navigator.userAgent,
    browser_user_agent: window.navigator.userAgent,
    browser_details: JSON.stringify({
      accept_header: navigator.userAgent,
      user_agent: navigator.userAgent,
      java_enabled: false,
      language: navigator.language,
      color_depth: screen.colorDepth,
      screen_height: screen.height,
      screen_width: screen.width,
      time_zone: new Date().getTimezoneOffset(),
    }),
  };
};

export const resolveBlobResponse = async (response: Response) => {
  if (!response.ok) {
    throw {
      status: response.status,
      message: response.statusText,
    };
  }

  return response.blob();
};

export const downloadBlob = async (blob: Blob, fileName: string) => {
  if (Capacitor.getPlatform() !== "web") {
    const filePath = await write_blob({
      blob,
      path: fileName,
      directory: Directory.Documents,
      on_fallback: async (error) => {
        const newError: ErrorModel = {
          message: error.message,
          status: 400,
        };
        throw newError;
      },
    });

    await FileOpener.open({
      filePath: filePath,
      openWithDefault: true,
    });
  } else {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }
};

export const getAbortController = (
  endpointFetching: EndpointFetchingModel[],
  endpoint: string,
  params?: ParamsApi,
  options?: MethodOptions,
  endpointClean?: string,
  body?: any,
) => {
  const endpointToAbort = endpointClean || endpoint;
  let newEndpointFetching = endpointFetching;

  if (options?.abortIfFetching === "endpoint") {
    const endpointFetch = endpointFetching.find((item) => item.endpoint === endpointToAbort);
    if (endpointFetch) {
      endpointFetch.abortController?.abort();
      newEndpointFetching = removeEndpointFetching(endpointFetching, endpointFetch.id);
    }
  }
  const abortController = new AbortController();

  const id = Date.now();
  endpointFetching.push({
    id,
    abortController,
    method: "GET",
    endpoint: endpointClean || endpoint,
    params,
    body,
  });

  return {
    signal: abortController.signal,
    id,
    newEndpointFetching,
  };
};

export const removeEndpointFetching = (endpointFetching: EndpointFetchingModel[], id: number) =>
  endpointFetching.filter((item) => item.id !== id);
