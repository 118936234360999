import { IonAccordion, IonAccordionGroup, IonCol, IonItem, IonSkeletonText } from "@ionic/react";

export const ShopHistoryAccountMerchantSkeleton = () => {
  return (
    <IonAccordionGroup className="account-merchant">
      <IonAccordion>
        <IonItem slot="header" color="light">
          <IonCol size="4">
            <IonSkeletonText animated={true} style={{ width: "70px", marginLeft: "20px" }} />
          </IonCol>
          <IonCol>
            <IonSkeletonText animated={true} style={{ width: "70px", marginLeft: "20px" }} />
          </IonCol>
          <IonCol style={{ marginLeft: "20px" }}>
            <IonSkeletonText animated={true} style={{ width: "70px", marginLeft: "20px" }} />{" "}
          </IonCol>
        </IonItem>
      </IonAccordion>
    </IonAccordionGroup>
  );
};
