import { NotificationActionType } from "@/commons/hooks/useNotificationActions";
import { RecentSearchesModel } from "@/core/models/recentSearchesModel";
import { UserInfoModel, UserModel, UserTheme, UserType, VehicleFormModel } from "@/core/models/userModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

export interface UserSlice {
  user: UserModel;
  userInfo: UserInfoModel | null;
  haveRequiredFields: boolean;
  userType: "guest" | "logged";
  isUserLogged: boolean;
  deviceId: string;
  seenOnboarding: boolean;
  theme: UserTheme;
  themeManual: boolean; // If the user has manually changed the theme
  token: string;
  recentSearchs: RecentSearchesModel[];
  guestInfo: {
    email: string | null;
    vehicle: VehicleFormModel | null;
  };
  lastNotification: NotificationActionType | null;
  settings: {
    alerts: {
      nearLocation: boolean;
    };
  };
  location: {
    latitude: number | null;
    longitude: number | null;
  };
}

export const userSliceInitialState: UserSlice = {
  user: { user_id: 0, auth_token: "" },
  userInfo: null,
  haveRequiredFields: false,
  userType: "guest",
  isUserLogged: false,
  deviceId: "",
  seenOnboarding: false,
  theme: "light",
  themeManual: false,
  token: "",
  recentSearchs: [],
  guestInfo: {
    email: null,
    vehicle: null,
  },
  lastNotification: null,
  settings: {
    alerts: {
      nearLocation: true,
    },
  },
  location: {
    latitude: null,
    longitude: null,
  },
};

export const userSlice = createSlice({
  name: "userReducer",
  initialState: userSliceInitialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<{ user: UserModel; userType?: UserType }>) => {
      state.user = payload.user;
      state.userType = payload.userType ?? "guest";
      state.isUserLogged = payload.userType === "logged";
      state.userInfo = null;
    },
    setUserInfo: (state, { payload }: PayloadAction<UserInfoModel | null>) => {
      state.userInfo = payload;
    },
    setHaveRequiredFields: (state, { payload }: PayloadAction<boolean>) => {
      state.haveRequiredFields = payload;
    },
    setDeviceId: (state, { payload }: PayloadAction<string>) => {
      state.deviceId = payload;
    },
    setSeenOnboarding: (state, { payload }: PayloadAction<boolean>) => {
      state.seenOnboarding = payload;
    },
    setTheme: (state, { payload }: PayloadAction<UserTheme>) => {
      state.theme = payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
      state.themeManual = true;
    },
    addRecentSearches: (state, { payload: newSearch }: PayloadAction<RecentSearchesModel>) => {
      const recentSearch = state.recentSearchs.find(
        (search) => search.longitude === newSearch.longitude && search.latitude === newSearch.latitude,
      );
      if (!recentSearch) {
        state.recentSearchs = [{ ...newSearch, date: new Date() }, ...state.recentSearchs];
        return;
      }

      recentSearch.date = new Date();

      state.recentSearchs = sortBy(state.recentSearchs, "date").reverse();
    },
    removeSearch: (state, { payload: nameToRemove }: PayloadAction<string>) => {
      state.recentSearchs = state.recentSearchs.filter((search) => search.name !== nameToRemove);
    },
    setGuestInfo: (state, { payload }: PayloadAction<{ email: string | null; vehicle: VehicleFormModel | null }>) => {
      state.guestInfo = payload;
    },
    setLastNotification: (state, { payload }: PayloadAction<NotificationActionType | null>) => {
      state.lastNotification = payload;
    },
    setSettings: (state, { payload }: PayloadAction<UserSlice["settings"]>) => {
      state.settings = payload;
    },
    setlocation: (state, { payload }: PayloadAction<UserSlice["location"]>) => {
      state.location = payload;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
