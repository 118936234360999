import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";

import { IconButton } from "@/commons/components/Buttons/IconButton/IconButton";
import { getMerchatProductsService } from "@/core/services/shop/shopService";
import { shopActions } from "@/core/store/slices/shopSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { App } from "@capacitor/app";
import { IonContent, IonIcon, IonLabel, IonPage, IonRow, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { bagOutline, cartOutline, timeOutline } from "ionicons/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ShopCartModal } from "./ShopCart/ShopCartModal";
import { ShopHistoryModal } from "./ShopHistory/ShopHistoryModal";
import "./ShopPage.scss";
import { ShopProduct } from "./ShopProduct/ShopProduct";
import { ShopProductSkeleton } from "./ShopProductSkeleton/ShopProductSkeleton";

export const ShopPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { user_id } = useAppSelector((state) => state.userReducer.user);
  const { shop, products } = useAppSelector((state) => state.shopReducer);
  const userCart = shop[user_id] || [];

  const [showLoadingData, setShowLoadingData] = useState(false);

  useIonViewWillEnter(() => {
    // first load
    getShopProducts();

    const listenerPromises = App.addListener("resume", getShopProducts);

    return () => {
      listenerPromises.then((listener) => listener.remove());
    };
  }, []);

  useIonViewWillLeave(() => {
    dispatch(uiActions.setIsCartModalOpen(false));
  });

  const getShopProducts = async () => {
    try {
      setShowLoadingData(true);
      const shopProducts = await getMerchatProductsService();

      if (!shopProducts.products) {
        dispatch(shopActions.setProducts([]));
      }

      shopProducts.products && dispatch(shopActions.setProducts(shopProducts.products));
    } catch (error) {
      console.error(error);
      dispatch(shopActions.setProducts([]));
    } finally {
      setShowLoadingData(false);
    }
  };

  const handleGoCart = () => {
    dispatch(uiActions.setIsCartModalOpen(true));
  };

  return (
    <IonPage>
      <AppHeader
        title={t("FILTERS.SHOP")}
        endIcon={
          <>
            <IconButton
              icon={timeOutline}
              onClick={() => {
                dispatch(uiActions.setIsShopHistoryOpen(true));
              }}
            />
            <IconButton icon={cartOutline} badge={userCart.length} onClick={handleGoCart} />
          </>
        }
      />
      <IonContent>
        <ShopCartModal />
        <ShopHistoryModal />
        <div>
          {showLoadingData ? (
            <div className="shop-products">
              <ShopProductSkeleton />
              <ShopProductSkeleton />
              <ShopProductSkeleton />
              <ShopProductSkeleton />
              <ShopProductSkeleton />
              <ShopProductSkeleton />
            </div>
          ) : (
            <>
              {products.length === 0 ? (
                <div className="empty-shop">
                  <IonRow>
                    <IonIcon src={bagOutline} className="icon" />
                  </IonRow>
                  <IonRow>
                    <IonLabel className="no-location-text">{t("ERRORS.NO_SHOP_PRODUCTS")}</IonLabel>
                  </IonRow>
                </div>
              ) : (
                <div className="shop-products">
                  {products.map((product) => (
                    <ShopProduct key={product.id} product={product} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
