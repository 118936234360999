import { ConnectorInput } from "@/commons/components/Form/ConnectorInput/ConnectorInput";
import { SetState } from "@/core/models/globalModel";
import { TargetFormType } from "@/pages/PersonalDataPage/PersonalDataPage";
import { IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import { DefaultValues } from "../RegisterPage";

interface FifthStepProps {
  defaultChargeValues: DefaultValues;
  setDefaultChargeValues: SetState<DefaultValues>;
}

export const FifthStep = ({ defaultChargeValues, setDefaultChargeValues }: FifthStepProps) => {
  const handleChangeForm = (target: TargetFormType) => {
    const { name, value } = target;

    setDefaultChargeValues({
      ...defaultChargeValues,
      [name]: value,
    });
  };

  return (
    <div>
      <IonRow className="ion-margin-bottom">
        <IonLabel className="neutralBlack70">
          {t("STEPS.STEP")} <IonLabel className="number number-bold">5</IonLabel> {t("STEPS.OUT_OF")}{" "}
          <IonLabel className="number number-bold">6 </IonLabel>
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonLabel className="titleH3">{t("TITLES.DEFAULT_VALUES")}</IonLabel>
      </IonRow>
      <div className="register-fifth-step-inputs">
        <IonItem lines="full">
          <IonInput
            name="default_kwh"
            label={t("PROFILE.DEFAULT_WH")}
            labelPlacement="floating"
            onIonInput={(event) => handleChangeForm(event.target)}
            type="number"
            value={defaultChargeValues.default_kwh}
          >
            <IonLabel slot="end">kWh</IonLabel>
          </IonInput>
        </IonItem>
        <ConnectorInput
          value={defaultChargeValues.app_connector_default}
          handleChange={(value) => setDefaultChargeValues({ ...defaultChargeValues, app_connector_default: value })}
        />
      </div>
    </div>
  );
};
