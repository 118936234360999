import { ChargeChartData } from "@/commons/components/charts/ChargeChart/ChargeChart";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useCheckChargeFinish } from "@/commons/hooks/useCheckChargeFinish";
import { useLocationsContext } from "@/commons/hooks/useLocationsContext";
import { classIf } from "@/commons/utils/componentsUtils/componentsUtils";
import { formatRemainingTime, formatToHours } from "@/commons/utils/dateUtils/dateUtils";
import { getSocketTimeByEnergy } from "@/commons/utils/socketUtils/socketUtils";
import { ErrorModel } from "@/core/models/apiModel";
import { ActiveChargeModel } from "@/core/models/chargeModel";
import { CHARGER_STATUS_IDS, ChargerModel, ChargerSocketModel } from "@/core/models/chargerModel";
import { RateTypeLimits } from "@/core/models/rateModel";
import { getChargerGraphService, getChargerService } from "@/core/services/charge/chargeServices";
import { stopSocketService } from "@/core/services/socket/socketServices";
import { getUserChargeLimitsService } from "@/core/services/user/userServices";
import { chargesActions } from "@/core/store/slices/chargesSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonButton, IonLabel, IonLoading, IonRow, IonSpinner, useIonViewWillLeave } from "@ionic/react";
import { differenceInSeconds, format } from "date-fns";
import { t } from "i18next";
import { useEffect, useState } from "react";
import "./ActiveCharge.scss";
import { ActiveChargeDetail } from "./ActiveChargeDetail/ActiveChargeDetail";
import { ActiveChargeMonitoring } from "./ActiveChargeMonitoring/ActiveChargeMonitoring";

interface ActiveChargeProps {
  activeCharge: ActiveChargeModel;
}

export interface ChargeInfo {
  energy: string;
  amount: number;
  amount_consumed: number;
  periods: ChargeChartData[];
  createAt: Date;
  startedAt: Date;
  remainingTime: number; // seconds
  remainingTimeFormatted?: string; // hh:mm:ss
  progressEnergy: number;
  timeConsumed?: string;
  totalEnergy: number;
  remainingEnergy: number;
  runtimePower: number;
  percentBattery?: number;
  unlockedCharger: boolean;
}

export const ActiveCharge = ({ activeCharge }: ActiveChargeProps) => {
  const dispatch = useAppDispatch();
  const { checkChargeFinish } = useCheckChargeFinish({ activeCharge });

  const { user } = useAppSelector((state) => state.userReducer);
  const { getLocations } = useLocationsContext();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [charger, setCharger] = useState<ChargerModel | null>(null);
  const [socket, setSocket] = useState<ChargerSocketModel>();
  const [chargeInfo, setChargeInfo] = useState<ChargeInfo | null>(null);
  const [rateLimits, setRateLimits] = useState<RateTypeLimits>();
  const [meterValues, setMeterValues] = useState({
    totalMeterValues: 0,
    remainingMeterValues: 0,
  });
  const [meterValuesInterval, setMeterValuesInterval] = useState<NodeJS.Timeout | null>(null);

  useIonViewWillLeave(() => {
    // Clear interval when leave page
    meterValuesInterval && clearInterval(meterValuesInterval);
  }, [meterValuesInterval]);

  // First load
  useEffect(() => {
    getChargeChargerGraph();
  }, [activeCharge]);

  useEffect(() => {
    const interval = setInterval(() => {
      chargeInfo &&
        // Update remaining time charge
        setChargeInfo((prevChargeInfo) => {
          if (!prevChargeInfo) {
            return null;
          }
          const remainingTimeProps = getTimeConsumed(prevChargeInfo.startedAt);

          // Stop interval if remaining time is less than 0
          if (prevChargeInfo.progressEnergy >= 1) {
            clearInterval(interval);

            checkChargeFinish();
          }

          return {
            ...prevChargeInfo,
            ...remainingTimeProps,
          };
        });

      // Update meter values remaining
      meterValues.remainingMeterValues > 0 &&
        setMeterValues((prevMeterValues) => {
          return {
            ...prevMeterValues,
            remainingMeterValues: prevMeterValues.remainingMeterValues - 1,
          };
        });
    }, 1000);

    return () => clearInterval(interval);
  }, [chargeInfo?.timeConsumed]);

  useEffect(() => {
    if (meterValues.totalMeterValues) {
      // Clear previous interval
      meterValuesInterval && clearInterval(meterValuesInterval);

      // Reload data every meter values interval
      const interval = setInterval(() => {
        getChargeChargerGraph(interval);
      }, meterValues.totalMeterValues * 1000);

      setMeterValuesInterval(interval);
    }

    return () => {
      meterValuesInterval && clearInterval(meterValuesInterval);
    };
  }, [meterValues.totalMeterValues]);

  const getChargeChargerGraph = (intervalId?: NodeJS.Timeout) => {
    Promise.all([getChargerGraphService(activeCharge.charge_id), getChargerService(activeCharge.charger_id)])
      .then(([newChargerGraph, newCharger]) => {
        if (newChargerGraph.session_end_at) {
          intervalId && clearInterval(intervalId);
          dispatch(chargesActions.removeActivePayload(activeCharge.charge_id));
          dispatch(uiActions.setToastType({ type: "chargedStopped" }));
        }
        let selectedSocket: ChargerSocketModel | undefined;
        // Set charger
        if (newCharger) {
          dispatch(chargesActions.checkVisibleCharge({ chargeId: activeCharge.charge_id, charger: newCharger }));

          setCharger(newCharger);
          selectedSocket = newCharger.charger_sockets.find(
            (socket) => Number(socket.socket_number) === newChargerGraph.socket_number,
          );
          if (selectedSocket) setSocket(selectedSocket);
          const meterValues = parseInt(newCharger.meter_values_interval);
          if (meterValues > 0) {
            const lastConsumtion = newChargerGraph.charger_periods?.at(-1);
            const lastConsumtionDate = lastConsumtion?.created_at ? new Date(lastConsumtion.created_at) : new Date();
            const diffSeconds = differenceInSeconds(new Date(), lastConsumtionDate);
            let newMeterValues = meterValues - diffSeconds;
            newMeterValues = newMeterValues > 0 ? newMeterValues : meterValues;

            setMeterValues({
              totalMeterValues: newMeterValues,
              remainingMeterValues: newMeterValues,
            });
          }
        }

        // Set charger graph info
        const chargerPeriods = newChargerGraph.charger_periods || [];
        const periods: ChargeChartData[] = chargerPeriods.map((period) => {
          const date = new Date(period.session_start_at);
          return {
            date,
            time: format(date, "HH:mm"),
            power: parseInt(period.energy) / 1000,
            percentBattery: period.percent_battery,
          };
        });
        const startedAt = new Date(newChargerGraph.session_start_at);

        const totalEnergy = Number(newChargerGraph.charging_energy) / 1000;
        const progressEnergy = Number(newChargerGraph.energy) / 1000 / totalEnergy;
        const remainingEnergy = totalEnergy - Number(newChargerGraph.energy) / 1000;
        const runtimePower = Number(newChargerGraph.runtime_power) / 1000;

        let remainingTime = 0;
        let remainingTimeFormatted = "-";
        if (selectedSocket) {
          remainingTime = getSocketTimeByEnergy(String(runtimePower), remainingEnergy);
          remainingTimeFormatted = formatRemainingTime(remainingTime);
        }
        const { timeConsumed } = getTimeConsumed(startedAt);

        setChargeInfo({
          energy: (Number(newChargerGraph.energy) / 1000.0).toFixed(2),
          amount: Number(newChargerGraph.amount),
          amount_consumed: Number(newChargerGraph.amount_consumed),
          periods,
          createAt: new Date(newChargerGraph.created_at),
          startedAt,
          remainingTime,
          remainingTimeFormatted,
          progressEnergy,
          timeConsumed,
          totalEnergy,
          remainingEnergy,
          runtimePower,
          percentBattery: newChargerGraph.percent_battery,
          unlockedCharger: !!newChargerGraph.unlocked_at,
        });
      })
      .catch((error: ErrorModel) => {
        dispatch(uiActions.setToastError(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserRateLimits = async (selectedSocket: ChargerSocketModel) => {
    try {
      setLoadingData(true);
      const userRatesLimits = await getUserChargeLimitsService(selectedSocket.id_socket, user.user_id);

      const firstRateId = selectedSocket.rates[0].id_rate;
      const rateLimits = userRatesLimits[firstRateId];

      setRateLimits(rateLimits);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setLoadingData(false);
    }
  };

  const getTimeConsumed = (startedAt: Date) => {
    const diffSeconds = differenceInSeconds(new Date(), startedAt);
    return {
      timeConsumed: formatToHours(diffSeconds),
    };
  };

  const handleConfirmStopCharge = () => {
    dispatch(uiActions.setAlertType({ type: "stoppingCharge", options: { primaryAction: handleStopCharge } }));
  };

  const handleStopCharge = async () => {
    setStopLoading(true);

    const MAX_ERROR_COUNTER = 6;
    let errorCounter = 0;
    const dateFirstTry = new Date();
    while (errorCounter < MAX_ERROR_COUNTER) {
      try {
        await stopSocketService(activeCharge.charger_id, activeCharge.socket_id);

        dispatch(chargesActions.removeActivePayload(activeCharge.charge_id));
        dispatch(uiActions.setToastType({ type: "chargedStopped" }));
        setStopLoading(false);

        getLocations(false);

        break;
      } catch (error) {
        errorCounter++;

        const timeFromFirstTry = differenceInSeconds(new Date(), dateFirstTry);
        if (errorCounter === MAX_ERROR_COUNTER || timeFromFirstTry > 30) {
          setStopLoading(false);
          dispatch(uiActions.setToastError(error));
          break;
        }

        // Wait 2 second before retry
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  };

  const handleShowMore = () => {
    setShowDetail(true);
    if (socket && socket.rates.length > 0) getUserRateLimits(socket);
  };

  return (
    <div className="active-charge">
      <div className={`info ${classIf(loading, "loading")}`}>
        <IonLoading isOpen={stopLoading} />
        {loading && <IonSpinner color="primary" name="dots"></IonSpinner>}
        {!loading && charger && chargeInfo && socket && (
          <div className="info-grid ion-margin">
            <ActiveChargeDetail
              chargeInfo={chargeInfo}
              charger={charger}
              showDetail={showDetail}
              setShowDetail={setShowDetail}
              remainingMeterValues={meterValues.remainingMeterValues}
              handleStopCharge={handleConfirmStopCharge}
              selectedSocket={socket}
              rateLimits={rateLimits}
              loadingData={loadingData}
            />
            <div>
              <IonLabel className="charger-name">{charger.name}</IonLabel>
              {charger.id_status === CHARGER_STATUS_IDS.OUT_OF_SERVICE && (
                <IonRow>
                  <StatusLabel> {t("CHARGER.NO_COMMUNICATION")} </StatusLabel>
                </IonRow>
              )}
              <ActiveChargeMonitoring chargeInfo={chargeInfo} />
            </div>
            <div className="info-grid-buttons">
              <IonButton
                className="info-grid-button"
                color="primary"
                fill="outline"
                size="small"
                onClick={handleShowMore}
              >
                {t("BUTTONS.SHOW_MORE")}
              </IonButton>
              {!chargeInfo.unlockedCharger && (
                <IonButton className="info-grid-button" size="small" onClick={handleConfirmStopCharge}>
                  {t("BUTTONS.STOP")}
                </IonButton>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
