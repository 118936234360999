import { IonCol, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";

export const VehicleCardSkeleton = () => {
  return (
    <div className="rowContainerEdit">
      <div className="vehicleContainer">
        <IonRow className="ion-justify-content-between ion-align-items-center ion-padding-start ion-margin-start socketInfo">
          <IonCol className="ion-align-items-center modalColumn">
            <IonLabel className="vehicleInfoLabel">
              <IonSkeletonText animated={true} style={{ width: "80px" }} />
            </IonLabel>
            <IonLabel className="vehicleInfoLabel">
              <IonSkeletonText animated={true} style={{ width: "80px" }} />
            </IonLabel>
          </IonCol>
          <IonCol className="ion-align-items-center modalColumn">
            <IonLabel className="vehicleInfoLabel">
              <IonSkeletonText animated={true} style={{ width: "80px" }} />
            </IonLabel>
            <IonLabel className="vehicleInfoLabel">
              <IonSkeletonText animated={true} style={{ width: "80px" }} />
            </IonLabel>
          </IonCol>
          <IonCol className="ion-align-items-center modalColumn">
            <IonLabel className="vehicleInfoLabel">
              <IonSkeletonText animated={true} style={{ width: "80px" }} />
            </IonLabel>
            <IonLabel className="vehicleInfoLabel">
              <IonSkeletonText animated={true} style={{ width: "80px" }} />
            </IonLabel>
          </IonCol>
          <IonCol className="ion-align-items-center modalColumn">
            <IonLabel className="vehicleInfoLabel">
              <IonSkeletonText
                animated={true}
                style={{ width: "120px", height: "20px", marginTop: "10px", marginBottom: "15px" }}
              />
            </IonLabel>
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};
