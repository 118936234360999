import { rejectError, resolveResponse } from "@/commons/utils/apiUtils/apiUtils";
import { api } from "@/core/api/api";
import {
  CustomerModel,
  ObjectCustomerModel,
  PaymentCreateIntentModel,
  PaymentIntentModel,
  WalletHistoryModel,
} from "@/core/models/PaymentModel";
import { AccountModel } from "@/core/models/accountModel";
import { ChargerSocketModel } from "@/core/models/chargerModel";
import { ParametersModel } from "@/core/models/globalModel";
import { ReservationModel } from "@/core/models/reservationModel";
import { ChargeStepActiveForm } from "@/core/store/slices/chargeStepsSlice";

interface PriceObject {
  price: number;
  currency: {
    symbol: string;
    code: string;
  };
}
/**
 * Service to get the price of a payment on cents
 * @param socket_id Socket id
 * @param energy Energy in kWh
 * @param time Time in minutes
 * @returns price in cents
 */
export const getPaymentPriceService = async (socket_id: string, energy: number): Promise<PriceObject> => {
  return api.get(`payments/get-price`, { socket_id, energy, time: 0 }).then(resolveResponse).catch(rejectError);
};

/**
 * Service to get the setup intent for the payment
 * @returns PaymentIntentModel
 */
export const getPaymentSetupIntentService = async (): Promise<PaymentIntentModel> => {
  return api.get(`payments/setup_intent`).then(resolveResponse).catch(rejectError);
};

/**
 * Service to create a customer on BBDD
 * @param payment_method Payment method id
 */
export const createPaymentCustomerService = async (
  payment_method: string,
): Promise<{
  customer: CustomerModel;
  object: ObjectCustomerModel;
}> => {
  return api.post(`payments/create_customer`, undefined, { payment_method }).then(resolveResponse).catch(rejectError);
};

/**
 * Service to create a account
 * @param parameters
 * @param userId
 * @param stripeEmail
 * @param cardCode
 * @param selectedSocket
 * @param chargingTime minutes
 * @param chargingEnergy kWh
 * @param amount
 * @param activeStep Vehicle data
 * @returns
 */
export const createPaymentAccountService = async (
  parameters: ParametersModel,
  userId: number,
  stripeEmail: string,
  cardCode: string,
  selectedSocket: ChargerSocketModel,
  chargingEnergy: number,
  amount: number,
  activeStep: ChargeStepActiveForm,
  deviceId: string,
): Promise<AccountModel> => {
  return api
    .post(`payments/create-payment`, {
      title: "AppPayment",
      paymentType: "STRIPE",
      paymentToken: "",
      paymentCapture: "false",
      currency: parameters.currency_code,
      userId,
      stripeEmail,
      cardCode,
      socketId: selectedSocket.id_socket,
      chargingEnergy,
      amount,
      priceTimeMin: selectedSocket.price_time_min,
      priceEnergyKwh: selectedSocket.price_energy_kwh,
      vehicle_id: activeStep.vehicleId,
      deviceId,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

/**
 * Service to create a account with user B2B
 */
export const createPaymentAccountB2BService = async (
  parameters: ParametersModel,
  userId: number,
  stripeEmail: string,
  cardCode: string,
  selectedSocket: ChargerSocketModel,
  chargingEnergy: number,
  amount: number,
  activeStep: ChargeStepActiveForm,
  deviceId: string,
): Promise<{ intent: null; account: string }> => {
  return api
    .post(`payments/create_account_b2b`, {
      title: "AppPayment",
      paymentType: "B2B",
      paymentToken: "",
      paymentCapture: "false",
      currency: parameters.currency_code,
      userId,
      stripeEmail,
      cardCode,
      socketId: selectedSocket.id_socket,
      chargingEnergy,
      amount,
      priceTimeMin: selectedSocket.price_time_min,
      priceEnergyKwh: selectedSocket.price_energy_kwh,
      vehicle_id: activeStep.vehicleId,
      deviceId,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentIntentService = async (
  currency: string,
  cardCode: string,
  socketId: string,
  chargingEnergy: number,
  paymentMethod: string,
  deviceId: string,
  vehicleId: string,
  extraField?: string,
): Promise<{ intent: PaymentCreateIntentModel; account: string }> => {
  return api
    .post(`payments/create_payment_intent`, {
      currency,
      cardCode,
      socketId,
      chargingEnergy,
      paymentMethod,
      deviceId,
      customerId: extraField,
      vehicleId,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const confirmPaymentIntentService = async (
  socketId: string,
  accountId: number,
): Promise<{ account: string }> => {
  return api
    .post(`payments/confirm_payment_intent`, {
      socketId,
      accountId,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentReservationService = async (
  currency: string,
  cardCode: string,
  socketId: string,
  reserveTime: number,
  paymentMethod: string,
): Promise<{ intent?: PaymentCreateIntentModel; account: AccountModel; reservation?: ReservationModel }> => {
  return api
    .post(`payments/create_reservation_payment_intent`, {
      currency,
      cardCode,
      socketId,
      reserveTime,
      paymentMethod,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const confirmPaymentIntentReservationService = async (
  socketId: string,
  accountId: number,
  cardCode: string,
  reserveTime: number,
): Promise<{ intent: null; reservation: ReservationModel }> => {
  return api
    .post(`payments/confirm_reservation_payment_intent`, {
      socketId,
      accountId,
      cardCode,
      reserveTime,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const createReservationWalletService = async (
  currency: string,
  cardCode: string,
  socketId: string,
  reserveTime: number,
) => {
  return api
    .post(`payments/create_account_reservation_with_wallet`, {
      currency,
      cardCode,
      socketId,
      reserveTime,
      type: "wallet",
      mode: "APP",
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const createPaymentWalletService = async (
  currency: string,
  paymentMethod: string,
  amount: number,
): Promise<any> => {
  return api
    .post(`payments/create_payment_intent_wallet`, {
      type: "wallet",
      mode: "APP",
      currency,
      paymentMethod,
      amount,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const checkPaymentWalletService = (transaction_id: string): Promise<boolean> => {
  return api
    .post(`payments/check_payment_wallet`, undefined, { transaction_id })
    .then(resolveResponse)
    .catch(rejectError);
};

export const getPaymentAccountService = async (accountId: number): Promise<AccountModel> => {
  return api.get(`payments/get-account`, { accountId }).then(resolveResponse).catch(rejectError);
};

export const createAccountWalletService = async (
  socketId: string,
  chargingEnergy: number,
  cardCode: string,
  vehicleId: string,
  currencyCode: string,
  deviceId: string,
): Promise<{ account: string }> => {
  return api
    .post(`payments/create_account_with_wallet`, {
      chargingEnergy: chargingEnergy.toString(),
      socketId: socketId,
      cardCode: cardCode,
      type: "wallet",
      mode: "APP",
      currency: currencyCode,
      vehicle_id: vehicleId,
      deviceId,
    })
    .then(resolveResponse)
    .catch(rejectError);
};

export const getHistoryWalletService = async (): Promise<WalletHistoryModel[]> => {
  return api.get(`payments/history_wallet`).then(resolveResponse).catch(rejectError);
};

export const deleteStripeCardService = async (paymentMethod: string): Promise<{ result: boolean }> => {
  return api
    .post(`payments/delete_card_stripe`, { payment_method: paymentMethod })
    .then(resolveResponse)
    .catch(rejectError);
};

export const setDefaultPaymentMethodService = async (body: {
  id: string;
  payment_gateway: string;
}): Promise<{ updated: boolean }> => {
  return api
    .post(`payments/set_default_payment_method`, undefined, { ...body, default: "true" })
    .then(resolveResponse)
    .catch(rejectError);
};
