import { QrScanner } from "@/commons/components/QrScaner/QrScanner";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { Capacitor } from "@capacitor/core";
import { IonAlert, IonFab, IonFabButton, IonFabList, IonIcon, IonicSafeString } from "@ionic/react";
import { informationCircleOutline, qrCodeOutline, radioOutline, scanOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ScanButton.scss";

export const ScanButton = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [showInfo, setShowInfo] = useState(false);
  const [showCamera, setshowCamera] = useState(false);

  const fabRef = useRef<HTMLIonFabElement>(null);

  useEffect(() => {
    const closeFabOnOutsideClick = (event: MouseEvent) => {
      if (fabRef.current && !fabRef.current.contains(event.target as Node)) {
        fabRef.current.close();
      }
    };

    document.addEventListener("click", closeFabOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeFabOnOutsideClick);
    };
  }, []);

  const handleScanQR = async () => {
    if (Capacitor.getPlatform() === "web") {
      dispatch(uiActions.setToastError(t("APP_ERRORS.QR")));
      return;
    }

    setshowCamera(true);
  };

  const handleScanNFC = async () => {
    dispatch(uiActions.setNfc({ isNfcOpen: true }));
  };

  return (
    <>
      <IonAlert
        isOpen={showInfo}
        onDidDismiss={() => setShowInfo(false)}
        message={
          new IonicSafeString(
            `<div class="alert">
                  <ion-label class="alertText">${t("ALERT.DEEP_LINK_INFO_SCAN")}</ion-label>
              </div>`,
          )
        }
        buttons={[`${t("BUTTONS.ACCEPT")}`]}
        cssClass="alertPriceInfo"
      />
      <QrScanner showCamera={showCamera} setShowCamera={setshowCamera} type="location" />
      <IonFab ref={fabRef} className="scan-button" slot="fixed" horizontal="center" vertical="bottom">
        <IonFabButton>
          <IonIcon icon={scanOutline} />
        </IonFabButton>
        <IonFabList side="top" className="scan-button-fab-list">
          <IonFabButton onClick={handleScanQR}>
            <IonIcon icon={qrCodeOutline} />
          </IonFabButton>
          <IonFabButton onClick={handleScanNFC}>
            <IonIcon icon={radioOutline} />
          </IonFabButton>
          <IonFabButton onClick={() => setShowInfo(true)}>
            <IonIcon icon={informationCircleOutline} />
          </IonFabButton>
        </IonFabList>
      </IonFab>
    </>
  );
};
