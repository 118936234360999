import { CHARGER_SOCKET_CONNECTOR_TYPE } from "@/core/models/chargerModel";
import { MapFiltersModel } from "@/core/store/slices/mapSlice";

export const buildURLFilters = (selectedFilters: MapFiltersModel) => {
  let url: string = "";

  const { available, free, connectorTypes, facilities, chargeTypes } = selectedFilters;
  if (available) {
    url += `available=YES&`;
  }
  if (free) {
    url += `free=YES&`;
  }

  if (facilities.length) {
    facilities.forEach((facility) => {
      url += `facilities[]=${facility}&`;
    });
  }

  if (chargeTypes.length) {
    chargeTypes.forEach((chargeType) => {
      url += `socket_power[]=${chargeType}&`;
    });
  }

  if (connectorTypes.length) {
    connectorTypes.forEach((connectorType) => {
      if (
        connectorType === CHARGER_SOCKET_CONNECTOR_TYPE.TYPE2F ||
        connectorType === CHARGER_SOCKET_CONNECTOR_TYPE.TYPE2M
      ) {
        url += `connector_types[]=TYPE-2-M&connector_types[]=TYPE-2-F&`;
        return;
      }

      if (
        connectorType === CHARGER_SOCKET_CONNECTOR_TYPE.TESLA_R ||
        connectorType === CHARGER_SOCKET_CONNECTOR_TYPE.TESLA_S
      ) {
        url += `connector_types[]=TESLA_R&connector_types[]=TESLA_S&`;
        return;
      }

      url += `connector_types[]=${connectorType}&`;
    });
  }

  return url;
};
