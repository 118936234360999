import { Divider } from "@/commons/components/Divider/Divider";
import { IonContent, IonRow, IonSkeletonText } from "@ionic/react";
import "./LocationDetailSkeleton.scss";

export const LocationDetailSkeleton = () => {
  const ChargerSkeleton = () => (
    <div className="location-detail-skeleton-charger-label">
      <IonSkeletonText animated />

      <div className="icon-charger-label">
        <IonSkeletonText className="icon-skeleton" animated />
        <IonSkeletonText className="icon-skeleton" animated />
        <IonSkeletonText className="icon-skeleton" animated />
      </div>
    </div>
  );

  const SocketSkeleton = ({ hideDivider }: { hideDivider?: boolean }) => (
    <>
      <div className="socket-skeleton">
        <div className="info-row">
          <div className="socket-skeleton-connection">
            <IonSkeletonText className="icon-skeleton" animated />
            <IonSkeletonText className="icon-skeleton" animated />
            <IonSkeletonText animated />
          </div>
          <div className="socket-skeleton-charge-type">
            <IonSkeletonText className="icon-skeleton" animated />
            <div className="labels">
              <IonSkeletonText animated />
              <IonSkeletonText animated />
            </div>
          </div>
          <div className="socket-skeleton-rate">
            <IonSkeletonText animated />
          </div>
        </div>
        <div>
          <IonSkeletonText className="status" animated />
        </div>
      </div>
      {!hideDivider && <Divider />}
    </>
  );

  return (
    <IonContent>
      <div className="location-detail-skeleton">
        <IonRow className="location-detail-skeleton-location-label">
          <IonSkeletonText animated />
        </IonRow>

        <div className="location-detail-skeleton-accordion">
          <IonSkeletonText className="icon-skeleton" animated />

          <IonSkeletonText animated />
        </div>

        <ChargerSkeleton />
        <SocketSkeleton />
        <SocketSkeleton hideDivider />

        <ChargerSkeleton />
        <SocketSkeleton />
        <SocketSkeleton hideDivider />
      </div>
    </IonContent>
  );
};
