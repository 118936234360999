import i18n from "@/assets/i18n/i18n";
import { useNotificationActions } from "@/commons/hooks/useNotificationActions";
import { updateUserFirebaseTokenService } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { PushNotifications } from "@capacitor/push-notifications";
import { useEffect, useState } from "react";

export const NotificationsContext = () => {
  const dispatch = useAppDispatch();
  const { exectNotificationActionReceived, execNotificationActionTap } = useNotificationActions();

  const { user, isUserLogged } = useAppSelector((state) => state.userReducer);

  const [firebaseToken, setFirebaseToken] = useState("");

  // Check permissions and register
  useEffect(() => {
    const platform = Capacitor.getPlatform();

    if (platform !== "web") {
      PushNotifications.checkPermissions()
        .then((permission) => {
          if (permission.receive !== "granted") {
            return PushNotifications.requestPermissions();
          } else {
            return permission;
          }
        })
        .then(({ receive }) => {
          receive !== "denied" && register();
        });
    }
  }, []);

  // Update token when user is logged
  useEffect(() => {
    firebaseToken && isUserLogged && setTokenDB(firebaseToken);
  }, [firebaseToken, isUserLogged]);

  const register = async () => {
    await PushNotifications.removeAllListeners();

    PushNotifications.register();

    PushNotifications.addListener("registration", ({ value: token }) => {
      token && setFirebaseToken(token);
    });

    PushNotifications.addListener("registrationError", ({ error }) => {
      dispatch(uiActions.setToastError(error));
    });

    // Method called when a notification is received
    PushNotifications.addListener("pushNotificationReceived", (notification) => {
      exectNotificationActionReceived(notification);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener("pushNotificationActionPerformed", ({ notification }) => {
      execNotificationActionTap(notification);
    });
  };

  const setTokenDB = async (token: string) => {
    const info = await Device.getInfo();
    const os = info.operatingSystem;

    const [language] = i18n.language.split("-");

    updateUserFirebaseTokenService(user, token, os, language);
    if (os === "android") {
      PushNotifications.createChannel({
        id: `general`,
        name: "General",
        description: "General Notifications",
        importance: 5,
        visibility: 1,
        vibration: true,
        lights: true,
      });
    }
  };

  return null;
};
