import { LocationModel } from "@/core/models/locationModel";
import { IonCol, IonLabel, IonRow } from "@ionic/react";
import { t } from "i18next";
import "swiper/css";
import { LocationMoreInfoCloseExceptionals } from "./LocationMoreInfoCloseExceptionals";
import { LocationMoreInfoOpenExceptionals } from "./LocationMoreInfoOpenExceptionals";

interface LocationMoreInfoExceptionalsProps {
  locationInfo: LocationModel;
}

export const LocationMoreInfoExceptionals = ({ locationInfo }: LocationMoreInfoExceptionalsProps) => {
  return (
    <IonRow>
      <IonCol>
        <IonLabel className="location-more-info-title">{t("RATES.EXCEPTIONAL_DAYS")}</IonLabel>
        <IonRow className="location-more-info-schedule">
          <IonCol className="regular-hours">
            {locationInfo.exceptional_periods_close.length > 0 && (
              <LocationMoreInfoCloseExceptionals
                locationInfoClosePeriods={locationInfo.exceptional_periods_close}
              ></LocationMoreInfoCloseExceptionals>
            )}
            {locationInfo.exceptional_periods_open.length > 0 && (
              <LocationMoreInfoOpenExceptionals
                locationInfoOpenPeriods={locationInfo.exceptional_periods_open}
              ></LocationMoreInfoOpenExceptionals>
            )}
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};
