import { useIsValidUser } from "@/commons/hooks/useIsValidUser";
import { useParametersContext } from "@/commons/hooks/useParametersContext";
import { useSlack } from "@/commons/hooks/useSlack";
import { useStripeInfo } from "@/commons/hooks/useStripeInfo";
import { SetState } from "@/core/models/globalModel";
import {
  confirmStripeMerchantPaymentIntentService,
  createMerchantPaymentIntentService,
} from "@/core/services/shop/shopService";
import { shopActions } from "@/core/store/slices/shopSlice";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { IonButton, IonFooter, IonToolbar } from "@ionic/react";
import { t } from "i18next";
import { useHistory } from "react-router-dom";

interface ShopCartFooterProps {
  disabled: boolean;
  setShowLoadingData: SetState<boolean>;
}

export const ShopCartFooter = ({ disabled, setShowLoadingData }: ShopCartFooterProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { loadStripeInfo } = useStripeInfo();
  const { isEmpty } = useIsValidUser();
  const slack = useSlack();

  const { parameters } = useParametersContext();
  const { userInfo } = useAppSelector((state) => state.userReducer);
  const { user_id } = useAppSelector((state) => state.userReducer.user);
  const { shop } = useAppSelector((state) => state.shopReducer);
  const userCart = shop[user_id] || [];

  const checkUserInfo = () => {
    if (userInfo?.user_cards.length === 0) {
      dispatch(
        uiActions.setToast({
          show: true,
          message: t("TOAST.MISSING_CARD"),
          color: "primary",
        }),
      );
      history.push(APP_PATH.WALLET);
      return;
    }

    if (
      isEmpty(userInfo?.country) ||
      isEmpty(userInfo?.cp) ||
      isEmpty(userInfo?.poblacio) ||
      isEmpty(userInfo?.ubicacio)
    ) {
      dispatch(
        uiActions.setToast({
          show: true,
          message: t("TOAST.MISSING_ADDRESS"),
          color: "primary",
        }),
      );
      history.push(APP_PATH.PERSONAL_DATA);
      return;
    }
    if (userInfo?.stripe_payment_methods.length === 0) {
      history.push(APP_PATH.WALLET_ADD_CREDITCARD);
      return;
    }

    createMerchantPaymentIntent();
  };

  const createMerchantPaymentIntent = async () => {
    setShowLoadingData(true);
    try {
      const response = await createMerchantPaymentIntentService(
        userCart,
        userInfo?.stripe_payment_methods[0].payment_method,
      );

      if (!response || !response.account || !response.intent) {
        throw new Error(t("APP_ERRORS.CREATE_PAYMENT"));
      }

      const transaction = response.transaction;

      if (parameters.payment_type.includes("stripe") || parameters.payment_type === "both") {
        const { stripe } = await loadStripeInfo();
        const { error } = await stripe.confirmCardPayment(response.intent.client_secret);
        if (error) {
          slack.error("createMerchantPaymentIntent", error);
          throw new Error(t("APP_ERRORS.CONFIRM_PAYMENT"));
        }
        const paymentConfirm = await confirmStripeMerchantPaymentIntentService(response.account.id, transaction?.id);
        if (paymentConfirm.ok) {
          dispatch(
            uiActions.setToast({
              show: true,
              message: t("ALERT.SUCCESS_ADD_WALLET_TITLE"),
              color: "success",
            }),
          );
          dispatch(shopActions.cleanCart({ user_id: user_id }));
          dispatch(uiActions.setIsCartModalOpen(false));
          return;
        }
      }
    } catch (error) {
      console.error(error);
      dispatch(
        uiActions.setToast({
          show: true,
          message: t("ALERT.ERROR_ADD_WALLET_TITLE"),
          color: "danger",
        }),
      );
    } finally {
      setShowLoadingData(false);
    }
  };

  return (
    <>
      <IonFooter className="charge-steps-footer ion-no-border">
        <IonToolbar>
          <div className="charge-steps-footer-buttons">
            <IonButton disabled={disabled} onClick={checkUserInfo}>
              {t("BUTTONS.BUY")}
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};
