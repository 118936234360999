import { GoBackButton } from "@/commons/components/Buttons/GoBackButton/GoBackButton";
import { AppHeader } from "@/commons/components/Headers/AppHeader/AppHeader";
import { WalletHistoryModel } from "@/core/models/PaymentModel";
import { getHistoryWalletService } from "@/core/services/payment/paymentServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { walletActions } from "@/core/store/slices/walletSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { IonContent, IonList, IonModal } from "@ionic/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { HistoryTransaction } from "./HistoryTransaction/HistoryTransaction";
import { HistoryTransactionSkeleton } from "./HistoryTransactionSkeleton/HistoryTransactionSkeleton";

export const WalletHistoryModal = () => {
  const dispatch = useAppDispatch();

  const { showHistory } = useAppSelector((state) => state.walletReducer);

  const [isLoading, setIsLoading] = useState(true);
  const [walletHistory, setWalletHistory] = useState<WalletHistoryModel[]>([]);

  useEffect(() => {
    showHistory && loadData();
  }, [showHistory]);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const newWalletHistory = await getHistoryWalletService();

      walletHistory && setWalletHistory(newWalletHistory);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    dispatch(walletActions.setShowHistory(false));
  };

  return (
    <IonModal isOpen={showHistory}>
      <AppHeader startIcon={<GoBackButton onClick={handleCloseModal} />} title={t("TITLES.HISTORY")} />
      <IonContent>
        {isLoading ? (
          <IonList>
            {new Array(10).fill(1).map((_, index) => (
              <HistoryTransactionSkeleton key={index} />
            ))}
          </IonList>
        ) : (
          <IonList>
            {walletHistory.map((transaction) => (
              <HistoryTransaction key={transaction.created_at} transaction={transaction} />
            ))}
          </IonList>
        )}
      </IonContent>
    </IonModal>
  );
};
