import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import { t } from "i18next";
import { checkmarkCircleOutline, closeCircleOutline, refreshOutline } from "ionicons/icons";
import "./AddCreditCardFeedback.scss";

interface AddCreditCardFeedbackProps {
  isCreditCardAdded?: boolean;
  handleRetry?: () => void;
}

export const AddCreditCardFeedback = ({ isCreditCardAdded = true, handleRetry }: AddCreditCardFeedbackProps) => {
  return (
    <div className="add-credit-card-feedback">
      <IonIcon
        className="icon"
        color={isCreditCardAdded ? "primary" : "danger"}
        icon={isCreditCardAdded ? checkmarkCircleOutline : closeCircleOutline}
      />
      <IonLabel>{t(`CREDIT_CARD.CREDIT_CARD_${isCreditCardAdded ? "ADDED" : "ERROR"}`)}</IonLabel>
      {!isCreditCardAdded && (
        <IonButton onClick={() => handleRetry && handleRetry()}>
          <IonIcon icon={refreshOutline} slot="start" />
          {t("BUTTONS.RETRY")}
        </IonButton>
      )}
    </div>
  );
};
