import keychainIcon from "@/assets/imgs/icons/keychain.svg";
import { Divider } from "@/commons/components/Divider/Divider";
import { IconLabel } from "@/commons/components/IconLabel/IconLabel";
import { StatusLabel } from "@/commons/components/StatusLabel/StatusLabel";
import { useUserContext } from "@/commons/hooks/useUserContext";
import { UserCardModel } from "@/core/models/userModel";
import { activateKeychain, disableKeychain } from "@/core/services/user/userServices";
import { uiActions } from "@/core/store/slices/uiSlice";
import { useAppDispatch } from "@/core/store/store";
import { IonIcon, IonLabel, IonLoading, IonRow, IonToggle } from "@ionic/react";
import { format } from "date-fns";
import { t } from "i18next";
import { calendarClearOutline } from "ionicons/icons";
import { useState } from "react";
import "./KeychainCard.scss";

interface KeyChainCardProps {
  card: UserCardModel;
}

export const KeychainCard = ({ card }: KeyChainCardProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(card.active_card === "true");

  const { loadUserInfo } = useUserContext();

  const handleChangeStatus = async (checked: boolean) => {
    const changeFunction = checked ? activateKeychain : disableKeychain;
    try {
      setIsLoading(true);
      await changeFunction(Number(card.id_card));
      await loadUserInfo();
      setActive(checked);
    } catch (error) {
      dispatch(uiActions.setToastError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IonLoading isOpen={isLoading} />
      <div className="keychain-card">
        <div className="keychain-card-container">
          <IonIcon icon={keychainIcon} className="keychain-card-icon" />
          <div className="keychain-card-info">
            <IonLabel>
              <span className="strong-text">Nº: </span>
              {card.id_card}
            </IonLabel>
            <IonLabel>
              <span className="strong-text">{t("KEYCHAIN.TAG")}:</span>
              {card.visual_number ? ` ${card.visual_number}` : " N/A"}
            </IonLabel>
            <IconLabel icon={calendarClearOutline}>{format(card.created_at, "dd/MM/yyyy")}</IconLabel>

            <IonRow className="status">
              {active ? (
                <StatusLabel active>{t("KEYCHAIN.ACTIVE")}</StatusLabel>
              ) : (
                <StatusLabel>{t("KEYCHAIN.DISABLED")}</StatusLabel>
              )}
            </IonRow>
          </div>
        </div>
        <IonToggle mode="ios" checked={active} onIonChange={(event) => handleChangeStatus(event.target.checked)} />
      </div>

      <Divider />
    </>
  );
};
