import { IonCol, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";

export const FavoriteCardSkeleton = () => {
  return (
    <IonRow
      className={"rowContainerFavCharger extend"}
      style={{ boxShadow: "0px 1px 1px var(--ion-color-focus)", width: "100%" }}
    >
      <IonCol className="chargerInfoColRight">
        <IonLabel className="chargerNameTitle">
          <IonSkeletonText animated={true} style={{ width: "180px" }} />
        </IonLabel>
        <IonLabel className="chargerAddressTitle">
          <IonSkeletonText animated={true} style={{ width: "100px" }} />
        </IonLabel>
        <IonLabel>
          <IonSkeletonText animated={true} style={{ width: "60px" }} />
        </IonLabel>
      </IonCol>
      <IonCol className="chargerInfoColLeft">
        <IonSkeletonText animated={true} style={{ width: "25px", height: "25px" }}></IonSkeletonText>
        <IonLabel className="number distanceLabel">
          <IonSkeletonText animated={true} style={{ width: "25px" }} />
        </IonLabel>
      </IonCol>
    </IonRow>
  );
};
