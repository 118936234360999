import { CHARGER_SOCKET_RUNTIME_STATUS, ChargerSocketModel } from "@/core/models/chargerModel";
import { useAppSelector } from "@/core/store/store";

export const useIsSocketAvailable = () => {
  const { socket_status_available_list } = useAppSelector((state) => state.parametersReducer.parameters);

  const isSocketAvailable = (socket: ChargerSocketModel) => {
    const { runtime_status_id, connector_type_code, charger_graph_id } = socket;

    const isFinishing = runtime_status_id === CHARGER_SOCKET_RUNTIME_STATUS.FINISHING;

    if (connector_type_code === "TYPE-2-F" && isFinishing) {
      return true;
    }

    // Own charge
    if (charger_graph_id && isFinishing) {
      return true;
    }

    return socket_status_available_list.includes(runtime_status_id);
  };

  return { isSocketAvailable };
};
