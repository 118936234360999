import { userActions } from "@/core/store/slices/userSlice";
import { useAppDispatch } from "@/core/store/store";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Geolocation, PermissionStatus } from "@capacitor/geolocation";
import React, { createContext, useEffect, useState } from "react";

interface LocationProviderProps {
  children: React.ReactNode;
}

export interface UserLocation {
  latitude: number | null;
  longitude: number | null;
  heading: number | null;
  isLoading: boolean;
}
interface LocationContextProps {
  userLocation: UserLocation;
  permissionStatus: PermissionStatus;
}

const defaultUserLocation: UserLocation = {
  latitude: null,
  longitude: null,
  heading: 0,
  isLoading: true,
};

const defaultPermissionStatus: PermissionStatus = { location: "denied", coarseLocation: "denied" };

export const LocationContext = createContext<LocationContextProps>({
  userLocation: defaultUserLocation,
  permissionStatus: defaultPermissionStatus,
});

const platform = Capacitor.getPlatform();

export const LocationProvider = ({ children }: LocationProviderProps) => {
  const dispatch = useAppDispatch();

  const [userLocation, setUserLocation] = useState<UserLocation>(defaultUserLocation);
  const [permissionStatus, setPermissionStatus] = useState<PermissionStatus>(defaultPermissionStatus);
  const [firstRequest, setFirstRequest] = useState(true);

  const [watchIds, setWatchIds] = useState<string[]>([]);

  useEffect(() => {
    requestLocationPermission(firstRequest);

    // Check permissions again when the app resumes
    const listenerPromises = App.addListener("resume", () => {
      requestLocationPermission(firstRequest);
    });

    return () => {
      listenerPromises.then((listener) => listener.remove());
    };
  }, [firstRequest]);

  useEffect(() => {
    getCurrentLocation();

    const listenerPromises = App.addListener("resume", getCurrentLocation);

    return () => {
      listenerPromises.then((listener) => listener.remove());
    };
  }, [permissionStatus]);

  useEffect(() => {
    return () => {
      // Clean previous watches of live reload

      watchIds.forEach((watchId) => {
        Geolocation.clearWatch({ id: watchId });
      });
    };
  }, [watchIds]);

  const requestLocationPermission = (firstRequest: boolean) => {
    if (platform === "web") {
      navigator.permissions.query({ name: "geolocation" }).then((permissionResult) => {
        setPermissionStatus({ location: permissionResult.state, coarseLocation: permissionResult.state });
        permissionResult.state !== "granted" && setUserLocation({ ...userLocation, isLoading: false });
      });
    } else {
      Geolocation.checkPermissions()
        .then((permissions: PermissionStatus) => {
          if (permissions.location !== "granted" && firstRequest) {
            setFirstRequest(false);
            return Geolocation.requestPermissions({ permissions: ["location"] });
          } else {
            return permissions;
          }
        })
        .then((permissionResult) => {
          setPermissionStatus(permissionResult);
          permissionResult.location !== "granted" && setUserLocation({ ...userLocation, isLoading: false });
        })
        .catch((error) => {
          console.error("Error al solicitar permisos de ubicación", error);
        });
    }
  };

  const getCurrentLocation = async () => {
    if (permissionStatus.location !== "denied") {
      const newWatchId = await Geolocation.watchPosition({ enableHighAccuracy: true }, (position) => {
        if (position) {
          dispatch(
            userActions.setlocation({ latitude: position.coords.latitude, longitude: position.coords.longitude }),
          );
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            // heading: position.coords.heading,
            heading: 0,
            isLoading: false,
          });
        }
      });

      setWatchIds((prev) => [...prev, newWatchId]);
    }
  };

  return <LocationContext.Provider value={{ userLocation, permissionStatus }}>{children}</LocationContext.Provider>;
};
