import { ParametersModel } from "@/core/models/globalModel";
import { UserInfoModel } from "@/core/models/userModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { uniq } from "lodash";

export interface UserFormSlice {
  defaultRequiredFields: string[];
  parametersRequiredFields: string[];
  requiredFields: string[]; // required fields for updated user
  requiredFieldsForm: string[]; // required fields for form validations
  defaultPublicAdministration: string[];
  defaultBillingUser: string[];
  defaultChargeValues: string[];
}

export const userFormSliceInitialState: UserFormSlice = {
  defaultRequiredFields: [
    "email",
    "password",
    "confirm_password",
    "name",
    "surname1",
    "telf",
    "car_id",
    "policy",
    "terms",
  ],
  parametersRequiredFields: [],
  requiredFields: [],
  requiredFieldsForm: [],
  defaultPublicAdministration: ["dir3"],
  defaultBillingUser: [
    "billing_user.fiscal_name",
    "billing_user.nif",
    "billing_user.city",
    "billing_user.fiscal_address",
    "billing_user.cp",
    "billing_user.billing_type",
  ],
  defaultChargeValues: [
    "default_charge_values.default_payment_method",
    "default_charge_values.default_credit_card",
    "default_charge_values.default_wh",
    "vehicle_id",
    "app_connector_default",
  ],
};

export const userFormSlice = createSlice({
  name: "userFormReducer",
  initialState: userFormSliceInitialState,
  reducers: {
    setParametersRequiredFields: (state, { payload }: PayloadAction<ParametersModel>) => {
      let newParametersRequiredFields = payload.required_fields ? payload.required_fields.split(",") : [];

      if (payload.payment_type.includes("sisp")) {
        newParametersRequiredFields = [
          ...newParametersRequiredFields,
          "dni_nif",
          "country",
          "ubicacio",
          "poblacio",
          "cp",
        ];
      }

      if (newParametersRequiredFields.includes("dni_nif")) {
        newParametersRequiredFields = [...newParametersRequiredFields, "document_type", "document_country"];
      }

      state.parametersRequiredFields = uniq(newParametersRequiredFields);
    },
    initRequiredFields: (state, { payload }: PayloadAction<UserInfoModel | null>) => {
      let newRequiredFields = [...state.defaultRequiredFields, ...state.parametersRequiredFields];

      if (payload) {
        if (payload.is_public_administration) {
          newRequiredFields.push(...state.defaultPublicAdministration);
        }

        if (payload.billing_user) {
          newRequiredFields.push(...state.defaultBillingUser);
        }

        if (payload.default_charge_values.active_automatic_charge) {
          newRequiredFields.push(...state.defaultChargeValues);

          const paymentMethod = payload.default_charge_values.default_payment_method;

          if (!paymentMethod || paymentMethod === "wallet") {
            newRequiredFields = newRequiredFields.filter(
              (field) => field !== "default_charge_values.default_credit_card",
            );
          }
        }
      }

      state.requiredFields = uniq(newRequiredFields);
      state.requiredFieldsForm = uniq(newRequiredFields);
    },
    setRequiredFieldsForm: (state, { payload }: PayloadAction<{ name: string; value: boolean }>) => {
      const { name, value } = payload;
      if (name === "is_public_administration") {
        const newRequiredFields = value
          ? uniq([...state.requiredFieldsForm, ...state.defaultPublicAdministration])
          : state.requiredFieldsForm.filter((field) => !state.defaultPublicAdministration.includes(field));
        state.requiredFieldsForm = newRequiredFields;
      }

      if (name === "isBillingUser") {
        const newRequiredFields = value
          ? uniq([...state.requiredFieldsForm, ...state.defaultBillingUser])
          : state.requiredFieldsForm.filter((field) => !state.defaultBillingUser.includes(field));
        state.requiredFieldsForm = newRequiredFields;
      }

      if (name === "default_charge_values.active_automatic_charge") {
        const newRequiredFields = value
          ? uniq([...state.requiredFieldsForm, ...state.defaultChargeValues])
          : state.requiredFieldsForm.filter((field) => !state.defaultChargeValues.includes(field));
        state.requiredFieldsForm = newRequiredFields;
      }

      if (name === "default_charge_values.default_payment_method") {
        const newRequiredFields = value
          ? uniq([...state.requiredFieldsForm, "default_charge_values.default_credit_card"])
          : state.requiredFieldsForm.filter((field) => field !== "default_charge_values.default_credit_card");
        state.requiredFieldsForm = newRequiredFields;
      }
    },
  },
});

export const userFormActions = userFormSlice.actions;

export default userFormSlice.reducer;
